import React, { useContext, useState, useEffect } from "react";
import {Row, Col, Form, Input, Button, notification, Typography} from "antd";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import MeQueries from "@dwellpass-client/common/utils/constants/queries/MeQueries";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import ProfileImageControl from "./user_profile/ProfileImageControl";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import LoadingContent from "../utility/LoadingContent";
import PasswordChangeModal from "./user_profile/PasswordChangeModal";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Title } = Typography;

const UserProfile = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [editingState, setEditingState] = useState(false);
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

    const [getMe, { loading: loadingMe }] = useLazyQuery(gql(MeQueries.getMe), { onCompleted: (meData => { setUserState(meData.me) }) });
    const [updateMe, { loading: loadingUpdateMe }] = useMutation(gql(MeQueries.updateMe), { onCompleted: (meData => { setUserState(meData.updateMe) }) });

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            firstName: userState.firstName,
            lastName: userState.lastName
        });
    }, [userState]);

    const handleFormSubmit = async (values) => {
        try {
            await updateMe({variables: { firstName: values.firstName, lastName: values.lastName }});

            setEditingState(false);
        }
        catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    const cancelEditing = () => {
        setEditingState(false);
        getMe();
    }

    const hidePasswordChangeModal = () => {
        setShowPasswordChangeModal(false);
    }

    return(
        <>
            {loadingMe ?
                <LoadingContent/>
                :
                <>
                    <Row style={{ marginTop: 25, marginBottom: 15 }} justify="center">
                        <Col>
                            <ProfileImageControl uploadSuccess={getMe}/>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={editingState && 0}>
                            <Title level={2}>{userState.fullName}</Title>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={editingState ? 8 : 0}>
                            <Form
                                form={form}
                                labelCol={{span: 16}}
                                wrapperCol={{span: 24}}
                                layout={"vertical"}
                                onFinish={handleFormSubmit}
                                requiredMark={false}
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="firstName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your first name',
                                                }
                                            ]}
                                        >
                                            <Input
                                                bordered={editingState}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="lastName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your last name',
                                                }
                                            ]}
                                        >
                                            <Input
                                                bordered={editingState}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Row gutter={10} justify="center">
                                            <Col>
                                                <Form.Item>
                                                    <Button type="primary"
                                                            htmlType="submit" loading={loadingUpdateMe}>
                                                        Save Changes
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <Button onClick={cancelEditing}>
                                                        Cancel
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={editingState ? 0 : 24}>
                            <Row gutter={10} justify="center">
                                <Col>
                                    <Button type="primary" onClick={() => setEditingState(true)}>Edit</Button>
                                </Col>
                                <Col>
                                    <Button onClick={() => setShowPasswordChangeModal(true)}>Change Password</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, color: Colors.monoMedLightGrey }} gutter={16} justify="center">
                        <Col>
                            Email: {userState.email}
                        </Col>
                        <Col>
                            User Since: {DateTimeFormatter.simpleDateOnly(userState.createdAt)}
                        </Col>
                    </Row>
                    <PasswordChangeModal visible={showPasswordChangeModal} hideMe={hidePasswordChangeModal} />
                </>
            }
        </>
    )
}

export default UserProfile;