import React from 'react';
import {Modal, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import UnitFeeQueries from "@dwellpass-client/common/utils/constants/queries/UnitFeeQueries";

const DeleteFeeModal = (props) => {
    const [createUnitFee, { loading: loadingCreateUnitFee }] = useMutation(gql(UnitFeeQueries.createUnitFee));
    const [destroyUnitFee, { loading: loadingDestroyUnitFee }] = useMutation(gql(UnitFeeQueries.destroyUnitFee));

    const modalBody = () => {
        switch(props.action) {
            case "create":
                return `After adding this Fee, the billing setup will change for this Unit. Changes will be reflected in the next billing cycle.`
            case "destroy":
                return `After removing this Fee, the billing setup will change for this Unit. Changes will be reflected in the next billing cycle.`
            default:
                return "-";
        }
    }

    const successMessage = () => {
        switch(props.action) {
            case "create":
                return "Fee Added";
            case "destroy":
                return "Fee Removed";
            default:
                return "-";
        }
    }

    const errorMessage = () => {
        switch(props.action) {
            case "create":
                return "Error Adding Fee";
            case "destroy":
                return "Error Removing Fee";
            default:
                return "-";
        }
    }

    const okButtonText = () => {
        switch(props.action) {
            case "create":
                return "Add";
            case "destroy":
                return "Remove";
            default:
                return "-";
        }
    }

    const successDescription = () => {
        switch(props.action) {
            case "create":
                return `Successfully added Fee: ${props.selectedFeeState.name}.`;
            case "destroy":
                return `Successfully removed Fee: ${props.selectedFeeState.name}.`;
            default:
                return "-";
        }
    }

    const errorDescription = () => {
        switch(props.action) {
            case "create":
                return `There was an issue adding Fee: ${props.selectedFeeState.name}. Please verify you haven't exceeded the fee quantity limit and try again.`;
            case "destroy":
                return `There was an issue removing Fee: ${props.selectedFeeState.name}. Please try again.`;
            default:
                return "-";
        }
    }

    const handleFeeChange = async () => {
        try {
            switch(props.action) {
                case "create":
                    await createUnitFee({ variables: { unitId: props.unitState.id, feeId: props.selectedFeeState.id } });
                    break;
                case "destroy":
                    await destroyUnitFee({ variables: { id: props.selectedUnitFeeState.id } });
                    break;
                default:
                    break;
            }
            popFeeChangeSuccessNotification();
            props.changeSuccess();
        } catch (error) {
            popFeeChangeErrorNotification();
            props.hideMe();
        }
    }

    const popFeeChangeSuccessNotification = () => {
        notification["success"]({
            message: successMessage(),
            duration: 10,
            description: successDescription(),
        });
    };

    const popFeeChangeErrorNotification = () => {
        notification["error"]({
            message: errorMessage(),
            duration: 10,
            description: errorDescription(),
        });
    };

    return (
        <Modal
            title="Are You Sure?"
            visible={props.visible}
            onOk={() => handleFeeChange()}
            confirmLoading={(loadingCreateUnitFee || loadingDestroyUnitFee || props.loadingPostChange)}
            onCancel={() => props.hideMe()}
            okText={okButtonText()}
            cancelText="Cancel"
        >
            <p>{modalBody()}</p>
        </Modal>
    );
}

export default DeleteFeeModal;