import React, { useState, useContext, useEffect } from "react";
import {Button, Col, Divider, Form, Input, Row, Select, Typography, Modal, DatePicker, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {DeleteOutlined, InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import PrimaryInput from "../../shared/PrimaryInput";
import moment from "moment";

const { Option } = Select;
const { Text } = Typography;

const UnitForm = (props) => {
    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [communityState, ] = useContext(CommunityContext);
    const [deletingUnit, setDeletingUnit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [form] = Form.useForm();

    const [createUnit, ] = useMutation(gql(UnitQueries.createUnit));
    const [updateUnit, ] = useMutation(gql(UnitQueries.updateUnit));
    const [destroyUnit, ] = useMutation(gql(UnitQueries.destroyUnit));

    useEffect(() => {
        form.setFieldsValue({
            internalIdentifier: props.selectedUnitState.internalIdentifier,
            tagList: props.selectedUnitState.tagList,
            addressLine1: props.selectedUnitState.address ? props.selectedUnitState.address.street1 : null,
            addressLine2: props.selectedUnitState.address ? props.selectedUnitState.address.street2 : null,
            addressCity: props.selectedUnitState.address ? props.selectedUnitState.address.city : null,
            addressState: props.selectedUnitState.address ? props.selectedUnitState.address.state : null,
            addressZip: props.selectedUnitState.address ? props.selectedUnitState.address.zip : null
        })
        setFormState({isSubmitting: false, errorMessage: null});
    }, [props.selectedUnitState]);

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        },500);
    };

    const promptForDeletion = () => {
        setShowDeleteModal(true);
    }

    const handleUnitDestroy = async () => {
        setShowDeleteModal(false);
        setDeletingUnit(true);
        try{
            await destroyUnit({ variables: { id: props.selectedUnitState.id } });
            setDeletingUnit(false);
            resetForm();
            props.formSuccess();
        } catch (e) {
            notification["error"]({
                message: "Error Deleting Unit",
                description:
                    "Please make sure there are no residents in the unit and try again.",
            });
            setDeletingUnit(false);
        }
    }

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            if(props.selectedUnitState.id) {
                await updateUnit({variables: { id: props.selectedUnitState.id, internalIdentifier: values.internalIdentifier, communityId: communityState.id, addressStreet1: values.addressLine1, addressStreet2: values.addressLine2, addressCity: values.addressCity, addressState: values.addressState, addressZip: values.addressZip, tagList: values.tagList }});
            } else {
                await createUnit({variables: { internalIdentifier: values.internalIdentifier, communityId: communityState.id, addressStreet1: values.addressLine1, addressStreet2: values.addressLine2, addressCity: values.addressCity, addressState: values.addressState, addressZip: values.addressZip, tagList: values.tagList }});
            }
            setFormState({isSubmitting: false, errorMessage: null});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    return(
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Divider>General</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="internalIdentifier"
                            label="Internal Identifier"
                            rules={[{ required: true, message: 'Please enter the internal identifier' }]}
                        >
                            <PrimaryInput placeholder="Lot number, unit name, etc" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="tagList"
                            label="Tags"
                        >
                            <Select mode="tags" placeholder="Enter or select a tag" notFoundContent="" >
                                {props.uniqueUnitTags.map((tag) =>
                                    <Option key={tag}>{tag}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Physical Address</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="addressLine1"
                            label="Address Line 1"
                            rules={[{ required: true, message: 'Please enter line 1 of your address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="addressLine2"
                            label="Address Line 2"
                            rules={[{ required: false, message: 'Please enter line 2 of your address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="addressCity"
                            label="City"
                            rules={[{ required: true, message: 'Please enter the city' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="addressState"
                            label="State"
                            rules={[{ required: true, message: 'Please enter the state' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="addressZip"
                            label="Zip Code"
                            rules={[{ required: true, message: 'Please enter the zip code' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100 }} type="primary" htmlType="submit" loading={formState.isSubmitting}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                {props.selectedUnitState.id &&
                    <Row justify="center">
                        <Col>
                            <Button type="link" onClick={() => promptForDeletion(props.selectedUnitState)}>
                                {
                                    deletingUnit ?
                                        <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                        :
                                        <DeleteOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                }
            </Form>
            <Modal
                title="Are You Sure?"
                visible={showDeleteModal}
                onOk={() => handleUnitDestroy()}
                onCancel={() => setShowDeleteModal(false)}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>This Unit will be removed along with any Residents, Resources, and Posts currently attached to it. Please confirm you want to delete it.</p>
            </Modal>
        </>
    )
}

export default UnitForm;