import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import {Button, Col, Empty, Row, Space, Table, Typography, Tooltip, Tag} from "antd";
import {CheckCircleOutlined, EditOutlined, LoadingOutlined, PlusOutlined, ReloadOutlined, AppstoreAddOutlined, MinusOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import ConfirmAddFeeToAllUnitsModal from "./ConfirmAddFeeToAllUnitsModal";

const { Text } = Typography;

const FeeTable = (props) => {
    const feePageSize = 30;

    const [communityState, ] = useContext(CommunityContext);
    const [totalFeeCount, setTotalFeeCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [feesState, setFeesState] = useState([]);
    const [showAddFeeToAllUnitsModal, setShowAddFeeToAllUnitsModal] = useState(false);
    const [selectedFeeState, setSelectedFeeState] = useState({});

    const { data: feesData, loading: loadingFees, refetch: refetchFees } = useQuery(gql(FeeQueries.getFees),{ notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, first: feePageSize, after: pageOffsetState } });

    useEffect(() => {
        feesData && setFeesState(feesData.fees);
        feesData && setTotalFeeCount(feesData.fees.totalCount);
    }, [feesData]);

    useEffect(() => {
        refetchFees();
    }, [pageOffsetState, props.refreshFeesTable]);

    const refetchAllData = () => {
        refetchFees();
    }

    const promptForAddFeeToAllUnits = (fee) => {
        setSelectedFeeState(fee);
        setShowAddFeeToAllUnitsModal(true);
    }

    const hideAddFeeToAllUnitsModal = () => {
        setShowAddFeeToAllUnitsModal(false);
        setTimeout(() => {
            setSelectedFeeState({});
        }, 500);
    }

    const addFeeToAllUnitsSuccess = async () => {
        hideAddFeeToAllUnitsModal();
        await refetchAllData();
    }

    const nameText = (node) => {
        return node.unitDefault ?
            <Text>
                {node.name}
                <Tag color={Colors.brandPrimary} style={{ marginLeft: 10 }}>Default</Tag>
            </Text>
            :
            node.name;
    }

    const classificationText = (classification) => {
        switch(classification) {
            case "hoa_dues":
                return "HOA Dues";
            case "membership":
                return "Membership";
            case "utility":
                return "Utility";
            case "miscellaneous":
                return "Miscellaneous";
            default:
                return "-";
        }
    }

    const recurringText = (recurring) => {
        return recurring ? StringFormatter.capitalizeFirstLetter(recurring) : <Text disabled>-</Text>
    }

    const changeFeeTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const feesTableColumns = [
        {
            title: 'Name',
            key: 'name',
            render: (text, record) => (
                nameText(record.node)
            ),
        },
        {
            title: 'Classification',
            key: 'classification',
            render: (text, record) => (
                classificationText(record.node.classification)
            ),
        },
        {
            title: 'Unit Count',
            key: 'unitFeeCount',
            render: (text, record) => (
                record.node.recurring
                    ? record.node.unitFeeCount
                    : <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            )
        },
        {
            title: 'Recurring',
            key: 'recurring',
            render: (text, record) => (
                record.node.recurring
                    ? recurringText(record.node.recurring)
                    : <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            )
        },
        {
            title: 'Price',
            key: 'priceCents',
            render: (text, record) => (
                MoneyFormatter.standardMoneyUSD(record.node.priceCents)
            ),
        },
        {
            title: 'Payout Account',
            key: 'payoutAccount',
            render: (text, record) => (
                `${record.node.payoutAccount.name}${record.node.payoutAccount.externalAccountLast4 && " ("+record.node.payoutAccount.externalAccountLast4+")"}`
            ),
        },
        {
            title: 'Created',
            key: 'createdAt',
            render: (text, record) => (
                DateTimeFormatter.simpleDateOnly(record.node.createdAt)
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {record.node.recurring ?
                        record.node.existsOnAllUnits ?
                            <Tooltip title="Exists on all Units">
                                <Button type="link"><CheckCircleOutlined style={{fontSize: 20}}/></Button>
                            </Tooltip>
                            :
                            <Tooltip title="Add to all Units">
                                <Button type="link" onClick={(e) => {
                                    e.stopPropagation();
                                    promptForAddFeeToAllUnits(record.node)
                                }}><AppstoreAddOutlined style={{fontSize: 20}}/></Button>
                            </Tooltip>
                        : undefined
                    }
                    <Button type="link" onClick={(e) => {
                        e.stopPropagation();
                        props.editFee(record.node)
                    }}><EditOutlined style={{fontSize: 20}}/></Button>
                </Space>
            ),
        }
    ];

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => props.addFee()}><PlusOutlined />Add Fee</Button>
                </Col>
                <Col>
                    <Row gutter={10}>
                        <Col>
                            <Button type="link" onClick={() => refetchAllData()}>
                                {loadingFees ?
                                    <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    :
                                    <ReloadOutlined style={{fontSize: 20}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center">
                <Table
                    style={{ width: "100%" }}
                    size="small"
                    dataSource={feesState ? feesState.edges : null}
                    loading={{spinning: loadingFees, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                    columns={feesTableColumns}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Fees" /> }}
                    rowKey={record => record.node.id}
                    pagination={{
                        pageSize: feePageSize,
                        position: ["none", "bottomCenter"],
                        total: totalFeeCount,
                        onChange: (page, pageSize) => { changeFeeTablePage(page, pageSize) }
                    }}
                />
            </Row>
            <ConfirmAddFeeToAllUnitsModal
                visible={showAddFeeToAllUnitsModal}
                hideMe={hideAddFeeToAllUnitsModal}
                addSuccess={addFeeToAllUnitsSuccess}
                selectedFeeState={selectedFeeState}
            />
        </>
    )
}

export default FeeTable;