import React from "react";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Col, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import logoImage from "../../assets/images/dwellPass-logo-white-800w.png";

const LoadingFullscreen = () => {
    return(
        <Row style={{ height: "100vh", backgroundColor: Colors.brandPrimary }} justify="center" align="middle">
            <Col span={24}>
                <div style={styles.logoContainer}></div>
                <Row justify="center">
                    <LoadingOutlined style={{ color: Colors.monoBright, fontSize: 25 }} />
                </Row>
            </Col>
        </Row>
    )
}

const styles = {
    logoContainer: {
        height: '80px',
        margin: '30px 0px',
        background: `url(${logoImage}) no-repeat center`,
        backgroundSize: 'auto 80px'
    }
}

export default LoadingFullscreen;