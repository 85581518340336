import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import AsyncStorage from "@react-native-async-storage/async-storage";

const initialState = {
    isAuthenticated: false,
    uid: null,
    client: null,
    accessToken: null
};

const initialContext = [{ ...initialState }, () => {}];

export const AuthContext = createContext(initialContext);

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            AsyncStorage.setItem("uid", action.payload.uid);
            AsyncStorage.setItem("client", action.payload.client);
            AsyncStorage.setItem("access-token", action.payload.accessToken);
            return {
                ...state,
                isAuthenticated: true,
                uid: action.payload.uid,
                client: action.payload.client,
                accessToken: action.payload.accessToken
            };
        case "LOGOUT":
            AsyncStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                uid: null,
                client: null,
                accessToken: null
            };
        default:
            return state;
    }
};

export function AuthController(props) {
    const [authState, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [authState, dispatch], [authState]);

    return (<AuthContext.Provider value={value}>
        {props.children}
        </AuthContext.Provider>);
};

AuthController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};