import React, { useContext, useEffect, useState } from "react";
import {Button, Col, Empty, Row, Select, Switch, Table, Tooltip, Input} from "antd";
import {
    LoadingOutlined,
    ReloadOutlined,
    EyeOutlined,
    UserOutlined,
    LockOutlined,
    HomeOutlined,
    ApartmentOutlined,
    PlusOutlined
} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {gql, useQuery} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import ResourceQueries from "@dwellpass-client/common/utils/constants/queries/ResourceQueries";
import FileUploader from "./FileUploader";

const { Search } = Input;

const MainResourcesTable = (props) => {
    const resourcePageSize = 30;

    const [communityState, _] = useContext(CommunityContext);
    const [resourcesState, setResourcesState] = useState([]);
    const [totalResourceCount, setTotalResourceCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [privateOnlyState, setPrivateOnlyState] = useState();
    const [communityOnlyState, setCommunityOnlyState] = useState(false);
    const [unitFilterState, setUnitFilterState] = useState(null);
    const [searchTextState, setSearchTextState] = useState("");

    const { data: resourceData, refetch: refetchResources, loading: loadingResources } = useQuery(gql(ResourceQueries.getResources), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, residentViewable: privateOnlyState, searchTerm: searchTextState, first: resourcePageSize, after: pageOffsetState, resourceableId: unitFilterState, community: communityOnlyState } } );

    useEffect(() => {
        if(resourceData) {
            setResourcesState(resourceData.resources);
            setTotalResourceCount(resourceData.resources.totalCount);
        }
    }, [resourceData]);

    useEffect(() => {
        refetchResources();
    }, [pageOffsetState, privateOnlyState, props.refreshMainResourcesTable, unitFilterState, searchTextState]);

    const changeResourceTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const filterByUnit = (unitId) => {
        setCommunityOnlyState(false);
        setUnitFilterState(unitId);
    }

    const clearFilteredUnit = () => {
        setUnitFilterState(null);
    }

    const filterCommunityOnly = (checked) => {
        setCommunityOnlyState(checked);
        checked && clearFilteredUnit();
    }

    const columns = [
        {
            title: 'Visible',
            key: 'residentViewable',
            dataIndex: ['node', 'residentViewable'],
            width: 80,
            render: (text, record) => (
                record.node.residentViewable ? <UserOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/> : <LockOutlined style={{fontSize: 20, color: Colors.monoMedLightGrey}}/>
            ),
        },
        {
            title: 'File',
            key: 'name',
            dataIndex: ['node', 'name'],
            render: (text, record) => (
                <Row wrap={false}>
                    <span style={{ whiteSpace: "nowrap" }}>{record.node.name ? record.node.name : record.node.resourceFileName}</span>
                    {record.node.resourceFileUrl ?
                        record.node.description &&
                            <span style={{color: Colors.monoMedLightGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>&nbsp; - {record.node.description}</span>
                        :
                        <span style={{color: Colors.monoMedLightGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>&nbsp; - Select to View</span>
                    }
                </Row>
            ),
        },
        {
            title: 'Assigned',
            key: 'resourceable',
            dataIndex: ['node', 'resourceable'],
            width: 200,
            render: (text, record) => (
                record.node.resourceable ?
                    <Row wrap={false} align="middle">
                        <Col>
                            <HomeOutlined style={{marginRight: 10, fontSize: 20, color: Colors.brandPrimary}}/>
                        </Col>
                        <Col>
                            <span style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{record.node.resourceable.internalIdentifier}</span>
                        </Col>
                    </Row>
                    :
                    <Row wrap={false} align="middle">
                        <Col>
                            <ApartmentOutlined style={{marginRight: 10, fontSize: 20, color: Colors.brandPrimary}}/>
                        </Col>
                        <Col>
                            <span style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Community</span>
                        </Col>
                    </Row>
            ),
        },
        {
            title: 'View',
            key: 'viewFile',
            width: 80,
            render: (text, record) => (
                record.node.resourceFileUrl ?
                    <a href={record.node.resourceFileUrl} onClick={(e) => {e.stopPropagation();}} target="_blank"><EyeOutlined style={{fontSize: 22}} /></a>
                :
                    <Tooltip title={record.node.description}>
                        <EyeOutlined style={{ marginTop: 4, fontSize: 22, color: Colors.brandPrimary }} />
                    </Tooltip>
            ),
        },
        {
            title: 'Uploaded',
            key: 'created',
            dataIndex: ['node', 'createdAt'],
            width: 200,
            align: "right",
            render: (text, record) => (
                DateTimeFormatter.standardDateTime(record.node.createdAt)
            ),
        }
    ];

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Row gutter={10}>
                        <Col>
                            <Button type="primary" onClick={() => props.startCreatingResource()}><PlusOutlined />New Resource</Button>
                        </Col>
                        <Col>
                            <FileUploader uploadSuccessResource={props.uploadSuccessResource} />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row align="middle" gutter={10}>
                        <Col>
                            <Search style={{ width: 200 }} placeholder="Search text..." allowClear onSearch={(term) => setSearchTextState(term)} />
                        </Col>
                        <Col>
                            <Switch checkedChildren="Community" unCheckedChildren="Community" onChange={(checked) => { filterCommunityOnly(checked) }} checked={communityOnlyState} />
                        </Col>
                        <Col>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Filter by Unit"
                                allowClear
                                showSearch
                                onSelect={(value) => filterByUnit(value)}
                                onClear={() => clearFilteredUnit()}
                                value={unitFilterState}
                                loading={props.loadingUnits}
                                options={props.unitsOptions}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Col>
                        <Col>
                            <Switch checkedChildren="Private" unCheckedChildren="Private" onChange={(checked) => { setPrivateOnlyState(checked ? !checked : undefined) }} />
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => refetchResources()}>
                                {loadingResources ?
                                    <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    :
                                    <ReloadOutlined style={{fontSize: 20}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={resourcesState ? resourcesState.edges : null}
                        loading={{spinning: loadingResources, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                        rowKey={record => record.node.id}
                        pagination={{
                            pageSize: resourcePageSize,
                            position: ["none", "bottomCenter"],
                            total: totalResourceCount,
                            onChange: (page, pageSize) => { changeResourceTablePage(page, pageSize) }
                        }}
                        scroll={{ y: "58vh" }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Resources Found" /> }}
                        onRow={(record) => {
                            return {
                                onClick: event => { props.selectResource(record) }
                            };
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default MainResourcesTable;