import React, { useState, useContext } from "react";
import {Row, Col, Modal, Typography, List, Space, notification} from "antd";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import SubscriptionQueries from "@dwellpass-client/common/utils/constants/queries/SubscriptionQueries";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const { Text } = Typography;

const FeeActivationModal = (props) => {
    const [unitState, updateUnitState] = useContext(UnitContext);
    const [activationErrors, setActivationErrors] = useState([]);

    const [createSubscriptions, { loading: loadingCreateSubscription }] = useMutation(gql(SubscriptionQueries.createSubscriptions));
    const [loadUnit, { loading: loadingUnit }] = useLazyQuery(gql(UnitQueries.getUnitWithCommunity), {fetchPolicy: "network-only", onCompleted: (unitData) => {updateUnitState({type: "set", payload: unitData.unit})}});

    const feeModalTitle = () => {
        switch (props.type) {
            case "monthly":
                return "Monthly Fees";
            case "quarterly":
                return "Quarterly Fees";
            default:
                return "Fees";
        }
    }

    const billingActivatedTitle = () => {
        switch (props.type) {
            case "monthly":
                return "Monthly Billing Activated";
            case "quarterly":
                return "Quarterly Billing Activated";
            default:
                return "Billing Activated";
        }
    }

    const serviceFeeAmounts = {
        "monthly": {
            "card": unitState.estimatedMonthlyCardServiceFeeAmountCents,
            "bank-account": unitState.estimatedMonthlyBankAccountServiceFeeAmountCents,
        },
        "quarterly": {
            "card": unitState.estimatedQuarterlyCardServiceFeeAmountCents,
            "bank-account": unitState.estimatedQuarterlyBankAccountServiceFeeAmountCents,
        }
    };

    const estimatedServiceFeeAmountCents = (frequency) => {
        let type;

        switch (props.derivedPaymentMethodState) {
            case "defaultPaymentMethod":
                type = props.defaultPaymentMethod?.type === "card" ? "card" : "bank-account";
                break;
            case "unverifiedSource":
            case "defaultSource":
                type = "bank-account";
                break;
            default:
                type = "";
        }

        return serviceFeeAmounts[frequency]?.[type] || 0;
    }

    const activateSubscription = async () => {
        try {
            await createSubscriptions({ variables: { interval: props.type, unitResidentId: props.currentUserUnitResident.id } });
            await loadUnit({ variables: { id: unitState.id } });
            props.success();

            notification['success']({
                message: billingActivatedTitle(props.type),
                description: 'Keep an eye out for invoices via email going forward and return here to pay in a click, or enable Autopay for added convenience.',
                duration: 15
            })
        }
        catch (error) {
            console.log(error.graphQLErrors ? error.graphQLErrors : error);
            setActivationErrors([{message: "There was an issue setting up billing. Please try again."}]);
        }
    }

    return(
        <Modal
            title={!props.subscription ? "Complete Billing Setup" : feeModalTitle()}
            visible={props.visible}
            onOk={!props.subscription ? activateSubscription : props.hideMe}
            confirmLoading={(loadingCreateSubscription || loadingUnit)}
            onCancel={() => props.hideMe()}
            okText={!props.subscription ? "Complete Setup" : "Ok"}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                {activationErrors && (
                    activationErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <List
                    size="small"
                    bordered
                    dataSource={props.fees}
                    renderItem={fee =>
                        <List.Item>
                            <Row style={{ width: "100%" }} justify="space-between" align="middle">
                                <Col>
                                    <Text style={{ color: Colors.brandPrimary }} strong>{fee.name}
                                    </Text>
                                </Col>
                                <Col>
                                    <Text strong>{MoneyFormatter.standardMoneyUSD(fee.priceCents)}</Text>
                                </Col>
                            </Row>
                        </List.Item>
                    }
                />
                {(!props.subscription || props.subscription.autopayAt) &&
                    <Row style={{width: "100%", padding: "5px 17px"}} justify="space-between" align="middle">
                        <Col>
                            <Text strong style={{color: Colors.monoMedGrey}}>Payment Processing Fee</Text>
                        </Col>
                        <Col>
                            <Text strong style={{color: Colors.monoMedGrey}}>{MoneyFormatter.standardMoneyUSD(estimatedServiceFeeAmountCents(props.type))}</Text>
                        </Col>
                    </Row>
                }
                <Row style={{width: "100%", padding: "5px 17px"}}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Text disabled>Some fees may be invoiced separately.</Text>
                        {!props.subscription &&
                            <Text style={{ marginTop: 10 }}>After completing setup, you'll receive invoices {props.type}, for which you can submit payment through this resident portal. If you recently moved in, you may receive an initial prorated invoice.</Text>
                        }
                        {(!props.subscription || props.subscription.autopayAt) &&
                            <Text>The Payment Processing Fee will be debited from your account separately for each invoice upon successful payment. <Text strong>This fee covers the costs associated with the processing of these transactions, and is not charged by your Community.</Text></Text>
                        }
                    </Space>
                </Row>
            </Space>
        </Modal>
    )
}

export default FeeActivationModal;