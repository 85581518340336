import React from "react";
import {Avatar, Divider, Empty, List, Row, Tooltip, Col, Typography} from "antd";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {ApartmentOutlined, HomeOutlined, UserOutlined, PlusOutlined, PushpinOutlined, MessageOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import PostReplyForm from "./PostReplyForm";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;

const PostDetail = (props) => {
    const receivableTypeIcon = (post) => {
        switch (true) {
            case post.receivable && post.receivable.__typename == "UnitResident":
                return (
                    <Tooltip title="Resident Post">
                        <UserOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case post.receivable && post.receivable.__typename == "Unit":
                return (
                    <Tooltip title="Unit Post">
                        <HomeOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case post.receivable && post.receivable.__typename == "Community":
                return (
                    <Tooltip title="Community Manager Post">
                        <MessageOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip title="Community Post">
                        <ApartmentOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
        }
    }

    const receiverFromPost = (post) => {
        switch (true) {
            case post.receivable && post.receivable.__typename == "UnitResident":
                return `Resident: ${post.receivable.resident.firstName} ${post.receivable.resident.lastName} (Unit: ${post.receivable.unit.internalIdentifier})`;
            case post.receivable && post.receivable.__typename == "Unit":
                return `Unit: ${post.receivable.internalIdentifier}`;
            case post.receivable && post.receivable.__typename == "Community":
                return `Community Managers`;
            default:
                return "Community";
        }
    }

    const senderFromPost = (post) => {
        let posterName = post.postable && post.postable.__typename ?
            post.postable.__typename == "CommunityManager" ?
                `${post.postable.manager.firstName} ${post.postable.manager.lastName} (Community Manager)`
                : `${post.postable.resident.firstName} ${post.postable.resident.lastName} (Unit: ${post.postable.unit.internalIdentifier})`
            : null;
        return post.hidePoster ?
            <><span>Community </span><span
                style={{color: Colors.monoMedLightGrey, fontStyle: "italic"}}>(Created by: {posterName})</span></>
            :
            posterName;
    }

    const postReplyCreationSuccess = async () => {
        await props.refetchPost();
    }

    const replyListMetaByReplyableType = (reply) => {
        if (reply.replyable.__typename == "CommunityManager") {
            return <List.Item.Meta
                avatar={<Avatar icon={!reply.hidePoster ? <UserOutlined/> : <MessageOutlined style={{color: Colors.brandPrimary}} />} src={!reply.hidePoster && reply?.replyable?.manager?.profileImageUrl}/>}
                title={reply.hidePoster ?
                    <><span>Community Manager <span style={{color: Colors.monoMedLightGrey, fontStyle: "italic"}}>({`${reply?.replyable?.manager?.firstName} ${reply?.replyable?.manager?.lastName}`})</span></span></>
                    : `${reply?.replyable?.manager?.firstName} ${reply?.replyable?.manager?.lastName}`
                    + ` - ${DateTimeFormatter.standardDateTime(reply.createdAt)}`
            }
                description={reply.body}
            />
        } else {
            return <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined/>}
                                src={reply?.replyable?.resident?.profileImageUrl}/>}
                title={`${reply?.replyable?.resident?.firstName} ${reply?.replyable?.resident?.lastName} (Resident) - ${DateTimeFormatter.standardDateTime(reply.createdAt)}`}
                description={reply.body}
            />
        }
    }

    const isPinExpired = (timestamp) => {
        return (timestamp && moment(timestamp).isBefore(moment()))
    }

    return (
        <>
            <Row gutter={10} justify="space-between">
                <Col>
                    <Row>
                        <Text
                            strong>To: {receivableTypeIcon(props.selectedPostState)} {receiverFromPost(props.selectedPostState)}</Text>
                    </Row>
                    <Row>
                        <Text
                            strong>From: {senderFromPost(props.selectedPostState)} {props.selectedPostState.type == "IncomingPost" && "(Resident)"}</Text>
                    </Row>
                </Col>
                <Col>
                    <Row align="middle">
                        <PlusOutlined style={{color: Colors.monoMedLightGrey, fontSize: 18, marginRight: 5}}/><span
                        style={{color: Colors.monoMedLightGrey}}>{DateTimeFormatter.standardDateTime(props.selectedPostState.createdAt)}</span>
                    </Row>
                    {props.selectedPostState.pinExpiresAt &&
                    <Row align="middle">
                        <PushpinOutlined style={{
                            color: isPinExpired(props.selectedPostState.pinExpiresAt) ? Colors.monoMedLightGrey : Colors.brandPrimary,
                            fontSize: 18,
                            marginRight: 5
                        }}/><span
                        style={{color: isPinExpired(props.selectedPostState.pinExpiresAt) ? Colors.monoMedLightGrey : Colors.brandPrimary}}>{DateTimeFormatter.standardDateTime(props.selectedPostState.pinExpiresAt)}</span>
                    </Row>
                    }
                </Col>
            </Row>
            <Divider></Divider>
            <Row>
                <Title level={3}>{props.selectedPostState.title}</Title>
            </Row>
            <Row>
                <Paragraph>
                    {props.selectedPostState.body}
                </Paragraph>
            </Row>
            {props.selectedPostState.receivable &&
                <>
                    <Divider>Replies
                        ({props.selectedPostState.postReplies && props.selectedPostState.postReplies.length})</Divider>
                    <List
                        itemLayout="horizontal"
                        dataSource={props.selectedPostState.postReplies}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Replies"/>}}
                        renderItem={reply => (
                            <List.Item>
                                {replyListMetaByReplyableType(reply)}
                            </List.Item>
                        )}
                    />
                    <PostReplyForm selectedPostState={props.selectedPostState}
                                   postReplyCreationSuccess={postReplyCreationSuccess}/>
                </>
            }
        </>
    )
}

export default PostDetail;