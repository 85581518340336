import React, { useContext } from 'react';
import {Avatar, Col, Divider, Empty, List, Row, Typography} from "antd";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import {UserOutlined} from "@ant-design/icons";

const { Text } = Typography;

const ResidentInfo = (props) => {
    const [unitState, ] = useContext(UnitContext);

    return (
        <>
            <Divider>Residents</Divider>
            <Row gutter={16}>
                <Col span={24}>
                    <List
                        size="small"
                        bordered
                        dataSource={unitState.currentUnitResidents}
                        locale={{
                            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Residents Listed"/>
                        }}
                        renderItem={unitResident =>
                            <List.Item
                                extra={
                                    <a href={`mailto:${unitResident.resident.email}`} target="_blank">{unitResident.resident.email}</a>
                                }
                            >
                                <Row gutter={10} align="middle">
                                    <Col>
                                        <Avatar icon={<UserOutlined/>} src={unitResident.resident.profileImageUrl && unitResident.resident.profileImageUrl}/>
                                    </Col>
                                    <Col>
                                        <Text strong>{unitResident.resident.firstName} {unitResident.resident.lastName}</Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        }
                    />
                </Col>
            </Row>
        </>
    );
}

export default ResidentInfo;