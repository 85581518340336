import React, { useContext } from "react";
import {Row, Col, Button, Typography} from "antd";
import Lottie from "react-lottie";
import * as animationData from "@dwellpass-client/common/utils/lottie/red-check-anim.json";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Title, Text } = Typography;

const HoldingStep = (props) => {
    const [communityState, ] = useContext(CommunityContext);

    const checkAnimOptions = {
        animationData: animationData.default,
        autoplay: true,
        loop: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <>
            <Row justify="center">
                <Col>
                    <Lottie options={checkAnimOptions} width={150} height={150} />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={16} style={{ textAlign: "center" }}>
                    <Title level={4} style={{ color: Colors.brandPrimary }}>{communityState.name}</Title>
                    <Text strong style={{ color: Colors.monoMedLightGrey }}>Thank you for registering your Community. We're excited to have you onboard and one of our team members will review your account shortly. If you need anything in the meantime, feel free to contact us below.</Text>
                </Col>
            </Row>
            <Row style={{ marginTop: 25 }} justify="center">
                <Col>
                    <a href={`mailto:${process.env.SALES_CONTACT_EMAIL_ADDRESS}`} target="_blank">
                        <Button type="primary">
                            Contact Us
                        </Button>
                    </a>
                </Col>
            </Row>
        </>
    )
}

export default HoldingStep;