import React, { useContext } from "react";
import {Button, Col, Divider, Form, Input, notification, Row, DatePicker, Select} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";
import moment from "moment";
import PrimaryInput from "../../shared/PrimaryInput";

const TaskForm = (props) => {
    const [communityState, _] = useContext(CommunityContext);
    const [form] = Form.useForm();

    const [createTask, { loading: loadingTaskCreation }] = useMutation(gql(TaskQueries.createTask));

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        },500);
    };

    const handleFormSubmit = async (values) => {
        try {
            let dueAt = values.dueAt ? values.dueAt.toISOString() : null

            await createTask({variables: { communityId: communityState.id, title: values.title, description: values.description, category: values.category, unitId: values.unitId, assigneeId: values.assigneeId, priority: values.priority, status: "open", dueAt }});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            notification['error']({
                message: 'Error Creating Task',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    return(
        <>
            <Form form={form}
                  layout="vertical"
                  initialValues={{ category: "maintenance", priority: "low" }}
                  onFinish={handleFormSubmit}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please enter the title' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="category"
                            label="Category"
                            rules={[{ required: true, message: 'Please select a category' }]}
                        >
                            <Select style={{width: 200}}>
                                <Select.Option value="maintenance">Maintenance</Select.Option>
                                <Select.Option value="repair">Repair</Select.Option>
                                <Select.Option value="construction">Construction</Select.Option>
                                <Select.Option value="miscellaneous">Miscellaneous</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="priority"
                            label="Priority"
                            rules={[{ required: true, message: 'Please select a priority' }]}
                        >
                            <Select style={{width: 200}}>
                                <Select.Option value="low">Low</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="high">High</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="assigneeId"
                            label="Assignee"
                        >
                            <Select
                                style={{width: 200}}
                                placeholder="Select an Assignee"
                                allowClear
                                showSearch
                                loading={props.loadingCommunityManagersOptions}
                                options={props.communityManagersOptions}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="unitId"
                            label="Unit"
                        >
                            <Select
                                style={{width: 200}}
                                placeholder="Select a Unit"
                                allowClear
                                showSearch
                                loading={props.loadingUnitsOptions}
                                options={props.unitsOptions}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="dueAt"
                            label="Due"
                        >
                            <DatePicker
                                style={{width: 200}}
                                showTime
                                showNow={false}
                                disabledDate={current => {
                                    return current && current < moment();
                                }}
                                format="MM/DD/YYYY h:mma"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100 }} type="primary" htmlType="submit" loading={loadingTaskCreation}>
                                Create
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default TaskForm;