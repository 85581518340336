import React, { useContext, useState } from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Layout, Row, Col, Menu, Dropdown, Avatar, Divider, PageHeader, Tooltip} from 'antd';
import {UserOutlined, QuestionCircleFilled} from '@ant-design/icons';
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import { UserContext } from "@dwellpass-client/common/contexts/UserContext";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import UserCommunitySelectionDrawer from "../../shared/UserCommunitySelectionDrawer";
import UserUnitSelectionDrawer from "../../shared/UserUnitSelectionDrawer";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const { Header } = Layout;

export const WebHeader = (props) => {
    let history = useHistory();
    let match = useRouteMatch();

    const [userState, ] = useContext(UserContext);
    const [communityState, ] = useContext(CommunityContext);
    const [unitState, ] = useContext(UnitContext);

    const [communityDrawerOpen, setCommunityDrawerOpen] = useState(false);
    const showCommunityDrawer = () => {
        setCommunityDrawerOpen(true);
    };
    const onCommunityDrawerClose = () => {
        setCommunityDrawerOpen(false);
    };

    const [unitDrawerOpen, setUnitDrawerOpen] = useState(false);
    const showUnitDrawer = () => {
        setUnitDrawerOpen(true);
    };
    const onUnitDrawerClose = () => {
        setUnitDrawerOpen(false);
    };

    const logout = () => {
        history.push({pathname: "/logout", state: { segment: "resident" }});
    }

    const navToUserProfile = () => {
        history.push(match.url+"/user-profile");
    }

    const navToPaymentMethods = () => {
        history.push(match.url+"/payment-methods");
    }

    const userMenu = (
        <Menu>
            {userState.managedCommunities.length >= 1 &&
                <Menu.Item key="communities" onClick={showCommunityDrawer}>
                    Communities
                </Menu.Item>
            }
            {userState.currentUnits.length > 1 &&
                <Menu.Item key="units" onClick={showUnitDrawer}>
                    Units
                </Menu.Item>
            }
            {(userState.managedCommunities.length >= 1 || userState.currentUnits.length > 1) && <Menu.Divider />}
            <Menu.Item key="profile" onClick={navToUserProfile}>
                Your Profile
            </Menu.Item>
            <Menu.Item key="payment-methods" onClick={navToPaymentMethods}>
                Payment Methods
            </Menu.Item>
            <Menu.Item key="logout" onClick={logout}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={styles.header}>
            <Row justify="space-between">
                <Col span={12}>
                    <Row>
                        <PageHeader title={unitState.address && unitState.address.street1} subTitle={communityState.name} />
                    </Row>
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Col>
                            <Tooltip placement="bottom" title="Get help">
                                <a href={`mailto:${process.env.SUPPORT_REQUEST_EMAIL_ADDRESS}?subject=New support request for ${unitState.address && unitState.address.street1}`} style={{ marginRight: 10 }}><QuestionCircleFilled style={{ fontSize: 20, paddingTop: 22, color: Colors.monoDarkGrey }} /></a>
                            </Tooltip>
                            <Divider type="vertical" />
                            <Dropdown overlay={userMenu} placement="bottomCenter">
                                <a onClick={e => e.preventDefault()}>
                                    {userState.firstName} {userState.lastName}<Avatar style={{ marginLeft: 10, marginRight: 20 }} icon={<UserOutlined />} src={userState.profileImageUrl && userState.profileImageUrl} />
                                </a>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <UserCommunitySelectionDrawer communityDrawerOpen={communityDrawerOpen} onCommunityDrawerClose={onCommunityDrawerClose} />
            {userState.currentUnits.length >= 1 &&
                <UserUnitSelectionDrawer unitDrawerOpen={unitDrawerOpen} onUnitDrawerClose={onUnitDrawerClose} />
            }
        </Header>
    );
};

const styles = {
    header: {
        padding: '0px',
        backgroundColor: 'white',
        borderBottomWidth: '1px',
        borderBottomColor: Colors.medGrey
    }
}

export default WebHeader;