import React, { useEffect, useReducer } from "react";
import {Timeline} from "antd";
import {HomeOutlined, InfoCircleOutlined, PlusCircleOutlined, LogoutOutlined, FileOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import LoadingContent from "../../../utility/LoadingContent";

const UnitTimeline = (props) => {
    const formattedDataTime = (timestamp) => {
        return DateTimeFormatter.standardDateTime(timestamp);
    };

    const setTitleByType = (itemType, info) => {
        switch (itemType) {
            case "created":
                return "Created";
            case "updated":
                return "Last Updated";
            case "resident created":
                return "Resident moved in: " + info;
            case "resident departed":
                return "Resident moved out: " + info;
            case "resource uploaded":
                return "Resource uploaded: " + info;
            default:
                return false;
        }
    };

    const setDotByType = (itemType) => {
        switch (itemType) {
            case "created":
                return <PlusCircleOutlined style={{fontSize: '16px'}}/>;
            case "updated":
                return <InfoCircleOutlined style={{fontSize: '16px'}}/>;
            case "resident created":
                return <HomeOutlined style={{fontSize: '16px', color: Colors.brandPrimary}}/>;
            case "resident departed":
                return <LogoutOutlined style={{fontSize: '16px', color: Colors.monoMedGrey}}/>;
            case "resource uploaded":
                return <FileOutlined style={{fontSize: '16px', color: Colors.brandPrimary}}/>;
            default:
                return false;

        }
    };

    const sortTimelineItemsByNewestFirst = (items) => {
        return items.sort((a, b) => -a.timestamp.localeCompare(b.timestamp));
    };

    let timelineItemReducer = (state, action) => {
        switch (action.type) {
            case "UPDATE_TIMELINE_ITEMS":
                return action.items;
            default:
                throw new Error("Invalid Action Type");
        }
    };
    let [timelineItems, dispatchTimelineItems] = useReducer(timelineItemReducer, []);

    useEffect(() => {
        const timelineItems = [];

        timelineItems.push({type: "created", timestamp: props.selectedUnitState.createdAt});

        props.selectedUnitState.createdAt != props.selectedUnitState.updatedAt && timelineItems.push({
            type: "updated",
            timestamp: props.selectedUnitState.updatedAt
        });

        props.selectedUnitState.unitResidents && props.selectedUnitState.unitResidents.length >= 1 &&
        props.selectedUnitState.unitResidents.map((unitResident) => {
            timelineItems.push({
                type: "resident created",
                timestamp: unitResident.createdAt,
                info: (`${unitResident.resident.firstName} ${unitResident.resident.lastName}`)
            })
            unitResident.departedAt != null &&
            timelineItems.push({
                type: "resident departed",
                timestamp: unitResident.departedAt,
                info: (`${unitResident.resident.firstName} ${unitResident.resident.lastName}`)
            })
        })
        props.selectedUnitState.resources && props.selectedUnitState.resources.length >= 1 &&
        props.selectedUnitState.resources.map((resource) => {
            resource.displayOnTimeline &&
            timelineItems.push({
                type: "resource uploaded",
                timestamp: resource.createdAt,
                info: resource.name ? resource.name : resource.resourceFileName
            })
        })

        const sortedTimelineItems = sortTimelineItemsByNewestFirst(timelineItems);

        dispatchTimelineItems({type: "UPDATE_TIMELINE_ITEMS", items: sortedTimelineItems})
    }, [props.selectedUnitState])

    return (
        <>
            {props.loadingUnit ?
                <LoadingContent style={{marginTop: 20}}/>
                :
                <Timeline mode="right">
                    {timelineItems.map((item, i) =>
                        <Timeline.Item key={i} label={formattedDataTime(item.timestamp)} dot={setDotByType(item.type)}>
                            {setTitleByType(item.type, item.info)}
                        </Timeline.Item>
                    )}
                </Timeline>
            }
        </>
    )
};

export default UnitTimeline;