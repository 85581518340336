import React, { useContext, useEffect, useState } from "react";
import {Button, Col, Empty, Row, Tooltip, List, Avatar} from "antd";
import {ApartmentOutlined, CommentOutlined, HomeOutlined, LoadingOutlined, UserOutlined, MessageOutlined, EyeOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {gql, useQuery} from "@apollo/client";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {useHistory} from "react-router-dom";

const LatestUnreadPostsTable = (props) => {
    const postPageSize = 10;
    const history = useHistory();

    const [communityState, _] = useContext(CommunityContext);
    const [postsState, setPostsState] = useState([]);

    const reader = { readerType: "CommunityManager", readerId: communityState.currentUserCommunityManager.id };
    const { data: postData, loading: loadingPosts } = useQuery(gql(PostQueries.getPosts), { notifyOnNetworkStatusChange: true, variables: { reader, unread: true, first: postPageSize } } );

    useEffect(() => {
        if(postData) {
            setPostsState(postData.posts);
        }
    }, [postData]);

    const navToEngagementForPostDetails = (post) => {
        history.push({pathname: "/community/engagement", state: { showPost: { node: post }}});
    }

    const receivableTypeIcon = (node) => {
        switch (true) {
            case node.receivable && node.receivable.__typename == "UnitResident":
                return(
                    <Tooltip title="Resident Post">
                        <UserOutlined style={{fontSize: 22, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case node.receivable && node.receivable.__typename == "Unit":
                return(
                    <Tooltip title="Unit Post">
                        <HomeOutlined style={{fontSize: 22, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case node.receivable && node.receivable.__typename == "Community":
                return(
                    <Tooltip title="Community Manager Post">
                        <MessageOutlined style={{fontSize: 22, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            default:
                return(
                    <Tooltip title="Community Post">
                        <ApartmentOutlined style={{fontSize: 22, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
        }
    }

    const senderReceiverFromPost = (node) => {
        switch (true) {
            case node.receivable && node.receivable.__typename == "UnitResident":
                return `${node.receivable.resident.firstName} ${node.receivable.resident.lastName} (Unit: ${node.receivable.unit.internalIdentifier})`;
            case node.receivable && node.receivable.__typename == "Unit":
                return node.receivable.internalIdentifier;
            case node.receivable && node.receivable.__typename == "Community":
                return `${node.postable.resident.firstName} ${node.postable.resident.lastName} (Unit: ${node.postable.unit.internalIdentifier})`;
            default:
                return "Community";
        }
    }

    return(
        <List
            style={{ width: "100%" }}
            itemLayout="horizontal"
            dataSource={postsState ? postsState.edges : null}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="All Caught Up!" /> }}
            loading={{spinning: loadingPosts, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            renderItem={record => (
                <List.Item
                    actions={[<Button type="link"><EyeOutlined style={{ marginTop: 4, fontSize: 22 }} /></Button>]}
                    onClick={() => navToEngagementForPostDetails(record.node)}
                >
                    <List.Item.Meta
                        avatar={<Avatar src={receivableTypeIcon(record.node)} />}
                        title={
                            <Row align="middle" gutter={10}>
                                <Col>
                                    {senderReceiverFromPost(record.node)}
                                </Col>
                                {record.node.unreadReplies &&
                                    <Col>
                                        <CommentOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    </Col>
                                }
                            </Row>
                        }
                        description={
                            <Row wrap={false}>
                                <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>{record.node.title}</span><span style={{ fontWeight: "bold", color: Colors.monoMedLightGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>&nbsp; - {record.node.body}</span>
                            </Row>
                        }
                    />
                </List.Item>
            )}
        />
    )
}

export default LatestUnreadPostsTable;