import React from "react";
import { AuthController } from './contexts/AuthContext';
import { BrowserRouter as Router } from "react-router-dom";
import RootRouter from "./routers/RootRouter";
import { Helmet } from "react-helmet";
import * as Sentry from 'sentry-expo';
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.APP_ENV==="production" ? 0.2 : 1,
    enableInExpoDevelopment: false,
    environment: process.env.APP_ENV,
    release: process.env.APP_VERSION.toString(),
    debug: process.env.APP_ENV!=="production"
});

export default function App() {
  return (
      <div style={{width: "100%", overflow: "hidden"}}>
          <Helmet>
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <meta http-equiv='cache-control' content='no-cache' />
              <meta http-equiv='expires' content='0' />
              <meta http-equiv='pragma' content='no-cache' />
          </Helmet>
          <AuthController>
                <Router>
                    <RootRouter />
                </Router>
          </AuthController>
      </div>
  );
};
