import React, {useState} from "react";
import {Modal, Form, Row, InputNumber, Typography, notification, Col} from "antd";
import {gql, useMutation} from "@apollo/client";
import PrimaryInput from "./PrimaryInput";
import PaymentSourceQueries from "@dwellpass-client/common/utils/constants/queries/PaymentSourceQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import PaymentMethodQueries from "@dwellpass-client/common/utils/constants/queries/PaymentMethodQueries";

const { Text } = Typography;

const VerifyBankAccountModal = (props) => {
    const [form] = Form.useForm();
    const [formErrors, setFormErrors] = useState([]);
    const [loadingFormSubmission, setLoadingFormSubmission] = useState(false);

    const [verifySource, ] = useMutation(gql(PaymentSourceQueries.verifySource));
    const [setDefaultPaymentMethod, ] = useMutation(gql(PaymentMethodQueries.setDefaultPaymentMethod));

    const handleFormSubmit = async (values) => {
        setLoadingFormSubmission(true);

        try {
            setFormErrors([]);
            const newSource = await verifySource({variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, stripeSourceId: props.selectedSource.id, depositAmount1: values.amount1, depositAmount2: values.amount2}});

            await setDefaultPaymentMethod({variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, stripePaymentMethodId: newSource.data?.verifySource?.id}});

            openSuccessNotification();

            resetFieldsAndHideMe();
            setLoadingFormSubmission(false);
            props.formSuccess();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors ? error.graphQLErrors : [error]);
            setLoadingFormSubmission(false);
        }
    }

    const resetFieldsAndHideMe = () => {
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
            setFormErrors([]);
        }, 500);
    }

    const openSuccessNotification = () => {
        notification["success"]({
            message: "Bank Account Verified",
            description: "This payment method is now ready for use."
        });
    }

    return(
        <Modal
            title="Verify Bank Account"
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={loadingFormSubmission}
            onCancel={() => resetFieldsAndHideMe()}
        >
            <Text>Enter the amounts deposited into your bank account to complete the verification.</Text>
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
            >
                {formErrors && (
                    formErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <Row style={{ marginTop: 10 }} gutter={16} justify="center">
                    <Col span={8}>
                        <Form.Item
                            label="Amount 1"
                            name="amount1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the first amount',
                                }
                            ]}
                        >
                            <PrimaryInput
                                type="numeric"
                                min={1}
                                max={99}
                                formatter={value => value ? !isNaN(value) ? MoneyFormatter.standardMoneyUSD(value) : "$0." : "$0."}
                                parser={value => value.replace(/\$0.\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Amount 2"
                            name="amount2"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the second amount',
                                }
                            ]}
                        >
                            <InputNumber
                                min={1}
                                max={99}
                                formatter={value => value ? !isNaN(value) ? MoneyFormatter.standardMoneyUSD(value) : "$0." : "$0."}
                                parser={value => value.replace(/\$0.\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default VerifyBankAccountModal;