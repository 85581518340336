import React, { useContext } from 'react';
import {Card, Col, Divider, Row} from "antd";
import {CreditCardOutlined, FileOutlined, UserOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {useHistory} from "react-router-dom";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const QuickActionsBar = (props) => {
    const history = useHistory();
    const [unitState, ] = useContext(UnitContext);

    const navToEngagementWithPostCreationDetails = () => {
        history.push({pathname: "/resident/posts", state: {postDetails: {}}});
    }

    const navToResources = () => {
        history.push("/resident/resources");
    }

    const navToPayments = () => {
        history.push("/resident/payments");
    }

    return (
        <>
            <Divider>Quick Actions</Divider>
            <Row gutter={10}>
                <Col span={8}>
                    <Card size="small" style={styles.quickActionCard} hoverable onClick={() => navToEngagementWithPostCreationDetails()}>
                        <Row gutter={10} wrap={false} justify="center" align="middle">
                            <Col>
                                <UserOutlined style={{ fontSize: 28 }} />
                            </Col>
                            <Col>
                                Contact a Manager
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size="small" style={styles.quickActionCard} hoverable onClick={() => navToResources()}>
                        <Row gutter={10} wrap={false} justify="center" align="middle">
                            <Col>
                                <FileOutlined style={{ fontSize: 28 }} />
                            </Col>
                            <Col>
                                View Resources
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size="small" style={styles.quickActionCard} hoverable onClick={() => navToPayments()}>
                        <Row gutter={10} wrap={false} justify="center" align="middle">
                            <Col>
                                <CreditCardOutlined style={{ fontSize: 28 }} />
                            </Col>
                            <Col>
                                Manage Payments
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

const styles = {
    quickActionCard: {
        height: 56,
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        color: Colors.brandPrimary,
        borderColor: Colors.brandPrimary,
        borderRadius: 5
    },
    quickActionNeededCard: {
        height: 56,
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        color: Colors.monoBright,
        backgroundColor: Colors.brandPrimary,
        borderRadius: 5
    }
}

export default QuickActionsBar;