import React, { useContext, useState, useEffect } from "react";
import '../assets/styles/css/antd.css';
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Form, Input, Button, Typography, Checkbox } from 'antd';
import { AuthContext } from "../contexts/AuthContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import backgroundImage from "../assets/images/register-bg.jpg";
import logoImage from "../assets/images/dwellPass-logo-red-800w.png";
import Endpoints from "@dwellpass-client/common/utils/constants/Endpoints";
import qs from "qs";
import ResidentContent from "./register/ResidentContent";
import CommunityContent from "./register/CommunityContent";
import PrimaryInput from "./shared/PrimaryInput";
import BrandAffix from "./shared/BrandAffix";

const { Content } = Layout;
const { Text, Title } = Typography;

export const Register = (props) => {
    let history = useHistory();
    const [form] = Form.useForm();

    const [_, dispatch] = useContext(AuthContext);
    const [data, setData] = useState({isSubmitting: false, errorMessage: null});

    useEffect(() => {
        verifyLegitURL();
    }, [])

    const handleFormSubmit = values => {
        const appRootURL = process.env.APP_ROOT_URL;

        setData({
            isSubmitting: true,
            errorMessages: []
        });
        fetch(process.env.API_ROOT_URL+"/auth", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password,
                password_confirmation: values.passwordConfirmation,
                confirm_success_url: `${appRootURL}/login/${getURLSegment()}`
            })
        })
            .then(res => {
                if (res.ok) {
                    if (res.headers.get('access-token')) {
                        const payload = {
                            uid: res.headers.get('uid'),
                            client: res.headers.get('client'),
                            accessToken: res.headers.get('access-token'),
                        }
                        return payload;
                    } else {
                        return res.json().then(res => {throw { errors: { full_messages: [res.message] } } });
                    }
                } else {
                    return res.json().then(res => {throw res});
                }
            })
            .then(payload => {
                dispatch({
                    type: "LOGIN",
                    payload
                });
                history.push({pathname: "/boot", state: { segment: getURLSegment() }});
            })
            .catch(error => {
                setData({
                    isSubmitting: false,
                    errorMessages: error.errors.full_messages
                });
            });
    };

    const getInviteTokenFromURL = () => {
        return qs.parse(props.location.search, { ignoreQueryPrefix: true }).inviteToken;
    }

    const getURLSegment = () => {
        return props.match.params.segment;
    }

    const verifyLegitURL = () => {
        if(getURLSegment() == "resident" && !getInviteTokenFromURL()) {
            navToRegisterAsCommunity();
        } else if(getURLSegment() != "resident" && getURLSegment() != "community") {
            navToRegisterAsCommunity();
        }
    }

    const navToLogin = event => {
        history.push("/login/"+getURLSegment());
    };

    const navToRegisterAsCommunity = () => {
        history.push("/register/community");
    }

    return (
        <Layout>
            <Content>
                <Row style={{ height: '100vh' }}>
                    <Col xs={24} sm={24} md={8}>
                        <Row style={{ height: '100%', backgroundColor: Colors.monoBright }} align="middle">
                            <Col span={18} offset={3}>
                                <Row justify="center">
                                    <Col style={styles.logoContainer} span={24}></Col>
                                </Row>
                                <Title level={2} style={{ textTransform: "capitalize" }}>{getURLSegment()} Registration</Title>
                                {data.errorMessages && (
                                    data.errorMessages.map(message =>
                                        <Text key={message} type="danger">{message}<br/></Text>
                                    )
                                )}
                                <Form
                                    form={form}
                                    labelCol={{ span: 16 }}
                                    wrapperCol={{ span: 24 }}
                                    name="basic"
                                    layout={"vertical"}
                                    onFinish={handleFormSubmit}
                                    requiredMark={false}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="First"
                                                name="firstName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your first name',
                                                    }
                                                ]}
                                            >
                                                <PrimaryInput />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Last"
                                                name="lastName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your last name',
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Must be a valid email',
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password',
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirm Password"
                                        name="passwordConfirmation"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('The passwords you entered don\'t match');
                                                },
                                            })
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject('Please accept this agreement'),
                                            },
                                        ]}
                                    >
                                        <Checkbox style={{ "--background-color": Colors.brandPrimary }}>
                                            I have read and agree to the <a href={Endpoints.termsOfUseURL} target="_blank">Terms of Use</a> and <a href={Endpoints.privacyPolicyURL} target="_blank">Privacy Policy</a>
                                        </Checkbox>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={data.isSubmitting}>
                                            Get Started
                                        </Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button style={{ width: '100%' }} htmlType="button" onClick={navToLogin}>
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={0} md={16} style={styles.backgroundContainer}>
                        <BrandAffix position="right" />
                        {getURLSegment() == "resident" ?
                            <ResidentContent />
                            :
                            <CommunityContent />
                        }
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

const styles = {
    backgroundContainer: {
        background: `url(${backgroundImage}) no-repeat center`,
        backgroundSize: 'cover'
    },
    logoContainer: {
        height: '100px',
        marginBottom: '30px',
        background: `url(${logoImage}) no-repeat center`,
        backgroundSize: 'auto 100px'
    }
}

export default Register;
