import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {Button, Col, Empty, Row, Space, Table, Typography, Tag} from "antd";
import {
    EditOutlined,
    LoadingOutlined,
    PlusOutlined,
    ReloadOutlined,
    MinusOutlined
} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import LinkHelpers from "@dwellpass-client/common/utils/helpers/LinkHelpers";
import PayoutAccountQueries from "@dwellpass-client/common/utils/constants/queries/PayoutAccountQueries";

const PayoutAccountTable = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [payoutAccountsState, setPayoutAccountsState] = useState([]);
    const [selectedPayoutAccountState, setSelectedPayoutAccountState] = useState({});
    
    const { data: payoutAccountsData, loading: loadingPayoutAccounts, refetch: refetchPayoutAccounts } = useQuery(gql(CommunityQueries.getCommunityPayoutAccounts),{ notifyOnNetworkStatusChange: true, variables: { id: communityState.id } });
    const [loadLoginLink, { data: loginLinkData, loading: loadingLoginLinkData }] = useLazyQuery(gql(PayoutAccountQueries.getStripeAccountLoginLink), { fetchPolicy: "network-only" });
    const [loadAccountLink, { data: accountLinkData, loading: loadingAccountLinkData }] = useLazyQuery(gql(PayoutAccountQueries.getStripeAccountLink), { fetchPolicy: "network-only" });

    useEffect(() => {
        loginLinkData && loginLinkData.stripeAccountLoginLink && LinkHelpers.openLinkInNewTab(loginLinkData.stripeAccountLoginLink.url);
    }, [loadingLoginLinkData]);

    useEffect(() => {
        accountLinkData && accountLinkData.stripeAccountLink && LinkHelpers.openLinkInNewTab(accountLinkData.stripeAccountLink.url);
    }, [loadingAccountLinkData]);

    useEffect(() => {
        payoutAccountsData && payoutAccountsData.community && setPayoutAccountsState(payoutAccountsData.community.payoutAccounts);
    }, [payoutAccountsData]);

    useEffect(() => {
        refetchPayoutAccounts();
    }, [props.refreshPayoutAccountsTable]);

    const loadStripeLoginLink = (payoutAccount) => {
        loadLoginLink({variables: {payoutAccountId: payoutAccount.id}})
    }

    const loadStripeAccountLink = (payoutAccount) => {
        loadAccountLink({variables: {payoutAccountId: payoutAccount.id}})
    }

    const stripeDashboardButton = (payoutAccount) => {
        return (
            <Row gutter={10} align="middle">
                <Col>
                    <Button
                        style={{fontWeight: "bold", color: Colors.stripeBrandColor, borderColor: Colors.stripeBrandColor}}
                        disabled={loadingLoginLinkData}
                        onClick={() => loadStripeLoginLink(payoutAccount)}
                    >Stripe Dashboard</Button>
                </Col>
            </Row>
        )
    }

    const stripeCompleteSetupButton = (payoutAccount) => {
        return (
            <Row gutter={10} align="middle">
                <Col>
                    <Button
                        style={{fontWeight: "bold", color: Colors.dangerRed, borderColor: Colors.dangerRed}}
                        disabled={loadingAccountLinkData}
                        onClick={() => loadStripeAccountLink(payoutAccount)}
                    >Complete Setup</Button>
                </Col>
            </Row>
        )
    }

    const payoutAccountsTableColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Account',
            key: 'account',
            render: (text, record) => (
                record.externalAccountLast4 ?
                    <Tag>{record.externalAccountLast4}</Tag>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Fees',
            key: 'fees',
            dataIndex: 'feesCount'
        },
        {
            title: '',
            key: 'stripe',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {record.status === "verified" ?
                        stripeDashboardButton(record)
                        : stripeCompleteSetupButton(record)
                    }
                </Space>
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={(e) => {
                        e.stopPropagation();
                        props.editPayoutAccount(record)
                    }}><EditOutlined style={{fontSize: 20}}/></Button>
                </Space>
            ),
        }
    ];

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => props.addPayoutAccount()}><PlusOutlined />Add Account</Button>
                </Col>
                <Col>
                    <Row gutter={10}>
                        <Col>
                            <Button type="link" onClick={() => refetchPayoutAccounts()}>
                                {(loadingPayoutAccounts || loadingLoginLinkData || loadingAccountLinkData) ?
                                    <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    :
                                    <ReloadOutlined style={{fontSize: 20}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center">
                <Table
                    style={{ width: "100%" }}
                    size="small"
                    dataSource={payoutAccountsState ? payoutAccountsState : null}
                    loading={{spinning: loadingPayoutAccounts, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                    columns={payoutAccountsTableColumns}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Payout Accounts" /> }}
                    rowKey={record => record.id}
                    pagination={false}
                />
            </Row>
        </>
    )
}

export default PayoutAccountTable;