import React from 'react';
import {Button, Col, Empty, List, Row, Typography} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {useHistory} from "react-router-dom";

const { Text } = Typography;

const UnitPosts = (props) => {
    const history = useHistory();

    const navToEngagementForPostDetails = (post) => {
        history.push({pathname: "/community/engagement", state: { showPost: { node: post }}});
    }

    return (
        <List
            size="small"
            bordered
            dataSource={props.unitState.posts}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Posts Listed" /> }}
            renderItem={item =>
                <List.Item
                    actions={[<Button type="link" onClick={() => navToEngagementForPostDetails(item)}><EyeOutlined style={{ marginTop: 4, fontSize: 22 }} /></Button>]}
                >
                    <Row justify="space-between">
                        <Col>
                            <Text strong>{item.title}</Text> - {DateTimeFormatter.standardDateTime(item.createdAt)}
                        </Col>
                    </Row>
                </List.Item>
            }
        />
    );
}

export default UnitPosts;