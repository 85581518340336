import React, { useState, useContext } from 'react';
import {Row, Col, Modal, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import * as animationData from "@dwellpass-client/common/utils/lottie/red-paper-rolling-anim.json";
import Lottie from "react-lottie";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const { Text } = Typography;

const CommunityBetaInfoModal = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [showMe, setShowMe] = useState(!communityState.id);

    const paperAnimOptions = {
        animationData: animationData.default,
        autoplay: true,
        loop: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <Modal
            visible={showMe}
            centered
            onCancel={() => setShowMe(false)}
            footer={null}
            okText="Continue"
        >
            <Row style={{ marginBottom: 10 }} justify="center">
                <Col>
                    <Lottie options={paperAnimOptions} width={200} height={100} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 10, textAlign: "center" }}>
                <Col>
                    <Text strong style={{ fontSize: 16, color: Colors.brandPrimary }}>Welcome! We're currently in a closed beta with limited availability.</Text>
                </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
                <Col>
                    <Text strong>However, you can still begin your registration for beta access consideration. We review every submission, and will be in touch as we expand service. We're excited to have your interest!</Text>
                </Col>
            </Row>
        </Modal>
    );
}

export default CommunityBetaInfoModal;