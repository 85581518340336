import React, { useState, useEffect, useRef } from "react";
import {Modal, Col, Form, Input, Row, Typography, message} from "antd";
import {gql, useMutation} from "@apollo/client";
import MeQueries from "@dwellpass-client/common/utils/constants/queries/MeQueries";

const { Text } = Typography;

const PasswordChangeModal = (props) => {
    const [form] = Form.useForm();
    const [formErrors, setFormErrors] = useState([]);
    const passwordInput = useRef();

    const [changeMyPassword, { loading: loadingChangeMyPassword }] = useMutation(gql(MeQueries.changeMyPassword));

    useEffect(() => {
        props.visible &&
            passwordInput.current.focus();
    }, [props.visible]);

    const handleFormSubmit = async (values) => {
        try {
            setFormErrors([]);
            await changeMyPassword({variables: { password: values.password, passwordConfirmation: values.passwordConfirmation }});

            message.success('Your password has been changed.');

            form.resetFields();
            props.hideMe();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors);
            formErrors.map((error) => { console.log(error.message) });
        }
    }

    return(
        <Modal
            title="Change Password"
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={loadingChangeMyPassword}
            onCancel={() => props.hideMe()}
        >
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
            >
                {formErrors && (
                    formErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password',
                                }
                            ]}
                        >
                            <Input.Password ref={passwordInput} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Confirm Password"
                            name="passwordConfirmation"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your new password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The passwords you entered don\'t match');
                                    },
                                })
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default PasswordChangeModal;