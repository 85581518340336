import React, {useContext, useEffect, useState} from 'react';
import InvoiceRevenuesTable from "./InvoiceRevenuesTable";
import UnitRevenuesTable from "./UnitRevenuesTable";
import {Button, Col, DatePicker, Row, Select, Tabs, Typography, Popconfirm, notification} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {ExportOutlined, LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {gql, useMutation, useQuery} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import moment from "moment";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {useHistory} from "react-router-dom";
import RevenueExportQueries from "@dwellpass-client/common/utils/constants/queries/RevenueExportQueries";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const RevenuesTables = (props) => {
    const history = useHistory();
    const [communityState, ] = useContext(CommunityContext);
    const [unitsOptions, setUnitsOptions] = useState([]);
    const [activeRevenuesTableTabKey, setActiveRevenuesTableTabKey] = useState("invoices");

    const { data: unitsData, loading: loadingUnits } = useQuery(gql(UnitQueries.getUnitsSimple), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id } } );
    const [createRevenueExport, { loading: loadingCreateRevenueExport }] = useMutation(gql(RevenueExportQueries.createRevenueExport));

    useEffect(() => {
        var options = [];
        unitsData &&
        unitsData.units.edges.map(unit => {
            options.push({ label: unit.node.internalIdentifier, value: unit.node.id });
        });
        setUnitsOptions(options);
    }, [unitsData]);

    useEffect(() => {
        setActiveRevenuesTableTabKey("invoices");
    }, [props.performRevenuesTableTabToInvoices]);

    const handleRevenueExportCreation = async () => {
        try {
            const options = {
                unitId: props.unitFilterState,
                status: props.statusFilterState,
                startDate: props.startDateFilterState,
                endDate: props.endDateFilterState
            }
            await createRevenueExport({ variables: { communityManagerId: communityState.currentUserCommunityManager.id, options, exportType: activeRevenuesTableTabKey }});
            notification["success"]({
                message: "It's on the way",
                description: "Your export is processing. You should receive an email shortly with a link to download."
            });
        }
        catch (error) {
            notification["warning"]({
                message: "Error",
                description: "There was an issue with the export. Please try again."
            });
        }
    }

    const filterByUnit = (unitId) => {
        props.setUnitFilterState(unitId);
    }

    const clearFilteredUnit = () => {
        props.setUnitFilterState(null);
    }

    const filterByStatus = (status) => {
        props.setStatusFilterState(status);
    }

    const clearFilteredStatus = () => {
        props.setStatusFilterState(null);
    }

    const handleSelectedDateRangeFilter = (selectedRange) => {
        props.setStartDateFilterState(selectedRange ? selectedRange[0].startOf('day').toISOString() : null);
        props.setEndDateFilterState(selectedRange ? selectedRange[1].startOf('day').toISOString() : null);
    }

    const navToConfigurationPayoutAccountsSection = () => {
        history.push({
            pathname: '/community/configuration',
            state: { showPayoutAccounts: true }
        })
    }

    const tabsExtra = () => {
        return <Row gutter={8} align="middle">
            <Col>
                <Select
                    style={{ width: 200 }}
                    placeholder="Filter by Unit"
                    allowClear
                    showSearch
                    onSelect={(value) => filterByUnit(value)}
                    onClear={() => clearFilteredUnit()}
                    value={props.unitFilterState}
                    loading={loadingUnits}
                    options={unitsOptions}
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                />
            </Col>
            <Col>
                <Select
                    style={{ width: 100 }}
                    placeholder="Status"
                    allowClear
                    onSelect={filterByStatus}
                    onClear={clearFilteredStatus}
                    value={props.statusFilterState}
                    options={[{label: "Paid", value: "paid"}, {label: "Open", value: "open"}, {label: "Past Due", value: "past_due"}]}
                />
            </Col>
            <Col>
                <RangePicker
                    ranges={{
                        "30": [moment().subtract(30, "days"), moment()],
                        "60": [moment().subtract(60, "days"), moment()],
                        "90": [moment().subtract(90, "days"), moment()],
                        "LTM": [moment().subtract(12, "months"), moment()],
                        "All Time": null,
                    }}
                    allowClear
                    onChange={handleSelectedDateRangeFilter}
                    placeholder={["Due date start", "Due date end"]}
                    value={
                        [
                            props.startDateFilterState ? moment(props.startDateFilterState.toString()) : null,
                            props.endDateFilterState ? moment(props.endDateFilterState.toString()) : null
                        ]
                    }
                />
            </Col>
            <Col>
                <Popconfirm
                    title="Ready to export this data? We'll send you a copy via email."
                    onConfirm={handleRevenueExportCreation}
                    okText="Export"
                    disabled={loadingCreateRevenueExport}
                    placement="left"
                    icon={<QuestionCircleOutlined style={{ color: Colors.brandPrimary }} />}
                >
                    <Button type="link">
                        {loadingCreateRevenueExport ?
                            <LoadingOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                            :
                            <ExportOutlined style={{ fontSize: 18, color: Colors.brandPrimary }} />
                        }
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    }

    return (
        <Tabs type="card" tabBarExtraContent={tabsExtra()} activeKey={activeRevenuesTableTabKey} onChange={setActiveRevenuesTableTabKey}>
            <TabPane tab="Invoices" key="invoices">
                <InvoiceRevenuesTable unitFilterState={props.unitFilterState} statusFilterState={props.statusFilterState} startDateFilterState={props.startDateFilterState} endDateFilterState={props.endDateFilterState} />
            </TabPane>
            <TabPane tab="Units" key="units">
                <UnitRevenuesTable unitFilterState={props.unitFilterState} statusFilterState={props.statusFilterState} startDateFilterState={props.startDateFilterState} endDateFilterState={props.endDateFilterState} />
            </TabPane>
            <TabPane tab="Payouts" key="payouts">
                <Row justify="center" style={{ marginTop: 100 }}>
                    <Col span={16}>
                        <Title level={4} style={{ color: Colors.monoMedLightGrey, textAlign: "center" }}>Payouts are visible in the Stripe Dashboard associated with each Payout Account</Title>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <Col>
                        <Button
                            type="primary"
                            onClick={navToConfigurationPayoutAccountsSection}
                        >Go to Payout Accounts</Button>
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    );
}

export default RevenuesTables;