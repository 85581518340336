import React, { useState, useContext, useEffect } from 'react';
import {Button, Col, Empty, Row, Space, Switch, Table, Tag, Badge, Input} from "antd";
import {EditOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, ReloadOutlined, CheckCircleFilled} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import UnitImportUploader from "./UnitImportUploader";

const { Search } = Input;

const MainUnitsTable = (props) => {
    const unitPageSize = 30;

    const [unitsState, setUnitsState] = useState({ units: [] });
    const [totalUnitCount, setTotalUnitCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [communityState, ] = useContext(CommunityContext);
    const [vacantOnlyState, setVacantOnlyState] = useState(false);
    const [pastDueInvoicesOnlyState, setPastDueInvoicesOnlyState] = useState(false);
    const [pendingInvitesOnlyState, setPendingInvitesOnlyState] = useState(false);
    const [openInvoicesOnlyState, setOpenInvoicesOnlyState] = useState(false);
    const [searchTextState, setSearchTextState] = useState("");
    const { data: unitData, refetch: refetchUnits, loading: loadingUnits } = useQuery(gql(UnitQueries.getUnits), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, vacant: vacantOnlyState, pastDueInvoices: pastDueInvoicesOnlyState, pendingInvites: pendingInvitesOnlyState, openUnitResidentInvoices: openInvoicesOnlyState, searchTerm: searchTextState, first: unitPageSize, after: pageOffsetState } } );

    useEffect(() => {
        const uniqueUnitTags = [];
        unitData ? unitData.units.edges.map(u => {
            u.node.tagList.length >= 1 &&
            u.node.tagList.map(tag => {
                uniqueUnitTags.indexOf(tag) === -1 &&
                uniqueUnitTags.push(tag);
            });
        }) : null;
        unitData && setUnitsState(unitData.units);
        props.dispatchUniqueUnitTags({ type: "UPDATE_TAGS", tags: uniqueUnitTags });
        unitData && setTotalUnitCount(unitData.units.totalCount);
    }, [unitData]);

    useEffect(() => {
        refetchUnits();
    }, [pageOffsetState, props.refreshMainUnitsTable, openInvoicesOnlyState, vacantOnlyState, pastDueInvoicesOnlyState, pendingInvitesOnlyState]);

    const changeUnitTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const filterByVacant = (checked) => {
        checked && setOpenInvoicesOnlyState(false);
        checked && setPastDueInvoicesOnlyState(false);
        checked && setPendingInvitesOnlyState(false);
        setVacantOnlyState(checked);
    }

    const filterByPastDueInvoices = (checked) => {
        checked && setOpenInvoicesOnlyState(false);
        checked && setVacantOnlyState(false);
        checked && setPendingInvitesOnlyState(false);
        setPastDueInvoicesOnlyState(checked);
    }

    const filterByPendingInvites = (checked) => {
        checked && setOpenInvoicesOnlyState(false);
        checked && setVacantOnlyState(false);
        checked && setPastDueInvoicesOnlyState(false);
        setPendingInvitesOnlyState(checked);
    }

    const filterByOpenInvoices = (checked) => {
        checked && setVacantOnlyState(false);
        checked && setPastDueInvoicesOnlyState(false);
        checked && setPendingInvitesOnlyState(false);
        setOpenInvoicesOnlyState(checked);
    }

    const dataImportUploadSuccess = (dataImport) => {
        refetchUnits();
    }

    const unitsTableColumns = [
        {
            title: 'Internal ID',
            dataIndex: ['node', 'internalIdentifier'],
            width: 250
        },
        {
            title: 'Address',
            dataIndex: ['node', 'address', 'fullAddress']
        },
        {
            title: 'Residents',
            key: 'residents',
            render: (text, record) => (
                record.node.currentUnitResidentsCount > 0 ?
                    <Row gutter={8} align="middle">
                        <Col>{record.node.currentUnitResidentsCount}</Col>
                        {record.node.pendingInvites.length >= 1 && <Col><span style={{ fontWeight: "bold", color: Colors.brandPrimary }}>(Pending)</span></Col>}
                    </Row>
                    :
                    <Tag color="purple">Vacant</Tag>
            ),
        },
        {
            title: 'Tags',
            key: 'tags',
            render: (text, record) => (
                record.node.tagList.length >= 1 ?
                    record.node.tagList.map((tag, i) =>
                        <Tag key={i}>{tag}</Tag>
                    )
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Open Invoices',
            key: 'open-invoices',
            render: (text, record) => (
                record.node.openUnitResidentInvoicesCount > 0 ?
                    <Badge style={{ backgroundColor: record.node.pastDueInvoices ? Colors.dangerRed : Colors.mainBlue }} count={record.node.openUnitResidentInvoicesCount} />
                    :
                    <CheckCircleFilled style={{ fontSize: 20, color: Colors.mainGreen }} />
            ),
        },
        {
            title: '',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={(e) => {e.stopPropagation(); props.editUnit(record.node)}}><EditOutlined style={{fontSize: 20}} /></Button>
                </Space>
            ),
        }
    ];

    return (
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Row gutter={10}>
                    <Col>
                        <Button type="primary" onClick={() => props.openDrawer()}><PlusOutlined />Add Unit</Button>
                    </Col>
                    <Col>
                        <UnitImportUploader uploadSuccessDataImport={dataImportUploadSuccess} />
                    </Col>
                </Row>
                <Row gutter={10} align="middle">
                    <Col>
                        <Badge style={{ backgroundColor: Colors.brandPrimary, fontWeight: "bold" }} count={totalUnitCount} />
                    </Col>
                    <Col>
                        <Search style={{ width: 200 }} placeholder="Search Internal ID" allowClear onSearch={(term) => setSearchTextState(term)} />
                    </Col>
                    <Col>
                        <Switch checkedChildren="Vacant" unCheckedChildren="Vacant" checked={vacantOnlyState} onChange={(checked) => { filterByVacant(checked) }} />
                    </Col>
                    <Col>
                        <Switch checkedChildren="Past Due" unCheckedChildren="Past Due" checked={pastDueInvoicesOnlyState} onChange={(checked) => { filterByPastDueInvoices(checked) }} />
                    </Col>
                    <Col>
                        <Switch checkedChildren="Invites" unCheckedChildren="Invites" checked={pendingInvitesOnlyState} onChange={(checked) => { filterByPendingInvites(checked) }} />
                    </Col>
                    <Col>
                        <Switch checkedChildren="Open Invoices" unCheckedChildren="Open Invoices" checked={openInvoicesOnlyState} onChange={(checked) => { filterByOpenInvoices(checked) }} />
                    </Col>
                    <Col>
                        <Button type="link" onClick={() => refetchUnits()}>
                            {loadingUnits ?
                                <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                :
                                <ReloadOutlined style={{fontSize: 20}}/>
                            }
                        </Button>
                    </Col>
                </Row>
            </Row>
            <Table
                columns={unitsTableColumns}
                dataSource={unitsState ? unitsState.edges : null}
                loading={{spinning: loadingUnits, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                rowKey={record => record.node.id}
                pagination={{
                    pageSize: unitPageSize,
                    position: ["none", "bottomCenter"],
                    total: totalUnitCount,
                    onChange: (page, pageSize) => { changeUnitTablePage(page, pageSize) }
                }}
                scroll={{ y: "58vh" }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Looks like you need to add a unit" /> }}
                onRow={(record) => {
                    return {
                        onClick: event => { props.selectUnit(record.node) }
                    };
                }}
            />
        </>
    );
}

export default MainUnitsTable;