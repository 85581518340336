import React, { useEffect, useState, useContext, useRef } from "react";
import {Row, Col, Drawer, Button, Popconfirm} from "antd";
import MainTasksTable from "./tasks/MainTasksTable";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";
import LoadingContent from "../utility/LoadingContent";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import TaskForm from "./tasks/TaskForm";
import TaskDetail from "./tasks/TaskDetail";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {QuestionCircleOutlined} from "@ant-design/icons";

const Engagement = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [selectedTaskState, setSelectedTaskState] = useState({});
    const [selectingTaskState, setSelectingTaskState] = useState(false);
    const [refreshMainTasksTable, setRefreshMainTasksTable] = useState(0);
    const [creatingTaskState, setCreatingTaskState] = useState(false);
    const [taskFormChangedState, setTaskFormChangedState] = useState(false);
    const [taskFormLoadingState, setTaskFormLoadingState] = useState(false);
    const [unitsOptions, setUnitsOptions] = useState([]);
    const [communityManagersOptions, setCommunityManagersOptions] = useState([]);
    const taskDetailRef = useRef();

    const { data: unitsData, loading: loadingUnits } = useQuery(gql(UnitQueries.getUnitsSimple), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id } } );
    const { data: communityManagersData, loading: loadingCommunityManagers } = useQuery(gql(CommunityQueries.getCommunityManagers), { notifyOnNetworkStatusChange: true, variables: { id: communityState.id } } );
    const [loadTask, { refetch: refetchTask }] = useLazyQuery(gql(TaskQueries.getTask), { notifyOnNetworkStatusChange: true, onCompleted: ( taskData ) => { setSelectedTaskState(taskData.task) }, fetchPolicy: "network-only" });
    const [destroyTask, { loading: destroyingTask }] = useMutation(gql(TaskQueries.destroyTask));

    useEffect(() => {
        props.location.state?.showTask && selectTask(props.location.state?.showTask);
    }, []);

    useEffect(() => {
        var options = [];
        unitsData &&
        unitsData.units.edges.map(unit => {
            options.push({ label: unit.node.internalIdentifier, value: unit.node.id });
        });
        setUnitsOptions(options);
    }, [unitsData]);

    useEffect(() => {
        var options = [];
        communityManagersData &&
        communityManagersData.community.communityManagers.map(communityManager => {
            options.push({ label: communityManager.manager.fullName, value: communityManager.manager.id });
        });
        setCommunityManagersOptions(options);
    }, [communityManagersData]);

    useEffect(() => {
        setSelectingTaskState(false);
        setTaskFormChangedState(false);
    }, [selectedTaskState?.id]);

    const [drawerVisible, setDrawerVisible] = useState(false);

    const openDrawer = () => {
        setDrawerVisible(true);
    };

    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedTask();
            stopCreatingTask();
        }, 500);
    };

    const selectTask = (task) => {
        setSelectingTaskState(true);
        setTimeout(() => {
            loadTask({ variables: { id: task.node.id } });
        }, 200);
        openDrawer();
    }

    const handleTaskDestroy = async () => {
        await destroyTask({ variables: { id: selectedTaskState.id } });
        onTaskFormSuccess();
    }

    const performRefreshMainTasksTable = () => {
        setRefreshMainTasksTable(prev => prev + 1);
        props.performRefreshMyOpenTasksCountInSider();
    }

    const clearSelectedTask = () => {
        setSelectedTaskState({});
    }

    const reloadSelectedTask = () => {
        refetchTask();
    }

    const onTaskFormSuccess = () => {
        performRefreshMainTasksTable();
        onDrawerClose();
    }

    const onTaskDetailUpdateSuccess = () => {
        performRefreshMainTasksTable();
    }

    const startCreatingTask = () => {
        setCreatingTaskState(true);
        openDrawer();
    }

    const stopCreatingTask = () => {
        setCreatingTaskState(false);
    }

    const triggerTaskDetailFormSubmit = () => {
        taskDetailRef.current.triggerTaskFormSubmit();
    }

    return (
        <>
            <Row gutter={10}>
                <Col span={24}>
                    <MainTasksTable selectTask={selectTask} refreshMainTasksTable={refreshMainTasksTable} startCreatingTask={startCreatingTask} />
                </Col>
            </Row>
            <Drawer
                title="Task Details"
                placement="right"
                width={600}
                onClose={onDrawerClose}
                visible={drawerVisible}
                footer={selectedTaskState?.id &&
                    <Row justify="space-between">
                        <Col>
                            <Button
                                type="primary"
                                disabled={!taskFormChangedState}
                                onClick={() => triggerTaskDetailFormSubmit()}
                                loading={taskFormLoadingState}
                            >
                                Save Changes
                            </Button>
                        </Col>
                        <Col>
                            <Popconfirm title="Are you sure" icon={<QuestionCircleOutlined style={{ color: "red" }} />} onConfirm={() => handleTaskDestroy()}>
                                <Button danger loading={destroyingTask}>Delete</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                }
            >
                {creatingTaskState ?
                    <TaskForm
                        unitsOptions={unitsOptions}
                        loadingUnitsOptions={loadingUnits}
                        communityManagersOptions={communityManagersOptions}
                        loadingCommunityManagersOptions={loadingCommunityManagers}
                        formSuccess={onTaskFormSuccess}
                    />
                :
                    selectingTaskState ?
                            <LoadingContent />
                            :
                            <TaskDetail
                                unitsOptions={unitsOptions}
                                loadingUnitsOptions={loadingUnits}
                                communityManagersOptions={communityManagersOptions}
                                loadingCommunityManagersOptions={loadingCommunityManagers}
                                selectedTaskState={selectedTaskState}
                                setSelectedTaskState={setSelectedTaskState}
                                refetchTask={reloadSelectedTask}
                                setTaskFormChangedState={setTaskFormChangedState}
                                setTaskFormLoadingState={setTaskFormLoadingState}
                                ref={taskDetailRef}
                                formSuccess={onTaskDetailUpdateSuccess}
                            />

                }
            </Drawer>
        </>
    )
}

export default Engagement;