import React, {useState} from "react";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Col, Menu, Row, Layout, Typography} from "antd";
import {BookFilled, ContainerFilled, DashboardFilled, DollarCircleFilled, MessageFilled, SoundFilled} from "@ant-design/icons";
import {Link, useRouteMatch, useLocation} from "react-router-dom";
import FeedbackCreationModal from "../../shared/FeedbackCreationModal";
import { logoImage } from "../../utility/Logo";
import { badgeImage } from "../../utility/StoreBadge";
import Endpoints from "@dwellpass-client/common/utils/constants/Endpoints";

const { Sider } = Layout;
const { Text } = Typography;

const NavSider = (props) => {
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    let match = useRouteMatch();
    const location = useLocation();
    const { pathname } = location;

    const hideFeedbackModal = () => {
        setShowFeedbackModal(false);
    }

    return(
        <Sider collapsible style={{ overflow: 'auto', backgroundColor: Colors.brandPrimary }} breakpoint="md" onCollapse={(collapsed) => { setSiderCollapsed(collapsed) }}>
            <Row justify="center">
                { siderCollapsed ? (<Col style={{ background: `url(${logoImage("mono", "emblem")}) no-repeat center`, ...styles.smLogoContainer}} span={24}></Col>) : (<Col style={{background: `url(${logoImage("mono", "full")}) no-repeat center`, ...styles.logoContainer}} span={24}></Col>) }
            </Row>
            <Menu style={styles.navMenu} mode="inline" selectedKeys={[pathname]}>
                <Menu.Item key={match.url+"/dashboard"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <DashboardFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Dashboard</span>
                        </span>
                    </span>
                    <Link to={match.url+"/dashboard"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/posts"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <MessageFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Posts</span>
                        </span>
                    </span>
                    <Link to={match.url+"/posts"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/resources"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <ContainerFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Resources</span>
                        </span>
                    </span>
                    <Link to={match.url+"/resources"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/directory"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <BookFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Directory</span>
                        </span>
                    </span>
                    <Link to={match.url+"/directory"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/payments"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <DollarCircleFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Payments</span>
                        </span>
                    </span>
                    <Link to={match.url+"/payments"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/feedback"} style={styles.navMenuItem} onClick={() => setShowFeedbackModal(true)}>
                    <span style={styles.navItemContent}>
                        <SoundFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Feedback</span>
                        </span>
                    </span>
                </Menu.Item>
            </Menu>
            {!siderCollapsed ?
                <Row width="100%" justify="center" style={{marginTop: 30}}>
                    <Text strong style={{color: Colors.monoBright, margin: "12px 20px", textAlign: "center"}}>Download our mobile app for Residents</Text>
                    <a href={Endpoints.residentMobileGooglePlayStoreURL} target="_blank" style={{background: `url(${badgeImage("google")}) no-repeat center`, ...styles.googleBadgeContainer}}/>
                    <a href={Endpoints.residentMobileAppStoreURL} target="_blank" style={{background: `url(${badgeImage("apple")}) no-repeat center`, ...styles.appleBadgeContainer}}/>
                </Row>
                :
                null
            }
            <FeedbackCreationModal visible={showFeedbackModal} feedbackableType="UnitResident" hideMe={hideFeedbackModal} />
        </Sider>
    )
}

const styles = {
    logoContainer: {
        height: '80px',
        margin: '30px 0px',
        backgroundSize: 'auto 80px'
    },
    smLogoContainer: {
        height: '60px',
        margin: '30px 0px',
        backgroundSize: 'auto 60px'
    },
    navMenu: {
        backgroundColor: Colors.brandPrimary,
        color: Colors.monoBright,
        borderRightColor: Colors.brandPrimary
    },
    navMenuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navItemContent: {
        display: 'flex',
        alignItems: 'center'
    },
    navIcon: {
        flex: 1,
        fontSize: 20
    },
    fontAwesomeIcon: {
        paddingRight: 5,
        color: Colors.monoBright
    },
    navItemLabel: {
        flex: 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    navText: {
        fontSize: "16 !important",
        fontWeight: "bold"
    },
    googleBadgeContainer: {
        width: '100%',
        height: '60px',
        backgroundSize: 'auto 60px'
    },
    appleBadgeContainer: {
        width: '100%',
        height: '46px',
        backgroundSize: 'auto 46px'
    }
}

export default NavSider;