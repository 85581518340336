import React, {useContext, useEffect, useState} from "react";
import {Modal, Col, Form, Row, message, Typography} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import FeedbackQueries from "@dwellpass-client/common/utils/constants/queries/FeedbackQueries";
import PrimaryInput from "./PrimaryInput";
import {featureFlagState} from "@dwellpass-client/common/utils/helpers/FeatureFlagHelpers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import { deviceDetect } from "react-device-detect";

const { Text } = Typography;

const FeedbackCreationModal = (props) => {
    const [formErrors, setFormErrors] = useState([]);
    const [communityState, ] = useContext(CommunityContext);
    const [unitState, ] = useContext(UnitContext);
    const [segmentState, setSegmentState] = useState(null);
    const betaIndicatorFlagState = featureFlagState('beta_visual_indicator');
    const clientInfo = deviceDetect();

    const [form] = Form.useForm();

    const [createFeedback, { loading: loadingCreateFeedback }] = useMutation(gql(FeedbackQueries.createFeedback));

    useEffect(() => {
        loadSegment();
    }, []);

    const loadSegment = async () => {
        const segment = await AsyncStorage.getItem("segment");
        setSegmentState(segment);
    }

    const handleFormSubmit = async (values) => {
        const feedbackableId = props.feedbackableType == "CommunityManager" ? communityState.currentUserCommunityManager.id : unitState.currentUserUnitResident.id
        setFormErrors([]);

        try {
            await createFeedback({variables: { body: values.body, feedbackableType: props.feedbackableType, feedbackableId, client: clientInfo ? JSON.stringify(clientInfo) : null }});
            message.success("We value your feedback and will review it shortly. Thanks!", 6);

            resetFormAndHide();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors);
        }
    }

    const resetFormAndHide = () => {
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
            setFormErrors([]);
        }, 500);
    }

    return(
        <Modal
            title="Leave Feedback"
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={loadingCreateFeedback}
            onCancel={() => resetFormAndHide()}
        >
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
            >
                {betaIndicatorFlagState && segmentState === "community" &&
                    <Row gutter={16} style={{ marginBottom: "15px" }}>
                        <Col span={24}>
                            <Text strong style={{ fontSize: 14, color: Colors.brandPrimary }}>Please submit any issues, suggestions, or general feedback you have throughout the beta here. Thank you.</Text>
                        </Col>
                    </Row>
                }
                {formErrors && (
                    formErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Thank you for using dwellPass. Let us know your thoughts about the experience!"
                            name="body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input some feedback',
                                }
                            ]}
                        >
                            <PrimaryInput type="textarea" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default FeedbackCreationModal;