import React from 'react';
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Affix, Typography} from "antd";

const { Text } = Typography;

const BrandAffix = (props) => {
    return (
        <Affix offsetTop={30}>
            <Text style={{position: "absolute", right: props.position === "right" ? 0 : null, padding: 14, fontSize: 26, fontWeight: "bold", color: Colors.monoBright, backgroundColor: Colors.brandPrimary}}>dwellPass</Text>
        </Affix>
    );
}

export default BrandAffix;