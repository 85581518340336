import React from 'react';
import {Col, Row, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CreditCardOutlined, FileTextOutlined, MessageOutlined} from "@ant-design/icons";

const { Title } = Typography;

const ResidentContent = (props) => {
    return (
        <Row style={{ height: '100%' }} justify="center" align="middle">
            <Col style={{ backgroundColor: Colors.monoMedGreySemiTransparent, padding: 24, borderRadius: 10 }} span={16}>
                <Title style={{ color: Colors.monoBright }}>The Key to Your Community</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><MessageOutlined style={{ marginRight: 6 }} /> Communicate</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><FileTextOutlined style={{ marginRight: 6 }} /> Find Policies</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><CreditCardOutlined style={{ marginRight: 6 }} /> Make Payments</Title>
            </Col>
        </Row>
    );
}

export default ResidentContent;