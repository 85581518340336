import React, {useState} from 'react';
import {Divider} from "antd";
import StatisticsSection from "./metrics/StatisticsSection";
import RevenuesSection from "./metrics/RevenuesSection";

const Metrics = (props) => {
    const [performFilterByMTDBilled, setPerformFilterByMTDBilled] = useState(0);
    const [performFilterByOpenInvoices, setPerformFilterByOpenInvoices] = useState(0);
    const [performFilterByPastDueInvoices, setPerformFilterByPastDueInvoices] = useState(0);

    const filterByMTDBilled = () => {
        setPerformFilterByMTDBilled(prev => prev + 1);
    }

    const filterByOpenInvoices = () => {
        setPerformFilterByOpenInvoices(prev => prev + 1);
    }

    const filterByPastDueInvoices = () => {
        setPerformFilterByPastDueInvoices(prev => prev + 1);
    }

    return (
        <>
            <StatisticsSection filterByMTDBilled={filterByMTDBilled} filterByOpenInvoices={filterByOpenInvoices} filterByPastDueInvoices={filterByPastDueInvoices} />
            <Divider />
            <RevenuesSection performFilterByMTDBilled={performFilterByMTDBilled} performFilterByOpenInvoices={performFilterByOpenInvoices} performFilterByPastDueInvoices={performFilterByPastDueInvoices} />
        </>
    );
}

export default Metrics;