import React, { useState, useContext } from "react";
import {notification, Upload, Button, message, Modal, Row, Typography, Drawer} from "antd";
import {ImportOutlined, LoadingOutlined, UploadOutlined, DownloadOutlined} from "@ant-design/icons";
import {gql, useMutation} from "@apollo/client";
import FileUploadQueries from "@dwellpass-client/common/utils/constants/queries/FileUploadQueries";
import axios from "axios";
import FileHelpers from "@dwellpass-client/common/utils/file_handling/FileHelpers";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import DataImportQueries from "@dwellpass-client/common/utils/constants/queries/DataImportQueries";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import UnitImportTemplate from "../../../assets/supporting/Unit-Import-Template.xlsx";

const { Title, Text } = Typography;

const UnitImportUploader = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [loadingState, setLoadingState] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [createDirectUpload, ] = useMutation(gql(FileUploadQueries.createDirectUpload));
    const [createDataImport, ] = useMutation(gql(DataImportQueries.createDataImport));

    const beforeUpload = (file)  => {
        const isLt30M = file.size / 1024 / 1024 < 30;
        if (!isLt30M) {
            message.error("Filesize cannot exceed 30MB");
        }
        return isLt30M;
    }

    const uploadFile = async options => {
        setIsModalOpen(true);
        setIsDrawerOpen(false);

        const { onSuccess, onError, file, onProgress } = options;
        var checksum = await FileHelpers.calculateChecksum(file);

        try {
            var directUpload = await createDirectUpload({ variables: { filename: file.name, contentType: file.type, checksum, byteSize: file.size } });
            var uploadHeaders = JSON.parse(directUpload.data.createDirectUpload.directUpload.headers);
            var signedBlobId = directUpload.data.createDirectUpload.directUpload.signedBlobId;

            const config = {
                headers: uploadHeaders,
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };
            await axios.put(directUpload.data.createDirectUpload.directUpload.url, file, config);

            var dataImport = await createDataImport({variables: { communityId: communityState.id, importType: "Unit", dataImportFileBlobId: signedBlobId }});

            props.uploadSuccessDataImport(dataImport.data.createDataImport);

            notification["success"]({
                message: "Units Imported Successfully",
                description:
                    dataImport.data.createDataImport.recordsProcessed !== dataImport.data.createDataImport.recordsChanged ?
                        `${dataImport.data.createDataImport.recordsProcessed} processed. ${dataImport.data.createDataImport.recordsChanged} imported or updated.`
                        :
                        `${dataImport.data.createDataImport.recordsProcessed} processed.`
            });
            setIsModalOpen(false);

            onSuccess("Ok");
        } catch (error) {
            notification["error"]({
                message: "Error Uploading File",
                description:
                    "Something went wrong. Please verify your file is formatted correctly and try again.",
            });
            setIsModalOpen(false);

            onError({ error });
        }
    }

    const handleChange = info => {
        if (info.file.status === "uploading") {
            setLoadingState(true);
        } else {
            setLoadingState(false);
        }
    };

    const infoDrawer = () => {
        return <Drawer title="Import Units" placement="right" onClose={() => setIsDrawerOpen(false)} visible={isDrawerOpen}>
            <Row>
                <Text>Entering Unit location information manually can be a large task, especially when there are clicks involved. We've provided an import mechanism to speed up this process. Simply download the below template and enter (or copy) your Unit information into the spreadsheet.</Text>
                <br />
                <Text style={{ color: Colors.dangerRed }}>Note: The header in this template <span style={{ fontStyle: "italic", fontWeight: "bold" }}>must</span> remain the same as it is when you downloaded it, and fields with an asterisk(*) are <span style={{ fontStyle: "italic", fontWeight: "bold" }}>required</span>.</Text>
            </Row>
            <Row style={{ padding: 20 }} justify="center">
                <a href={UnitImportTemplate} download="Unit-Import-Template.xlsx" target="_blank">
                    <Button type="secondary" icon={<DownloadOutlined />}>Download Template</Button>
                </a>
            </Row>
            <Row>
                <Text>When you're finished filling out the information, you can upload it here.</Text>
                <br />
                <Text>Note: If you make a mistake, or need to add any additional Units, you can re-upload the template. Any Units with an existing "Internal Identifier" will remain in the system and the address will be updated, new Units will be added in addition to the existing.</Text>
            </Row>
            <Row style={{ padding: 20 }} justify="center">
                <Upload
                    name="file-uploader"
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={uploadFile}
                >
                    <Button type="primary" icon={loadingState ? <LoadingOutlined /> : <UploadOutlined />}>Upload Completed File</Button>
                </Upload>
            </Row>
        </Drawer>
    }

    const loadingModal = () => {
        return <Modal style={{ padding: 100 }} visible={isModalOpen} closable={false} maskClosable={false} footer={null}>
            <Row justify="center" style={{ marginBottom: 5 }}>
                <Title level={3}>Importing Units</Title>
            </Row>
            <Row justify="center">
                <LoadingOutlined style={{ fontSize: 35, color: Colors.brandPrimary }} />
            </Row>
        </Modal>;
    }

    return(
        <>
            <Button type="secondary" icon={loadingState ? <LoadingOutlined /> : <ImportOutlined />} onClick={() => setIsDrawerOpen(true)}>Import Units</Button>
            {loadingModal()}
            {infoDrawer()}
        </>
    )
}

export default UnitImportUploader;