import React, { useContext } from "react";
import { Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import Login from "../components/Login";
import Register from "../components/Register";
import PasswordReset from "../components/PasswordReset";
import BootRouter from "./BootRouter";
import { AuthContext } from "../contexts/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CommunityMaster from "../components/community/Master";
import ResidentMaster from "../components/resident/Master";
import PrivateRoute from "../helpers/PrivateRoute";
import QuickStartRoute from "../helpers/QuickStartRoute";
import GraphQLProvider from "../providers/GraphQLProvider";
import { UserController } from "@dwellpass-client/common/contexts/UserContext";
import CommunityRegistration from "../components/CommunityRegistration";
import {CommunityController} from "@dwellpass-client/common/contexts/CommunityContext";
import Logout from "../components/Logout";
import {UnitController} from "@dwellpass-client/common/contexts/UnitContext";
import {FeatureFlagsController} from "@dwellpass-client/common/contexts/FeatureFlagsContext";
import DesktopOnlyOverlay from "../components/utility/DesktopOnlyOverlay";
import {SettingsController} from "@dwellpass-client/common/contexts/SettingsContext";

const RootRouter = () => {
    let history = useHistory();
    let location = useLocation();
    const [authContext, dispatch] = useContext(AuthContext);

    React.useEffect(() => {
        checkCommunityRegistrationIntent();
        authenticateUser();
    }, []);

    const checkCommunityRegistrationIntent = () => {
        location.pathname === "/community-registration" && setCommunityRegistrationIntent();
    }

    const authenticateUser = () => {
        var uid, client, accessToken;

        AsyncStorage.multiGet(['uid', 'client', 'access-token'], (err, stores) => {
            stores.map((result, i, store) => {
                if(store[i][0] == 'uid'){uid = store[i][1]}
                if(store[i][0] == 'client'){client = store[i][1]}
                if(store[i][0] == 'access-token'){accessToken = store[i][1]}
            });
            if(uid && client && accessToken){
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        uid,
                        client,
                        accessToken
                    }
                });
                history.replace("/boot");
            }
        });
    }

    const setCommunityRegistrationIntent = () => {
        AsyncStorage.setItem("community-registration-intent", true);
    }

    return (
        <Switch>
            <Redirect from="/" exact to="/boot" />
            <Redirect from="/login" exact to="/login/community" />
            <QuickStartRoute path="/login/:segment" component={ Login } authed={ authContext.isAuthenticated } />
            <Redirect from="/register" exact to="/register/community" />
            <QuickStartRoute path="/register/:segment" component={ Register } authed={ authContext.isAuthenticated } />
            <QuickStartRoute path="/password-reset/:segment" component={ PasswordReset } authed={ authContext.isAuthenticated } />
            <GraphQLProvider>
                <UserController>
                    <FeatureFlagsController>
                        <SettingsController>
                            <CommunityController>
                                <UnitController>
                                    <DesktopOnlyOverlay />
                                    <PrivateRoute path="/boot" component={ BootRouter } authed={ authContext.isAuthenticated } />
                                    <PrivateRoute path="/logout" component={ Logout } authed={ authContext.isAuthenticated } />
                                    <PrivateRoute path="/community-registration" component={ CommunityRegistration } authed={ authContext.isAuthenticated } unauthedRoute="/register/community" />
                                    <PrivateRoute path="/community" component={ CommunityMaster } authed={ authContext.isAuthenticated } />
                                    <PrivateRoute path="/resident" component={ ResidentMaster } authed={ authContext.isAuthenticated } />
                                </UnitController>
                            </CommunityController>
                        </SettingsController>
                    </FeatureFlagsController>
                </UserController>
            </GraphQLProvider>
            <Redirect from="*" exact to="/boot" />
        </Switch>
    )
}

export default RootRouter;