import React, {useContext, useEffect, useState} from 'react';
import {Table, Typography} from "antd";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import MetricsQueries from "@dwellpass-client/common/utils/constants/queries/MetricsQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {LoadingOutlined, MinusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import {useHistory} from "react-router-dom";

const { Text } = Typography;

const LatestInvoicesTable = (props) => {
    const invoicePageSize = 10;
    const history = useHistory();

    const [communityState,] = useContext(CommunityContext);
    const [invoiceRevenuesState, setInvoiceRevenuesState] = useState([]);

    const {
        data: invoiceRevenuesData,
        loading: loadingInvoiceRevenues
    } = useQuery(gql(MetricsQueries.getInvoiceRevenues), {
        notifyOnNetworkStatusChange: true,
        variables: {communityId: communityState.id, status: "open", first: invoicePageSize}
    });

    useEffect(() => {
        invoiceRevenuesData && invoiceRevenuesData.invoiceRevenues && setInvoiceRevenuesState(invoiceRevenuesData.invoiceRevenues);
    }, [invoiceRevenuesData]);

    const navToUnitDetailInvoicesSection = (invoice) => {
        history.push({
            pathname: '/community/units/detail',
            state: { unitId: invoice.unitResident.unit.id, showInvoices: true }
        })
    }

    const invoiceStatusText = (invoice) => {
        switch (invoice.status) {
            case "open":
                return invoice.pastDue ?
                    <Text strong style={{ color: Colors.dangerRed }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                    :
                    <Text style={{ color: Colors.mainBlue }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                break;
            default:
                return <Text>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
        }
    }

    const tableColumns = [
        {
            title: 'Unit',
            dataIndex: ['node', 'unitResident', 'unit', 'internalIdentifier'],
        },
        {
            title: 'Resident',
            dataIndex: ['node', 'unitResident', 'resident', 'fullName'],
        },
        {
            title: 'Status',
            render: (text, record) => (
                invoiceStatusText(record.node)
            )
        },
        {
            title: 'Due Date',
            render: (text, record) => (
                <Text>{DateTimeFormatter.simpleDateOnly(record.node.dueDate)}</Text>
            )
        },
        {
            title: 'Past Due',
            render: (text, record) => (
                record.node.pastDueDays > 0 ?
                    <Text style={{ color: Colors.dangerRed }}>{record.node.pastDueDays} day{record.node.pastDueDays > 1 && 's'}</Text>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Total Due',
            render: (text, record) => (
                <Text>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        }
    ];

    return (
        <Table
            columns={tableColumns}
            dataSource={invoiceRevenuesState && invoiceRevenuesState.edges}
            rowKey={record => record.node.id}
            scroll={{ y: "50vh" }}
            loading={{spinning: loadingInvoiceRevenues, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            pagination={false}
            onRow={(record) => {
                return {
                    onClick: event => { navToUnitDetailInvoicesSection(record.node) }
                };
            }}
        />
    );
}

export default LatestInvoicesTable;