import React, {useContext, useEffect, useState} from 'react';
import {Table, Typography, Row, Col} from "antd";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import MetricsQueries from "@dwellpass-client/common/utils/constants/queries/MetricsQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {LoadingOutlined, MinusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";

const { Text } = Typography;

const InvoiceRevenuesTable = (props) => {
    const invoicePageSize = 20;

    const [communityState,] = useContext(CommunityContext);
    const [invoiceRevenuesState, setInvoiceRevenuesState] = useState([]);
    const [invoiceGrandTotalCents, setInvoiceGrandTotalCents] = useState();
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);

    const {
        data: invoiceRevenuesData,
        loading: loadingInvoiceRevenues,
        refetch: refetchInvoiceRevenues
    } = useQuery(gql(MetricsQueries.getInvoiceRevenues), {
        notifyOnNetworkStatusChange: true,
        variables: {communityId: communityState.id, unitId: props.unitFilterState, status: props.statusFilterState, startDate: props.startDateFilterState, endDate: props.endDateFilterState, first: invoicePageSize, after: pageOffsetState}
    });

    useEffect(() => {
        invoiceRevenuesData && invoiceRevenuesData.invoiceRevenues && setInvoiceRevenuesState(invoiceRevenuesData.invoiceRevenues);
        invoiceRevenuesData && invoiceRevenuesData.invoiceRevenues && setInvoiceGrandTotalCents(invoiceRevenuesData.invoiceRevenues.grandTotalCents);
        invoiceRevenuesData && invoiceRevenuesData.invoiceRevenues && setTotalInvoiceCount(invoiceRevenuesData.invoiceRevenues.totalCount);
    }, [invoiceRevenuesData]);

    useEffect(() => {
        refetchInvoiceRevenues();
    }, [props.unitFilterState, props.statusFilterState, props.startDateFilterState, props.endDateFilterState, pageOffsetState]);

    const invoiceStatusText = (invoice) => {
        switch (invoice.status) {
            case "open":
                return invoice.pastDue ?
                    <Text strong style={{ color: Colors.dangerRed }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                    :
                    <Text style={{ color: Colors.mainBlue }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                break;
            default:
                return <Text>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
        }
    }

    const tableColumns = [
        {
            title: 'Unit',
            dataIndex: ['node', 'unitResident', 'unit', 'internalIdentifier'],
        },
        {
            title: 'Resident',
            dataIndex: ['node', 'unitResident', 'resident', 'fullName'],
        },
        {
            title: 'Status',
            render: (text, record) => (
                invoiceStatusText(record.node)
            )
        },
        {
            title: 'Due Date',
            render: (text, record) => (
                <Text>{DateTimeFormatter.simpleDateOnly(record.node.dueDate)}</Text>
            )
        },
        {
            title: 'Past Due',
            render: (text, record) => (
                record.node.pastDueDays > 0 ?
                    <Text style={{ color: Colors.dangerRed }}>{record.node.pastDueDays} day{record.node.pastDueDays > 1 && 's'}</Text>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Refunded',
            render: (text, record) => (
                record.node.amountRefundedCents > 0 ?
                    <Text>{MoneyFormatter.standardMoneyUSD(record.node.amountRefundedCents)}</Text>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Revenue',
            render: (text, record) => (
                <Text>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        }
    ];

    const tableFooter =
        invoiceGrandTotalCents > 0 ?
            <Row gutter={10} justify="center">
                <Col>
                    <Text strong>Invoices: {totalInvoiceCount}</Text>
                </Col>
                <Col>
                    <Text strong>|</Text>
                </Col>
                <Col>
                    <Text strong>Grand Total: {MoneyFormatter.standardMoneyUSD(invoiceGrandTotalCents)}</Text>
                </Col>
            </Row>
            : undefined;

    const changeInvoiceTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const expandedRowRender = (parentRecord) => {
        const itemizationColumns = [
            {
                title: 'Line Item Description',
                dataIndex: 'description'
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity'
            },
            {
                title: 'Amount',
                render: (text, record) => (
                    <Text>{MoneyFormatter.standardMoneyUSD(record.amountCents)}</Text>
                )
            }
        ];
        const refundColumns = [
            {
                title: 'Refunds',
                render: (text, record) => (
                    <Text>{StringFormatter.capitalizeFirstLetter(record.status)}</Text>
                )
            },
            {
                title: 'Created',
                render: (text, record) => (
                    <Text>{DateTimeFormatter.simpleDateOnly(record.stripeCreated)}</Text>
                )
            },
            {
                title: 'Amount',
                render: (text, record) => (
                    <Text>{MoneyFormatter.standardMoneyUSD(record.amountCents)}</Text>
                )
            }
        ];
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Table columns={itemizationColumns} dataSource={parentRecord.node.invoiceLineItems} pagination={false} />
                    </Col>
                </Row>
                {!!parentRecord.node.refunds[0] &&
                    <Row style={{ marginTop: 16 }}>
                        <Col span={24}>
                            <Table columns={refundColumns} dataSource={parentRecord.node.refunds} pagination={false} />
                        </Col>
                    </Row>
                }
            </>
            );
    };

    return (
        <Table
            columns={tableColumns}
            dataSource={invoiceRevenuesState && invoiceRevenuesState.edges}
            size="small"
            rowKey={record => record.node.id}
            scroll={{ y: "40vh" }}
            loading={{spinning: loadingInvoiceRevenues, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            pagination={{
                pageSize: invoicePageSize,
                position: ["none", "bottomCenter"],
                total: totalInvoiceCount,
                onChange: (page, pageSize) => { changeInvoiceTablePage(page, pageSize) }
            }}
            expandable={{
                expandIconColumnIndex: 7,
                expandRowByClick: true,
                expandedRowRender
            }}
            footer={() => tableFooter}
        />
    );
}

export default InvoiceRevenuesTable;