import React, { useContext, useEffect } from "react";
import LoadingFullscreen from "./utility/LoadingFullscreen";
import {AuthContext} from "../contexts/AuthContext";
import {useHistory} from "react-router-dom";

const Logout = (props) => {
    let history = useHistory();
    const [_, dispatch] = useContext(AuthContext);

    useEffect(() => {
        dispatch({
            type: 'LOGOUT'
        });

        const segment = props.location?.state?.segment;
        segment ? navToLoginWithSegment(segment) : navToCommunityLogin();
    }, [])

    const navToCommunityLogin = () => {
        history.push("/login/community");
    }

    const navToLoginWithSegment = (segment) => {
        history.push("/login/"+segment);
    }

    return(
        <LoadingFullscreen />
    )
}

export default Logout;