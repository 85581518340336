import React, {useState} from 'react';
import DirectoryListingTable from "./directory/DirectoryListingTable";
import {Drawer} from "antd";
import DirectoryListingForm from "./directory/DirectoryListingForm";

const Directory = (props) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedDirectoryListingState, setSelectedDirectoryListingState] = useState({});
    const [refreshDirectoryListingsTable, setRefreshDirectoryListingsTable] = useState(0);

    const openDrawer = () => {
        setDrawerVisible(true);
    };

    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedDirectoryListing();
        }, 500);
    };

    const performRefreshDirectoryListingsTable = () => {
        setRefreshDirectoryListingsTable(prev => prev + 1);
    }

    const editDirectoryListing = (directoryListing) => {
        setSelectedDirectoryListingState(directoryListing);
        openDrawer();
    }

    const clearSelectedDirectoryListing = () => {
        setSelectedDirectoryListingState({});
    }

    const closeDrawerAndRefetchDirectoryListings = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedDirectoryListing();
        }, 500);
        performRefreshDirectoryListingsTable();
    }

    return (
        <>
            <DirectoryListingTable refreshDirectoryListingsTable={refreshDirectoryListingsTable} editDirectoryListing={editDirectoryListing} addDirectoryListing={openDrawer} />
            <Drawer
                title="Listing Information"
                placement="right"
                width={340}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <DirectoryListingForm selectedDirectoryListingState={selectedDirectoryListingState} formSuccess={closeDrawerAndRefetchDirectoryListings} />
            </Drawer>
        </>
    );
}

export default Directory;