import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useMutation, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {Button, Empty, Popconfirm, Row, Space, Table, message, Tooltip} from "antd";
import {ApartmentOutlined, DeleteOutlined, HomeOutlined, SendOutlined, MinusOutlined, QuestionCircleOutlined, LoadingOutlined, InfoCircleOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import InviteQueries from "@dwellpass-client/common/utils/constants/queries/InviteQueries";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const PendingInviteList = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const { data: communityPendingInvitesData, loading: loadingCommunityPendingInvites, refetch: refetchCommunityPendingInvites } = useQuery(gql(CommunityQueries.getCommunityAllPendingInvites),{ notifyOnNetworkStatusChange: true, variables: { id: communityState.id } });
    const [communityPendingInvitesState, setCommunityPendingInvitesState] = useState([]);
    const [resendInvite, { loading: loadingResendInvite }] = useMutation(gql(InviteQueries.resendInvite));
    const [destroyInvite, { loading: loadingDestroyInvite }] = useMutation(gql(InviteQueries.destroyInvite));

    useEffect(() => {
        communityPendingInvitesData && setCommunityPendingInvitesState(communityPendingInvitesData.community.allPendingInvites);
    }, [communityPendingInvitesData]);

    useEffect(() => {
        props.setLoadingState(loadingCommunityPendingInvites || loadingDestroyInvite || loadingResendInvite);
    }, [loadingCommunityPendingInvites, loadingDestroyInvite, loadingResendInvite]);

    useEffect(() => {
        refetchCommunityPendingInvites();
    }, [props.refreshCurrentTabTable]);

    const typeIcon = (type) => {
        switch (type) {
            case "Community":
                return(
                    <ApartmentOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                )
            case "Unit":
                return(
                    <HomeOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                )
            default:
                return <MinusOutlined style={{fontSize: 18}}/>;
        }
    }

    const invitedToFromInvite = (invite) => {
        switch (true) {
            case invite.invitable && invite.invitable.__typename == "Community":
                return "Community";
            case invite.invitable && invite.invitable.__typename == "Unit":
                return invite.invitable.internalIdentifier;
            default:
                return "Not Found";
        }
    }

    const pendingInvitesTableColumns = [
        {
            key: 'type',
            dataIndex: ['node', 'type'],
            width: 50,
            render: (text, record) => (
                typeIcon(record.invitable.__typename)
            ),
        },
        {
            title: 'Invited to',
            key: 'invitedTo',
            render: (text, record) => (
                invitedToFromInvite(record)
            ),
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email'
        },
        {
            title: 'Created',
            key: 'createdAt',
            render: (text, record) => (
                DateTimeFormatter.standardDateTime(record.createdAt)
            ),
        },
        {
            title: <Tooltip placement="topLeft" title="This includes manually sent invitations and automated system reminders">
                Notifications <InfoCircleOutlined style={{ fontSize: 14, color: Colors.monoMedGrey }} />
            </Tooltip>,
            key: 'sendCount',
            dataIndex: 'sendCount'
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <>
                    <Button type="link" onClick={(e) => {e.stopPropagation(); resendPendingInvite(record);}}><SendOutlined style={{fontSize: 13}} />Resend</Button>
                    <Space size="middle">
                        <Popconfirm title="Are you sure" icon={<QuestionCircleOutlined style={{ color: "red" }} />} onConfirm={() => deletePendingInvite(record)}>
                            <Button type="link" onClick={(e) => {e.stopPropagation()}}><DeleteOutlined style={{fontSize: 13}} /></Button>
                        </Popconfirm>
                    </Space>
                </>
            ),
        }
    ];

    const resendPendingInvite = async (invite) => {
        await resendInvite({ variables: { id: invite.id } });
        message.success('Invite resent to: '+invite.email);
        setTimeout(() => {
            refetchCommunityPendingInvites();
        }, 500);
    }

    const deletePendingInvite = async (invite) => {
        await destroyInvite({ variables: { id: invite.id } });
        refetchCommunityPendingInvites();
    }

    return(
        <Row justify="center">
            <Table
                style={{ width: "100%" }}
                size="small"
                dataSource={communityPendingInvitesState ? communityPendingInvitesState : null}
                loading={{spinning: props.loadingState, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                columns={pendingInvitesTableColumns}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Pending Invites" /> }}
                pagination={false}
                rowKey="id"
            />
        </Row>
    )
}

export default PendingInviteList;