import React, { useContext, useState, useEffect } from "react";
import {Button, Checkbox, Col, Divider, Form, Input, Row, Typography, Select, Tooltip, InputNumber} from "antd";
import {gql, useMutation, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import PrimaryInput from "../shared/PrimaryInput";
import moment from "moment-timezone";
import TimeZoneQueries from "@dwellpass-client/common/utils/constants/queries/TimeZoneQueries";
import {InfoCircleOutlined} from "@ant-design/icons";

const { Text } = Typography;

const GeneralInfoStep = (props) => {
    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [communityState, updateCommunityState] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const [form] = Form.useForm();
    const [timeZonesState, setTimeZonesState] = useState([]);

    const { data: timeZonesData, loading: loadingTimeZones } = useQuery(gql(TimeZoneQueries.getTimeZones), { notifyOnNetworkStatusChange: true } );
    const [createCommunity, ] = useMutation(gql(CommunityQueries.createCommunity), { onCompleted: ( communityData ) => { updateCommunityState({type: "set", payload: communityData.createCommunity}) } });
    const [updateCommunity, ] = useMutation(gql(CommunityQueries.updateCommunity), { onCompleted: ( communityData ) => { updateCommunityState({type: "set", payload: communityData.updateCommunity}) } });

    useEffect(() => {
        timeZonesData?.timeZones && setTimeZonesState(timeZonesData.timeZones);
    }, [timeZonesData]);

    useEffect(() => {
        const guessedTimeZone = moment.tz.guess(true);
        const verifiedTimeZone = timeZonesState.find(timeZone => timeZone.name === guessedTimeZone);
        const currentTimeZoneSelectValue = form.getFieldValue("timeZoneId");
        verifiedTimeZone && !currentTimeZoneSelectValue && form.setFieldsValue({timeZoneId: verifiedTimeZone.id});
    }, [timeZonesState]);

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            if(communityState.id) {
                await updateCommunity({variables: { id: communityState.id, name: values.name, website: values.website, contactEmail: values.contactEmail, contactPhoneNumber: values.contactPhoneNumber, addressStreet1: values.addressLine1, addressStreet2: values.addressLine2, addressCity: values.addressCity, addressState: values.addressState, addressZip: values.addressZip, timeZoneId: values.timeZoneId, communityStatedUnitCount: values.unitCount }});
            } else {
                await createCommunity({variables: { name: values.name, website: values.website, contactEmail: values.contactEmail, contactPhoneNumber: values.contactPhoneNumber, addressStreet1: values.addressLine1, addressStreet2: values.addressLine2, addressCity: values.addressCity, addressState: values.addressState, addressZip: values.addressZip, timeZoneId: values.timeZoneId, communityStatedUnitCount: values.unitCount }});
            }
            setFormState({isSubmitting: false, errorMessage: null});
            props.goToNextStep();
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    const fillEmailFieldIfChecked = (e) => {
        form.setFieldsValue({ contactEmail: e.target.checked ? userState.email : "" });
    }

    return (
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
                  initialValues={{
                      ["name"]: communityState.name,
                      ["website"]: communityState.website,
                      ["contactEmail"]: communityState.contactEmail,
                      ["contactPhoneNumber"]: communityState.contactPhoneNumber,
                      ["addressLine1"]: communityState.address ? communityState.address.street1 : null,
                      ["addressLine2"]: communityState.address ? communityState.address.street2 : null,
                      ["addressCity"]: communityState.address ? communityState.address.city : null,
                      ["addressState"]: communityState.address ? communityState.address.state : null,
                      ["addressZip"]: communityState.address ? communityState.address.zip : null,
                      ["timeZoneId"]: communityState.timeZone ? communityState.timeZone?.id : "",
                      ["unitCount"]: communityState.communityStatedUnitCount
                  }}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="name"
                            label="Community Name"
                            rules={[{ required: true, message: 'Please enter the name of your community' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="website"
                            label="Website"
                        >
                            <Input
                                style={{ width: '100%' }}
                                addonBefore="https://"
                                placeholder="www..."
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="unitCount"
                            label={
                                <>
                                    Unit Count&nbsp;
                                    <Tooltip title="How many places of residence does your Community manage? This can be a home, apartment, or any other dwelling.">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                </>
                            }
                            rules={[{required: true, message: 'Please enter the unit count'}]}
                        >
                            <InputNumber
                                style={{width: "100%"}}
                                min={1}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Contact Email"
                            name="contactEmail"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the contact email',
                                },
                                {
                                    type: 'email',
                                    message: 'Must be a valid email',
                                },
                            ]}
                            style={{ marginBottom: 10 }}
                        >
                            <Input />
                        </Form.Item>
                        <Checkbox onChange={fillEmailFieldIfChecked}>Use my email address</Checkbox>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="contactPhoneNumber"
                            label="Contact Phone Number"
                            rules={[{ required: true, message: 'Please enter the contact phone number' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="timeZoneId"
                            label="Time Zone"
                            rules={[{ required: true, message: 'Please select a time zone' }]}
                        >
                            <Select
                                loading={loadingTimeZones}
                                placeholder="Select Time Zone"
                                options={timeZonesState.map((tz) => ({
                                    value: tz.id,
                                    label: tz.label
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Mailing Address</Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="addressLine1"
                            label="Address Line 1"
                            rules={[{ required: true, message: 'Please enter line 1 of your address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="addressLine2"
                            label="Address Line 2"
                            rules={[{ required: false, message: 'Please enter line 2 of your address' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="addressCity"
                            label="City"
                            rules={[{ required: true, message: 'Please enter the city' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="addressState"
                            label="State"
                            rules={[{ required: true, message: 'Please enter the state' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="addressZip"
                            label="Zip Code"
                            rules={[{ required: true, message: 'Please enter the zip code' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button style={{ marginTop: 10 }} type="primary" htmlType="submit" loading={formState.isSubmitting}>
                                Continue
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default GeneralInfoStep;