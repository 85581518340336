import React, { useState, useContext, useEffect } from "react";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import ResourceQueries from "@dwellpass-client/common/utils/constants/queries/ResourceQueries";
import {Col, Drawer, Row} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import LoadingContent from "../utility/LoadingContent";
import MainResourcesTable from "./resources/MainResourcesTable";
import ResourceForm from "./resources/ResourceForm";
import ResourceDetail from "./resources/ResourceDetail";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const Resources = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [selectedResourceState, setSelectedResourceState] = useState({});
    const [refreshMainResourcesTable, setRefreshMainResourcesTable] = useState(0);
    const [unitsOptions, setUnitsOptions] = useState([]);

    const { data: unitsData, loading: loadingUnits } = useQuery(gql(UnitQueries.getUnitsSimple), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id } } );
    const [loadResource, { loading: loadingResource }] = useLazyQuery(gql(ResourceQueries.getResource), { notifyOnNetworkStatusChange: true, onCompleted: ( resourceData ) => { setSelectedResourceState(resourceData.resource) }, fetchPolicy: "network-only" })
    const [destroyResource, { loading: destroyingResource }] = useMutation(gql(ResourceQueries.destroyResource));

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);

    useEffect(() => {
        var options = [];
        unitsData &&
        unitsData.units.edges.map(unit => {
            options.push({ label: unit.node.internalIdentifier, value: unit.node.id });
        });
        setUnitsOptions(options);
    }, [unitsData]);

    const openDrawer = () => {
        setDrawerVisible(true);
        setDetailVisible(false);
    };
    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedResource();
        }, 500);
    };

    const selectResource = (resource) => {
        setDetailVisible(true);
        loadResource({ variables: { id: resource.node.id } });
    }

    const performRefreshMainResourcesTable = () => {
        setRefreshMainResourcesTable(prev => prev + 1);
    }

    const clearSelectedResource = () => {
        setDetailVisible(false);
        setSelectedResourceState({});
    }

    const onResourceFormSuccess = () => {
        performRefreshMainResourcesTable();
        onDrawerClose();
    }

    const uploadSuccessResource = (resource) => {
        performRefreshMainResourcesTable();
        setSelectedResourceState(resource);
        editResource();
    }

    const editResource = () => {
        openDrawer();
    }

    const startCreatingResource = () => {
        clearSelectedResource();
        openDrawer();
    }

    const handleResourceDestroy = async () => {
        await destroyResource({ variables: { id: selectedResourceState.id } });
        onResourceFormSuccess();
    }

    return(
        <>
            <Row gutter={10}>
                <Col span={detailVisible ? 17 : 24}>
                    <MainResourcesTable startCreatingResource={startCreatingResource} selectResource={selectResource} refreshMainResourcesTable={refreshMainResourcesTable} uploadSuccessResource={uploadSuccessResource} loadingUnits={loadingUnits} unitsOptions={unitsOptions} />
                </Col>
                <Col span={detailVisible ? 7 : 0} style={{ borderLeft: `2px solid ${Colors.monoLightGrey}` }}>
                    {loadingResource ?
                        <LoadingContent/>
                        :
                        selectedResourceState &&
                            <ResourceDetail selectedResourceState={selectedResourceState} editResource={editResource} handleResourceDestroy={handleResourceDestroy} destroyingResource={destroyingResource} clearSelectedResource={clearSelectedResource} />
                    }
                </Col>
            </Row>
            <Drawer
                title="Resource Details"
                placement="right"
                width={420}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <ResourceForm selectedResourceState={selectedResourceState} formSuccess={onResourceFormSuccess} loadingUnits={loadingUnits} unitsOptions={unitsOptions}/>
            </Drawer>
        </>
    )
}

export default Resources;