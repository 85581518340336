import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Drawer} from "antd";
import MainPostsTable from "./engagement/MainPostsTable";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import PostDetail from "./engagement/PostDetail";
import PostForm from "./engagement/PostForm";
import LoadingContent from "../utility/LoadingContent";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const Engagement = (props) => {
    const [unitState, ] = useContext(UnitContext);
    const [selectedPostState, setSelectedPostState] = useState({});
    const [refreshMainPostsTable, setRefreshMainPostsTable] = useState(0);
    const [creatingPostState, setCreatingPostState] = useState(false);

    const [loadPost, { loading: loadingPost, refetch: refetchPost }] = useLazyQuery(gql(PostQueries.getPost), { notifyOnNetworkStatusChange: true, onCompleted: ( postData ) => { setSelectedPostState(postData.post) }, fetchPolicy: "network-only" })
    const [readPost, ] = useMutation(gql(PostQueries.readPost), { onCompleted: ( postData ) => { performRefreshMainPostsTable(); } });

    useEffect(() => {
        props.location.state && props.location.state.postDetails && startCreatingPost();
        props.location.state && props.location.state.showPost && selectPost(props.location.state.showPost);
    },[]);

    const [drawerVisible, setDrawerVisible] = useState(false);

    const openDrawer = () => {
        setDrawerVisible(true);
    };
    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedPost();
            stopCreatingPost();
        }, 500);
    };

    const selectPost = (post) => {
        loadPost({ variables: { id: post.node.id } });
        markPostReadIfNecessary(post);
        openDrawer();
    }

    const markPostReadIfNecessary = (post) => {
        if(post.node.unread || post.node.unreadReplies) {
            readPost({ variables: { id: post.node.id, readerType: "UnitResident", readerId: unitState.currentUserUnitResident.id } });
        }
    }

    const performRefreshMainPostsTable = () => {
        setRefreshMainPostsTable(prev => prev + 1);
    }

    const clearSelectedPost = () => {
        setSelectedPostState({});
    }

    const reloadSelectedPost = () => {
        refetchPost();
    }

    const onPostFormSuccess = () => {
        performRefreshMainPostsTable();
        onDrawerClose();
    }

    const startCreatingPost = () => {
        setCreatingPostState(true);
        openDrawer();
    }

    const stopCreatingPost = () => {
        setCreatingPostState(false);
    }

    return (
        <>
            <Row gutter={10}>
                <Col span={24}>
                    <MainPostsTable selectPost={selectPost} refreshMainPostsTable={refreshMainPostsTable} startCreatingPost={startCreatingPost} />
                </Col>
            </Row>
            <Drawer
                title="Post Details"
                placement="right"
                width={720}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                {creatingPostState ?
                    <PostForm formSuccess={onPostFormSuccess} />
                :
                    loadingPost ?
                            <LoadingContent />
                            :
                            <PostDetail selectedPostState={selectedPostState} refetchPost={reloadSelectedPost} />

                }
            </Drawer>
        </>
    )
}

export default Engagement;