import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import FeeRevenuesBreakdown from "./FeeRevenuesBreakdown";
import RevenuesTables from "./RevenuesTables";
import moment from "moment";

const RevenuesSection = (props) => {
    const [unitFilterState, setUnitFilterState] = useState(null);
    const [statusFilterState, setStatusFilterState] = useState(null);
    const [startDateFilterState, setStartDateFilterState] = useState(null);
    const [endDateFilterState, setEndDateFilterState] = useState(null);
    const [performRevenuesTableTabToInvoices, setPerformRevenuesTableTabToInvoices] = useState(0);

    useEffect(() => {
        props.performFilterByMTDBilled > 0 && filterByMTDBilled();
    }, [props.performFilterByMTDBilled]);

    useEffect(() => {
        props.performFilterByOpenInvoices > 0 && filterByOpenInvoices();
    }, [props.performFilterByOpenInvoices]);

    useEffect(() => {
        props.performFilterByPastDueInvoices > 0 &&filterByPastDueInvoices();
    }, [props.performFilterByPastDueInvoices]);

    const filterByMTDBilled = () => {
        setStartDateFilterState(moment().startOf('month'));
        setEndDateFilterState(moment());
        setStatusFilterState(null);
        setUnitFilterState(null);
        setRevenuesTableTabToInvoices();
    }

    const filterByOpenInvoices = () => {
        setStartDateFilterState(null);
        setEndDateFilterState(null);
        setStatusFilterState("open");
        setUnitFilterState(null);
        setRevenuesTableTabToInvoices();
    }

    const filterByPastDueInvoices = () => {
        setStartDateFilterState(null);
        setEndDateFilterState(null);
        setStatusFilterState("past_due");
        setUnitFilterState(null);
        setRevenuesTableTabToInvoices();
    }

    const setRevenuesTableTabToInvoices = () => {
        setPerformRevenuesTableTabToInvoices(prev => prev + 1);
    }

    return (
        <Row>
            <Col span={24}>
                <Row gutter={16}>
                    <Col span={6}>
                        <FeeRevenuesBreakdown unitFilterState={unitFilterState} statusFilterState={statusFilterState} startDateFilterState={startDateFilterState} endDateFilterState={endDateFilterState} />
                    </Col>
                    <Col span={18}>
                        <RevenuesTables
                            unitFilterState={unitFilterState}
                            statusFilterState={statusFilterState}
                            startDateFilterState={startDateFilterState}
                            endDateFilterState={endDateFilterState}
                            setUnitFilterState={setUnitFilterState}
                            setStatusFilterState={setStatusFilterState}
                            setStartDateFilterState={setStartDateFilterState}
                            setEndDateFilterState={setEndDateFilterState}
                            performRevenuesTableTabToInvoices={performRevenuesTableTabToInvoices}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default RevenuesSection;