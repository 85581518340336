import React, { useContext } from "react";
import {Row, Col, Button, Typography} from "antd";
import Lottie from "react-lottie";
import * as animationData from "@dwellpass-client/common/utils/lottie/red-check-anim.json";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";

const { Title } = Typography;

const RegistrationCompleteStep = (props) => {
    const [communityState, ] = useContext(CommunityContext);

    const [activateCommunity, { loading: loadingActivateCommunity }] = useMutation(gql(CommunityQueries.activateCommunity), { variables: { id: communityState.id }, onCompleted: () => { props.rebootAfterCommunityActivation(communityState) } });

    const checkAnimOptions = {
        animationData: animationData.default,
        autoplay: true,
        loop: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <>
            <Row justify="center">
                <Col>
                    <Lottie options={checkAnimOptions} width={150} height={150} />
                </Col>
            </Row>
            <Row justify="center">
                <Title level={5}>You're all set up. Welcome to dwellPass.</Title>
            </Row>
            <Row style={{ marginTop: 25 }} justify="center">
                <Col>
                    <Button type="primary" onClick={() => activateCommunity()} loading={loadingActivateCommunity}>
                        Continue to Community Dashboard
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default RegistrationCompleteStep;