import React, { useState, useContext, useEffect } from "react";
import {Button, Col, Form, Row, Select, Typography, Popconfirm, Input} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import PrimaryInput from "../../../shared/PrimaryInput";
import DirectoryListingQueries from "@dwellpass-client/common/utils/constants/queries/DirectoryListingQueries";

const { Text } = Typography;

const DirectoryListingForm = (props) => {
    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [communityState, ] = useContext(CommunityContext);
    const [form] = Form.useForm();
    const [deletingDirectoryListing, setDeletingDirectoryListing] = useState(false);

    const [createDirectoryListing, ] = useMutation(gql(DirectoryListingQueries.createDirectoryListing));
    const [updateDirectoryListing, ] = useMutation(gql(DirectoryListingQueries.updateDirectoryListing));
    const [destroyDirectoryListing] = useMutation(gql(DirectoryListingQueries.destroyDirectoryListing));

    useEffect(() => {
        form.setFieldsValue({
            name: props.selectedDirectoryListingState.name,
            category: props.selectedDirectoryListingState.category,
            phoneNumber: props.selectedDirectoryListingState.phoneNumber,
            email: props.selectedDirectoryListingState.email,
            website: props.selectedDirectoryListingState.website,
        })
    }, [props.selectedDirectoryListingState]);

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
            setDeletingDirectoryListing(false);
        },500);
    };

    const handleDirectoryListingDeletion = async () => {
        setDeletingDirectoryListing(true);
        await destroyDirectoryListing({ variables: { id: props.selectedDirectoryListingState.id } });
        resetForm();
        props.formSuccess();
    }

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            let formValues = { name: values.name, category: values.category }
            formValues.phoneNumber = values.phoneNumber ? values.phoneNumber : null;
            formValues.email = values.email ? values.email : null;
            formValues.website = values.website ? values.website : null;

            if(props.selectedDirectoryListingState.id) {
                formValues.id = props.selectedDirectoryListingState.id;
                await updateDirectoryListing({variables: formValues});
            } else {
                formValues.communityId = communityState.id;
                await createDirectoryListing({variables: formValues});
            }
            setFormState({isSubmitting: false, errorMessage: null});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    return(
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="category"
                            label="Category"
                            rules={[{ required: true, message: 'Please select a category' }]}
                        >
                            <Select>
                                <Select.Option value="Animal Services">Animal Services</Select.Option>
                                <Select.Option value="Emergency Services">Emergency Services</Select.Option>
                                <Select.Option value="Hospitals/Clinics">Hospitals/Clinics</Select.Option>
                                <Select.Option value="Local Services">Local Services</Select.Option>
                                <Select.Option value="Miscellaneous">Miscellaneous</Select.Option>
                                <Select.Option value="Recreation">Recreation</Select.Option>
                                <Select.Option value="Schools">Schools</Select.Option>
                                <Select.Option value="Shopping/Entertainment">Shopping/Entertainment</Select.Option>
                                <Select.Option value="Transportation">Transportation</Select.Option>
                                <Select.Option value="Utility/Communications">Utility/Communications</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="phoneNumber"
                            label="Phone Number"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{type: 'email', message: 'Must be a valid email'}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="website"
                            label="Website"
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="https://"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100, marginTop: 20 }} type="primary" htmlType="submit" loading={formState.isSubmitting} disabled={deletingDirectoryListing}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                {props.selectedDirectoryListingState.id &&
                    <Row justify="center">
                        <Col>
                            {deletingDirectoryListing ?
                                <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            :
                                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleDirectoryListingDeletion()}>
                                    <Button type="link">
                                        <DeleteOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    </Button>
                                </Popconfirm>
                            }
                        </Col>
                    </Row>
                }
            </Form>
        </>
    )
}

export default DirectoryListingForm;