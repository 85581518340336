import React, { useState, useEffect } from 'react';
import {Row, Col, List, Typography, Button, Divider, Tooltip, Result} from "antd";
import {gql, useQuery} from "@apollo/client";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import LoadingContent from "../../../utility/LoadingContent";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    PauseCircleOutlined,
    RetweetOutlined,
    MoreOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import CreateRefundModal from "./CreateRefundModal";

const { Text } = Typography;

const InvoiceChargeDetail = (props) => {
    const [invoiceState, setInvoiceState] = useState({});
    const [selectedChargeState, setSelectedChargeState] = useState({});
    const [showCreateRefundModal, setShowCreateRefundModal] = useState(false);
    const [showRefundCreationSuccessResult, setShowRefundCreationSuccessResult] = useState(false);

    const {
        data: invoiceData,
        loading: loadingInvoice,
        refetch: refetchInvoice
    } = useQuery(gql(InvoiceQueries.getInvoice), {
        notifyOnNetworkStatusChange: true,
        variables: {id: props.invoiceId}
    });

    useEffect(() => {
        invoiceData && invoiceData.invoice && setInvoiceState(invoiceData.invoice);
    }, [invoiceData]);

    const promptForRefundCreation = (charge) => {
        setSelectedChargeState(charge);
        setShowCreateRefundModal(true);
    }

    const hideCreateRefundModal = () => {
        setShowCreateRefundModal(false);
        setTimeout(() => {
            setSelectedChargeState({});
        }, 500);
    }

    const refundCreationSuccess = () => {
        setShowRefundCreationSuccessResult(true);
    }

    const hideRefundCreationSuccess = () => {
        refetchInvoice();
        setShowRefundCreationSuccessResult(false);
    }

    const refundStatusIcon = (status) => {
        switch (status) {
            case "pending":
                return <PauseCircleOutlined style={{fontSize: 13}}/>
                break;
            case "succeeded":
                return <CheckCircleOutlined style={{fontSize: 13}}/>
                break;
            case "failed":
                return <CloseCircleOutlined style={{fontSize: 13}}/>
                break;
            default:
                return;
        }
    }

    return (
        showRefundCreationSuccessResult ?
            <Result
                status="success"
                title="Refund Submitted"
                subTitle="Note: It may take a few minutes for the refund to be displayed within our systems."
                extra={[
                    <Button type="primary" onClick={hideRefundCreationSuccess}>
                        Back to Details
                    </Button>,
                ]}
            />
            :
            (!invoiceState.id || loadingInvoice) ?
                <Row style={{
                    padding: 24,
                    border: `1px solid ${Colors.monoLightGrey}`,
                    borderRadius: 2,
                    backgroundColor: Colors.monoBright
                }} justify="center">
                    <Col>
                        <LoadingContent style={{marginVertical: 30}}/>
                    </Col>
                </Row>
                :
                !!invoiceState.charges[0] ?
                    <>
                        {invoiceState.charges.map(charge => (
                            <Row style={{backgroundColor: Colors.monoBright}} key={charge.id}>
                                <Row
                                    style={{
                                        width: "100%",
                                        padding: 16,
                                        border: `1px solid ${Colors.monoLightGrey}`
                                    }}
                                    justify="space-between"
                                    align="middle"
                                >
                                    <Col>
                                        <Row gutter={5} align="middle">
                                            <Col>
                                                <Text style={{color: Colors.brandPrimary}} strong>Charge
                                                    ({DateTimeFormatter.simpleDateOnly(charge.stripeCreated)})</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row gutter={5} align="middle">
                                            {charge.amountRefundedCents > 0 &&
                                            <>
                                                <Col>
                                                    <Text
                                                        strong>Refunded: {MoneyFormatter.standardMoneyUSD(charge.amountRefundedCents)}</Text>
                                                </Col>
                                                <Divider type="vertical"></Divider>
                                            </>
                                            }
                                            <Col>
                                                <Text
                                                    strong>Total: {MoneyFormatter.standardMoneyUSD(charge.amountCents)}</Text>
                                            </Col>
                                            <Divider type="vertical"></Divider>
                                            <Col>
                                                <Text
                                                    strong>Status: {StringFormatter.capitalizeFirstLetter(charge.status)}</Text>
                                            </Col>
                                            {charge.status === "succeeded" &&
                                                <>
                                                    <Divider type="vertical"></Divider>
                                                    <Col>
                                                        <Button type="primary"
                                                                disabled={charge.refunded}
                                                                onClick={() => promptForRefundCreation(charge)}><RetweetOutlined/>Refund</Button>
                                                    </Col>
                                                </>
                                            }
                                            {charge.status === "failed" &&
                                                <Tooltip title={charge.failureMessage}>
                                                    <InfoCircleOutlined
                                                        style={{
                                                            marginLeft: 8,
                                                            fontSize: 18,
                                                            color: Colors.monoMedLightGrey
                                                        }}/>
                                                </Tooltip>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                {!!charge.refunds[0] &&
                                <Row style={{width: "100%"}}>
                                    <List
                                        bordered
                                        style={{width: "100%", backgroundColor: Colors.monoBright}}
                                        dataSource={charge.refunds}
                                        locale={{emptyText: "No Refunds"}}
                                        renderItem={item => (
                                            <List.Item>
                                                <Row style={{width: "100%"}} justify="space-between" align="middle">
                                                    <Col>
                                                        <Row gutter={5} align="middle">
                                                            <Col>
                                                                <MoreOutlined
                                                                    style={{
                                                                        fontSize: 16,
                                                                        color: Colors.monoMedLightGrey
                                                                    }}/>
                                                            </Col>
                                                            <Col>
                                                                <Text style={{color: Colors.brandPrimary}} strong>Refund
                                                                    ({DateTimeFormatter.simpleDateOnly(item.stripeCreated)})</Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row gutter={5} align="middle">
                                                            <Col>
                                                                <Text
                                                                    strong>Amount: {MoneyFormatter.standardMoneyUSD(item.amountCents)}</Text>
                                                            </Col>
                                                            <Divider type="vertical"></Divider>
                                                            <Col>
                                                                <Text style={{color: Colors.brandPrimary}}
                                                                      strong>Status: {StringFormatter.capitalizeFirstLetter(item.status)} {refundStatusIcon(item.status)}</Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </Row>
                                }
                            </Row>
                        ))}
                        <CreateRefundModal chargeState={selectedChargeState} visible={showCreateRefundModal}
                                           hideMe={hideCreateRefundModal} formSuccess={refundCreationSuccess}/>
                    </>
                    :
                    <Row style={{
                        padding: 24,
                        border: `1px solid ${Colors.monoLightGrey}`,
                        borderRadius: 2,
                        backgroundColor: Colors.monoBright
                    }} justify="center">
                        <Col>
                            <Text style={{color: Colors.brandPrimary}} strong>Charge Manually Captured by
                                Community</Text>
                        </Col>
                    </Row>
    );
}

export default InvoiceChargeDetail;