import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import DirectoryListingQueries from "@dwellpass-client/common/utils/constants/queries/DirectoryListingQueries";
import {gql, useQuery} from "@apollo/client";
import {Button, Col, Empty, Row, Table, Typography} from "antd";
import {
    EditOutlined,
    LoadingOutlined,
    MinusOutlined,
    PlusOutlined,
    ReloadOutlined
} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const DirectoryListingTable = (props) => {
    const directoryListingPageSize = 30;

    const [communityState, ] = useContext(CommunityContext);
    const [totalDirectoryListingCount, setTotalDirectoryListingCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const { data: directoryListingsData, loading: loadingDirectoryListings, refetch: refetchDirectoryListings } = useQuery(gql(DirectoryListingQueries.getDirectoryListings),{ notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, first: directoryListingPageSize, after: pageOffsetState } });
    const [directoryListingsState, setDirectoryListingsState] = useState([]);

    useEffect(() => {
        directoryListingsData && setDirectoryListingsState(directoryListingsData.directoryListings);
        directoryListingsData && setTotalDirectoryListingCount(directoryListingsData.directoryListings.totalCount);
    }, [directoryListingsData]);

    useEffect(() => {
        refetchDirectoryListings();
    }, [pageOffsetState, props.refreshDirectoryListingsTable]);

    const changeDirectoryListingTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const directoryListingsTableColumns = [
        {
            title: 'Name',
            key: 'name',
            render: (text, record) => (
                <Text strong>{record.node.name}</Text>
            ),
        },
        {
            title: 'Category',
            key: 'category',
            dataIndex: ['node', 'category']
        },
        {
            title: 'Phone Number',
            key: 'phoneNumber',
            render: (text, record) => (
                record.node.phoneNumber ? <a href={`tel:${record.node.phoneNumber}`}>{record.node.phoneNumber}</a> : <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Email',
            key: 'email',
            render: (text, record) => (
                record.node.email ? <a href={`mailto:${record.node.email}`}>{record.node.email}</a> : <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: 'Website',
            key: 'website',
            render: (text, record) => (
                record.node.website ? <span style={{ display: "inline-block", maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: Colors.brandPrimary }}><a href={record.node.website} target="_blank">{record.node.website}</a></span> : <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Button type="link" onClick={(e) => {e.stopPropagation(); props.editDirectoryListing(record.node)}}><EditOutlined style={{fontSize: 20}} /></Button>
            ),
        }
    ];

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => props.addDirectoryListing()}><PlusOutlined />Add Listing</Button>
                </Col>
                <Col>
                    <Button type="link" onClick={() => refetchDirectoryListings()}>
                        {loadingDirectoryListings ?
                            <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            :
                            <ReloadOutlined style={{fontSize: 20}}/>
                        }
                    </Button>
                </Col>
            </Row>
            <Row justify="center">
                <Table
                    style={{ width: "100%" }}
                    size="small"
                    dataSource={directoryListingsState ? directoryListingsState.edges : null}
                    loading={{spinning: loadingDirectoryListings, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                    columns={directoryListingsTableColumns}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Listings" /> }}
                    rowKey={record => record.node.id}
                    pagination={{
                        pageSize: directoryListingPageSize,
                        position: ["none", "bottomCenter"],
                        total: totalDirectoryListingCount,
                        onChange: (page, pageSize) => { changeDirectoryListingTablePage(page, pageSize) }
                    }}
                />
            </Row>
        </>
    )
}

export default DirectoryListingTable;