import React, { useContext } from 'react';
import { ApolloClient, InMemoryCache, ApolloLink, split, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from '@apollo/client/utilities';
import { AuthContext } from "../contexts/AuthContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useHistory} from "react-router-dom";

export const GraphQLProvider = (props) => {
    const [authState, dispatch] = useContext(AuthContext);
    let history = useHistory();

    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'uid': authState.uid,
                'client': authState.client,
                'access-token': authState.accessToken,
            },
        }));

        return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (networkError && networkError.statusCode === 401) {
            directToLoginForReauth();
            return forward(operation);
        }
    });

    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    characters: {
                        merge: false,
                    },
                },
            },
        },
        possibleTypes: {},
    });

    const httpLink = new HttpLink({ uri: process.env.API_ROOT_URL+'/graphql' });
    const uploadLink = createUploadLink({
        uri: process.env.API_ROOT_URL+'/graphql'
    });

    const link = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'mutation'
            );
        },
        uploadLink,
        httpLink
    );

    const combinedLink = ApolloLink.from([authMiddleware, errorLink, link]);

    const client = new ApolloClient({
        cache,
        link: combinedLink
    });

    const directToLoginForReauth = async () => {
        const segment = await AsyncStorage.getItem("segment");
        segment ? history.push({pathname: "/logout", state: { segment: segment }}) : history.push({pathname: "/logout"})
    }

    return(
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    )
}

export default GraphQLProvider;