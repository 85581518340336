import React, { useContext, useState, useEffect } from 'react';
import {Divider, Result, Button, Row} from "antd";
import InvoicesTable from "./payments/InvoicesTable";
import PaymentProfile from "./payments/PaymentProfile";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import PaymentSourceQueries from "@dwellpass-client/common/utils/constants/queries/PaymentSourceQueries";
import PaymentMethodQueries from "@dwellpass-client/common/utils/constants/queries/PaymentMethodQueries";

const Payments = (props) => {
    const [unitState,] = useContext(UnitContext);
    const [paymentSuccessInvoice, setPaymentSuccessInvoice] = useState(false);
    const [refreshInvoicesTable, setRefreshInvoicesTable] = useState(0);
    const [currentUserUnitResident, setCurrentUserUnitResident] = useState({});
    const [loadingPaymentProfileState, setLoadingPaymentProfileState] = useState(false);
    const [defaultSourceState, setDefaultSourceState] = useState({});
    const [defaultPaymentMethodIdState, setDefaultPaymentMethodIdState] = useState(null);
    const [defaultPaymentMethodState, setDefaultPaymentMethodState] = useState({});
    const [derivedPaymentMethodState, setDerivedPaymentMethodState] = useState(null);
    const {data: currentUserUnitResidentData, loading: loadingCurrentUserUnitResident, refetch: refetchCurrentUserUnitResident} = useQuery(gql(UnitQueries.getUnitCurrentUserUnitResident), {notifyOnNetworkStatusChange: true, variables: {id: unitState.id}});
    const [loadStripeCustomer, {data: stripeCustomerData, loading: loadingStripeCustomer}] = useLazyQuery(gql(PaymentSourceQueries.getStripeCustomerWithSources), {notifyOnNetworkStatusChange: true, fetchPolicy: "network-only"});
    const [loadStripePaymentMethods, {data: stripePaymentMethodsData, loading: loadingStripePaymentMethods}] = useLazyQuery(gql(PaymentMethodQueries.getStripePaymentMethods), {notifyOnNetworkStatusChange: true, fetchPolicy: "network-only"});

    useEffect(() => {
        currentUserUnitResidentData?.unit && setCurrentUserUnitResident(currentUserUnitResidentData.unit.currentUserUnitResident);
    }, [currentUserUnitResidentData]);

    useEffect(() => {
        currentUserUnitResident?.stripeCustomerId && performLoadStripeCustomer();
    }, [currentUserUnitResident]);

    useEffect(() => {
        stripeCustomerData?.stripeCustomer && setDefaultSource(stripeCustomerData.stripeCustomer);
        stripeCustomerData?.stripeCustomer?.invoiceSettings?.defaultPaymentMethod && setDefaultPaymentMethodIdState(stripeCustomerData.stripeCustomer.invoiceSettings.defaultPaymentMethod);
    }, [stripeCustomerData]);

    useEffect(() => {
        defaultPaymentMethodIdState && performLoadStripePaymentMethodOverride(stripeCustomerData.stripeCustomer);
    }, [defaultPaymentMethodIdState])

    useEffect(() => {
        stripePaymentMethodsData?.stripePaymentMethods && setDefaultPaymentMethodState(stripePaymentMethodsData.stripePaymentMethods.find(paymentMethod => paymentMethod.id === defaultPaymentMethodIdState));
    }, [stripePaymentMethodsData]);

    useEffect(() => {
        setLoadingPaymentProfileState((loadingStripePaymentMethods || loadingStripeCustomer || loadingCurrentUserUnitResident) || false);
    }, [loadingStripePaymentMethods, loadingStripeCustomer, loadingCurrentUserUnitResident]);

    const performLoadStripeCustomer = () => {
        loadStripeCustomer({ variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId} });
    }

    const performRefreshInvoicesTable = () => {
        setRefreshInvoicesTable(prev => prev + 1);
    }

    const setDefaultSource = (customer) => {
        customer.defaultSource && setDefaultSourceState(customer.sources.find(source => source.id === customer.defaultSource));
    }

    const performLoadStripePaymentMethodOverride = (customer) => {
        loadStripePaymentMethods({ variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId} });
    }

    const dismissSuccessResult = () => {
        setPaymentSuccessInvoice(false);
        performRefreshInvoicesTable();
    }

    useEffect(() => {
        derivePaymentMethodState();
    }, [defaultPaymentMethodState, defaultSourceState]);

    const derivePaymentMethodState = () => {
        // if default payment method present, and not the same id as the default source state (card OR bank account... - display - ready to pay)
        if (defaultPaymentMethodState?.id && (defaultPaymentMethodState?.id !== defaultSourceState?.id)) {
            setDerivedPaymentMethodState("defaultPaymentMethod");
        }
        // else if there's a default source present, and the default source is unverified (unverified bank account - prompt to verify)
        else if (defaultSourceState?.id && defaultSourceState?.status !== "verified") {
            setDerivedPaymentMethodState("unverifiedSource");
        }
        // else if there's a default source that is verified (verified bank account - display - ready to pay)
        else if (defaultSourceState?.id && defaultSourceState?.status === "verified") {
            setDerivedPaymentMethodState("defaultSource");
        }
        // else there's no default source/payment method (prompt for adding anything they want)
        else {
            setDerivedPaymentMethodState("none");
        }
    }

    return (
        <>
            <PaymentProfile
                defaultSource={defaultSourceState}
                defaultPaymentMethod={defaultPaymentMethodState}
                derivedPaymentMethodState={derivedPaymentMethodState}
                performLoadStripeCustomer={performLoadStripeCustomer}
                loadingPaymentProfile={loadingPaymentProfileState}
                refreshInvoicesTable={performRefreshInvoicesTable}
                currentUserUnitResident={currentUserUnitResident}
                refreshCurrentUserUnitResident={refetchCurrentUserUnitResident}
            />
            {(currentUserUnitResident && currentUserUnitResident.stripeCustomerId) &&
                <Row style={{ display: paymentSuccessInvoice ? "none" : "block" }}>
                    <InvoicesTable
                        refreshTable={refreshInvoicesTable}
                        setPaymentSuccessInvoice={setPaymentSuccessInvoice}
                        currentUserUnitResident={currentUserUnitResident}
                        defaultSource={defaultSourceState}
                        defaultPaymentMethod={defaultPaymentMethodState}
                        derivedPaymentMethodState={derivedPaymentMethodState}
                    />
                </Row>
            }
            {paymentSuccessInvoice &&
                <>
                    <Divider></Divider>
                    <Result
                        status="success"
                        title="Payment Successfully Submitted!"
                        subTitle={"Note that it can take a few minutes to display within our systems, and up to 5 business days for funds to clear your account."}
                        extra={[
                            <Button type="primary" key="back" onClick={dismissSuccessResult}>
                                Back to Invoices
                            </Button>
                        ]}
                    />
                </>
            }
        </>
    );
}

export default Payments;