import React, {useEffect, useState} from 'react';``
import {Drawer} from "antd";
import PayoutAccountTable from "./payout_accounts/PayoutAccountTable";
import PayoutAccountForm from "./payout_accounts/PayoutAccountForm";

const PayoutAccounts = (props) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedPayoutAccountState, setSelectedPayoutAccountState] = useState({});
    const [refreshPayoutAccountsTable, setRefreshPayoutAccountsTable] = useState(0);

    useEffect(() => {
        performRefreshPayoutAccountsTable();
    }, [props.refreshPayoutAccounts]);

    const openDrawer = () => {
        setDrawerVisible(true);
    };

    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedPayoutAccount();
        }, 500);
    };

    const performRefreshPayoutAccountsTable = () => {
        setRefreshPayoutAccountsTable(prev => prev + 1);
    }

    const editPayoutAccount = (payoutAccount) => {
        setSelectedPayoutAccountState(payoutAccount);
        openDrawer();
    }

    const clearSelectedPayoutAccount = () => {
        setSelectedPayoutAccountState({});
    }

    const closeDrawerAndRefetchPayoutAccounts = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedPayoutAccount();
        }, 500);
        performRefreshPayoutAccountsTable();
    }

    return (
        <>
            <PayoutAccountTable refreshPayoutAccountsTable={refreshPayoutAccountsTable} editPayoutAccount={editPayoutAccount} addPayoutAccount={openDrawer} />
            <Drawer
                title="Account Information"
                placement="right"
                width={340}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <PayoutAccountForm selectedPayoutAccountState={selectedPayoutAccountState} formSuccess={closeDrawerAndRefetchPayoutAccounts} drawerVisible={drawerVisible} />
            </Drawer>
        </>
    );
}

export default PayoutAccounts;