import React, {useState} from "react";
import {Modal, Form, Typography, notification, Checkbox, Row, Col} from "antd";
import {gql, useMutation} from "@apollo/client";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from "@stripe/react-stripe-js";
import PaymentMethodQueries from "@dwellpass-client/common/utils/constants/queries/PaymentMethodQueries";
import PaymentMethodDisclaimer from "./PaymentMethodDisclaimer";

const { Text } = Typography;

const NewPaymentCardModal = (props) => {
    const [form] = Form.useForm();
    const stripe = useStripe();
    const elements = useElements();

    const [formErrors, setFormErrors] = useState([]);
    const [loadingFormSubmission, setLoadingFormSubmission] = useState(false);

    const [attachPaymentMethod, ] = useMutation(gql(PaymentMethodQueries.attachPaymentMethod));

    const cardElementContainerStyle = {
        backgroundColor: '#fff',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        height: '40px',
        padding: '10px',
    };

    const cardElementStyle = {
        base: {
            fontSize: '14px',
            color: '#555',
            '::placeholder': {
                color: '#999',
            },
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
            height: '40px',
            padding: '10px',
        },
        invalid: {
            color: '#dc3545',
            border: '1px solid #dc3545',
            backgroundColor: '#fff6f6',
        },
    };

    const handleFormSubmit = async (values) => {
        setLoadingFormSubmission(true);

        try {
            setFormErrors([]);

            if (!stripe || !elements) {
                throw 'Stripe.js has not yet loaded.';
            }

            const cardNumberElement = elements.getElement(CardNumberElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement
            });

            if (error) {
                throw error;
            } else {
                await attachPaymentMethod({variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, stripePaymentMethodId: paymentMethod.id, setAsCustomerDefault: true}});

                openSuccessNotification();
            }

            resetFieldsAndHideMe();
            setLoadingFormSubmission(false);
            props.formSuccess();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors ? error.graphQLErrors : [error]);
            setLoadingFormSubmission(false);
        }
    }

    const resetFieldsAndHideMe = () => {
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
            setFormErrors([]);
        }, 500);
    }

    const openSuccessNotification = () => {
        notification["success"]({
            message: "Card Added",
            description: "This payment method is now ready for use."
        });
    }

    return(
        <Modal
            title="New Card"
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={loadingFormSubmission}
            onCancel={() => resetFieldsAndHideMe()}
        >
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
            >
                {formErrors &&
                    <Row style={{marginBottom: 20}}>
                        {formErrors.map(error =>
                            <Text key={error.message} type="danger">{error.message}<br/></Text>
                        )}
                    </Row>
                }
                    <Form.Item
                        label="Card Number"
                        name="cardNumber"
                    >
                        <div style={cardElementContainerStyle}>
                            <CardNumberElement options={{style: cardElementStyle}} />
                        </div>
                    </Form.Item>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label="Expiration"
                                name="cardExpiry"
                            >
                                <div style={cardElementContainerStyle}>
                                    <CardExpiryElement options={{style: cardElementStyle}} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="CVC"
                                name="cardCvc"
                            >
                                <div style={cardElementContainerStyle}>
                                    <CardCvcElement options={{style: cardElementStyle}} />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject('Please authorize'),
                            },
                        ]}
                        style={{marginTop: 20}}
                    >
                        <Checkbox style={{ "--background-color": Colors.brandPrimary }}>
                            I authorize dwellPass to electronically charge my credit or debit card and, if necessary, electronically credit my card to correct or refund any charges. This includes both one-time and recurring charges.
                        </Checkbox>
                    </Form.Item>
            </Form>
            <PaymentMethodDisclaimer />
        </Modal>
    )
}

export default NewPaymentCardModal;