import React, { useContext } from "react";
import {Button, Col, Divider, Form, Input, notification, Row, Tooltip, Typography, Switch, DatePicker} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import {ApartmentOutlined, HomeOutlined, UserOutlined, InfoCircleOutlined, MessageOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import moment from "moment";
import PrimaryInput from "../../shared/PrimaryInput";

const { Text } = Typography;

const PostForm = (props) => {
    const [communityState, _] = useContext(CommunityContext);
    const [form] = Form.useForm();

    const [createPost, { loading: loadingPostCreation }] = useMutation(gql(PostQueries.createPost));

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        },500);
    };

    const handleFormSubmit = async (values) => {
        try {
            let receivableId = props.creatingPostWith.receivable ? props.creatingPostWith.receivable.id : null
            let pinExpiresAt = values.pinExpiresAt ? values.pinExpiresAt.toISOString() : null

            await createPost({variables: { communityId: communityState.id, receivableType: props.creatingPostWith.receivableType, postableType: "CommunityManager", postableId: communityState.currentUserCommunityManager.id, receivableId, title: values.title, body: values.body, hidePoster: values.hidePoster, pinExpiresAt }});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            notification['error']({
                message: 'Error Creating Post',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    const receivableTypeIcon = (receivableType) => {
        switch (receivableType) {
            case "UnitResident":
                return(
                    <Tooltip title="Resident Post">
                        <UserOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case "Unit":
                return(
                    <Tooltip title="Unit Post">
                        <HomeOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case "Community":
                return(
                    <Tooltip title="Community Manager Post">
                        <MessageOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            default:
                return(
                    <Tooltip title="Community Post">
                        <ApartmentOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
        }
    }

    const receiverText = (receivable) => {
        switch (true) {
            case receivable && receivable.__typename == "UnitResident":
                return `Resident: ${receivable.resident.firstName} ${receivable.resident.lastName} (Unit: ${receivable.unit.internalIdentifier})`;
            case receivable && receivable.__typename == "Unit":
                return `Unit: ${receivable.internalIdentifier}`;
            default:
                return "Community";
        }
    }

    return(
        <>
            <Row gutter={10} justify="space-between">
                <Col>
                    <Text strong>To: {receivableTypeIcon(props.creatingPostWith.receivableType)} {receiverText(props.creatingPostWith.receivable)}</Text>
                </Col>
            </Row>
            <Divider></Divider>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  initialValues={{ hidePoster: communityState.maskPostCreatorByDefault }}
                  onFinish={handleFormSubmit}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please enter the title' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="body"
                            label="Body"
                            rules={[{ required: true, message: 'Please enter the body' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="hidePoster"
                            label="Mask Creator"
                            tooltip={{ title: "If enabled, this post will appear to come from the Community, hiding the creator details.", icon: <InfoCircleOutlined /> }}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    {!props.creatingPostWith.receivableType &&
                        <Col span={12}>
                            <Form.Item
                                name="pinExpiresAt"
                                label="Pin Until"
                                tooltip={{
                                    title: "This Post will show up on the dashboard for Residents until it expires",
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <DatePicker
                                    showTime
                                    showNow={false}
                                    disabledDate={current => {
                                        return current && current < moment();
                                    }}
                                    format="MM/DD/YYYY h:mma"
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100 }} type="primary" htmlType="submit" loading={loadingPostCreation}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PostForm;