import React from 'react';
import {Row, Col, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import logoImage from "../../assets/images/dwellPass-logo-white-800w.png";
import Endpoints from "@dwellpass-client/common/utils/constants/Endpoints";
import {badgeImage} from "./StoreBadge";
import {useLocation} from "react-router-dom";

const { Title, Text } = Typography;

const DesktopOnlyOverlay = (props) => {
    const location = useLocation();

    return (
        <Row className="desktop-only-overlay" justify="center" align="middle">
            <Col xs={18} sm={12}>
                <div style={styles.logoContainer}></div>
                <Row justify="center">
                    <Title level={5} style={{ color: Colors.monoBright, textAlign: "center" }}>This application is optimized for use on desktop web browsers. Thank you for using dwellPass.</Title>
                </Row>
                {location?.pathname?.includes("/resident") &&
                    <Row width="100%" justify="center" style={{marginTop: 30}}>
                        <Text strong style={{ color: Colors.monoBright, margin: "12px 20px", textAlign: "center" }}>Download our mobile app for Residents</Text>
                        <a href={Endpoints.residentMobileGooglePlayStoreURL} target="_blank" style={{background: `url(${badgeImage("google")}) no-repeat center`, ...styles.googleBadgeContainer}} />
                        <a href={Endpoints.residentMobileAppStoreURL} target="_blank" style={{background: `url(${badgeImage("apple")}) no-repeat center`, ...styles.appleBadgeContainer}} />
                    </Row>
                }
            </Col>
        </Row>
    );
}

const styles = {
    logoContainer: {
        height: '80px',
        margin: '30px 0px',
        background: `url(${logoImage}) no-repeat center`,
        backgroundSize: 'auto 80px'
    },
    googleBadgeContainer: {
        width: '100%',
        height: '60px',
        backgroundSize: 'auto 60px'
    },
    appleBadgeContainer: {
        width: '100%',
        height: '46px',
        backgroundSize: 'auto 46px'
    }
}

export default DesktopOnlyOverlay;