import React, {useState} from 'react';
import FeeTable from "./fee_setup/FeeTable";
import {Drawer} from "antd";
import FeeForm from "./fee_setup/FeeForm";

const FeeSetup = (props) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedFeeState, setSelectedFeeState] = useState({});
    const [refreshFeesTable, setRefreshFeesTable] = useState(0);

    const openDrawer = () => {
        setDrawerVisible(true);
    };

    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedFee();
        }, 500);
    };

    const performRefreshFeesTable = () => {
        setRefreshFeesTable(prev => prev + 1);
        props.performPayoutAccountsRefresh();
    }

    const editFee = (fee) => {
        setSelectedFeeState(fee);
        openDrawer();
    }

    const clearSelectedFee = () => {
        setSelectedFeeState({});
    }

    const closeDrawerAndRefetchFees = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedFee();
        }, 500);
        performRefreshFeesTable();
    }

    return (
        <>
            <FeeTable refreshFeesTable={refreshFeesTable} editFee={editFee} addFee={openDrawer} />
            <Drawer
                title="Fee Information"
                placement="right"
                width={340}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <FeeForm selectedFeeState={selectedFeeState} formSuccess={closeDrawerAndRefetchFees} drawerVisible={drawerVisible} />
            </Drawer>
        </>
    );
}

export default FeeSetup;