import React, {useState, useContext, useEffect} from 'react';
import {Card, Col, Row, Statistic} from "antd";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Bullet} from "@ant-design/charts";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";

const StatisticsSection = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [communityStatisticsState, setCommunityStatisticsState] = useState({});

    const { data: communityStatisticsData, loading: loadingCommunityStatistics } = useQuery(gql(CommunityQueries.getCommunityStatistics), { notifyOnNetworkStatusChange: true, variables: { id: communityState.id } } );

    useEffect(() => {
        communityStatisticsData && communityStatisticsData.community && setCommunityStatisticsState(communityStatisticsData.community);
    }, [communityStatisticsData]);

    const data = [
        {
            title: 'Unit Status',
            ranges: [communityStatisticsState.unitsCount],
            current: [(communityStatisticsState.unitsCount - communityStatisticsState.unitsWithOpenInvoicesCount), (communityStatisticsState.unitsWithOpenInvoicesCount - communityStatisticsState.unitsWithPastDueInvoicesCount), communityStatisticsState.unitsWithPastDueInvoicesCount],
            target: communityStatisticsState.unitsCount,
        },
    ];

    const config = {
        height: 40,
        data: data,
        measureField: 'current',
        rangeField: 'ranges',
        targetField: 'target',
        xField: 'title',
        label: false,
        color: {
            range: [Colors.monoLightGrey],
            measure: [Colors.mainGreen, Colors.mainBlue, Colors.dangerRed],
            target: Colors.mainBlue,
        },
        xAxis: { line: null },
        yAxis: false,
        tooltip: {
            showTitle: false,
            formatter: (datum) => {
                switch (datum.mKey) {
                    case 'current_0':
                        return { name: 'Up to Date', value: datum.current };
                    case 'current_1':
                        return { name: 'Awaiting Payment', value: datum.current };
                    case 'current_2':
                        return { name: 'Past Due', value: datum.current };
                    default:
                        return { name: 'Unit Count', value: communityStatisticsState.unitsCount };
                }
            },
        }
    };

    return (
        <>
            <Row gutter={16}>
                <Col span={6}>
                    <Card style={{ cursor: "pointer" }} onClick={props.filterByMTDBilled}>
                        <Statistic
                            title="MTD Billed"
                            value={MoneyFormatter.standardMoneyUSD(communityStatisticsState.monthToDateBilledCents)}
                            valueStyle={{ fontWeight: "bold", color: Colors.mainBlue }}
                            loading={loadingCommunityStatistics}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="MTD Revenue"
                            value={MoneyFormatter.standardMoneyUSD(communityStatisticsState.monthToDateRevenueCents)}
                            valueStyle={{ fontWeight: "bold", color: Colors.mainGreen }}
                            loading={loadingCommunityStatistics}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{ cursor: "pointer" }} onClick={props.filterByOpenInvoices}>
                        <Statistic
                            title="Open Invoices"
                            value={MoneyFormatter.standardMoneyUSD(communityStatisticsState.openInvoicesTotalCents)}
                            valueStyle={{ fontWeight: "bold", color: Colors.mainBlue }}
                            suffix={`/ ${communityStatisticsState.openInvoicesCount}`}
                            loading={loadingCommunityStatistics}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{ cursor: "pointer" }} onClick={props.filterByPastDueInvoices}>
                        <Statistic
                            title="Past Due Invoices"
                            value={MoneyFormatter.standardMoneyUSD(communityStatisticsState.pastDueInvoicesTotalCents)}
                            valueStyle={{ fontWeight: "bold", color: communityStatisticsState.pastDueInvoicesCount > 0 ? Colors.dangerRed : Colors.mainBlue }}
                            suffix={`/ ${communityStatisticsState.pastDueInvoicesCount}`}
                            loading={loadingCommunityStatistics}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row align="middle" wrap={false}>
                        <Col flex="auto">
                            <Bullet {...config} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default StatisticsSection;