import React, { useState, useContext, useEffect } from "react";
import {Button, Col, Form, Row, Select, Typography, InputNumber, Switch} from "antd";
import {gql, useMutation, useQuery} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {DeleteOutlined, InfoCircleOutlined} from "@ant-design/icons";
import PrimaryInput from "../../../shared/PrimaryInput";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";
import DeleteFeeModal from "./DeleteFeeModal";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";

const { Text } = Typography;

const FeeForm = (props) => {
    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [communityState, ] = useContext(CommunityContext);
    const [recurringInterval, setRecurringInterval] = useState(null);
    const [showDeleteFeeModal, setShowDeleteFeeModal] = useState(false);
    const [payoutAccountOptions, setPayoutAccountOptions] = useState([]);
    const [form] = Form.useForm();

    const [createFee, ] = useMutation(gql(FeeQueries.createFee));
    const [updateFee, ] = useMutation(gql(FeeQueries.updateFee));
    const { data: payoutAccountsData, loading: loadingPayoutAccounts } = useQuery(gql(CommunityQueries.getCommunityVerifiedPayoutAccounts), { notifyOnNetworkStatusChange: true, variables: { id: communityState.id } } );

    useEffect(() => {
        form.setFieldsValue({
            name: props.selectedFeeState.name,
            classification: props.selectedFeeState.classification,
            unitDefault: props.selectedFeeState.unitDefault
        })
    }, [props.selectedFeeState]);

    useEffect(() => {
        props.drawerVisible === false && resetForm();
    }, [props.drawerVisible]);

    useEffect(() => {
        var options = [];
        payoutAccountsData &&
        payoutAccountsData.community.verifiedPayoutAccounts.map(payoutAccount => {
            options.push({ label: `${payoutAccount.name}${payoutAccount.externalAccountLast4 && " ("+payoutAccount.externalAccountLast4+")"}`, value: payoutAccount.id });
        });
        setPayoutAccountOptions(options);
    }, [payoutAccountsData]);

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
            setRecurringInterval(null);
        },500);
    };

    const promptForFeeDeletion = () => {
        setShowDeleteFeeModal(true);
    }

    const hideDeleteFeeModal = () => {
        setShowDeleteFeeModal(false);
    }

    const deletionSuccess = async () => {
        setShowDeleteFeeModal(false);
        resetForm();
        props.formSuccess();
    }

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            var price = values.priceCents * 100;

            if(props.selectedFeeState.id) {
                await updateFee({variables: { id: props.selectedFeeState.id, name: values.name, classification: values.classification, unitDefault: values.unitDefault }});
            } else {
                await createFee({variables: { communityId: communityState.id, name: values.name, classification: values.classification, unitDefault: values.unitDefault, payoutAccountId: values.payoutAccountId, priceCents: price, recurring: values.recurring }});
            }
            setFormState({isSubmitting: false, errorMessage: null});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    return(
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="classification"
                            label="Classification"
                            rules={[{ required: true, message: 'Please select a classification' }]}
                        >
                            <Select>
                                <Select.Option value="hoa_dues">HOA Dues</Select.Option>
                                <Select.Option value="membership">Membership</Select.Option>
                                <Select.Option value="utility">Utility</Select.Option>
                                <Select.Option value="miscellaneous">Miscellaneous</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {!props.selectedFeeState.id &&
                    <>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="payoutAccountId"
                                    label="Payout Account"
                                    rules={[{ required: true, message: 'Please select a Payout Account' }]}
                                >
                                    <Select
                                        placeholder="Select an Account"
                                        loading={loadingPayoutAccounts}
                                        options={payoutAccountOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="priceCents"
                                    label="Price"
                                    rules={[{ required: true, message: 'Please enter the price' }]}
                                >
                                    <InputNumber
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        precision={2}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="recurring"
                                    label="Recurring (interval)"
                                >
                                    <Select allowClear onChange={(value) => setRecurringInterval(value)}>
                                        <Select.Option value="monthly">Monthly</Select.Option>
                                        <Select.Option value="quarterly">Quarterly</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
                {(props.selectedFeeState.recurring || recurringInterval) &&
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="unitDefault"
                                label="Unit Default"
                                tooltip={{
                                    title: "Default Unit Fees are automatically added when Units are created or when all Residents are removed from a Unit.",
                                    icon: <InfoCircleOutlined/>
                                }}
                                valuePropName="checked"
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row gutter={16}>
                    <Col span={24}>
                        <Text style={{ color: Colors.monoMedLightGrey }}>Note that the price and recurring interval can't be changed after the Fee is created. You'll need to remove and re-create the Fee to change those values.</Text>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100, marginTop: 20 }} type="primary" htmlType="submit" loading={formState.isSubmitting}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                {props.selectedFeeState.id &&
                    <Row justify="center">
                        <Col>
                            <Button type="link" onClick={() => promptForFeeDeletion(props.selectedFeeState)}>
                                <DeleteOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            </Button>
                        </Col>
                    </Row>
                }
            </Form>
            <DeleteFeeModal visible={showDeleteFeeModal} hideMe={hideDeleteFeeModal} selectedFeeState={props.selectedFeeState} deletionSuccess={deletionSuccess} />
        </>
    )
}

export default FeeForm;