import React from 'react';
import {Typography, Row, Col} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const {Text} = Typography;

const PaymentMethodDisclaimer = (props) => {
    return (
        <Row justify="center">
            <Row justify="center" style={{marginBottom: 8}}>
                <Col span={18} style={{textAlign: "center"}}>
                    <Text style={{color: Colors.monoMedGrey}}>We value your security. We do not process or store any payment method information. All transactions are securely handled by Stripe.</Text>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <a href={process.env.STRIPE_PRIVACY_URL} target="_blank"><InfoCircleOutlined /> More information</a>
                </Col>
            </Row>
        </Row>
    );
}

export default PaymentMethodDisclaimer;