import React, { useEffect, useRef } from 'react';
import { Input, InputNumber } from "antd";

const { TextArea } = Input;

const PrimaryInput = (props) => {
    const primaryInput = useRef();

    useEffect(() => {
        setTimeout(() => {
            focusOnPrimaryInput();
        }, 100);
    }, []);

    const focusOnPrimaryInput = () => {
        primaryInput.current &&
            primaryInput.current.focus();
    }

    const inputType = () => {
        switch(props.type) {
            case "numeric":
                return (<InputNumber ref={primaryInput} {...props} />);
            case "textarea":
                return (<TextArea ref={primaryInput} {...props} />);
            default:
                return (<Input ref={primaryInput} {...props} />);
        }
    }

    return (
        inputType()
    );
}

export default PrimaryInput;