import React, { useContext, useState } from "react";
import {message, notification, Upload, Image, Row, Col, Tooltip} from "antd";
import FileHelpers from "@dwellpass-client/common/utils/file_handling/FileHelpers";
import axios from "axios";
import {gql, useMutation} from "@apollo/client";
import FileUploadQueries from "@dwellpass-client/common/utils/constants/queries/FileUploadQueries";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import EncodedImages from "@dwellpass-client/common/utils/constants/EncodedImages";
import LoadingContent from "../../utility/LoadingContent";

const ProfileImageControl = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [loadingState, setLoadingState] = useState(false);

    const [createDirectUpload, ] = useMutation(gql(FileUploadQueries.createDirectUpload));
    const [attachProfileImage, ] = useMutation(gql(CommunityQueries.attachProfileImage));

    const beforeUpload = (file)  => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Image must be a JPG or PNG");
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error("Image must be smaller than 10MB");
        }
        return isJpgOrPng && isLt2M;
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        var checksum = await FileHelpers.calculateChecksum(file);

        try {
            var directUpload = await createDirectUpload({ variables: { filename: file.name, contentType: file.type, checksum, byteSize: file.size } });
            var uploadHeaders = JSON.parse(directUpload.data.createDirectUpload.directUpload.headers);
            var signedBlobId = directUpload.data.createDirectUpload.directUpload.signedBlobId;

            const config = {
                headers: uploadHeaders,
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };
            await axios.put(directUpload.data.createDirectUpload.directUpload.url, file, config);

            await attachProfileImage({ variables: { communityId: communityState.id, profileImageBlobId: signedBlobId } })

            props.uploadSuccess();

            onSuccess("Ok");
        } catch (error) {
            notification["error"]({
                message: "Error Uploading Photo",
                description:
                    "Something went wrong. Please try again.",
            });

            onError({ error });
        }
    }

    const handleChange = info => {
        if (info.file.status === "uploading") {
            setLoadingState(true);
            return;
        }
        if (info.file.status === "done") {
            setLoadingState(false);
        }
    };

    return(
        <Upload
            showUploadList={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={uploadImage}
        >
            {loadingState ?
                <Row style={{ width: 300, maxWidth: 300, height: 200, maxHeight: 200, overflow: "hidden" }} justify="center" align="middle">
                    <Col>
                        <LoadingContent />
                    </Col>
                </Row>
            :
                <Tooltip title="Upload new image">
                    <Image
                        style={{ cursor: "pointer" }}
                        width={200}
                        height={200}
                        preview={false}
                        src={communityState.profileImageUrl ? communityState.profileImageUrl : EncodedImages.fallbackImage}
                    />
                </Tooltip>
            }
        </Upload>
    )
}

export default ProfileImageControl;