import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Avatar, Button, Empty, Modal, notification, Row, Space, Table, Typography} from "antd";
import {LoadingOutlined, LogoutOutlined, MinusOutlined, UserOutlined} from "@ant-design/icons";
import UnitResidentQueries from "@dwellpass-client/common/utils/constants/queries/UnitResidentQueries";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const {Text} = Typography;

const ResidentsList = (props) => {
    const unitResidentPageSize = 30;

    const [communityState, ] = useContext(CommunityContext);
    const [totalUnitResidentCount, setTotalUnitResidentCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const { data: unitResidentsData, loading: loadingUnitResidents, refetch: refetchUnitResidents } = useQuery(gql(UnitResidentQueries.getUnitResidents),{ notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, currentOnly: true, first: unitResidentPageSize, after: pageOffsetState } });
    const [unitResidentsState, setUnitResidentsState] = useState([]);
    const [showResidentRemoveModal, setShowResidentRemoveModal] = useState(false);
    const [unitResidentToRemoveState, setUnitResidentToRemoveState] = useState({ resident: {} });
    const [departUnitResident, { loading: loadingDepartUnitResident }] = useMutation(gql(UnitResidentQueries.departUnitResident));
    const [unitResidentDepartureErrors, setUnitResidentDepartureErrors] = useState([]);

    useEffect(() => {
        unitResidentsData && setUnitResidentsState(unitResidentsData.unitResidents);
        unitResidentsData && setTotalUnitResidentCount(unitResidentsData.unitResidents.totalCount);
    }, [unitResidentsData]);

    useEffect(() => {
        props.setLoadingState(loadingUnitResidents || loadingDepartUnitResident);
    }, [loadingUnitResidents, loadingDepartUnitResident]);

    useEffect(() => {
        refetchUnitResidents();
    }, [props.refreshCurrentTabTable]);

    const changeUnitResidentTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const unitResidentsTableColumns = [
        {
            key: 'image',
            render: (text, record) => (
                <Avatar size={36} icon={<UserOutlined />} src={record.node.resident && record.node.resident.profileImageUrl && record.node.resident.profileImageUrl} />
            ),
        },
        {
            title: 'First Name',
            key: 'firstName',
            dataIndex: ['node', 'resident', 'firstName'],
            sorter: true
        },
        {
            title: 'Last Name',
            key: 'lastName',
            dataIndex: ['node', 'resident', 'lastName'],
            sorter: true
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: ['node', 'resident', 'email']
        },
        {
            title: 'Unit',
            key: 'unit',
            dataIndex: ['node', 'unit', 'internalIdentifier']
        },
        {
            title: 'Last Accessed',
            key: 'lastAccessedAt',
            render: (text, record) => (
                record.node.lastAccessedAt == record.node.createdAt ?
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
                    :
                    DateTimeFormatter.standardDateTime(record.node.lastAccessedAt)
            ),
        },
        {
            title: 'Resident Since',
            key: 'createdAt',
            render: (text, record) => (
                DateTimeFormatter.standardDateTime(record.node.createdAt)
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={(e) => {e.stopPropagation(); promptForResidentRemoval(record.node)}}><LogoutOutlined style={{fontSize: 13}} />Revoke</Button>
                </Space>
            ),
        }
    ];

    const promptForResidentRemoval = (unitResident) => {
        setUnitResidentToRemoveState(unitResident);
        setShowResidentRemoveModal(true);
    }

    const hideResidentRemoveModal = () => {
        setShowResidentRemoveModal(false);
        setTimeout(() => {
            clearUnitResidentDepartureErrors();
        }, 500);
    }

    const clearUnitResidentDepartureErrors = () => {
        setUnitResidentDepartureErrors([]);
    }

    const revokeResidentMembership = async () => {
        clearUnitResidentDepartureErrors();
        try {
            await departUnitResident({ variables: { id: unitResidentToRemoveState.id } });
            setShowResidentRemoveModal(false);
            popResidentRemovalSuccessNotification();
            refetchUnitResidents();
        } catch (error) {
            setUnitResidentDepartureErrors(error.graphQLErrors);
        }
    }

    const popResidentRemovalSuccessNotification = () => {
        notification["success"]({
            message: "Resident Removed",
            description: `Successfully removed resident: ${unitResidentToRemoveState.resident.firstName} ${unitResidentToRemoveState.resident.lastName}`,
        });
    };

    return(
        <Row justify="center">
            <Table
                style={{ width: "100%" }}
                size="small"
                dataSource={unitResidentsState ? unitResidentsState.edges : null}
                loading={{spinning: props.loadingState, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                columns={unitResidentsTableColumns}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Residents" /> }}
                rowKey={record => record.node.id}
                pagination={{
                    pageSize: unitResidentPageSize,
                    position: ["none", "bottomCenter"],
                    total: totalUnitResidentCount,
                    onChange: (page, pageSize) => { changeUnitResidentTablePage(page, pageSize) }
                }}
            />
            <Modal
                title="Are You Sure?"
                visible={showResidentRemoveModal}
                onOk={() => revokeResidentMembership()}
                confirmLoading={loadingDepartUnitResident}
                onCancel={() => hideResidentRemoveModal()}
                okText="Remove"
                cancelText="Cancel"
            >
                {unitResidentDepartureErrors.length >= 1 && (
                    <Space style={{marginBottom: 10}} direction="vertical">
                        {unitResidentDepartureErrors.map(error =>
                            <Text key={error.message} type="danger">{error.message}<br/></Text>
                        )}
                    </Space>
                )}
                <p>This will remove {unitResidentToRemoveState.resident.firstName}'s access to this unit's profile and cancel auto-pay and/or future invoices.</p>
                <Text style={{ color: Colors.monoMedLightGrey }}>Note that if this results in Unit vacancy, the latest closing date will be cleared and fees will revert to the Community default.</Text>
            </Modal>
        </Row>
    )
}

export default ResidentsList;