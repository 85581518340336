import React, { useEffect, useRef, useContext, useState } from "react";
import {Modal, Col, Form, Input, Row, message, Typography, DatePicker} from "antd";
import {gql, useMutation} from "@apollo/client";
import InviteQueries from "@dwellpass-client/common/utils/constants/queries/InviteQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {InfoCircleOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import moment from "moment";

const { Text } = Typography;

const InviteCreationModal = (props) => {
    const [formErrors, setFormErrors] = useState([]);
    const [communityState, ] = useContext(CommunityContext);
    const [showClosingDateInput, setShowClosingDateInput] = useState(false);

    const [form] = Form.useForm();
    const emailInput = useRef();

    const [setUnitClosingDate, { loading: loadingSetUnitClosingDate }] = useMutation(gql(UnitQueries.setUnitClosingDate));
    const [createInvite, { loading: loadingCreateInvite }] = useMutation(gql(InviteQueries.createInvite));

    useEffect(() => {
        props.visible ?
            !showClosingDate() ?
                emailInput.current.focus()
                :
                setShowClosingDateInput(true)
            :
            setShowClosingDateInput(false);
    }, [props.visible]);

    const modalTitle = () => {
        switch(props.invitableType) {
            case "Unit":
                return "Invite a Resident"
                break;
            default:
                return "Invite a Community Manager"
        }
    }

    const handleFormSubmit = async (values) => {
        const invitableType = (props.invitableType && props.invitableId) ? props.invitableType : "Community"
        const invitableId = (props.invitableType && props.invitableId) ? props.invitableId : communityState.id
        setFormErrors([]);

        try {
            if(showClosingDate() && form.getFieldValue("latestClosingAt")){
                let latestClosingAt = values.latestClosingAt ? values.latestClosingAt.hours(0).minutes(0).seconds(0).toISOString() : null;
                await setUnitClosingDate({variables: { id: invitableId, latestClosingAt }});
            }
            await createInvite({variables: { email: values.email, invitableType, invitableId }});
            message.success('An invite notification was sent to: '+values.email);

            resetFormAndHide();
            props.formSuccess();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors);
        }
    }

    const resetFormAndHide = () => {
        setFormErrors([]);
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
        }, 500);
    }

    const showClosingDate = () => {
        return (!props.latestClosingAt && props.invitableType === "Unit")
    }

    const isOutsideLastQuarter = (currentDate) => {
        const currentMoment = moment(currentDate);
        const lastQuarterStart = moment().startOf('quarter');
        const currentDateMoment = moment();
        return currentMoment.isBefore(lastQuarterStart) || currentMoment.isAfter(currentDateMoment);
    };

    return(
        <Modal
            title={modalTitle()}
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={(loadingCreateInvite || loadingSetUnitClosingDate)}
            onCancel={() => resetFormAndHide()}
        >
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
            >
                {formErrors && (
                    formErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                {showClosingDateInput &&
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="latestClosingAt"
                                label="Latest Closing Date"
                                tooltip={{
                                    title: "When set within the current billing period, an initial, prorated invoice for the remainder of the cycle will be sent. When left blank, the first recurring invoice will be sent at the start of the next billing cycle.",
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <DatePicker
                                    format="MM/DD/YYYY"
                                    disabledDate={(currentDate) => isOutsideLastQuarter(currentDate)}
                                    onChange={() => emailInput.current.focus()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input an email',
                                },
                                {
                                    type: 'email',
                                    message: 'Must be a valid email',
                                }
                            ]}
                        >
                            <Input ref={emailInput} />
                        </Form.Item>
                    </Col>
                </Row>
                {(props.invitableType === "Unit" && !props.defaultSubscriberUnitResidentId) &&
                    <Row style={{marginBottom: 10}}>
                        <Col space={24}>
                            <Text><Text strong style={{color: Colors.dangerRed}}>Please verify the recurring fee setup for this Unit prior to sending out an invite.</Text><br /><br />The first resident to accept will be designated as the primary billing contact for recurring invoices.</Text>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>
    )
}

export default InviteCreationModal;