import React, {useContext, useEffect, useState} from 'react';
import {Pie} from "@ant-design/charts";
import {Tabs, Table, Typography, Empty} from "antd";
import LoadingContent from "../../utility/LoadingContent";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import MetricsQueries from "@dwellpass-client/common/utils/constants/queries/MetricsQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";

const { TabPane } = Tabs;
const { Text } = Typography;

const FeeRevenuesBreakdown = (props) => {
    const [communityState,] = useContext(CommunityContext);
    const [feeRevenuesState, setFeeRevenuesState] = useState([]);

    const {
        data: feeRevenuesData,
        loading: loadingFeeRevenues,
        refetch: refetchFeeRevenues
    } = useQuery(gql(MetricsQueries.getFeeRevenues), {
        notifyOnNetworkStatusChange: true,
        variables: {communityId: communityState.id, unitId: props.unitFilterState, status: props.statusFilterState, startDate: props.startDateFilterState, endDate: props.endDateFilterState}
    });

    useEffect(() => {
        feeRevenuesData && feeRevenuesData.feeRevenues && setFeeRevenuesState(feeRevenuesData.feeRevenues);
    }, [feeRevenuesData]);

    useEffect(() => {
        refetchFeeRevenues();
    }, [props.unitFilterState, props.statusFilterState, props.startDateFilterState, props.endDateFilterState]);

    var pieConfig = {
        appendPadding: 10,
        data: feeRevenuesState,
        angleField: 'percentageOfTotalRevenue',
        colorField: 'name',
        radius: 1,
        legend: false,
        label: {
            type: 'inner',
            offset: "-8%",
            content: '{name}',
            style: {
                fontWeight: 'bold'
            }
        },
        tooltip: {
            fields: ['totalRevenueAmountCents', 'percentageOfTotalRevenue']
        },
        meta: {
            totalRevenueAmountCents: {
                alias: 'Revenue',
                formatter: (v) => {
                    return `${MoneyFormatter.standardMoneyUSD(v)}`;
                },
            },
            percentageOfTotalRevenue: {
                alias: 'Percentage of Total',
                formatter: (v) => {
                    return `${v}%`;
                },
            },
        },
        interactions: [{type: 'element-active'}],
    };

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Revenue',
            dataIndex: 'totalRevenueAmountCents',
            sorter: (a, b) => a.totalRevenueAmountCents - b.totalRevenueAmountCents,
            defaultSortOrder: 'descend',
            render: (text, record) => (
                <Text>{MoneyFormatter.standardMoneyUSD(record.totalRevenueAmountCents)}</Text>
            )
        },
        {
            title: '%',
            dataIndex: 'percentageOfTotalRevenue',
            align: 'right',
            render: (text, record) => (
                <Text>{record.percentageOfTotalRevenue}%</Text>
            )
        },
    ];

    return (
        loadingFeeRevenues ?
            <LoadingContent/>
            :
            <Tabs type="card">
                <TabPane tab="Chart" key="chart">
                    {!!feeRevenuesState[0] ?
                        <Pie {...pieConfig} />
                    :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </TabPane>
                <TabPane tab="Breakdown" key="breakdown">
                    <Table
                        columns={tableColumns}
                        dataSource={feeRevenuesState}
                        rowKey={record => record.id}
                        size="small"
                        pagination={false}
                    />
                </TabPane>
            </Tabs>
    );
}

export default FeeRevenuesBreakdown;