import React, { useState, useContext } from "react";
import {Avatar, Button, Comment, Form, Input, notification} from "antd";
import {SendOutlined, UserOutlined} from "@ant-design/icons";
import {gql, useMutation} from "@apollo/client";
import PostReplyQueries from "@dwellpass-client/common/utils/constants/queries/PostReplyQueries";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const PostReplyForm = (props) => {
    const [createPostReply, { loading: loadingPostReply }] = useMutation(gql(PostReplyQueries.createPostReply));
    const [replyTextState, setReplyTextState] = useState("");
    const [userState, _] = useContext(UserContext);
    const [unitState, ] = useContext(UnitContext);

    const handleReplySubmit = async (e) => {
        try {
            await createPostReply({ variables: { postId: props.selectedPostState.id, replyableType: "UnitResident", replyableId: unitState.currentUserUnitResident.id, body: replyTextState } });
            clearReplyText();
            props.postReplyCreationSuccess();
        } catch (e) {
            notification['error']({
                message: 'Error Creating Reply',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    };

    const handleReplyTextChange = (e) => {
        setReplyTextState(e.target.value);
    };

    const clearReplyText = () => {
        setReplyTextState("");
    };

    return(
        <Comment
            avatar={<Avatar style={{ marginLeft: 10, marginRight: 20 }} icon={<UserOutlined />} src={userState.profileImageUrl && userState.profileImageUrl} />}
            content={
                <>
                    <Form.Item>
                        <Input.TextArea rows={2} onChange={(e) => handleReplyTextChange(e)} value={replyTextState} />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={loadingPostReply} onClick={(e) => handleReplySubmit(e)} type="primary" disabled={replyTextState === ""}>
                            <SendOutlined /> Reply
                        </Button>
                    </Form.Item>
                </>
            }
        />
    )
}

export default PostReplyForm;