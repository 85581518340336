import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Avatar, Button, Col, Empty, List, message, Modal, notification, Popconfirm, Row, Tooltip, Typography, Space, Divider} from "antd";
import {DeleteOutlined, InfoCircleOutlined, LoadingOutlined, MessageOutlined, QuestionCircleOutlined, SendOutlined, UserOutlined} from "@ant-design/icons";
import {gql, useMutation, useQuery} from "@apollo/client";
import UnitResidentQueries from "@dwellpass-client/common/utils/constants/queries/UnitResidentQueries";
import InviteQueries from "@dwellpass-client/common/utils/constants/queries/InviteQueries";
import InviteCreationModal from "../../../shared/InviteCreationModal";
import {useHistory} from "react-router-dom";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import LoadingContent from "../../../utility/LoadingContent";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const UnitResidents = forwardRef((props, ref) => {
    let history = useHistory();

    const [showResidentRemoveModal, setShowResidentRemoveModal] = useState(false);
    const [unitResidentToRemoveState, setUnitResidentToRemoveState] = useState({resident: {}});
    const [showCreateInviteModal, setShowCreateInviteModal] = useState(false);
    const [resendingInviteState, setResendingInviteState] = useState({});
    const [destroyingInviteState, setDestroyingInviteState] = useState({});
    const [unitResidentsInvitesState, setUnitResidentsInvitesState] = useState({});
    const [unitResidentDepartureErrors, setUnitResidentDepartureErrors] = useState([]);

    const {
        data: unitResidentsInvitesData,
        loading: loadingUnitResidentsInvites,
        refetch: refetchUnitResidentsInvites
    } = useQuery(gql(UnitQueries.getUnitResidentsInvites), {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {id: props.unitState.id}
    });
    const [departUnitResident, {loading: loadingDepartUnitResident}] = useMutation(gql(UnitResidentQueries.departUnitResident));
    const [resendInvite, {loading: loadingResendInvite}] = useMutation(gql(InviteQueries.resendInvite));
    const [destroyInvite, {loading: loadingDestroyInvite}] = useMutation(gql(InviteQueries.destroyInvite));

    useEffect(() => {
        unitResidentsInvitesData && setUnitResidentsInvitesState(unitResidentsInvitesData.unit);
    }, [unitResidentsInvitesData]);

    useImperativeHandle(ref, () => ({
        promptForResidentInvite
    }));

    const promptForResidentInvite = () => {
        setShowCreateInviteModal(true);
    }

    const promptForResidentRemoval = (unitResident) => {
        setUnitResidentToRemoveState(unitResident);
        setShowResidentRemoveModal(true);
    }

    const hideResidentRemoveModal = () => {
        setShowResidentRemoveModal(false);
        setTimeout(() => {
            clearUnitResidentDepartureErrors();
        }, 500);
    }

    const clearUnitResidentDepartureErrors = () => {
        setUnitResidentDepartureErrors([]);
    }

    const handleResidentRemoval = async () => {
        clearUnitResidentDepartureErrors();
        try {
            await departUnitResident({variables: {id: unitResidentToRemoveState.id}});
            hideResidentRemoveModal();
            popResidentRemovalSuccessNotification();
            props.refreshUnitState();
        } catch (error) {
            setUnitResidentDepartureErrors(error.graphQLErrors);
        }
    }

    const popResidentRemovalSuccessNotification = () => {
        notification["success"]({
            message: "Resident Removed",
            description: `Successfully removed resident: ${unitResidentToRemoveState.resident.firstName} ${unitResidentToRemoveState.resident.lastName}`,
        });
    };

    const navToEngagementWithPostCreationDetails = (unitResident) => {
        history.push({
            pathname: "/community/engagement",
            state: {postDetails: {receivableType: "UnitResident", receivable: unitResident}}
        });
    }

    const hideCreateInviteModal = () => {
        setShowCreateInviteModal(false);
    }

    const inviteCreationSuccess = () => {
        props.refreshUnitState();
    }

    const resendPendingInvite = async (invite) => {
        setResendingInviteState(invite);
        await resendInvite({variables: {id: invite.id}});
        message.success('Invite resent to: ' + invite.email);
        setResendingInviteState({});
        setTimeout(() => {
            refetchUnitResidentsInvites();
        }, 500);
    }

    const deletePendingInvite = async (invite) => {
        setDestroyingInviteState(invite);
        await destroyInvite({variables: {id: invite.id}});
        refetchUnitResidentsInvites();
        setDestroyingInviteState({});
    }

    return (
        (props.loadingUnit || loadingUnitResidentsInvites) ?
            <LoadingContent style={{marginTop: 100}}/>
            :
            <>
                <Divider orientation="left">Current</Divider>
                <List
                    size="small"
                    bordered
                    dataSource={props.unitState.currentUnitResidents}
                    locale={{
                        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Residents Listed"/>
                    }}
                    renderItem={unitResident =>
                        <List.Item
                            actions={
                                [
                                    <Button type="link"
                                            onClick={() => navToEngagementWithPostCreationDetails(unitResident)}><MessageOutlined
                                        style={{fontSize: 18}}/></Button>,
                                    <Button type="link"
                                            onClick={() => promptForResidentRemoval(unitResident)}><DeleteOutlined
                                        style={{fontSize: 18}}/></Button>
                                ]
                            }
                        >
                            <Row gutter={10} justify="space-between" align="middle">
                                <Col>
                                    <Avatar icon={<UserOutlined/>}
                                            src={unitResident.resident.profileImageUrl && unitResident.resident.profileImageUrl}/>
                                </Col>
                                <Col>
                                    <Text
                                        strong>{unitResident.resident.firstName} {unitResident.resident.lastName}</Text> - {unitResident.resident.email}
                                </Col>
                                {props.unitState?.defaultSubscriberUnitResident?.id === unitResident.id &&
                                    <Col>
                                        <Text strong style={{color: Colors.brandPrimary}}>Primary Billing</Text>
                                    </Col>
                                }
                            </Row>
                        </List.Item>
                    }
                />
                {unitResidentsInvitesState.pendingInvites && unitResidentsInvitesState.pendingInvites.length >= 1 &&
                <>
                    <Divider orientation="left">Pending</Divider>
                    <List
                        size="small"
                        bordered
                        dataSource={unitResidentsInvitesState.pendingInvites}
                        renderItem={invite =>
                            <List.Item
                                actions={
                                    [
                                        (loadingResendInvite && resendingInviteState.id == invite.id) ?
                                            <Button type="link"><LoadingOutlined style={{fontSize: 18}}/></Button>
                                            :
                                            <Tooltip title="Resend">
                                                <Button type="link"
                                                        onClick={(e) => resendPendingInvite(invite)}><SendOutlined
                                                    style={{fontSize: 18}}/></Button>
                                            </Tooltip>,
                                        (loadingDestroyInvite && destroyingInviteState.id == invite.id) ?
                                            <Button type="link"><LoadingOutlined style={{fontSize: 18}}/></Button>
                                            :
                                            <Popconfirm title="Are you sure"
                                                        icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                                                        onConfirm={() => deletePendingInvite(invite)}>
                                                <Button type="link"><DeleteOutlined style={{fontSize: 18}}/></Button>
                                            </Popconfirm>
                                    ]
                                }
                            >
                                <Row gutter={10} justify="space-between" align="middle">
                                    <Col>
                                        <Text strong>{invite.email}</Text>
                                    </Col>
                                    <Col>
                                        <Tooltip placement="topLeft" title="This includes manually sent invitations and automated system reminders">
                                            Notifications <InfoCircleOutlined style={{ fontSize: 14, color: Colors.monoMedGrey }} />: <Text strong>{invite.sendCount}</Text>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </List.Item>
                        }
                    />
                </>
                }
                <Modal
                    title="Are You Sure?"
                    visible={showResidentRemoveModal}
                    onOk={() => handleResidentRemoval()}
                    confirmLoading={loadingDepartUnitResident}
                    onCancel={() => hideResidentRemoveModal()}
                    okText="Remove"
                    cancelText="Cancel"
                >
                    {unitResidentDepartureErrors.length >= 1 && (
                        <Space style={{marginBottom: 10}} direction="vertical">
                            {unitResidentDepartureErrors.map(error =>
                                <Text key={error.message} type="danger">{error.message}<br/></Text>
                            )}
                        </Space>
                    )}
                    <Space direction="vertical">
                        <Text>This will remove {unitResidentToRemoveState.resident.firstName}'s access to this Unit's profile, and cancel any recurring billing they've set up without issuing any refunds.</Text>
                        <Text style={{ color: Colors.monoMedLightGrey }}>Note that if this results in Unit vacancy, the latest closing date will be cleared and fees will revert to the Community default.</Text>
                    </Space>
                </Modal>
                <InviteCreationModal
                    visible={showCreateInviteModal}
                    hideMe={hideCreateInviteModal}
                    formSuccess={inviteCreationSuccess}
                    invitableType="Unit"
                    invitableId={props.unitState.id}
                    defaultSubscriberUnitResidentId={props.unitState.defaultSubscriberUnitResident?.id}
                    latestClosingAt={props.unitState.latestClosingAt}
                />
            </>

    );
})

export default UnitResidents;