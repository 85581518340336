import React from "react";
import { Route, Redirect } from 'react-router-dom';

const QuickStartRoute = ({component: Component, authed, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => authed === false
                ? <Component {...props} />
                : <Redirect to={{pathname: '/boot', state: {from: props.location}}} />}
        />
    )
}

export default QuickStartRoute;