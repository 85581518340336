import React from 'react';
import {Modal, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";

const DeleteFeeModal = (props) => {
    const [destroyFee, { loading: loadingDestroyFee }] = useMutation(gql(FeeQueries.destroyFee));

    const modalBody = () => {
        if(props.selectedFeeState.unitFeeCount >= 1) {
            return `This Fee is attached to ${props.selectedFeeState.unitFeeCount} Units. After deletion, your Unit billing setup will change and you'll need to synchronize recurring invoices.`;
        } else {
            return "Are you sure you want to delete this Fee?";
        }
    }

    const successDescription = () => {
        if(props.selectedFeeState.unitFeeCount >= 1) {
            return `Successfully deleted Fee: ${props.selectedFeeState.name}. Remember that you may need to synchronize billing for the affected Units when you're finished making changes.`;
        } else {
            return `Successfully deleted Fee: ${props.selectedFeeState.name}.`;
        }
    }

    const handleFeeDeletion = async () => {
        await destroyFee({ variables: { id: props.selectedFeeState.id } });
        popFeeDeletionSuccessNotification();
        props.deletionSuccess();
    }

    const popFeeDeletionSuccessNotification = () => {
        notification["success"]({
            message: "Fee Deleted",
            duration: 10,
            description: successDescription(),
        });
    };

    return (
        <Modal
            title="Are You Sure?"
            visible={props.visible}
            onOk={() => handleFeeDeletion()}
            confirmLoading={loadingDestroyFee}
            onCancel={() => props.hideMe()}
            okText="Delete"
            cancelText="Cancel"
        >
            <p>{modalBody()}</p>
        </Modal>
    );
}

export default DeleteFeeModal;