import React, { useContext, useEffect, useState } from "react";
import {Divider, Row, Col, Typography, Form, Input, Button, notification, Select} from "antd";
import ProfileImageControl from "./ProfileImageControl";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import LoadingContent from "../../utility/LoadingContent";
import {FacebookOutlined, TwitterOutlined, InstagramOutlined, LoadingOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import TimeZoneQueries from "@dwellpass-client/common/utils/constants/queries/TimeZoneQueries";

const { Title } = Typography;

const CommunityProfile = (props) => {
    const [communityState, updateCommunityState] = useContext(CommunityContext);
    const [editingCommunity, setEditingCommunity] = useState(false);
    const [timeZonesState, setTimeZonesState] = useState([]);

    const { data: timeZonesData, loading: loadingTimeZones } = useQuery(gql(TimeZoneQueries.getTimeZones), { notifyOnNetworkStatusChange: true } );
    const [loadCommunity, { loading: loadingCommunity }] = useLazyQuery(gql(CommunityQueries.getCommunity), { fetchPolicy: "network-only", onCompleted: (communityData => { updateCommunityState({ type: "set", payload: communityData.community }) }) });
    const [updateCommunity, { loading: loadingUpdateCommunity }] = useMutation(gql(CommunityQueries.updateCommunity), { onCompleted: (communityData => { updateCommunityState({ type: "set", payload: communityData.updateCommunity }) }) });

    const [form] = Form.useForm();

    useEffect(() => {
        timeZonesData?.timeZones && setTimeZonesState(timeZonesData.timeZones);
    }, [timeZonesData]);

    useEffect(() => {
        form.setFieldsValue({
            name: communityState.name,
            website: communityState.website,
            contactEmail: communityState.contactEmail,
            contactPhoneNumber: communityState.contactPhoneNumber,
            addressLine1: communityState.address.street1,
            addressLine2: communityState.address.street2,
            addressCity: communityState.address.city,
            addressState: communityState.address.state,
            addressZip: communityState.address.zip,
            establishedYear: communityState.establishedYear ? communityState.establishedYear.toString() : null,
            twitterHandle: communityState.twitterHandle,
            facebookHandle: communityState.facebookHandle,
            instagramHandle: communityState.instagramHandle,
            timeZoneId: communityState.timeZone?.id
        });
    }, [communityState]);

    const cancelEditingCommunity = () => {
        setEditingCommunity(false);
        reloadCommunity();
    }

    const reloadCommunity = () => {
        loadCommunity({ variables: { id: communityState.id } });
    }

    const handleFormSubmit = async (values) => {
        try {
            await updateCommunity({variables: {
                    id: communityState.id,
                    name: values.name,
                    website: values.website,
                    contactEmail: values.contactEmail,
                    contactPhoneNumber: values.contactPhoneNumber,
                    addressStreet1: values.addressLine1,
                    addressStreet2: values.addressLine2,
                    addressCity: values.addressCity,
                    addressState: values.addressState,
                    addressZip: values.addressZip,
                    establishedYear: parseInt(values.establishedYear),
                    twitterHandle: values.twitterHandle,
                    facebookHandle: values.facebookHandle,
                    instagramHandle: values.instagramHandle,
                    timeZoneId: values.timeZoneId
            }});

            setEditingCommunity(false);
        }
        catch (error) {
            notification['error']({
                message: 'Error',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    return(
        loadingCommunity ?
            <Row style={{ height: 400 }} justify="center">
                <LoadingContent/>
            </Row>
            :
            <>
                <Form
                    form={form}
                    hideRequiredMark
                    labelAlign="right"
                    onFinish={handleFormSubmit}
                >
                    <Row justify="space-between">
                        <Col>
                            <Row align="middle">
                                <Col>
                                    <ProfileImageControl uploadSuccess={reloadCommunity}/>
                                </Col>
                                <Col push={1}>
                                    {editingCommunity ?
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[{required: true, message: 'Please enter the name of your community'}]}
                                        >
                                            <Input bordered={editingCommunity} disabled={!editingCommunity} />
                                        </Form.Item>
                                        :
                                        <Title level={2}>{communityState.name}</Title>
                                    }
                                    <Form.Item
                                        name="establishedYear"
                                        label="Established"
                                        rules={[
                                            {
                                                len: 4,
                                                message: 'Must be 4 numbers'
                                            },
                                            {
                                                pattern: /^(?:\d*)$/,
                                                message: "Only numbers allowed",
                                            },
                                        ]}
                                    >
                                        <Input placeholder={!editingCommunity ? "Not Listed" : "Year"} bordered={editingCommunity} disabled={!editingCommunity} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Form.Item
                                name="twitterHandle"
                                label={<TwitterOutlined style={{ fontSize: 20, color: Colors.brandPrimary }} />}
                            >
                                <Input placeholder={!editingCommunity ? "Not Listed" : "Handle"} bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="facebookHandle"
                                label={<FacebookOutlined style={{ fontSize: 20, color: Colors.brandPrimary }} />}
                            >
                                <Input placeholder={!editingCommunity ? "Not Listed" : "Handle"} bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="instagramHandle"
                                label={<InstagramOutlined style={{ fontSize: 20, color: Colors.brandPrimary }} />}
                            >
                                <Input placeholder={!editingCommunity ? "Not Listed" : "Handle"} bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                        </Col>
                        <Col>
                            {editingCommunity ?
                                <Row gutter={10}>
                                    <Col>
                                        <Button type="primary" htmlType="submit" loading={loadingUpdateCommunity}>
                                            Save
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => cancelEditingCommunity()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                                :
                                <Button type="primary" onClick={() => setEditingCommunity(true)}>
                                    Edit
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Divider>General Information</Divider>
                            <Form.Item
                                name="website"
                                label="Website"
                            >
                                <Input
                                    addonBefore={editingCommunity && "https://"}
                                    placeholder={editingCommunity ? "www..." : "Not Listed"}
                                    bordered={editingCommunity}
                                    disabled={!editingCommunity}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Contact Email"
                                name="contactEmail"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the contact email',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Must be a valid email',
                                    },
                                ]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="contactPhoneNumber"
                                label="Contact Phone Number"
                                rules={[{required: true, message: 'Please enter the contact phone number'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="timeZoneId"
                                label="Time Zone"
                                rules={[{ required: true, message: 'Please select a time zone' }]}
                            >
                                {loadingTimeZones ?
                                    <LoadingOutlined style={{ fontSize: 20, color: Colors.brandPrimary }} />
                                    :
                                    <Select
                                        bordered={editingCommunity}
                                        disabled={!editingCommunity}
                                        placeholder="Select Time Zone"
                                        options={timeZonesState.map((tz) => ({
                                            value: tz.id,
                                            label: tz.label
                                        }))}
                                    />
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Divider>Mailing Address</Divider>
                            <Form.Item
                                name="addressLine1"
                                label="Address Line 1"
                                rules={[{required: true, message: 'Please enter line 1 of your address'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="addressLine2"
                                label="Address Line 2"
                                rules={[{required: false, message: 'Please enter line 2 of your address'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="addressCity"
                                label="City"
                                rules={[{required: true, message: 'Please enter the city'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="addressState"
                                label="State"
                                rules={[{required: true, message: 'Please enter the state'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                            <Form.Item
                                name="addressZip"
                                label="Zip Code"
                                rules={[{required: true, message: 'Please enter the zip code'}]}
                            >
                                <Input bordered={editingCommunity} disabled={!editingCommunity} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>

    )
}

export default CommunityProfile;