import React, {useContext, useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import {Button, Empty, Row, Space, Table, Typography} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import ChangeUnitFeeModal from "./ChangeUnitFeeModal";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import LoadingContent from "../../../utility/LoadingContent";
import CreateInvoiceModal from "./CreateInvoiceModal";

const { Text } = Typography;

const UnitFeeTable = forwardRef((props, ref) => {
    const feePageSize = 30;

    const [communityState, ] = useContext(CommunityContext);
    const [totalFeeCount, setTotalFeeCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const { data: feesData, loading: loadingFees, refetch: refetchFees } = useQuery(gql(FeeQueries.getFees),{ notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, first: feePageSize, after: pageOffsetState } });
    const { data: unitFeesData, loading: loadingUnitFees, refetch: refetchUnitFees } = useQuery(gql(UnitQueries.getUnitUnitFees),{ fetchPolicy: "network-only", notifyOnNetworkStatusChange: true, variables: { id: props.unitState.id } });
    const [feesState, setFeesState] = useState([]);
    const [unitFeesState, setUnitFeesState] = useState([]);
    const [showChangeUnitFeeModal, setShowChangeUnitFeeModal] = useState(false);
    const [selectedFeeState, setSelectedFeeState] = useState({});
    const [selectedUnitFeeState, setSelectedUnitFeeState] = useState({});
    const [selectedFeeAction, setSelectedFeeAction] = useState("");
    const [invoiceCreationFeeState, setInvoiceCreationFeeState] = useState({});

    useEffect(() => {
        feesData && setFeesState(feesData.fees);
        feesData && setTotalFeeCount(feesData.fees.totalCount);
    }, [feesData]);

    useEffect(() => {
        unitFeesData && setUnitFeesState(unitFeesData.unit.unitFees);
    }, [unitFeesData]);

    useEffect(() => {
        refetchFees();
    }, [pageOffsetState]);

    useImperativeHandle(ref, () => ({
        performRefreshUnitFees
    }));

    const performRefreshUnitFees = () => {
        refetchUnitFees();
    }

    const promptForUnitFeeChange = (fee, action, unitFee) => {
        setSelectedFeeState(fee);
        unitFee && setSelectedUnitFeeState(unitFee);
        setSelectedFeeAction(action);
        setShowChangeUnitFeeModal(true);
    }

    const hideChangeFeeModal = () => {
        setSelectedFeeState({});
        setSelectedUnitFeeState({});
        setSelectedFeeAction("");
        setShowChangeUnitFeeModal(false);
    }

    const unitFeeChangeSuccess = async () => {
        hideChangeFeeModal();
        await refetchUnitFees();
        await refetchFees();
    }

    const classificationText = (classification) => {
        switch(classification) {
            case "hoa_dues":
                return "HOA Dues";
            case "membership":
                return "Membership";
            case "utility":
                return "Utility";
            case "miscellaneous":
                return "Miscellaneous";
            default:
                return "-";
        }
    }

    const recurringText = (recurring) => {
        return recurring ? StringFormatter.capitalizeFirstLetter(recurring) : <Text disabled>-</Text>
    }

    const changeFeeTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const feesTableColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: ['node', 'name']
        },
        {
            title: 'Classification',
            key: 'classification',
            render: (text, record) => (
                classificationText(record.node.classification)
            ),
        },
        {
            title: 'Recurring',
            key: 'recurring',
            render: (text, record) => (
                recurringText(record.node.recurring)
            )
        },
        {
            title: 'Price',
            key: 'priceCents',
            render: (text, record) => (
                MoneyFormatter.standardMoneyUSD(record.node.priceCents)
            ),
        },
        {
            title: 'Payout Account',
            key: 'payoutAccount',
            render: (text, record) => (
                `${record.node.payoutAccount.name}${record.node.payoutAccount.externalAccountLast4 && " ("+record.node.payoutAccount.externalAccountLast4+")"}`
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {record.node.recurring ?
                        unitFeesState.find((unitFee) => unitFee.fee.id === record.node.id) ?
                            <Button type="link" onClick={(e) => {e.stopPropagation();promptForUnitFeeChange(record.node, "destroy", unitFeesState.find((unitFee) => unitFee.fee.id === record.node.id))}}><DeleteOutlined style={{fontSize: 20}}/> Remove</Button>
                            :
                            <Button type="primary" onClick={(e) => {e.stopPropagation();promptForUnitFeeChange(record.node, "create")}}><PlusOutlined /> Add</Button>
                        :
                        <Button type="primary" disabled={!props.unitState.currentUnitResidents[0]} onClick={(e) => {e.stopPropagation();setInvoiceCreationFeeState(record.node)}}><PlusOutlined /> Invoice</Button>
                    }
                </Space>
            ),
        }
    ];

    return(
        <>
            <Row justify="center">
                {(loadingUnitFees || loadingFees || props.loadingUnit) ?
                    <LoadingContent style={{ marginTop: 100 }} />
                    :
                    <Table
                        style={{width: "100%"}}
                        size="small"
                        dataSource={feesState ? feesState.edges : null}
                        columns={feesTableColumns}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Residents"/>}}
                        rowKey={record => record.node.id}
                        pagination={{
                            pageSize: feePageSize,
                            position: ["none", "bottomCenter"],
                            total: totalFeeCount,
                            onChange: (page, pageSize) => {
                                changeFeeTablePage(page, pageSize)
                            }
                        }}
                    />
                }
                {!!props.unitState.currentUnitResidents[0] &&
                    <CreateInvoiceModal
                        selectedFee={invoiceCreationFeeState}
                        visible={!!invoiceCreationFeeState.id}
                        hideMe={() => setInvoiceCreationFeeState({})}
                        unitState={props.unitState}
                    />
                }
                <ChangeUnitFeeModal
                    visible={showChangeUnitFeeModal}
                    hideMe={hideChangeFeeModal}
                    unitState={props.unitState}
                    selectedFeeState={selectedFeeState}
                    selectedUnitFeeState={selectedUnitFeeState}
                    action={selectedFeeAction}
                    changeSuccess={unitFeeChangeSuccess}
                />
            </Row>
        </>
    )
})

export default UnitFeeTable;