import React, { useContext, useState, useEffect } from "react";
import '../assets/styles/css/antd.css';
import { useHistory } from "react-router-dom";
import {Layout, Row, Col, Form, Input, Button, Typography, notification} from 'antd';
import { AuthContext } from "../contexts/AuthContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import backgroundImage from "../assets/images/login-bg.jpg";
import logoImage from "../assets/images/dwellPass-logo-red-800w.png";
import ResidentContent from "./login/ResidentContent";
import CommunityContent from "./login/CommunityContent";
import PrimaryInput from "./shared/PrimaryInput";
import BrandAffix from "./shared/BrandAffix";

const { Content } = Layout;
const { Text, Title } = Typography;

export const Login = (props) => {
    let history = useHistory();

    const [_, dispatch] = useContext(AuthContext);
    const [data, setData] = useState({isSubmitting: false, errorMessages: []});
    const [confirmationRequiredState, setConfirmationRequiredState] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        data.errorMessages && data.errorMessages.find(message => message.search("confirmation") > 0) ? setConfirmationRequiredState(true) : setConfirmationRequiredState(false)
    }, [data.errorMessages]);

    const handleFormSubmit = values => {
        setData({
            isSubmitting: true,
            errorMessages: []
        });
        fetch(process.env.API_ROOT_URL+"/auth/sign_in", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: values.email,
                password: values.password
            })
        })
            .then(res => {
                if (res.ok) {
                    const payload = {
                        uid: res.headers.get('uid'),
                        client: res.headers.get('client'),
                        accessToken: res.headers.get('access-token'),
                    }
                    return payload;
                }
                return res.json().then(res => {throw res});
            })
            .then(payload => {
                dispatch({
                    type: "LOGIN",
                    payload
                });
                history.push({pathname: "/boot", state: { segment: getURLSegment() }});
            })
            .catch(error => {
                setData({
                    isSubmitting: false,
                    errorMessages: error.errors
                });
            });
    };

    const handleResendEmailConfirmation = () => {
        const email = form.getFieldsValue().email;

        fetch(process.env.API_ROOT_URL+"/auth/confirmation", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                redirect_url: `${process.env.APP_ROOT_URL}/login/${getURLSegment()}`
            })
        })
            .then(res => {
                if (res.ok) {
                    notification.success({
                        message: "Confirmation Sent",
                        description: `A new confirmation was sent to ${email}.`
                    })
                } else {
                    return res.json().then(res => {throw res});
                }
            })
            .catch(error => {
                notification.error({
                    message: "Error",
                    description: "There was a problem. Please verify you typed the correct email address and try again.",
                    duration: 6
                });
            });
    };

    const handleInitiatePasswordReset = () => {
        const email = form.getFieldsValue().email;

        fetch(process.env.API_ROOT_URL+"/auth/password", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                redirect_url: `${process.env.APP_ROOT_URL}/password-reset/${getURLSegment()}`
            })
        })
            .then(res => {
                if (res.ok) {
                    notification.success({
                        message: "Instructions Sent",
                        description: `Password reset instructions were sent to ${email}.`,
                        duration: 6
                    })
                } else {
                    return res.json().then(res => {throw res});
                }
            })
            .catch(error => {
                notification.error({
                    message: "Error",
                    description: "There was a problem. Please verify you typed the correct email address and try again.",
                    duration: 6
                });
            });
    };

    const navToRegisterCommunity = event => {
        history.push("/register/community");
    };

    const navToResidentLogin = event => {
        history.push("/login/resident");
    }

    const navToCommunityLogin = event => {
        history.push("/login/community");
    }

    const getURLSegment = () => {
        return props.match.params.segment;
    }

    return (
        <Layout>
            <Content>
                <Row style={{ height: '100vh' }}>
                    <Col xs={0} sm={0} md={16} style={styles.backgroundContainer}>
                        <BrandAffix />
                        {getURLSegment() == "resident" ?
                            <ResidentContent />
                            :
                            <CommunityContent />
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Row style={{ height: '100%', backgroundColor: Colors.monoBright }} align="middle">
                            <Col span={18} offset={3}>
                                <Row justify="center">
                                    <Col style={styles.logoContainer} span={24}></Col>
                                </Row>
                                <Title level={2} style={{ textTransform: "capitalize" }}>{getURLSegment()} Login</Title>
                                {data.errorMessages && (
                                    data.errorMessages.map(message =>
                                        <Text key={message} type="danger">{message}<br/></Text>
                                    )
                                )}
                                <Form
                                    labelCol={{ span: 16 }}
                                    wrapperCol={{ span: 24 }}
                                    layout={"vertical"}
                                    onFinish={handleFormSubmit}
                                    requiredMark={false}
                                    form={form}
                                >
                                    {confirmationRequiredState &&
                                        <Row style={{ marginTop: 10 }} justify="center">
                                            <Col>
                                                <Form.Item>
                                                    <Button onClick={() => handleResendEmailConfirmation()}>Resend Confirmation</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Must be a valid email',
                                            },
                                        ]}
                                    >
                                        <PrimaryInput />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password',
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={data.isSubmitting}>
                                            Login
                                        </Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button style={{ width: '100%' }} htmlType="button" onClick={navToRegisterCommunity}>
                                            Register a Community
                                        </Button>
                                    </Form.Item>

                                    <Row justify="space-between">
                                        <Col>
                                            {getURLSegment() == "community" &&
                                                <Form.Item>
                                                    <Button type="link" htmlType="button"
                                                            onClick={navToResidentLogin}>
                                                        Resident Login
                                                    </Button>
                                                </Form.Item>
                                            }

                                            {getURLSegment() == "resident" &&
                                                <Form.Item>
                                                    <Button type="link" htmlType="button"
                                                            onClick={navToCommunityLogin}>
                                                        Community Login
                                                    </Button>
                                                </Form.Item>
                                            }
                                        </Col>
                                        <Col>
                                            <Button type="link" onClick={() => handleInitiatePasswordReset()}>Forgot Password?</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

const styles = {
    backgroundContainer: {
        background: `url(${backgroundImage}) no-repeat center`,
        backgroundSize: 'cover'
    },
    logoContainer: {
        height: '100px',
        marginBottom: '30px',
        background: `url(${logoImage}) no-repeat center`,
        backgroundSize: 'auto 100px'
    }
}

export default Login;