import React, { useState, useReducer, useEffect } from "react";
import '../../assets/styles/css/antd.css';
import { Drawer } from "antd";
import UnitForm from "./units/UnitForm";
import MainUnitsTable from "./units/MainUnitsTable";

export const Units = (props) => {
    const { history } = props;

    const [selectedUnitState, setSelectedUnitState] = useState({});
    const [refreshMainUnitsTable, setRefreshMainUnitsTable] = useState(0);

    useEffect(() => {
        props.location.state && props.location.state.createUnit && openDrawer();
    },[]);

    let uniqueUnitTagReducer = (state, action) => {
        switch (action.type) {
            case "UPDATE_TAGS":
                return action.tags;
            default:
                throw new Error("Invalid Action Type");
        }
    };
    let [uniqueUnitTags, dispatchUniqueUnitTags] = useReducer(uniqueUnitTagReducer, []);

    const [drawerVisible, setDrawerVisible] = useState(false);
    const openDrawer = () => {
        setDrawerVisible(true);
    };
    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedUnit();
        }, 500);
    };

    const closeDrawerAndRefetchUnits = () => {
        onDrawerClose();
        performRefreshMainUnitsTable();
    }

    const selectUnit = (unit) => {
        history.push({
            pathname: '/community/units/detail',
            state: { unitId: unit.id }
        })
    }

    const editUnit = (unit) => {
        setSelectedUnitState(unit);
        openDrawer();
    }

    const clearSelectedUnit = () => {
        setSelectedUnitState({});
    }

    const performRefreshMainUnitsTable = () => {
        setRefreshMainUnitsTable(prev => prev + 1);
    }

    return (
        <>
            <MainUnitsTable selectUnit={selectUnit} editUnit={editUnit} openDrawer={openDrawer} dispatchUniqueUnitTags={dispatchUniqueUnitTags} refreshMainUnitsTable={refreshMainUnitsTable} />
            <Drawer
                title="Unit Information"
                placement="right"
                width={720}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <UnitForm uniqueUnitTags={uniqueUnitTags} selectedUnitState={selectedUnitState} formSuccess={closeDrawerAndRefetchUnits} />
            </Drawer>
        </>
    );
};

export default Units;