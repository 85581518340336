import React, { useContext } from "react";
import {Button, Col, Divider, Form, Input, notification, Row, Typography} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import {MessageOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import PrimaryInput from "../../shared/PrimaryInput";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";

const { Text } = Typography;

const PostForm = (props) => {
    const [communityState, _] = useContext(CommunityContext);
    const [unitState, ] = useContext(UnitContext);
    const [form] = Form.useForm();

    const [createPost, { loading: loadingPostCreation }] = useMutation(gql(PostQueries.createPost));

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        },500);
    };

    const handleFormSubmit = async (values) => {
        try {
            await createPost({variables: { communityId: communityState.id, postableType: "UnitResident", postableId: unitState.currentUserUnitResident.id, receivableType: "Community", title: values.title, body: values.body }});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            notification['error']({
                message: 'Error Creating Post',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    return(
        <>
            <Row gutter={10} justify="space-between">
                <Col>
                    <Text strong>To: <MessageOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/> Community Manager</Text>
                </Col>
            </Row>
            <Divider></Divider>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please enter the title' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="body"
                            label="Body"
                            rules={[{ required: true, message: 'Please enter the body' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100 }} type="primary" htmlType="submit" loading={loadingPostCreation}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PostForm;