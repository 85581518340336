import React, { useState } from "react";
import {gql, useLazyQuery} from "@apollo/client";
import ResourceQueries from "@dwellpass-client/common/utils/constants/queries/ResourceQueries";
import {Col, Row} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import LoadingContent from "../utility/LoadingContent";
import MainResourcesTable from "./resources/MainResourcesTable";
import ResourceDetail from "./resources/ResourceDetail";

const Resources = (props) => {
    const [selectedResourceState, setSelectedResourceState] = useState({});

    const [loadResource, { loading: loadingResource }] = useLazyQuery(gql(ResourceQueries.getResource), { notifyOnNetworkStatusChange: true, onCompleted: ( resourceData ) => { setSelectedResourceState(resourceData.resource) }, fetchPolicy: "network-only" })

    const [detailVisible, setDetailVisible] = useState(false);

    const selectResource = (resource) => {
        setDetailVisible(true);
        loadResource({ variables: { id: resource.node.id } });
    }

    const clearSelectedResource = () => {
        setDetailVisible(false);
        setSelectedResourceState({});
    }

    return(
        <>
            <Row gutter={10}>
                <Col span={detailVisible ? 17 : 24}>
                    <MainResourcesTable selectResource={selectResource} />
                </Col>
                <Col span={detailVisible ? 7 : 0} style={{ borderLeft: `2px solid ${Colors.monoLightGrey}` }}>
                    {loadingResource ?
                        <LoadingContent/>
                        :
                        selectedResourceState &&
                        <ResourceDetail selectedResourceState={selectedResourceState} clearSelectedResource={clearSelectedResource} />
                    }
                </Col>
            </Row>
        </>
    )
}

export default Resources;