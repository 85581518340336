import React, { useState } from 'react';
import NewBankAccountModal from "../../shared/NewBankAccountModal";
import NewPaymentCardModal from "../../shared/NewPaymentCardModal";
import VerifyBankAccountModal from "../../shared/VerifyBankAccountModal";
import {Button, Card, Col, Row, Space, Typography} from "antd";
import {CreditCardOutlined, BankOutlined, LoadingOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {useHistory} from "react-router-dom";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";

const { Text } = Typography;

const PaymentMethod = (props) => {
    const history = useHistory();
    const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false);
    const [showNewPaymentCardModal, setShowNewPaymentCardModal] = useState(false);
    const [showVerifyBankAccountModal, setShowVerifyBankAccountModal] = useState(false);

    const hideNewBankAccountModal = () => {
        setShowNewBankAccountModal(false);
    }

    const hideNewPaymentCardModal = () => {
        setShowNewPaymentCardModal(false);
    }

    const hideVerifyBankAccountModal = () => {
        setShowVerifyBankAccountModal(false);
    }

    const navToPaymentMethods = () => {
        history.push("/resident/payment-methods");
    }

    const cardTitle = () => {
        return (<Text>Payment Method</Text>);
    }

    const cardBody = () => {
        switch (props.derivedPaymentMethodState) {
            case "defaultPaymentMethod":
                return <Row justify="space-between" align="middle" wrap={false}>
                    <Col span={19}>
                        {props.defaultPaymentMethod?.type === "card" && <Text strong type="secondary" ellipsis={true} style={{fontSize: 16}}><CreditCardOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}} />{StringFormatter.capitalizeFirstLetter(props.defaultPaymentMethod.card.brand)}</Text>}
                        {props.defaultPaymentMethod?.type === "bankAccount" && <Text strong type="secondary" ellipsis={true} style={{fontSize: 16}}><BankOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}} />{StringFormatter.capitalizeFirstLetter(props.defaultPaymentMethod.bankAccount.bankName)}</Text>}
                    </Col>
                    <Col span={5}>
                        {props.defaultPaymentMethod?.type === "card" && <Text strong style={{color: Colors.brandPrimary}}>**{props.defaultPaymentMethod.card.last4}</Text>}
                        {props.defaultPaymentMethod?.type === "bankAccount" && <Text strong style={{color: Colors.brandPrimary}}>**{props.defaultPaymentMethod.bankAccount.last4}</Text>}
                    </Col>
                </Row>;
            case "unverifiedSource":
                return <Button style={{width: "100%"}} type="primary"
                               onClick={() => setShowVerifyBankAccountModal(true)}>Verify Account
                    ({props.defaultSource.last4})</Button>;
            case "defaultSource":
                return <Row justify="space-between" align="middle" wrap={false}>
                    <Col span={19}>
                        <Text strong type="secondary" ellipsis={true} style={{fontSize: 16}}><BankOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}} />{props.defaultSource.bankName}</Text>
                    </Col>
                    <Col span={5}>
                        <Text strong style={{color: Colors.brandPrimary}}>**{props.defaultSource.last4}</Text>
                    </Col>
                </Row>;
            default:
                return <Space direction="vertical" style={{width: "100%"}}>
                    <Row>
                        <Button style={{width: "100%"}} type="primary"
                                onClick={() => setShowNewBankAccountModal(true)}>
                            <BankOutlined style={{color: Colors.monoBright}} />Add Bank Account
                        </Button>
                    </Row>
                    <Row>
                        <Button style={{width: "100%"}} type="primary"
                                onClick={() => setShowNewPaymentCardModal(true)}>
                            <CreditCardOutlined style={{color: Colors.monoBright}} />Add Card
                        </Button>
                    </Row>
                </Space>;
        }
    }

    return (
        <>
            <Card title={cardTitle()}
                  extra={(props.defaultPaymentMethod?.id || props.defaultSource?.status === "verified") && <Button type="link" style={{ paddingRight: 0 }} onClick={() => navToPaymentMethods()}>Change</Button>}
                  style={{ width: 300, minHeight: 130 }}>
                {props.loadingPaymentProfile ?
                    <Col span={24}>
                        <Row justify="center">
                            <Col>
                                <LoadingOutlined style={{fontSize: 30, color: Colors.brandPrimary}}/>
                            </Col>
                        </Row>
                    </Col>
                    :
                    cardBody()
                }
            </Card>
            <NewBankAccountModal visible={showNewBankAccountModal} hideMe={hideNewBankAccountModal} currentUserUnitResident={props.currentUserUnitResident} formSuccess={props.refreshStripeCustomer} />
            <NewPaymentCardModal visible={showNewPaymentCardModal} hideMe={hideNewPaymentCardModal} currentUserUnitResident={props.currentUserUnitResident} formSuccess={props.refreshStripeCustomer} />
            <VerifyBankAccountModal selectedSource={props.defaultSource} visible={showVerifyBankAccountModal} hideMe={hideVerifyBankAccountModal} currentUserUnitResident={props.currentUserUnitResident} formSuccess={props.refreshStripeCustomer} />
        </>
    );
}

export default PaymentMethod;