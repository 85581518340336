import React from 'react';
import UnitsMapView from "./units_map/UnitsMapView";

const UnitsMap = (props) => {
    const { history } = props;

    const navToUnitsAndCreate = () => {
        history.push({
            pathname: "/community/units",
            state: {createUnit: true}
        });
    }

    const selectUnit = (unit) => {
        history.push({
            pathname: '/community/units/detail',
            state: { unitId: unit.id }
        })
    }

    return (
        <UnitsMapView selectUnit={selectUnit} createUnit={navToUnitsAndCreate} />
    );
}

export default UnitsMap;