import React, {useEffect, useState} from "react";
import {gql, useQuery, useLazyQuery} from "@apollo/client";
import {Button, Empty, Row, Col, Table, Typography, Radio, Divider, Tag, Tooltip, Modal, notification} from "antd";
import {FileTextOutlined, ExclamationCircleOutlined, LoadingOutlined, MinusOutlined, ReloadOutlined, ClockCircleOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import ConfirmPaymentModal from "./ConfirmPaymentModal";
import InvoiceChargeDetail from "./InvoiceChargeDetail";

const { Text, Title } = Typography;

const InvoicesTable = (props) => {
    const invoicePageSize = 10;

    const [invoicesState, setInvoicesState] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState("open");
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
    const [isLoadingInvoiceModalOpen, setIsLoadingInvoiceModalOpen] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [currentTablePage, setCurrentTablePage] = useState(1);
    const {data: invoicesData, loading: loadingInvoices, refetch: refetchInvoices} = useQuery(gql(InvoiceQueries.getInvoices), {notifyOnNetworkStatusChange: true, variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, status: invoiceStatusFilter, first: invoicePageSize, after: pageOffsetState}});
    const [loadStripeInvoiceData, ] = useLazyQuery(gql(InvoiceQueries.getStripeInvoice), { notifyOnNetworkStatusChange: true } );

    useEffect(() => {
        invoicesData && setInvoicesState(invoicesData.invoices);
        invoicesData && setTotalInvoiceCount(invoicesData.invoices.totalCount);
    }, [invoicesData]);

    useEffect(() => {
        onFirstPage() ? refetchInvoices() : clearPageOffset();
    }, [invoiceStatusFilter]);

    useEffect(() => {
        refetchInvoices();
    }, [pageOffsetState, props.refreshTable]);

    const openStripeHostedInvoiceUrl = async (stripeInvoiceId) => {
        setIsLoadingInvoiceModalOpen(true);

        let stripeInvoiceData = await loadStripeInvoiceData({variables: { stripeInvoiceId: stripeInvoiceId }})
        setTimeout(() => {
            setIsLoadingInvoiceModalOpen(false);
            stripeInvoiceData?.data?.stripeInvoice?.hostedInvoiceUrl ?
                window.open(stripeInvoiceData.data.stripeInvoice.hostedInvoiceUrl, "_blank")
                :
                notification["error"]({
                    message: "Error Retrieving Invoice",
                    description:
                        "Something went wrong. Please try again.",
                });
        }, 1000);
    }

    const changeInvoiceTablePage = (page, pageSize) => {
        setCurrentTablePage(page);
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const clearPageOffset = () => {
        changeInvoiceTablePage(1, invoicePageSize);
    }

    const onFirstPage = () => {
        return currentTablePage === 1
    }

    const invoicesOpenTableColumns = [
        {
            title: 'Invoice Type',
            key: 'recurring',
            render: (text, record) => (
                record.node.recurring ?
                    "Recurring Charges"
                    :
                    "One-time Invoice"
            ),
        },
        {
            title: 'Due',
            key: 'dueDate',
            render: (text, record) => (
                record.node.dueDate ?
                    <Row gutter={5}>
                        <Col>
                            <Text type={record.node.pastDue ? 'danger' : 'default'}>{DateTimeFormatter.simpleDateOnly(record.node.dueDate)}</Text>
                        </Col>
                        {record.node.pastDue &&
                        <Col>
                            <Tag color="red">PAST DUE</Tag>
                        </Col>
                        }
                    </Row>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Method',
            key: 'collectionMethod',
            render: (text, record) => (
                record.node.collectionMethod === 'send_invoice' ?
                    <Text type="danger">Manual Pay</Text>
                    :
                    <Text type="success">Autopay</Text>
            ),
        },
        {
            title: 'Total',
            key: 'totalCents',
            render: (text, record) => (
                <Text strong>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        },
        {
            title: 'View',
            key: 'view',
            render: (text, record) => (
                record.node.stripeInvoiceId ?
                    <Button type="link" onClick={(e) => {
                        openStripeHostedInvoiceUrl(record.node.stripeInvoiceId)
                    }}><FileTextOutlined style={{fontSize: 13}}/>View</Button>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: '',
            align: 'right',
            render: (text, record) => (
                record.node.pendingCharges ?
                    <Tooltip title="Payment pending">
                        <ClockCircleOutlined
                            style={{
                                marginTop: 6,
                                fontSize: 20,
                                color: Colors.monoMedLightGrey
                            }}/>
                    </Tooltip>
                    :
                    !!record.node.charges[0] &&
                        <Tooltip title="Payment failure occurred">
                            <ExclamationCircleOutlined
                                style={{
                                    marginTop: 6,
                                    fontSize: 20,
                                    color: Colors.dangerRed
                                }}/>
                        </Tooltip>
            )
        },
        {
            title: '',
            key: 'payAction',
            align: 'right',
            render: (text, record) => (
                <Button type="primary" style={{
                    backgroundColor: (!record.node.pendingCharges && (props.derivedPaymentMethodState === "defaultSource" || props.derivedPaymentMethodState === "defaultPaymentMethod")) && Colors.mainGreen,
                    borderColor: (!record.node.pendingCharges && (props.derivedPaymentMethodState === "defaultSource" || props.derivedPaymentMethodState === "defaultPaymentMethod")) && Colors.mainGreen,
                    fontWeight: "bold"
                }} onClick={(e) => {
                    e.stopPropagation();
                    promptConfirmPayment(record.node);
                }} disabled={record.node.pendingCharges || (props.derivedPaymentMethodState === "none" || props.derivedPaymentMethodState === "unverifiedSource")}>Pay</Button>
            ),
        }
    ];

    const invoicesPaidTableColumns = [
        {
            title: 'Invoice Type',
            key: 'recurring',
            render: (text, record) => (
                record.node.recurring ?
                    "Recurring Charges"
                    :
                    "One-time Invoice"
            ),
        },
        {
            title: 'Paid',
            key: 'paidAt',
            render: (text, record) => (
                record.node.paidAt ?
                    DateTimeFormatter.simpleDateOnly(record.node.paidAt)
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Method',
            key: 'collectionMethod',
            render: (text, record) => (
                record.node.collectionMethod === 'send_invoice' ?
                    <Text type="danger">Manual Pay</Text>
                    :
                    <Text type="success">Autopay</Text>
            ),
        },
        {
            title: 'Total',
            key: 'totalCents',
            render: (text, record) => (
                <Text strong>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        },
        {
            title: 'View',
            key: 'view',
            render: (text, record) => (
                record.node.stripeInvoiceId ?
                    <Button type="link" onClick={(e) => {
                        openStripeHostedInvoiceUrl(record.node.stripeInvoiceId)
                    }}><FileTextOutlined style={{fontSize: 13}}/>View</Button>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        }
    ];

    const changeInvoiceStatusFilter = (toStatus) => {
        setInvoicesState([]);
        setInvoiceStatusFilter(toStatus);
    }

    const promptConfirmPayment = (invoice) => {
        setSelectedInvoice(invoice);
        setShowConfirmPaymentModal(true);
    }

    const hideConfirmPaymentModal = () => {
        setShowConfirmPaymentModal(false);
    }

    const invoiceChargesPresent = () => {
        return invoicesState ? invoicesState.edges ? invoicesState.edges.find(i => i.node.charges.length > 0) : false : false;
    }

    const loadingInvoiceModal = () => {
        return <Modal style={{ padding: 100 }} visible={isLoadingInvoiceModalOpen} closable={false} maskClosable={false} footer={null}>
            <Row justify="center" style={{ marginBottom: 5 }}>
                <Title level={3}>Retrieving Invoice</Title>
            </Row>
            <Row justify="center">
                <LoadingOutlined style={{ fontSize: 35, color: Colors.brandPrimary }} />
            </Row>
        </Modal>;
    }

    return (
        <>
            <Divider>Invoices</Divider>
            <Row gutter={16} style={{marginBottom: 10}} justify="space-between">
                <Col>
                    <Radio.Group onChange={(e) => changeInvoiceStatusFilter(e.target.value)} defaultValue="open"
                                 buttonStyle="solid">
                        <Radio.Button value="open">Open</Radio.Button>
                        <Radio.Button value="paid">Paid</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col>
                    <Button type="link" onClick={() => refetchInvoices()}>
                        {loadingInvoices ?
                            <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            :
                            <ReloadOutlined style={{fontSize: 20}}/>
                        }
                    </Button>
                </Col>
            </Row>
            <Row justify="center">
                <Table
                    style={{width: "100%"}}
                    dataSource={invoicesState ? invoicesState.edges : null}
                    loading={{spinning: loadingInvoices, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                    columns={invoiceStatusFilter === "open" ? invoicesOpenTableColumns : invoicesPaidTableColumns}
                    locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Invoices"/>}}
                    rowKey={record => record.node.id}
                    pagination={{
                        current: currentTablePage,
                        pageSize: invoicePageSize,
                        position: ["none", "bottomCenter"],
                        total: totalInvoiceCount,
                        onChange: (page, pageSize) => { changeInvoiceTablePage(page, pageSize) }
                    }}
                    expandable={
                        invoiceChargesPresent() ?
                            {
                                expandIconColumnIndex: 7,
                                rowExpandable: record => !!record.node.charges[0],
                                expandedRowRender: record => <InvoiceChargeDetail charges={record.node.charges} />
                            }
                            : undefined
                        }
                />
            </Row>
            <ConfirmPaymentModal visible={showConfirmPaymentModal}
                                 hideMe={hideConfirmPaymentModal}
                                 invoice={selectedInvoice}
                                 setPaymentSuccessInvoice={props.setPaymentSuccessInvoice}
                                 currentUserUnitResident={props.currentUserUnitResident}
                                 defaultSource={props.defaultSource}
                                 defaultPaymentMethod={props.defaultPaymentMethod}
                                 derivedPaymentMethodState={props.derivedPaymentMethodState}
            />
            {loadingInvoiceModal()}
        </>
    )
}

export default InvoicesTable;