import React from 'react';
import googlePlayBadge from "../../assets/images/store_badges/google-play-badge.png";
import appStoreBadge from "../../assets/images/store_badges/app-store-badge.svg";

const badgeImage = (type) => {
    switch(type) {
        case "google":
            return googlePlayBadge;
        case "apple":
            return appStoreBadge;
    }
}

export {
    badgeImage
};