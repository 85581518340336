import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import CommunityProfile from "./configuration/CommunityProfile";
import Members from "./configuration/Members";
import FeeSetup from "./configuration/FeeSetup";
import Settings from "./configuration/Settings";
import Directory from "./configuration/Directory";
import PayoutAccounts from "./configuration/PayoutAccounts";

const { TabPane } = Tabs;

const Configuration = (props) => {
    const {location} = props;
    const [activeTabState, setActiveTabState] = useState("profile");
    const [refreshPayoutAccounts, setRefreshPayoutAccounts] = useState(0);

    useEffect(() => {
        location.state && location.state.showPayoutAccounts && setActiveTabState("payoutAccounts");
    }, []);

    const performPayoutAccountsRefresh = () => {
        setRefreshPayoutAccounts(prev => prev + 1);
    }

    return(
        <Tabs
            type="card"
            activeKey={activeTabState}
            onChange={setActiveTabState}
        >
            <TabPane tab="Community Profile" key="profile">
                <CommunityProfile />
            </TabPane>
            <TabPane tab="Fee Setup" key="fees">
                <FeeSetup performPayoutAccountsRefresh={performPayoutAccountsRefresh} />
            </TabPane>
            <TabPane tab="Payout Accounts" key="payoutAccounts">
                <PayoutAccounts refreshPayoutAccounts={refreshPayoutAccounts} />
            </TabPane>
            <TabPane tab="Members" key="members">
                <Members />
            </TabPane>
            <TabPane tab="Directory" key="directory">
                <Directory />
            </TabPane>
            <TabPane tab="Settings" key="settings">
                <Settings />
            </TabPane>
        </Tabs>
    )
}

export default Configuration;