import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "@dwellpass-client/common/contexts/UserContext";
import { gql, useApolloClient, useQuery, useLazyQuery } from "@apollo/client";
import MeQueries from "@dwellpass-client/common/utils/constants/queries/MeQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import LoadingFullscreen from "../components/utility/LoadingFullscreen";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {FeatureFlagsContext} from "@dwellpass-client/common/contexts/FeatureFlagsContext";
import FeatureFlagQueries from "@dwellpass-client/common/utils/constants/queries/FeatureFlagQueries";
import {SettingsContext} from "@dwellpass-client/common/contexts/SettingsContext";
import SettingQueries from "@dwellpass-client/common/utils/constants/queries/SettingQueries";

const BootRouter = (props) => {
    let history = useHistory();
    const [userState, setUserState] = useContext(UserContext);
    const [communityState, updateCommunityState] = useContext(CommunityContext);
    const [unitState, updateUnitState] = useContext(UnitContext);
    const [featureFlagsState, updateFeatureFlagsState] = useContext(FeatureFlagsContext);
    const [settingsState, updateSettingsState] = useContext(SettingsContext);

    const client = useApolloClient();
    const { data: userQueryData } = useQuery(gql(MeQueries.getMe));
    const [loadCommunity, ] = useLazyQuery(gql(CommunityQueries.getCommunityWithAccessUpdate), { fetchPolicy: "network-only", onCompleted: ( communityData ) => { setCommunityAndRouteOnStatus(communityData.communityWithAccessUpdate) } });
    const [loadUnit, ] = useLazyQuery(gql(UnitQueries.getUnitWithCommunityAndAccessUpdate), { fetchPolicy: "network-only", onCompleted: ( unitData ) => { setUnitAndRouteToResident(unitData.unitWithAccessUpdate) } });

    useEffect(() => {
        userQueryData?.me && setUserAndVetDestination(userQueryData.me);
    }, [userQueryData]);

    const setUserAndVetDestination = async (userData) => {
        setUserState(userData);

        await loadFeatureFlags();
        await loadSettings();

        const request = props.location?.state?.request;
        const segment = props.location?.state?.segment;

        checkCommunityRegistrationIntent();

        if(request) {
            loadRequestDataAndRoute(request);
        } else if(segment) {
            loadSegmentedLastAccessedAndRoute(segment, userData);
        } else {
            loadLastAccessedAndRoute(userData);
        }
    }

    const checkCommunityRegistrationIntent = async () => {
        const communityRegistrationIntent = await AsyncStorage.getItem("community-registration-intent");
        communityRegistrationIntent && startNewCommunityRegistration();
    }

    const loadFeatureFlags = async () => {
        const { data: featureFlagsData } = await client.query({query: gql(FeatureFlagQueries.getFeatureFlags)});
        updateFeatureFlagsState({type: "set", payload: featureFlagsData.featureFlags});
    }

    const loadSettings = async () => {
        const { data: settingsData } = await client.query({query: gql(SettingQueries.getSettings)});
        updateSettingsState({type: "set", payload: settingsData.settings});
    }

    const loadRequestDataAndRoute = (request) => {
        switch(request) {
            case "community":
                loadCommunity({variables: {id: props.location.state.community.id}});
                break;
            case "unit":
                loadUnit({variables: {id: props.location.state.unit.id}});
                break;
            case "registerNewCommunity":
                startNewCommunityRegistration();
                break;
            default:
                fallBackToCommunityRegistration();
        }
    }

    const loadSegmentedLastAccessedAndRoute = (segment, userData) => {
        switch(segment) {
            case "community":
                if (userData.lastAccessedManagedCommunity) {
                    loadCommunity({variables: {id: userData.lastAccessedManagedCommunity.id}});
                    break;
                }
            case "resident":
                if (userData.lastAccessedUnit) {
                    loadUnit({variables: {id: userData.lastAccessedUnit.id}});
                    break;
                } else if (userData.lastAccessedManagedCommunity) {
                    loadCommunity({variables: {id: userData.lastAccessedManagedCommunity.id}});
                    break;
                }
            default:
                fallBackToCommunityRegistration();
        }
    }

    const loadLastAccessedAndRoute = (userData) => {
        switch(userData.lastAccessedSegment) {
            case "community":
                loadCommunity({variables: {id: userData.lastAccessedManagedCommunity.id}});
                break;
            case "resident":
                loadUnit({variables: {id: userData.lastAccessedUnit.id}});
                break;
            default:
                fallBackToCommunityRegistration();
        }
    }

    const fallBackToCommunityRegistration = () => {
        startNewCommunityRegistration();
    }

    const startNewCommunityRegistration = () => {
        updateCommunityState({type: "reset"});
        history.push("/community-registration");
    }

    const setCommunityAndRouteOnStatus = async (community) => {
        await saveAccessedSegmentToStorage("community");
        updateCommunityState({ type: "set", payload: community});
        history.push(community.status == "active" ? "/community" : "/community-registration");
    }

    const setUnitAndRouteToResident = async (unit) => {
        await saveAccessedSegmentToStorage("resident");
        updateUnitState({ type: "set", payload: unit});
        updateCommunityState({ type: "set", payload: unit.community});
        history.push("/resident");
    }

    const saveAccessedSegmentToStorage = (segment) => {
        AsyncStorage.setItem("segment", segment);
    }

    return (
        <LoadingFullscreen />
    )
}

export default BootRouter;