import React, { useLayoutEffect, useRef, useEffect, useContext, useState } from 'react';
import H from "@here/maps-api-for-javascript";
import {Helmet} from "react-helmet";
import {gql, useLazyQuery} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import yellowMarkerImage from "../../../assets/images/markers/dwellPass-emblem-map-yellow-shadow-120.png";
import blueMarkerImage from "../../../assets/images/markers/dwellPass-emblem-map-blue-shadow-120.png";
import greenMarkerImage from "../../../assets/images/markers/dwellPass-emblem-map-green-shadow-120.png";
import purpleMarkerImage from "../../../assets/images/markers/dwellPass-emblem-map-purple-shadow-120.png";
import redMarkerImage from "../../../assets/images/markers/dwellPass-emblem-map-red-shadow-120.png";
import {Typography, Space, Modal, Alert, Row, Col, Empty, Button, Tag} from "antd";
import Lottie from "react-lottie";
import * as animationData from "@dwellpass-client/common/utils/lottie/circle-expand-loader-anim.json";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const UnitsMapView = (props) => {
    const mapRef = useRef(null);
    const [mapState, setMapState] = useState(null);
    const [uiState, setUiState] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [mapLoadedState, setMapLoadedState] = useState(false);

    const [communityState, ] = useContext(CommunityContext);
    const [loadUnits, { data: unitsData }] = useLazyQuery(gql(UnitQueries.getUnitsForMap), { notifyOnNetworkStatusChange: true } );

    useLayoutEffect(() => {
        if (!mapRef.current) return;
        const platform = new H.service.Platform({
            apikey: process.env.HERE_MAPS_API_KEY
        });
        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(
            mapRef.current,
            defaultLayers.vector.normal.map,
            {
                pixelRatio: window.devicePixelRatio || 1,
                center: {lat: 0, lng: 0},
                zoom: 4,
                padding: {top: 140, right: 140, bottom: 140, left: 140}
            },
        );
        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        var ui = H.ui.UI.createDefault(map, defaultLayers);
        setMapState(map);
        setUiState(ui);
        loadUnits({ variables: { communityId: communityState.id } });
        return () => {
            map.dispose();
        };
    }, [mapRef]);

    useEffect(() => {
        unitsData && unitsData.units && (unitsData.units.edges.length > 0 ? addMarkersAndSetViewBounds(mapState, uiState, unitsData.units.edges) : setMapLoadedState(true));
    }, [unitsData]);

    window.addEventListener('resize', function () {
        mapState && mapState.getViewPort().resize();
    });

    const loaderAnimOptions = {
        animationData: animationData.default,
        autoplay: true,
        loop: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const markerIcon = (unit) => {
        var yellowMarkerIcon = new H.map.Icon(yellowMarkerImage, { size: { w: 40, h: 40 } });
        var blueMarkerIcon = new H.map.Icon(blueMarkerImage, { size: { w: 40, h: 40 } });
        var greenMarkerIcon = new H.map.Icon(greenMarkerImage, { size: { w: 40, h: 40 } });
        var purpleMarkerIcon = new H.map.Icon(purpleMarkerImage, { size: { w: 40, h: 40 } });
        var redMarkerIcon = new H.map.Icon(redMarkerImage, { size: { w: 40, h: 40 } });

        if(unit.pastDueInvoices) {
            return redMarkerIcon;
        } else if(unit.openUnitResidentInvoicesCount > 0) {
            return blueMarkerIcon;
        } else if(unit.currentUnitResidentsCount === 0) {
            return purpleMarkerIcon;
        } else {
            return greenMarkerIcon;
        }
    }

    const addMarkersAndSetViewBounds = (map, ui, units) => {
        var markers = [];
        units.map(unit => {
            (unit.node.address.latitude && unit.node.address.longitude) &&
               markers.push(new H.map.Marker({lat:unit.node.address.latitude,  lng:unit.node.address.longitude}, {
                   icon: markerIcon(unit.node),
                   data: unit.node
               }))
        });
        var group = new H.map.Group();
        group.addObjects(markers);
        map.addObject(group);
        map.getViewModel().setLookAtData({
            bounds: group.getBoundingBox(),
        });
        map.getEngine().addEventListener('render', (evt) => { if (map.getEngine() === evt.target) { setMapLoadedState(true); } });

        group.addEventListener('tap', function (evt) {
            setSelectedUnit(evt.target.getData())
        }, false);
    }

    return (
        <>
            <Helmet>
                <link rel="stylesheet" type="text/css" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
            </Helmet>
            <Row style={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
                {!mapLoadedState ?
                    <Row style={{ width: "100%", height: "100%", backgroundColor: Colors.monoBright, position: "absolute", zIndex: 10 }} justify="center" align="middle">
                        <Col>
                            <Lottie options={loaderAnimOptions} width={200} height={200}/>
                        </Col>
                    </Row>
                    :
                    unitsData && unitsData.units && unitsData.units.edges.length === 0 &&
                        <Row style={{ width: "100%", height: "100%", backgroundColor: Colors.monoBright, position: "absolute", zIndex: 10 }} justify="center" align="middle">
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                imageStyle={{ height: 60 }}
                                description={
                                    <span>
                                        No Units Found
                                    </span>
                                }
                            >
                                <Button type="primary" onClick={() => props.createUnit()}>Add One Now</Button>
                            </Empty>
                        </Row>
                }
                <div ref={mapRef} style={{width: "100%", height: "100%", position: "absolute", zIndex: 1}} />
            </Row>
            <Modal
                title={`Unit: ${selectedUnit && selectedUnit.internalIdentifier}`}
                onOk={() => props.selectUnit(selectedUnit)}
                onCancel={() => setSelectedUnit(null)}
                cancelButtonProps={{style: {display: "none"}}}
                visible={(selectedUnit && selectedUnit.id)}
                okText={"Unit Details"}
                centered={true}
            >
                <Space style={{width: "100%"}} direction="vertical">
                    <Row justify="center" align="middle">
                        <Col>
                            <Text strong style={{ fontSize: 14 }}>{selectedUnit && selectedUnit.address && selectedUnit.address.fullAddress}</Text>
                        </Col>
                    </Row>
                    {selectedUnit && selectedUnit.currentUnitResidentsCount === 0 &&
                    <Row justify="center" align="middle">
                        <Col>
                            <Tag color="purple">Vacant</Tag>
                        </Col>
                    </Row>
                    }
                    {selectedUnit && selectedUnit.pastDueInvoices &&
                    <Alert message="Invoices Past Due" type="error"/>}
                    {selectedUnit && selectedUnit.openUnitResidentInvoicesCount > 0 &&
                    <Alert message={`${selectedUnit.openUnitResidentInvoicesCount} open invoice${selectedUnit.openUnitResidentInvoicesCount > 1 ? 's' : ''}`}
                           type="info"/>}
                </Space>
            </Modal>
        </>
    );
}

export default UnitsMapView;