import React, {useState} from "react";
import {Modal, Form, Input, Typography, notification, Checkbox, Select, Button} from "antd";
import {gql, useMutation} from "@apollo/client";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import { loadStripe } from "@stripe/stripe-js/pure";
import PaymentSourceQueries from "@dwellpass-client/common/utils/constants/queries/PaymentSourceQueries";
import PaymentMethodDisclaimer from "./PaymentMethodDisclaimer";

const { Text } = Typography;
const { Option } = Select;

const NewBankAccountModal = (props) => {
    const [form] = Form.useForm();
    const [formErrors, setFormErrors] = useState([]);
    const [loadingFormSubmission, setLoadingFormSubmission] = useState(false);

    const [createSource, ] = useMutation(gql(PaymentSourceQueries.createSource));

    const handleFormSubmit = async (values) => {
        setLoadingFormSubmission(true);
        const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

        try {
            setFormErrors([]);

            await stripe
                .createToken('bank_account', {
                    type: 'us_bank_account',
                    account_holder_type: values.accountType,
                    account_holder_name: values.accountHolderName,
                    routing_number: values.routingNumber,
                    account_number: values.accountNumber,
                    country: 'US',
                    currency: 'usd'
                })
                .then(async function(result) {
                    if(result.error) {
                        throw result.error;
                    } else {
                        await createSource({variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, stripeSourceToken: result.token.id}});

                        openSuccessNotification();
                    }
                });

            resetFieldsAndHideMe();
            setLoadingFormSubmission(false);
            props.formSuccess();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors ? error.graphQLErrors : [error]);
            setLoadingFormSubmission(false);
        }
    }

    const resetFieldsAndHideMe = () => {
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
            setFormErrors([]);
        }, 500);
    }

    const openSuccessNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" onClick={() => notification.close(key)}>
                Ok
            </Button>
        );

        notification["success"]({
            message: "Bank Account Added",
            description:
                "In 2-3 business days, you'll receive 2 small (less than $1) deposits from us. Once you receive them, return here to complete your bank account verification.",
            key,
            btn,
            duration: 0
        });
    }

    return(
        <Modal
            title="New Bank Account"
            visible={props.visible}
            onOk={form.submit}
            confirmLoading={loadingFormSubmission}
            onCancel={() => resetFieldsAndHideMe()}
        >
            <Form
                form={form}
                labelCol={{span: 16}}
                wrapperCol={{span: 24}}
                layout={"vertical"}
                onFinish={handleFormSubmit}
                requiredMark={false}
                initialValues={{
                    "accountType": "individual"
                }}
            >
                {formErrors && (
                    formErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <Form.Item
                    name="accountType"
                    label="Account Type"
                    rules={[{ required: true }]}
                >
                    <Select>
                        <Option value="individual">Individual</Option>
                        <Option value="company">Company</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Account Holder Name"
                    name="accountHolderName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of the account holder',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Routing Number"
                    name="routingNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the routing number',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Account Number"
                    name="accountNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the account number',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Please authorize'),
                        },
                    ]}
                >
                    <Checkbox style={{ "--background-color": Colors.brandPrimary }}>
                        I authorize dwellPass to electronically debit my account and, if necessary, electronically credit my account to correct or refund any charges. This includes both one-time and recurring charges.
                    </Checkbox>
                </Form.Item>
            </Form>
            <PaymentMethodDisclaimer />
        </Modal>
    )
}

export default NewBankAccountModal;