import React, { useState, useContext } from "react";
import {notification, Upload, Button, message} from "antd";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import {gql, useMutation} from "@apollo/client";
import FileUploadQueries from "@dwellpass-client/common/utils/constants/queries/FileUploadQueries";
import axios from "axios";
import FileHelpers from "@dwellpass-client/common/utils/file_handling/FileHelpers";
import ResourceQueries from "@dwellpass-client/common/utils/constants/queries/ResourceQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const FileUploader = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [loadingState, setLoadingState] = useState(false);

    const [createDirectUpload, ] = useMutation(gql(FileUploadQueries.createDirectUpload));
    const [createResource, ] = useMutation(gql(ResourceQueries.createResource));

    const beforeUpload = (file)  => {
        const isLt30M = file.size / 1024 / 1024 < 30;
        if (!isLt30M) {
            message.error("Filesize cannot exceed 30MB");
        }
        return isLt30M;
    }

    const uploadFile = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        var checksum = await FileHelpers.calculateChecksum(file);

        try {
            var directUpload = await createDirectUpload({ variables: { filename: file.name, contentType: file.type, checksum, byteSize: file.size } });
            var uploadHeaders = JSON.parse(directUpload.data.createDirectUpload.directUpload.headers);
            var signedBlobId = directUpload.data.createDirectUpload.directUpload.signedBlobId;

            const config = {
                headers: uploadHeaders,
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };
            await axios.put(directUpload.data.createDirectUpload.directUpload.url, file, config);

            var resource = await createResource({variables: { communityId: communityState.id, resourceFileBlobId: signedBlobId }});

            props.uploadSuccessResource(resource.data.createResource);

            onSuccess("Ok");
        } catch (error) {
            notification["error"]({
                message: "Error Uploading File",
                description:
                    "Something went wrong. Please try again.",
            });

            onError({ error });
        }
    }

    const handleChange = info => {
        if (info.file.status === "uploading") {
            setLoadingState(true);
        } else {
            setLoadingState(false);
        }
    };

    return(
        <Upload
            name="file-uploader"
            showUploadList={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={uploadFile}
        >
            <Button type="primary" icon={loadingState ? <LoadingOutlined /> : <UploadOutlined />}>Upload a File</Button>
        </Upload>
    )
}

export default FileUploader;