import React, { useContext, useState } from "react";
import {message, notification, Upload, Avatar, Tooltip} from "antd";
import ImgCrop from "antd-img-crop";
import FileHelpers from "@dwellpass-client/common/utils/file_handling/FileHelpers";
import axios from "axios";
import {LoadingOutlined, UserOutlined} from "@ant-design/icons";
import {gql, useMutation} from "@apollo/client";
import FileUploadQueries from "@dwellpass-client/common/utils/constants/queries/FileUploadQueries";
import MeQueries from "@dwellpass-client/common/utils/constants/queries/MeQueries";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const ProfileImageControl = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [loadingState, setLoadingState] = useState(false);

    const [createDirectUpload, ] = useMutation(gql(FileUploadQueries.createDirectUpload));
    const [attachProfileImage, ] = useMutation(gql(MeQueries.attachProfileImage));

    const beforeUpload = (file)  => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Image must be a JPG or PNG");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must be smaller than 2MB");
        }
        return isJpgOrPng && isLt2M;
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        setLoadingState(true);

        var checksum = await FileHelpers.calculateChecksum(file);

        try {
            var directUpload = await createDirectUpload({ variables: { filename: file.name, contentType: file.type, checksum, byteSize: file.size } });
            var uploadHeaders = JSON.parse(directUpload.data.createDirectUpload.directUpload.headers);
            var signedBlobId = directUpload.data.createDirectUpload.directUpload.signedBlobId;

            const config = {
                headers: uploadHeaders,
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };
            await axios.put(directUpload.data.createDirectUpload.directUpload.url, file, config);

            await attachProfileImage({ variables: { profileImageBlobId: signedBlobId } })

            props.uploadSuccess();

            onSuccess("Ok");
        } catch (error) {
            notification["error"]({
                message: "Error Uploading Photo",
                description:
                    "Something went wrong. Please try again.",
            });

            onError({ error });
        }
    }

    const handleChange = info => {
        if (info.file.status === "uploading") {
            setLoadingState(true);
            return;
        }
        if (info.file.status === "done") {
            setLoadingState(false);
        }
    };

    return(
        <ImgCrop rotate>
            <Upload
                showUploadList={false}
                maxCount={1}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={uploadImage}
            >
                <Tooltip title="Upload new image">
                    <Avatar style={{ cursor: "pointer" }} size={160} icon={loadingState ? <LoadingOutlined style={{ color: Colors.brandPrimary }} /> : <UserOutlined />} src={(userState.profileImageUrl && !loadingState) && userState.profileImageUrl} />
                </Tooltip>
            </Upload>
        </ImgCrop>
    )
}

export default ProfileImageControl;