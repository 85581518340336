import React, {useContext, useState} from "react";
import {Row, Col, Modal, Typography, List, Space, Select, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import {FileTextOutlined, CalendarOutlined, DollarCircleOutlined, UserOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const { Text } = Typography;

const CreateInvoiceModal = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [invoiceErrors, setInvoiceErrors] = useState([]);
    const [createInvoice, { loading: loadingCreateInvoice }] = useMutation(gql(InvoiceQueries.createInvoice));
    const [selectedStripeCustomerId, setSelectedStripeCustomerId] = useState(props.unitState.currentUnitResidents[0].stripeCustomerId);

    const handleCreation = async () => {
        try {
            setInvoiceErrors([]);

            await createInvoice({variables: {stripeCustomerId: selectedStripeCustomerId, stripePriceId: props.selectedFee.stripePriceId}});

            notification.success({
                message: "Invoice Created",
                description: "Please allow a few minutes for the invoice to process and make its way to the resident.",
                duration: 12
            })

            props.hideMe();
        }
        catch (error) {
            console.log(error);
            setInvoiceErrors([{message: "Failed to create the invoice. Please try again."}]);
        }
    }

    const resetAndHideMe = () => {
        setInvoiceErrors([]);
        props.hideMe();
    }

    const currentResidentSelectOptions = () => {
        return (
            props.unitState.currentUnitResidents.map(cur => <Select.Option key={cur.id} value={cur.stripeCustomerId}>{cur.resident.fullName}</Select.Option>)
        )
    }

    const invoiceData = [
        {icon: <FileTextOutlined />, title: "Invoice Type", data: "One-time Invoice"},
        {icon: <CalendarOutlined />, title: "Invoice Net Days", data: communityState.invoiceNetDays},
        {icon: <DollarCircleOutlined />, title: `Fee: ${props.selectedFee && props.selectedFee.name}`, data: props.selectedFee && MoneyFormatter.standardMoneyUSD(props.selectedFee.priceCents)},
        {icon: <UserOutlined />, title: "Resident", data: <Select value={selectedStripeCustomerId} onChange={value => setSelectedStripeCustomerId(value)} style={{ width: 180 }}>{currentResidentSelectOptions()}</Select>},
    ]

    return(
        <Modal
            title="Create Invoice"
            visible={props.visible}
            onOk={handleCreation}
            confirmLoading={loadingCreateInvoice}
            onCancel={resetAndHideMe}
            okText="Create Invoice"
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                {invoiceErrors && (
                    invoiceErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <List
                    size="small"
                    bordered
                    dataSource={invoiceData}
                    renderItem={item =>
                        <List.Item>
                            <Row style={{ width: "100%" }} justify="space-between" align="middle">
                                <Col>
                                    <Text style={{ color: Colors.brandPrimary }} strong>{item.icon} {item.title}
                                    </Text>
                                </Col>
                                <Col>
                                    <Text strong>{item.data}</Text>
                                </Col>
                            </Row>
                        </List.Item>
                    }
                />
            </Space>
        </Modal>
    )
}

export default CreateInvoiceModal;