import React from 'react';
import {Modal, notification, Space, Typography} from "antd";
import {gql, useMutation} from "@apollo/client";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";

const { Text } = Typography;

const ConfirmAddFeeToAllUnitsModal = (props) => {
    const [addFeeToAllUnits, { loading: loadingAddFeeToAllUnits }] = useMutation(gql(FeeQueries.addFeeToAllUnits));

    const handleAddingFee = async () => {
        await addFeeToAllUnits({ variables: { id: props.selectedFeeState.id } });
        popSuccessNotification();
        props.addSuccess();
    }

    const popSuccessNotification = () => {
        notification["success"]({
            message: "Fee Added",
            duration: 10,
            description: "The Fee has been added to all Units in your Community.",
        });
    };

    return (
        <Modal
            title="Are You Sure?"
            visible={props.visible}
            onOk={() => handleAddingFee()}
            confirmLoading={loadingAddFeeToAllUnits}
            onCancel={() => props.hideMe()}
            okText="Add"
            cancelText="Cancel"
        >
            <Space direction="vertical">
                <Text strong>Fee: {props.selectedFeeState && props.selectedFeeState.name}</Text>
                <Text>This will take effect immediately for each unit, and new fees will be charged in the next billing cycle. Please confirm you'd like to do so.</Text>
            </Space>
        </Modal>
    );
}

export default ConfirmAddFeeToAllUnitsModal;