import React, {useContext, useState, useEffect} from "react";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Col, Menu, Row, Layout, Badge} from "antd";
import {CheckSquareFilled, ContainerFilled, DashboardFilled, EnvironmentFilled, HomeFilled, MessageFilled, PieChartFilled, SettingFilled, SoundFilled} from "@ant-design/icons";
import {Link, useRouteMatch, useLocation} from "react-router-dom";
import {logoImage} from "../../utility/Logo";
import FeedbackCreationModal from "../../shared/FeedbackCreationModal";
import {featureFlagState} from "@dwellpass-client/common/utils/helpers/FeatureFlagHelpers";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import {gql, useQuery} from "@apollo/client";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";

const { Sider } = Layout;

const NavSider = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [myOpenTasksCount, setMyOpenTasksCount] = useState(0);
    const betaIndicatorFlagState = featureFlagState('beta_visual_indicator');

    let match = useRouteMatch();
    const location = useLocation();
    const { pathname } = location;

    const { data: taskData, refetch: refetchMyTasksForCount } = useQuery(gql(TaskQueries.getMyTasksForCount), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, assigneeId: userState.id, active: true, first: 1 } } );

    useEffect(() => {
        if(taskData) {
            setMyOpenTasksCount(taskData.tasks.totalCount);
        }
    }, [taskData]);

    useEffect(() => {
        refetchMyTasksForCount();
    }, [props.refreshMyOpenTasksCount]);

    const hideFeedbackModal = () => {
        setShowFeedbackModal(false);
    }

    return(
        <Sider collapsible style={{ overflow: 'auto', backgroundColor: Colors.brandPrimary }} breakpoint="md" onCollapse={(collapsed) => { setSiderCollapsed(collapsed) }}>
            <Row justify="center">
                { siderCollapsed ? (<Col style={{ background: `url(${logoImage("mono", "emblem", betaIndicatorFlagState)}) no-repeat center`, ...styles.smLogoContainer}} span={24}></Col>) : (<Col style={{background: `url(${logoImage("mono", "full", betaIndicatorFlagState)}) no-repeat center`, ...styles.logoContainer}} span={24}></Col>) }
            </Row>
            <Menu style={styles.navMenu} mode="inline" selectedKeys={[pathname]}>
                <Menu.Item key={match.url+"/dashboard"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <DashboardFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Dashboard</span>
                        </span>
                    </span>
                    <Link to={match.url+"/dashboard"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/tasks"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <CheckSquareFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Tasks</span>
                            <Badge count={myOpenTasksCount} style={{marginLeft: siderCollapsed ? 15 : 0, color: Colors.brandPrimary, backgroundColor: Colors.monoBright, fontWeight: "bold"}} />
                        </span>
                    </span>
                    <Link to={match.url+"/tasks"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/units"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <HomeFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Units</span>
                        </span>
                    </span>
                    <Link to={match.url+"/units"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/units-map"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <EnvironmentFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Map</span>
                        </span>
                    </span>
                    <Link to={match.url+"/units-map"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/engagement"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <MessageFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Engagement</span>
                        </span>
                    </span>
                    <Link to={match.url+"/engagement"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/resources"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <ContainerFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Resources</span>
                        </span>
                    </span>
                    <Link to={match.url+"/resources"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/metrics"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <PieChartFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Metrics</span>
                        </span>
                    </span>
                    <Link to={match.url+"/metrics"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/configuration"} style={styles.navMenuItem}>
                    <span style={styles.navItemContent}>
                        <SettingFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Configuration</span>
                        </span>
                    </span>
                    <Link to={match.url+"/configuration"} />
                </Menu.Item>
                <Menu.Item key={match.url+"/feedback"} style={styles.navMenuItem} onClick={() => setShowFeedbackModal(true)}>
                    <span style={styles.navItemContent}>
                        <SoundFilled style={styles.navIcon} />
                        <span style={styles.navItemLabel}>
                            <span className="nav-text" style={styles.navText}>Feedback</span>
                        </span>
                    </span>
                </Menu.Item>
            </Menu>
            <FeedbackCreationModal visible={showFeedbackModal} feedbackableType="CommunityManager" hideMe={hideFeedbackModal} />
        </Sider>
    )
}

const styles = {
    logoContainer: {
        height: '80px',
        margin: '30px 0px',
        backgroundSize: 'auto 80px'
    },
    smLogoContainer: {
        height: '60px',
        margin: '30px 0px',
        backgroundSize: 'auto 60px'
    },
    navMenu: {
        backgroundColor: Colors.brandPrimary,
        color: Colors.monoBright,
        borderRightColor: Colors.brandPrimary
    },
    navMenuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navItemContent: {
        display: 'flex',
        alignItems: 'center'
    },
    navIcon: {
        flex: 1,
        fontSize: 20
    },
    fontAwesomeIcon: {
        paddingRight: 5,
        color: Colors.monoBright
    },
    navItemLabel: {
        flex: 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    navText: {
        fontSize: "16 !important",
        fontWeight: "bold"
    }
}

export default NavSider;