import React, { useState, useContext, useEffect } from "react";
import {Button, Col, Form, Row, Tooltip, Typography, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {DeleteOutlined} from "@ant-design/icons";
import PrimaryInput from "../../../shared/PrimaryInput";
import DeletePayoutAccountModal from "./DeletePayoutAccountModal";
import PayoutAccountQueries from "@dwellpass-client/common/utils/constants/queries/PayoutAccountQueries";

const { Text } = Typography;

const PayoutAccountForm = (props) => {
    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [communityState, ] = useContext(CommunityContext);
    const [showDeletePayoutAccountModal, setShowDeletePayoutAccountModal] = useState(false);
    const [form] = Form.useForm();

    const [createPayoutAccount, ] = useMutation(gql(PayoutAccountQueries.createPayoutAccount));
    const [updatePayoutAccount, ] = useMutation(gql(PayoutAccountQueries.updatePayoutAccount));

    useEffect(() => {
        form.setFieldsValue({
            name: props.selectedPayoutAccountState.name
        })
    }, [props.selectedPayoutAccountState]);

    useEffect(() => {
        props.drawerVisible === false && resetForm();
    }, [props.drawerVisible]);

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        },500);
    };

    const promptForPayoutAccountDeletion = () => {
        setShowDeletePayoutAccountModal(true);
    }

    const hideDeletePayoutAccountModal = () => {
        setShowDeletePayoutAccountModal(false);
    }

    const deletionSuccess = async () => {
        setShowDeletePayoutAccountModal(false);
        resetForm();
        props.formSuccess();
    }

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            if(props.selectedPayoutAccountState.id) {
                await updatePayoutAccount({variables: { id: props.selectedPayoutAccountState.id, name: values.name }});
            } else {
                await createPayoutAccount({variables: { communityId: communityState.id, name: values.name }});
                notification.success({
                        message: 'Account Created',
                        description: 'Look for the button below to complete account setup.',
                        duration: 12,
                    })
            }
            setFormState({isSubmitting: false, errorMessage: null});
            resetForm();
            props.formSuccess();
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    return(
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name' }]}
                        >
                            <PrimaryInput />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Text style={{ color: Colors.monoMedLightGrey }}>Note: After creating the account, you'll be able to complete the account setup within Stripe using a link provided in the account list. You'll need to complete the setup in order to assign fees to this account.</Text>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Text style={{ color: Colors.monoMedLightGrey }}>Residents will receive separate invoices for each Payout Account that fees are associated with.</Text>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button style={{ width: 100, marginTop: 20 }} type="primary" htmlType="submit" loading={formState.isSubmitting}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                {props.selectedPayoutAccountState.id &&
                <Row justify="center">
                    <Col>
                        {props.selectedPayoutAccountState.feesCount === 0 ?
                            <Button type="link"
                                    onClick={() => promptForPayoutAccountDeletion(props.selectedPayoutAccountState)}>
                                <DeleteOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            </Button>
                            :
                            <Tooltip title="Account can't be deleted while fees are attached">
                                <DeleteOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            </Tooltip>
                        }
                    </Col>
                </Row>
                }
            </Form>
            <DeletePayoutAccountModal visible={showDeletePayoutAccountModal} hideMe={hideDeletePayoutAccountModal} selectedPayoutAccountState={props.selectedPayoutAccountState} deletionSuccess={deletionSuccess} />
        </>
    )
}

export default PayoutAccountForm;