import React, { useContext } from "react";
import {Button, Col, Row, Typography, Space} from "antd";
import {gql, useQuery} from "@apollo/client";
import LoadingContent from "../utility/LoadingContent";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import PayoutAccountQueries from "@dwellpass-client/common/utils/constants/queries/PayoutAccountQueries";

const { Title, Text } = Typography;

const StripeAccountStep = (props) => {
    const [communityState,] = useContext(CommunityContext);

    const {data: linkData, loading: loadingLinkData} = useQuery(gql(PayoutAccountQueries.getStripeAccountLink), {variables: {payoutAccountId: communityState.payoutAccounts[0].id}});

    return (
        <>
            <Row style={{ minHeight: 200 }} justify="center" align="middle">
                {loadingLinkData ?
                    <LoadingContent />
                :
                    <Col span={16}>
                        <Row justify="center">
                            <Title level={3}>Payout Account Setup</Title>
                        </Row>
                        <Space direction="vertical">
                            <Text>To receive funds from your residents, you need to link your initial Payout Account with Stripe, the processor that we partner with for payments.</Text>
                            <Text>When you click the link below, you'll be directed to Stripe to verify your identity and register your account.</Text>
                            <Text>This first account will be automatically associated with the first fee you've created, your HOA Dues fee. It's also the account that we'll use to process the platform fee. You can set up and modify as many accounts and fees as are necessary after registration is complete.</Text>
                            <Text>When your account is set up and verified, we'll move on.</Text>
                            <Text style={{ color: Colors.monoMedLightGrey }}>If your account is already set up, you may need to refresh this page or check back later, as Stripe may not have confirmed your account with us just yet.</Text>
                        </Space>
                    </Col>
                }
            </Row>
            <Row style={{marginTop: 25}} justify="center">
                <Col>
                    <Button style={{margin: '0 8px'}} onClick={() => props.goBackAStep()} disabled={loadingLinkData}>
                        Go Back
                    </Button>
                    {communityState.status == "open" ?
                        linkData && linkData.stripeAccountLink &&
                            <a href={linkData.stripeAccountLink.url}>
                                <Button type="primary" style={{margin: '0 8px'}}>Link your Stripe Account</Button>
                            </a>
                    :
                        <Button type="primary" style={{margin: '0 8px'}} onClick={() => props.goToNextStep()}>
                            Continue
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}

export default StripeAccountStep;