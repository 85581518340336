import React, {useContext} from 'react';
import {Card, Col, Drawer, Row, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {PlusOutlined} from "@ant-design/icons";
import EncodedImages from "@dwellpass-client/common/utils/constants/EncodedImages";
import {useHistory} from "react-router-dom";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";

const { Title, Text } = Typography;

const UserCommunitySelectionDrawer = (props) => {
    let history = useHistory();

    const [userState, ] = useContext(UserContext);

    const goToNewCommunityRegistration = () => {
        history.replace({pathname: "/boot", state: { request: "registerNewCommunity" }});
    }

    const switchToSelectedCommunity = (community) => {
        history.replace({pathname: "/boot", state: { request: "community", community: community }})
    }

    return (
        <Drawer
            title="Your Communities"
            placement="right"
            closable={true}
            onClose={props.onCommunityDrawerClose}
            visible={props.communityDrawerOpen}
            width={350}
        >
            <Card
                hoverable
                style={{ width: 300, marginTop: 16, backgroundColor: Colors.brandPrimary }}
                onClick={goToNewCommunityRegistration}
            >
                <Row justify="center">
                    <Col>
                        <PlusOutlined style={{ marginBottom: 10, fontSize: 40, color: Colors.monoBright }} />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Title level={5} style={{ color: Colors.monoBright }}>Create a Community</Title>
                    </Col>
                </Row>
            </Card>
            { [...userState.managedCommunities].sort(function(a, b) {return a.name.toLowerCase().localeCompare(b.name.toLowerCase());}).map((community, i) => {
                return(
                    <Card
                        hoverable
                        style={{ width: 300, marginTop: 16, backgroundColor: community.status == "active" ? Colors.monoBright : Colors.monoBrightGrey }}
                        key={i}
                        onClick={() => switchToSelectedCommunity(community)}
                        cover={
                            <img
                                alt="community-image"
                                width={200}
                                height={120}
                                style={{ objectFit: "cover" }}
                                src={community.profileImageUrl ? community.profileImageUrl : EncodedImages.fallbackImage}
                            />
                        }
                    >
                        <Row>
                            <Col>
                                <Text strong>
                                    {community.name} {community.status == "active" ? null : "(inactive)"}
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                )
            })}
        </Drawer>
    );
}

export default UserCommunitySelectionDrawer;