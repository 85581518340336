import React, { useContext } from 'react';
import {Col, Divider, Image, Row, Typography} from "antd";
import EncodedImages from "@dwellpass-client/common/utils/constants/EncodedImages";
import {FacebookOutlined, InstagramOutlined, TwitterOutlined, LinkOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const { Title, Text } = Typography;

const CommunityInfo = (props) => {
    const [communityState, ] = useContext(CommunityContext);

    return (
        <>
            <Divider>Community</Divider>
            <Row>
                <Col>
                    <Row gutter={16} align="bottom">
                        <Col>
                            <Image
                                width={200}
                                height={200}
                                preview={false}
                                src={communityState.profileImageUrl ? communityState.profileImageUrl : EncodedImages.fallbackImage}
                            />
                        </Col>
                        <Col>
                            <Title level={3}>{communityState.name}</Title>
                            {communityState.establishedYear &&
                                <>
                                    <Row>
                                        <Text strong>Established:</Text>
                                    </Row>
                                    <Row>
                                        <Text>{communityState.establishedYear}</Text>
                                    </Row>
                                </>
                            }
                            <Row>
                                <Text strong>Mailing Address:</Text>
                            </Row>
                            <Row>
                                <Text>{communityState.address.street1}{communityState.address.street2 && `, ${communityState.address.street2}` }</Text>
                            </Row>
                            <Row>
                                <Text>{communityState.address.city}, {communityState.address.state} {communityState.address.zip}</Text>
                            </Row>
                        </Col>
                        <Col>
                            {communityState.twitterHandle &&
                                <Row>
                                    <TwitterOutlined style={{ marginRight: 10, fontSize: 20, color: Colors.brandPrimary }} /><Text>{communityState.twitterHandle}</Text>
                                </Row>
                            }
                            {communityState.facebookHandle &&
                                <Row>
                                    <FacebookOutlined style={{ marginRight: 10, fontSize: 20, color: Colors.brandPrimary }} /><Text>{communityState.facebookHandle}</Text>
                                </Row>
                            }
                            {communityState.instagramHandle &&
                                <Row>
                                    <InstagramOutlined style={{ marginRight: 10, fontSize: 20, color: Colors.brandPrimary }} /><Text>{communityState.instagramHandle}</Text>
                                </Row>
                            }
                            {communityState.website &&
                            <>
                                <Row>
                                    <LinkOutlined style={{ marginRight: 10, fontSize: 20, color: Colors.brandPrimary }} /><a href={`https://${communityState.website}`} target="_blank">{`https://${communityState.website}`}</a>
                                </Row>
                            </>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default CommunityInfo;