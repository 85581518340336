import React, { useState, useEffect, useRef } from "react";
import {Row, Col, Typography, Divider, Tag, Breadcrumb, Button, Tabs, Descriptions, Skeleton, Badge, Space} from "antd";
import {LeftOutlined, MessageOutlined, UserOutlined, ContainerOutlined, DollarCircleOutlined, PlusOutlined, LoadingOutlined, ExclamationCircleFilled, CheckCircleFilled, FileTextOutlined} from "@ant-design/icons";
import {gql, useQuery} from "@apollo/client";
import UnitTimeline from "./unit_detail/UnitTimeline";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import UnitResidents from "./unit_detail/UnitResidents";
import UnitResources from "./unit_detail/UnitResources";
import UnitPosts from "./unit_detail/UnitPosts";
import UnitFeeTable from "./unit_detail/UnitFeeTable";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import UnitInvoicesTable from "./unit_detail/UnitInvoicesTable";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const UnitDetail = (props) => {
    const {location, history} = props;
    const unitResidentsComponent = useRef();
    const unitFeeTableComponent = useRef();

    const [unitState, setUnitState] = useState({});
    const [refreshUnitState, setRefreshUnitState] = useState(0);
    const [activeTabState, setActiveTabState] = useState("residents");

    const {
        data: unitData,
        refetch: refetchUnit,
        loading: loadingUnit
    } = useQuery(gql(UnitQueries.getUnit), {notifyOnNetworkStatusChange: true, variables: {id: location.state.unitId}});

    useEffect(() => {
        unitData && unitData.unit && setUnitState(unitData.unit);
        unitData && unitData.unit && location.state.showInvoices && setActiveTabState("invoices");
    }, [unitData]);

    useEffect(() => {
        refetchUnit();
    }, [refreshUnitState]);

    const handleDetailTabChange = (activeKey) => {
        setActiveTabState(activeKey);
        switch (activeKey) {
            case "fees":
                unitFeeTableComponent.current && unitFeeTableComponent.current.performRefreshUnitFees();
                break;
        }
    }

    const performRefreshUnitState = () => {
        setRefreshUnitState(prev => prev + 1);
    }

    const promptForResidentInvite = () => {
        unitResidentsComponent.current.promptForResidentInvite();
    }

    const navToEngagementWithPostCreationDetails = (unit) => {
        history.push({
            pathname: "/community/engagement",
            state: {postDetails: {receivableType: "Unit", receivable: unit}}
        });
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.goBack()}>
                    <a><LeftOutlined/> Units</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{unitState.internalIdentifier}</Breadcrumb.Item>
            </Breadcrumb>
            <Row gutter={16}>
                <Col span={16}>
                    <Row style={{padding: "10px 0px"}}>
                        <Col span={24}>
                            <Descriptions layout="vertical" bordered>
                                <Descriptions.Item label="Internal Idenfitier">
                                    {!unitState.internalIdentifier ?
                                        <Skeleton title paragraph={false} active/>
                                        :
                                        <Title level={3}>{unitState.internalIdentifier}</Title>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Physical Address">
                                    {!unitState.address ?
                                        <Skeleton title={false} paragraph={{rows: 2}} active/>
                                        :
                                        unitState.address &&
                                        <>
                                            <Row style={{minWidth: 200}}>
                                                <Text>{unitState.address.street1}{unitState.address.street2 && `, ${unitState.address.street2}`}</Text>
                                            </Row>
                                            <Row style={{minWidth: 200}}>
                                                <Text>{unitState.address.city}, {unitState.address.state} {unitState.address.zip}</Text>
                                            </Row>
                                        </>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Requirements">
                                    {loadingUnit ?
                                        <Row justify="center">
                                            <Col>
                                                <LoadingOutlined
                                                    style={{fontSize: 30, color: Colors.brandPrimary}}/>
                                            </Col>
                                        </Row>
                                        :
                                        <Space direction="vertical">
                                            {unitState.pastDueInvoices ?
                                                <Text type="danger"><ExclamationCircleFilled /> Invoices Past Due</Text>
                                                :
                                                <Text style={{color: Colors.mainGreen}}><CheckCircleFilled /> Up to Date</Text>
                                            }
                                        </Space>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Latest Closing Date">
                                    {loadingUnit ?
                                        <Row justify="center">
                                            <Col>
                                                <LoadingOutlined
                                                    style={{fontSize: 30, color: Colors.brandPrimary}}/>
                                            </Col>
                                        </Row>
                                        :
                                        unitState.latestClosingAt ?
                                            <Text>{DateTimeFormatter.simpleDateOnly(unitState.latestClosingAt)}</Text>
                                            :
                                            <Text disabled>N/A</Text>
                                    }
                                </Descriptions.Item>
                                {(unitState.tagList && unitState.tagList.length >= 1) &&
                                <Descriptions.Item label="Tags">{unitState.tagList.map((tag, i) => <Tag
                                    key={i}>{tag}</Tag>)}</Descriptions.Item>
                                }
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Tabs
                                tabBarExtraContent={
                                    <Row gutter={20} align="end">
                                        <Col>
                                            {activeTabState == "residents" &&
                                            <Button type="primary"
                                                    onClick={() => promptForResidentInvite()}><PlusOutlined/> Invite
                                                Resident</Button>
                                            }
                                            {activeTabState == "posts" &&
                                            <Button type="primary"
                                                    onClick={() => navToEngagementWithPostCreationDetails(unitState)}><MessageOutlined/> Create
                                                Post</Button>
                                            }
                                        </Col>
                                    </Row>
                                }
                                activeKey={activeTabState}
                                type="card"
                                onChange={(activeKey) => handleDetailTabChange(activeKey)}>
                                <TabPane tab={
                                    <span><UserOutlined/>Residents</span>}
                                         key="residents">
                                    {loadingUnit ?
                                        <Row justify="center">
                                            <Col>
                                                <LoadingOutlined
                                                    style={{fontSize: 30, color: Colors.brandPrimary}}/>
                                            </Col>
                                        </Row>
                                        :
                                        unitState.id &&
                                            <UnitResidents ref={unitResidentsComponent}
                                                           unitState={unitState}
                                                           refreshUnitState={performRefreshUnitState}
                                                           loadingUnit={loadingUnit}
                                            />
                                    }
                                </TabPane>
                                <TabPane tab={<span><DollarCircleOutlined/>Fees</span>} key="fees">
                                    <UnitFeeTable ref={unitFeeTableComponent}
                                                  unitState={unitState}
                                                  loadingUnit={loadingUnit}
                                                  refreshUnitState={performRefreshUnitState}
                                    />
                                </TabPane>
                                <TabPane tab={
                                    <Row
                                        gutter={6}
                                        align="middle">
                                        <Col>
                                            <FileTextOutlined />Invoices
                                        </Col>
                                        {unitState && unitState.openUnitResidentInvoicesCount > 0 &&
                                            <Col>
                                                <Badge style={{ backgroundColor: Colors.brandPrimary }} count={unitState.openUnitResidentInvoicesCount}/>
                                            </Col>
                                        }
                                    </Row>
                                }
                                         key="invoices">
                                    <UnitInvoicesTable unitState={unitState} refreshUnitState={performRefreshUnitState} />
                                </TabPane>
                                <TabPane tab={
                                    <span><ContainerOutlined />Resources</span>}
                                         key="resources"
                                         forceRender>
                                    <UnitResources unitState={unitState}/>
                                </TabPane>
                                <TabPane tab={
                                    <span><MessageOutlined/>Posts</span>}
                                         key="posts" forceRender>
                                    <UnitPosts unitState={unitState}/>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Divider>Timeline</Divider>
                    <Row>
                        <Col span={24}>
                            <UnitTimeline selectedUnitState={unitState} loadingUnit={loadingUnit}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default UnitDetail;