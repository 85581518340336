import React, { useState } from "react";
import {Row, Col, Modal, Typography, List, Space} from "antd";
import {gql, useMutation} from "@apollo/client";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import {FileTextOutlined, CalendarOutlined, BankOutlined, DollarCircleOutlined, CreditCardOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";

const { Text } = Typography;

const ConfirmPaymentModal = (props) => {
    const [paymentErrors, setPaymentErrors] = useState([]);
    const [payInvoice, { loading: loadingPayInvoice }] = useMutation(gql(InvoiceQueries.payInvoice));

    const submitPayment = async () => {
        try {
            setPaymentErrors([]);

            const variables = {};
            variables.stripeInvoiceId = props.invoice.stripeInvoiceId;
            variables.stripeSourceId = props.derivedPaymentMethodState === "defaultSource" ? props.defaultSource?.id : null;
            variables.stripePaymentMethodId = props.derivedPaymentMethodState === "defaultPaymentMethod"? props.defaultPaymentMethod?.id : null;

            await payInvoice({variables});

            props.setPaymentSuccessInvoice(props.invoice);
            props.hideMe();
        }
        catch (error) {
            console.log(error);
            setPaymentErrors([{message: "The payment failed to process. Please make sure your bank account contains sufficient funds and try again."}]);
        }
    }

    const resetAndHideMe = () => {
        setPaymentErrors([]);
        props.hideMe();
    }

    const paymentIconFromPaymentMethodState = (paymentMethodState) => {
        switch (paymentMethodState) {
            case "defaultPaymentMethod":
                return props.defaultPaymentMethod?.type === "card" ? <CreditCardOutlined /> : <BankOutlined />;
            default:
                return <BankOutlined />;
        }
    }

    const paymentTitleFromPaymentMethodState = (paymentMethodState) => {
        switch (paymentMethodState) {
            case "defaultPaymentMethod":
                return props.defaultPaymentMethod?.type === "card" ? "Card" : "Bank Account";
            default:
                return "Bank Account";
        }
    }

    const paymentMethodTextFromPaymentMethodState = (paymentMethodState) => {
        switch (paymentMethodState) {
            case "defaultPaymentMethod":
                return props.defaultPaymentMethod?.type === "card" ?
                    StringFormatter.capitalizeFirstLetter(props.defaultPaymentMethod?.card?.brand) + " - " + props.defaultPaymentMethod?.card?.last4
                    :
                    StringFormatter.capitalizeFirstLetter(props.defaultPaymentMethod?.bankAccount?.bankName) + " - " + props.defaultPaymentMethod?.bankAccount?.last4;
            default:
                return props.defaultSource?.bankName + " - " + props.defaultSource?.last4;
        }
    }

    const estimatedServiceFeeAmountCents = (paymentMethodState) => {
        switch (paymentMethodState) {
            case "defaultPaymentMethod":
                return props.defaultPaymentMethod?.type === "card" ?
                    props.invoice?.estimatedCardServiceFeeAmountCents
                    :
                    props.invoice?.estimatedBankAccountServiceFeeAmountCents
            default:
                return props.invoice?.estimatedBankAccountServiceFeeAmountCents;
        }
    }

    const paymentData = [
        {icon: <FileTextOutlined />, title: "Invoice Type", data: props.invoice && props.invoice.recurring ? "Recurring Charges" : "One-time Invoice"},
        {icon: <CalendarOutlined />, title: "Due on", data: props.invoice && DateTimeFormatter.simpleDateOnly(props.invoice.dueDate)},
        {icon: paymentIconFromPaymentMethodState(props.derivedPaymentMethodState), title: paymentTitleFromPaymentMethodState(props.derivedPaymentMethodState), data: paymentMethodTextFromPaymentMethodState(props.derivedPaymentMethodState)},
        {icon: <DollarCircleOutlined />, title: "Invoice Total", data: props.invoice && MoneyFormatter.standardMoneyUSD(props.invoice.totalCents)},
    ]

    return(
        <Modal
            title="Confirm Payment"
            visible={props.visible}
            onOk={submitPayment}
            confirmLoading={loadingPayInvoice}
            onCancel={() => resetAndHideMe()}
            okText={props.invoice && `Pay ${MoneyFormatter.standardMoneyUSD(props.invoice.totalCents + estimatedServiceFeeAmountCents(props.derivedPaymentMethodState))}`}
        >
            <Space direction="vertical">
                {paymentErrors && (
                    paymentErrors.map(error =>
                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                    )
                )}
                <List
                    size="small"
                    bordered
                    dataSource={paymentData}
                    renderItem={item =>
                        <List.Item>
                            <Row style={{ width: "100%" }} justify="space-between" align="middle">
                                <Col>
                                    <Text style={{ color: Colors.brandPrimary }} strong>{item.icon} {item.title}
                                    </Text>
                                </Col>
                                <Col>
                                    <Text strong>{item.data}</Text>
                                </Col>
                            </Row>
                        </List.Item>
                    }
                />
                <Row style={{width: "100%", padding: "5px 17px"}} justify="space-between" align="middle">
                    <Col>
                        <Text strong style={{color: Colors.dangerRed}}>Payment Processing Fee</Text>
                    </Col>
                    <Col>
                        <Text strong style={{color: Colors.dangerRed}}>{props.invoice && MoneyFormatter.standardMoneyUSD(estimatedServiceFeeAmountCents(props.derivedPaymentMethodState))}</Text>
                    </Col>
                </Row>
                <Row style={{padding: "0px 17px"}}>
                    <Text type="secondary">By submitting your payment, you agree to an electronic debit from your account for the stated amount pursuant to any relevant payment processing terms outlined in our Terms of Use. <Text strong>The Payment Processing Fee will be charged separately, and covers the costs associated with the processing of this transaction. It is not charged by your Community.</Text></Text>
                </Row>
            </Space>
        </Modal>
    )
}

export default ConfirmPaymentModal;