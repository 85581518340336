import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import '../assets/styles/css/antd.css';
import {Row, Col, Steps, Typography, Button, Dropdown, Menu} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import { FormOutlined, HomeOutlined, LinkOutlined, CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
import {logoImage} from "./utility/Logo";
import GeneralInfoStep from "./community_registration/GeneralInfoStep";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import StripeAccountStep from "./community_registration/StripeAccountStep";
import RegistrationCompleteStep from "./community_registration/RegistrationCompleteStep";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import DuesSetupStep from "./community_registration/DuesSetupStep";
import { featureFlagState } from "@dwellpass-client/common/utils/helpers/FeatureFlagHelpers";
import CommunityBetaInfoModal from "./community_registration/CommunityBetaInfoModal";
import HoldingStep from "./community_registration/HoldingStep";
import AsyncStorage from "@react-native-async-storage/async-storage";

const { Step } = Steps;
const { Title, Text } = Typography;

const CommunityRegistration = (props) => {
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState(0);
    const [communityState, ] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const communityHoldOnCreateFeatureFlagState = featureFlagState('community_hold_on_create');
    const betaIndicatorFlagState = featureFlagState('beta_visual_indicator');

    useEffect(() => {
        AsyncStorage.removeItem("community-registration-intent");
    }, []);

    useEffect(() => {
        communityState.status === "ready" && goToCompletionStep();
        communityState.status === "holding" && goToHoldingStep();
    }, [communityState]);

    const communityHoldOnCreateEnabled = () => {
        return communityState.status ? communityState.status === "holding" : communityHoldOnCreateFeatureFlagState;
    }

    const goToNextStep = () => {
        !communityHoldOnCreateEnabled() && setCurrentStep(currentStep + 1);
    };

    const goBackAStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const goToCompletionStep = () => {
        setCurrentStep(3);
    };

    const goToHoldingStep = () => {
        setCurrentStep(4);
    };

    const navToLogout = () => {
        history.push({pathname: "/logout", state: { segment: "community" }});
    }

    const switchToSelectedUnit = (unit) => {
        history.replace({pathname: "/boot", state: { request: "unit", unit: unit }});
    }

    const switchToSelectedCommunity = (community) => {
        history.replace({pathname: "/boot", state: { request: "community", community: community }});
    }

    const unitsMenu = (
        <Menu>
            { userState.currentUnits.map((unit, i) => {
                return(
                    <Menu.Item key={i} onClick={() => switchToSelectedUnit(unit)}>
                        {unit.address && unit.address.street1}
                    </Menu.Item>
                )
            })}
        </Menu>
    );

    const communityMenu = (
        <Menu>
            { userState.managedCommunities.map((community, i) => {
                return(
                    (community.id != communityState.id) && (
                        <Menu.Item key={i} onClick={() => switchToSelectedCommunity(community)}>
                            {community.name} {community.status == "active" ? null : "(inactive)"}
                        </Menu.Item>
                    )
                )
            })}
        </Menu>
    );

    return (
        <>
            <Row style={{ height: "100vh", padding: 24, backgroundColor: Colors.monoLightGrey, overflow: "scroll" }} justify="center" align="middle">
                <Col style={{ padding: 24, backgroundColor: Colors.monoBright, borderRadius: 15 }} span={16}>
                    <Row align="middle">
                        <Col span={8}>
                            <Button type="link" onClick={navToLogout}>Logout</Button>
                        </Col>
                        <Col style={{ background: `url(${logoImage("brand", "full", betaIndicatorFlagState)}) no-repeat center`, ...styles.logoContainer }} span={8}></Col>
                        <Col span={8}>
                            <Row gutter={10} justify="end">
                                {(userState.currentUnits.length >= 1) && (
                                    <Col>
                                        <Dropdown overlay={unitsMenu}>
                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                Units <DownOutlined />
                                            </a>
                                        </Dropdown>
                                    </Col>
                                )}
                                {(userState.managedCommunities.length >= 2 || (userState.managedCommunities.length === 1 && userState.managedCommunities[0].id !== communityState.id)) && (
                                    <Col>
                                        <Dropdown overlay={communityMenu}>
                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                Communities <DownOutlined />
                                            </a>
                                        </Dropdown>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Title level={3}>Community Registration</Title>
                    </Row>
                    {communityHoldOnCreateEnabled() && currentStep === 0 &&
                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col>
                                <Text strong type="secondary">The following information will help us evaluate your community for eligibility.</Text>
                            </Col>
                        </Row>
                    }
                    <Row justify="center">
                        <Col span={20}>
                            {!communityHoldOnCreateEnabled() &&
                                <Steps style={{ marginBottom: 25 }} current={currentStep}>
                                    <Step title="General Info" icon={<FormOutlined />} />
                                    <Step title="Dues Setup" icon={<HomeOutlined />} />
                                    <Step title="Payout Account" icon={<LinkOutlined />} />
                                    <Step title="Complete" icon={<CheckCircleOutlined />} />
                                </Steps>
                            }
                            { currentStep === 0 && (
                                <GeneralInfoStep goToNextStep={goToNextStep} />
                            )}
                            { currentStep === 1 && (
                                <DuesSetupStep goBackAStep={goBackAStep} goToNextStep={goToNextStep} />
                            )}
                            { currentStep === 2 && (
                                <StripeAccountStep goBackAStep={goBackAStep} goToNextStep={goToNextStep} />
                            )}
                            { currentStep === 3 && (
                                <RegistrationCompleteStep goBackAStep={goBackAStep} rebootAfterCommunityActivation={switchToSelectedCommunity} />
                            )}
                            { currentStep === 4 && (
                                <HoldingStep />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {betaIndicatorFlagState &&
                <CommunityBetaInfoModal />
            }
        </>
    )
}

const styles = {
    logoContainer: {
        height: '60px',
        marginBottom: '20px',
        backgroundSize: 'auto 60px'
    }
}

export default CommunityRegistration;