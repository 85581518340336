import React, { useContext, useEffect, useState } from "react";
import {Button, Col, Empty, Row, Switch, Table, Tooltip, Input} from "antd";
import {ApartmentOutlined, CommentOutlined, FormOutlined, HomeOutlined, LoadingOutlined, ReloadOutlined, UserOutlined, PushpinOutlined, MessageOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {gql, useQuery} from "@apollo/client";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import moment from "moment";

const { Search } = Input;

const MainPostsTable = (props) => {
    const [communityState, _] = useContext(CommunityContext);
    const [postsState, setPostsState] = useState([]);
    const [postPageSize, setPostPageSize] = useState(30);
    const [totalPostCount, setTotalPostCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [unreadOnlyState, setUnreadOnlyState] = useState(false);
    const [searchTextState, setSearchTextState] = useState("");

    const reader = { readerType: "CommunityManager", readerId: communityState.currentUserCommunityManager.id };
    const { data: postData, refetch: refetchPosts, loading: loadingPosts } = useQuery(gql(PostQueries.getPosts), { notifyOnNetworkStatusChange: true, variables: { reader, unread: unreadOnlyState, searchTerm: searchTextState, first: postPageSize, after: pageOffsetState } } );

    useEffect(() => {
        if(postData) {
            setPostsState(postData.posts);
            setTotalPostCount(postData.posts.totalCount);
        }
    }, [postData]);

    useEffect(() => {
        refetchPosts();
    }, [pageOffsetState, unreadOnlyState, props.refreshMainPostsTable, searchTextState]);

    const receivableTypeIcon = (node) => {
        switch (true) {
            case node.receivable && node.receivable.__typename == "UnitResident":
                return(
                    <Tooltip title="Resident Post">
                        <UserOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case node.receivable && node.receivable.__typename == "Unit":
                return(
                    <Tooltip title="Unit Post">
                        <HomeOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            case node.receivable && node.receivable.__typename == "Community":
                return(
                    <Tooltip title="Community Manager Post">
                        <MessageOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
            default:
                return(
                    <Tooltip title="Community Post">
                        <ApartmentOutlined style={{fontSize: 18, color: Colors.brandPrimary}}/>
                    </Tooltip>
                )
        }
    }

    const changePostTablePage = (page, pageSize) => {
        setPostPageSize(pageSize);
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const senderReceiverFromPost = (node) => {
        switch (true) {
            case node.receivable && node.receivable.__typename == "UnitResident":
                return `${node.receivable.resident.firstName} ${node.receivable.resident.lastName} (Unit: ${node.receivable.unit.internalIdentifier})`;
            case node.receivable && node.receivable.__typename == "Unit":
                return node.receivable.internalIdentifier;
            case node.receivable && node.receivable.__typename == "Community":
                return `${node.postable.resident.firstName} ${node.postable.resident.lastName} (Unit: ${node.postable.unit.internalIdentifier})`;
            default:
                return "Community";
        }
    }

    const columns = [
        {
            key: 'receivableType',
            dataIndex: ['node', 'receivableType'],
            width: 50,
            render: (text, record) => (
                receivableTypeIcon(record.node)
            ),
        },
        {
            key: 'party',
            width: 220,
            ellipsis: true,
            render: (text, record) => (
                <div style={{ fontWeight: record.node.unread ? "bold" : "normal" }}>{senderReceiverFromPost(record.node)}</div>
            ),
        },
        {
            key: 'title',
            dataIndex: ['node', 'title'],
            render: (text, record) => (
                <Row wrap={false}>
                    <span style={{ fontWeight: record.node.unread ? "bold" : "normal", whiteSpace: "nowrap" }}>{record.node.title}</span><span style={{ fontWeight: record.node.unread ? "bold" : "normal", color: Colors.monoMedLightGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>&nbsp; - {record.node.body}</span>
                </Row>
            ),
        },
        {
            key: 'pinExpiresAt',
            dataIndex: ['node', 'pinExpiresAt'],
            width: 40,
            render: (text, record) => (
                (record.node.pinExpiresAt && moment(record.node.pinExpiresAt).isAfter(moment())) ? <PushpinOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/> : null
            ),
        },
        {
            key: 'unreadReplies',
            dataIndex: ['node', 'unreadReplies'],
            width: 40,
            render: (text, record) => (
                record.node.unreadReplies ? <CommentOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/> : null
            ),
        },
        {
            key: 'created',
            dataIndex: ['node', 'createdAt'],
            width: 180,
            align: "right",
            render: (text, record) => (
                <div style={{ fontWeight: record.node.unread ? "bold" : "normal" }}>{DateTimeFormatter.standardDateTime(record.node.createdAt)}</div>
            ),
        }
    ];

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => props.startCreatingPostWith({receivableType: null, receivable: null})}><FormOutlined />New Post</Button>
                </Col>
                <Col>
                    <Row align="middle">
                        <Col>
                            <Search style={{ width: 200, marginRight: 15 }} placeholder="Search text..." allowClear onSearch={(term) => setSearchTextState(term)} />
                        </Col>
                        <Col>
                            <Switch checkedChildren="Unread" unCheckedChildren="Unread" onChange={(checked) => { setUnreadOnlyState(checked) }} />
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => refetchPosts()}>
                                {loadingPosts ?
                                    <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    :
                                    <ReloadOutlined style={{fontSize: 20}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        size="small"
                        showHeader={false}
                        dataSource={postsState ? postsState.edges : null}
                        loading={{spinning: loadingPosts, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                        rowKey={record => record.node.id}
                        pagination={{
                            pageSize: postPageSize,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            position: ["none", "bottomCenter"],
                            total: totalPostCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} posts`,
                            onChange: (page, pageSize) => { changePostTablePage(page, pageSize) }
                        }}
                        scroll={{ y: "58vh" }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={searchTextState ? "No Posts Found" : "All caught up!"} /> }}
                        onRow={(record) => {
                            return {
                                onClick: event => { props.selectPost(record) }
                            };
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default MainPostsTable;