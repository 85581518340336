import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import {Empty, Row, Table} from "antd";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import {MinusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import LoadingContent from "../../utility/LoadingContent";

const UnitsList = (props) => {
    const unitPageSize = 30;

    const [unitsState, setUnitsState] = useState({});
    const [totalUnitCount, setTotalUnitCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [communityState, ] = useContext(CommunityContext);
    const { data: unitsData, loading: loadingUnits } = useQuery(gql(UnitQueries.getUnits), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, first: unitPageSize, after: pageOffsetState } } );

    useEffect(() => {
        unitsData && setUnitsState(unitsData.units);
        unitsData && setTotalUnitCount(unitsData.units.totalCount);
    }, [unitsData]);

    const changeUnitTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const unitsTableColumns = [
        {
            title: 'ID',
            key: 'internalIdentifier',
            dataIndex: ['node', 'internalIdentifier'],
            sorter: true
        },
        {
            title: 'Address',
            key: 'street1',
            dataIndex: ['node', 'address', 'street1']
        },
        {
            title: 'Residents',
            key: 'residents',
            render: (text, record) => (
                record.node.unitResidents.length >= 1 ?
                    record.node.unitResidents.filter((ur) => ur.departedAt == null).length
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
    ];

    return(
        <>
            <Row justify="center">
                {loadingUnits ?
                    <LoadingContent />
                    :
                    <Table
                        style={{ width: "100%" }}
                        size="small"
                        dataSource={unitsState ? unitsState.edges : null}
                        columns={unitsTableColumns}
                        pagination={{
                            pageSize: unitPageSize,
                            position: ["none", "bottomCenter"],
                            total: totalUnitCount,
                            onChange: (page, pageSize) => { changeUnitTablePage(page, pageSize) }
                        }}
                        rowKey={record => record.node.id}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Units" /> }}
                        onRow={(record) => {
                            return {
                                onClick: event => { props.startCreatingPostWith({ receivableType: "Unit", receivable: record.node }) }
                            };
                        }}
                    />
                }
            </Row>
        </>
    )
}

export default UnitsList;