import React, {useEffect, useState} from "react";
import '../../assets/styles/css/antd.css';
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import WebHeader from "./master/WebHeader";
import { Layout } from 'antd';
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import Endpoints from "@dwellpass-client/common/utils/constants/Endpoints";
import Units from "./Units";
import UnitsMap from "./UnitsMap";
import Dashboard from "./Dashboard";
import Tasks from "./Tasks";
import UnitDetail from "./units/UnitDetail";
import Engagement from "./Engagement";
import Resources from "./Resources";
import NavSider from "./master/NavSider";
import UserProfile from "../shared/UserProfile";
import Configuration from "./Configuration";
import Metrics from "./Metrics";

const { Content, Footer } = Layout;

export const Master = (props) => {
    let match = useRouteMatch();
    const [refreshMyOpenTasksCountInSider, setRefreshMyOpenTasksCountInSider] = useState(0);

    useEffect(() => {
        performRefreshMyOpenTasksCountInSider();
    }, []);

    const getYear = () => {
        return new Date().getFullYear();
    }

    const performRefreshMyOpenTasksCountInSider = () => {
        setRefreshMyOpenTasksCountInSider(prev => prev + 1);
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <NavSider refreshMyOpenTasksCount={refreshMyOpenTasksCountInSider} />
            <Layout>
                <WebHeader navigation={props.navigation} />
                <Content style={styles.contentWrapper}>
                    <Redirect exact from="/community" to={match.url+"/dashboard"} />
                    <div style={styles.contentContainer}>
                        <Route path={match.path+"/dashboard"} component={Dashboard} />
                        <Route exact path={match.path+"/tasks"} render={(props) => <Tasks {...props} performRefreshMyOpenTasksCountInSider={performRefreshMyOpenTasksCountInSider} />} />
                        <Route exact path={match.path+"/units"} component={Units} />
                        <Route exact path={match.path+"/units-map"} component={UnitsMap} />
                        <Route path={match.path+"/units/detail"} component={UnitDetail} />
                        <Route exact path={match.path+"/engagement"} component={Engagement} />
                        <Route exact path={match.path+"/resources"} component={Resources} />
                        <Route exact path={match.path+"/metrics"} component={Metrics} />
                        <Route exact path={match.path+"/configuration"} component={Configuration} />
                        <Route exact path={match.path+"/user-profile"} component={UserProfile} />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Copyright © {getYear()} dwellPass. All rights reserved. <a href={Endpoints.termsOfUseURL} target="_blank">Terms of Use</a> | <a href={Endpoints.privacyPolicyURL} target="_blank">Privacy Policy</a></Footer>
            </Layout>
        </Layout>
    );
};

const styles = {
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        margin: 0,
    },
    contentContainer: {
        flex: 1,
        padding: 18,
        backgroundColor: Colors.monoBright,
        overflow: "scroll"
    }
}

export default Master;