import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useMutation, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {Avatar, Button, Empty, notification, Popconfirm, Row, Space, Table} from "antd";
import {LoadingOutlined, LogoutOutlined, MinusOutlined, QuestionCircleOutlined, UserOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const CommunityManagersList = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const { data: communityManagersData, loading: loadingCommunityManagers, refetch: refetchCommunityManagers } = useQuery(gql(CommunityQueries.getCommunityManagers),{ notifyOnNetworkStatusChange: true, variables: { id: communityState.id } });
    const [communityManagersState, setCommunityManagersState] = useState([]);
    const [destroyCommunityManager, { loading: loadingDestroyCommunityManager }] = useMutation(gql(CommunityQueries.destroyCommunityManager));

    useEffect(() => {
        communityManagersData && setCommunityManagersState(communityManagersData.community.communityManagers);
    }, [communityManagersData])

    useEffect(() => {
        props.setLoadingState(loadingCommunityManagers || loadingDestroyCommunityManager);
    }, [loadingCommunityManagers, loadingDestroyCommunityManager]);

    useEffect(() => {
        refetchCommunityManagers();
    }, [props.refreshCurrentTabTable]);

    const communityManagersTableColumns = [
        {
            key: 'image',
            render: (text, record) => (
                <Avatar size={36} icon={<UserOutlined />} src={record.manager && record.manager.profileImageUrl && record.manager.profileImageUrl} />
            ),
        },
        {
            title: 'First Name',
            key: 'firstName',
            dataIndex: ['manager', 'firstName'],
            sorter: true
        },
        {
            title: 'Last Name',
            key: 'lastName',
            dataIndex: ['manager', 'lastName'],
            sorter: true
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: ['manager', 'email']
        },
        {
            title: 'Last Accessed',
            key: 'lastAccessedAt',
            render: (text, record) => (
                record.lastAccessedAt == record.createdAt ?
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
                    :
                    DateTimeFormatter.standardDateTime(record.lastAccessedAt)
            ),
        },
        {
            title: 'Manager Since',
            key: 'createdAt',
            render: (text, record) => (
                DateTimeFormatter.standardDateTime(record.createdAt)
            ),
        },
        {
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                record.manager.id != userState.id ?
                    <Space size="middle">
                        <Popconfirm title="Are you sure" icon={<QuestionCircleOutlined style={{ color: "red" }} />} onConfirm={() => revokeManagerMembership(record)}>
                            <Button type="link" onClick={(e) => {e.stopPropagation()}}><LogoutOutlined style={{fontSize: 13}} />Revoke</Button>
                        </Popconfirm>
                    </Space>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        }
    ];

    const revokeManagerMembership = async (communityManager) => {
        await destroyCommunityManager({ variables: { id: communityManager.id } });
        popManagerRemovalSuccessNotification(communityManager);
        refetchCommunityManagers();
    }

    const popManagerRemovalSuccessNotification = (communityManager) => {
        notification["success"]({
            message: "Manager Removed",
            description: `Successfully removed manager: ${communityManager.manager.firstName} ${communityManager.manager.lastName}`,
        });
    };

    return(
        <Row justify="center">
            <Table
                style={{ width: "100%" }}
                size="small"
                dataSource={communityManagersState ? communityManagersState : null}
                loading={{spinning: props.loadingState, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                columns={communityManagersTableColumns}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Community Managers" /> }}
                pagination={false}
                rowKey="id"
            />
        </Row>
    )
}

export default CommunityManagersList;