import React, {useState} from "react";
import {Button, Col, Row, Tabs, Typography} from "antd";
import ResidentsList from "./members/ResidentsList";
import {ApartmentOutlined, UserOutlined, MailOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import CommunityManagersList from "./members/CommunityManagersList";
import PendingInvitesList from "./members/PendingInvitesList";
import InviteCreationModal from "../../shared/InviteCreationModal";

const { TabPane } = Tabs;
const { Text } = Typography;

const Members = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [showCreateInviteModal, setShowCreateInviteModal] = useState(false);
    const [refreshCurrentTabTable, setRefreshCurrentTabTable] = useState(0);
    const [activeTabState, setActiveTabState] = useState("managers");

    const performRefreshCurrentTabTable = () => {
        setRefreshCurrentTabTable(prev => prev + 1);
    }

    const inviteCreateSuccess = () => {
        performRefreshCurrentTabTable();
    }

    const hideCreateInviteModal = () => {
        setShowCreateInviteModal(false);
    }

    return(
        <>
            <Tabs
                type="card"
                tabBarExtraContent={
                    <Row gutter={20} align="middle">
                        {loadingState &&
                            <Col>
                                <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                            </Col>
                        }
                        <Col>
                            {activeTabState != "residents" ?
                                <Button type="primary" onClick={() => setShowCreateInviteModal(true)}><PlusOutlined />Invite Manager</Button>
                                :
                                <Text style={{ color: Colors.monoMedLightGrey }}>Invite from Unit Details</Text>
                            }
                        </Col>
                    </Row>
                }
                defaultActiveKey="managers"
                onChange={(activeKey) => setActiveTabState(activeKey)}>
                <TabPane tab={<span><ApartmentOutlined /> Managers</span>} key="managers" forceRender>
                    <CommunityManagersList setLoadingState={setLoadingState} loadingState={loadingState} refreshCurrentTabTable={refreshCurrentTabTable} />
                </TabPane>
                <TabPane tab={<span><UserOutlined /> Residents</span>} key="residents" forceRender>
                    <ResidentsList setLoadingState={setLoadingState} loadingState={loadingState} refreshCurrentTabTable={refreshCurrentTabTable} />
                </TabPane>
                <TabPane tab={<span><MailOutlined /> Pending</span>} key="pending" forceRender>
                    <PendingInvitesList setLoadingState={setLoadingState} loadingState={loadingState} refreshCurrentTabTable={refreshCurrentTabTable} />
                </TabPane>
            </Tabs>
            <InviteCreationModal visible={showCreateInviteModal} hideMe={hideCreateInviteModal} formSuccess={inviteCreateSuccess} />
        </>
    )
}

export default Members;