import React, {useContext, useEffect, useState} from "react";
import { Row, Col, Divider } from "antd";
import CommunityInfo from "./dashboard/CommunityInfo";
import QuickActionsBar from "./dashboard/QuickActionsBar";
import ResidentInfo from "./dashboard/ResidentInfo";
import LatestInvoicesTable from "./dashboard/LatestInvoicesTable";
import LatestPostsList from "./dashboard/LatestPostsList";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import {gql, useQuery} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import LoadingContent from "../utility/LoadingContent";

const Dashboard = (props) => {
    const [unitState,] = useContext(UnitContext);
    const [currentUserUnitResident, setCurrentUserUnitResident] = useState({});

    const {data: currentUserUnitResidentData, loading: loadingCurrentUserUnitResident} = useQuery(gql(UnitQueries.getUnitCurrentUserUnitResident), {notifyOnNetworkStatusChange: true, variables: {id: unitState.id}});

    useEffect(() => {
        currentUserUnitResidentData && currentUserUnitResidentData.unit && setCurrentUserUnitResident(currentUserUnitResidentData.unit.currentUserUnitResident);
    }, [currentUserUnitResidentData]);

    return(
        <>
            <Row gutter={16}>
                <Col span={16}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <CommunityInfo />
                        </Col>
                        <Col span={12}>
                            <ResidentInfo />
                        </Col>
                    </Row>
                    <QuickActionsBar />
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={24}>
                            <Divider>Latest Posts</Divider>
                            {(currentUserUnitResident && currentUserUnitResident.id) ?
                                <LatestPostsList currentUserUnitResident={currentUserUnitResident}/>
                                :
                                <Row style={{ height: "26vh" }} justify="center">
                                    <Col>
                                        <LoadingContent style={{ height: "30vh" }} />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider>Latest Invoices</Divider>
                            {(currentUserUnitResident && currentUserUnitResident.stripeCustomerId) ?
                                <LatestInvoicesTable currentUserUnitResident={currentUserUnitResident}/>
                                :
                                <Row style={{ height: "26vh" }} justify="center">
                                    <Col>
                                        <LoadingContent style={{ height: "30vh" }} />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard;