import React, { useContext, useEffect, useState } from "react";
import {Button, Col, Empty, Row, List, Tag, Typography} from "antd";
import {LoadingOutlined, EyeOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {gql, useQuery} from "@apollo/client";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {useHistory} from "react-router-dom";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";

const {Text} = Typography;

const LatestTasksList = (props) => {
    const taskPageSize = 10;
    const history = useHistory();

    const [communityState, ] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const [tasksState, setTasksState] = useState([]);

    const { data: taskData, loading: loadingTasks } = useQuery(gql(TaskQueries.getTasks), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, assigneeId: userState.id, active: true, first: taskPageSize } } );

    useEffect(() => {
        if(taskData) {
            setTasksState(taskData.tasks);
        }
    }, [taskData]);

    const navToTasksForTaskDetails = (task) => {
        history.push({pathname: "/community/tasks", state: { showTask: { node: task }}});
    }

    const taskStatus = status => {
        switch (status) {
            case "open":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainBlue}>Open</Tag>;
            case "in_progress":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.brandPrimary}>In Progress</Tag>;
            case "on_hold":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainOrange}>On Hold</Tag>;
            case "canceled":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.monoMedGrey}>Canceled</Tag>;
            case "completed":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainGreenDark}>Completed</Tag>;
            default:
                return "--";
        }
    }

    return(
        <List
            style={{ width: "100%" }}
            itemLayout="horizontal"
            dataSource={tasksState ? tasksState.edges : null}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Tasks" /> }}
            loading={{spinning: loadingTasks, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            renderItem={record => (
                <List.Item
                    actions={[<Button type="link"><EyeOutlined style={{ marginTop: 4, fontSize: 22 }} /></Button>]}
                    onClick={() => navToTasksForTaskDetails(record.node)}
                >
                    <List.Item.Meta
                        title={<span style={{ fontWeight: "bold" }}>{record.node.title}</span>}
                        description={
                            record.node.dueAt &&
                                <>
                                    <Text strong>Due:&nbsp;</Text>
                                    <Text type={record.node.pastDue ? 'danger' : 'default'}>{DateTimeFormatter.standardDateTime(record.node.dueAt)}</Text>
                                </>
                            }
                    />
                    {taskStatus(record.node.status)}
                </List.Item>
            )}
        />
    )
}

export default LatestTasksList;