import React, { useContext, useEffect, useState } from "react";
import {Divider, Row, Col, Typography, Form, Button, notification, InputNumber, Tooltip, Switch} from "antd";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import LoadingContent from "../../utility/LoadingContent";
import {InfoCircleOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const Settings = (props) => {
    const [formErrors, setFormErrors] = useState([]);
    const [communityState, updateCommunityState] = useContext(CommunityContext);
    const [editingCommunity, setEditingCommunity] = useState(false);

    const [loadCommunity, { loading: loadingCommunity }] = useLazyQuery(gql(CommunityQueries.getCommunity), { fetchPolicy: "network-only", onCompleted: (communityData => { updateCommunityState({ type: "set", payload: communityData.community }) }) });
    const [updateCommunitySettings, { loading: loadingUpdateCommunitySettings }] = useMutation(gql(CommunityQueries.updateCommunitySettings));

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            invoiceNetDays: communityState.invoiceNetDays,
            maskPostCreatorByDefault: communityState.maskPostCreatorByDefault
        });
    }, [communityState]);

    useEffect(() => {
        setFormErrors([]);
    }, [editingCommunity]);

    const cancelEditingCommunity = () => {
        setEditingCommunity(false);
        reloadCommunity();
    }

    const reloadCommunity = async () => {
        await loadCommunity({ variables: { id: communityState.id } });
    }

    const handleFormSubmit = async (values) => {
        setFormErrors([]);

        try {
            await updateCommunitySettings({variables: {
                id: communityState.id,
                invoiceNetDays: values.invoiceNetDays,
                maskPostCreatorByDefault: values.maskPostCreatorByDefault
            }});
            setEditingCommunity(false);
            popSuccessNotification();
            reloadCommunity();
        }
        catch (error) {
            setFormErrors(error.graphQLErrors);
        }
    }

    const popSuccessNotification = () => {
        notification['success']({
            message: 'Settings Updated',
            duration: 0,
            description:
                'Note that if changes were made to billing related settings, they\'ll be reflected in the next billing cycle.',
        });
    }

    return(
        loadingCommunity ?
            <Row style={{ height: 400 }} justify="center">
                <LoadingContent/>
            </Row>
            :
            <>
                <Form
                    form={form}
                    hideRequiredMark
                    labelAlign="right"
                    onFinish={handleFormSubmit}
                >
                    <Row justify="end">
                        <Col>
                            {editingCommunity ?
                                <Row gutter={10}>
                                    <Col>
                                        <Button type="primary" htmlType="submit" loading={loadingUpdateCommunitySettings}>
                                            Save
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => cancelEditingCommunity()}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                                :
                                <Button type="primary" onClick={() => setEditingCommunity(true)}>
                                    Edit
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row justify="center">
                        {formErrors && (
                            formErrors.map(error =>
                                <Text key={error.message} type="danger">{error.message}<br/></Text>
                            )
                        )}
                    </Row>
                    <Row gutter={16} justify="center">
                        <Divider>Billing</Divider>
                        <Col>
                            <Form.Item
                                name="invoiceNetDays"
                                label={
                                    <>
                                        Invoice Net Days&nbsp;
                                        <Tooltip title="How many days do your residents have to pay their invoices before they're considered past due?">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    </>
                                }
                                rules={[{required: true, message: 'Please enter the invoice net days'}]}
                            >
                                <InputNumber
                                    min={5}
                                    max={30}
                                    bordered={editingCommunity}
                                    disabled={!editingCommunity}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {editingCommunity &&
                        <Row justify="center">
                            <Text style={{color: Colors.monoMedLightGrey}}>Note: Changing this value will take effect in the next billing cycle.</Text>
                        </Row>
                    }
                    <Row gutter={16} justify="center">
                        <Divider>Posts</Divider>
                        <Col>
                            <Form.Item
                                name="maskPostCreatorByDefault"
                                label={
                                    <>
                                        Mask creator by default&nbsp;
                                        <Tooltip title="Posts and post replies from a Community Manager will mask the creator by default. You can always override this on a per-post basis.">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    </>
                                }
                                valuePropName="checked"
                            >
                                <Switch disabled={!editingCommunity} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>

    )
}

export default Settings;