import React, { useState, useEffect } from "react";
import '../assets/styles/css/antd.css';
import { useHistory } from "react-router-dom";
import {Row, Col, Form, Input, Button, Typography, notification} from 'antd';
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import backgroundImage from "../assets/images/register-bg.jpg";
import logoImage from "../assets/images/dwellPass-logo-red-800w.png";

const { Title } = Typography;

export const PasswordReset = (props) => {
    let history = useHistory();
    const query = new URLSearchParams(props.location.search);
    const [form] = Form.useForm();

    const [data, setData] = useState({isSubmitting: false, errorMessage: null});

    useEffect(() => {
        verifyLegitURL();
    }, [])

    const handleFormSubmit = values => {
        setData({
            isSubmitting: true,
            errorMessages: []
        });
        fetch(process.env.API_ROOT_URL+"/auth/password", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "uid": query.get("uid"),
                "client": query.get("client"),
                "access-token": query.get("access-token"),
            },
            body: JSON.stringify({
                password: values.password,
                password_confirmation: values.passwordConfirmation,
            })
        })
            .then(res => {
                if (res.ok) {
                    navToLogin();
                } else {
                    return res.json().then(res => {throw res});
                }
            })
            .catch(error => {
                setData({
                    isSubmitting: false,
                    errorMessages: error.errors.full_messages
                });
                notification.error({
                    message: "Error",
                    description: "There was a problem. Please try again.",
                    duration: 6
                });
            });
    };

    const getURLSegment = () => {
        return props.match.params.segment;
    }

    const verifyLegitURL = () => {
        (!query.get("uid") || !query.get("client") || !query.get("access-token")) && navToLogin();
    }

    const navToLogin = event => {
        history.push("/login/"+getURLSegment());
    };

    return (
        <Row style={{ height: "100vh", backgroundColor: Colors.monoLightGrey }} justify="center" align="middle">
            <Col style={{ width: 380, padding: 24, backgroundColor: Colors.monoBright, borderRadius: 15 }}>
                <Row justify="center">
                    <Col style={styles.logoContainer} span={24}></Col>
                </Row>
                <Row justify="center">
                    <Title level={4}>Reset your Password</Title>
                </Row>
                <Row justify="center">
                    <Col span={20}>
                        <Form
                            form={form}
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 24 }}
                            layout={"vertical"}
                            onFinish={handleFormSubmit}
                            requiredMark={false}
                        >

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your new password',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="passwordConfirmation"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your new password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The passwords you entered don\'t match');
                                        },
                                    })
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item>
                                <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={data.isSubmitting}>
                                    Reset Password
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const styles = {
    backgroundContainer: {
        background: `url(${backgroundImage}) no-repeat center`,
        backgroundSize: 'cover'
    },
    logoContainer: {
        height: '80px',
        marginBottom: '30px',
        background: `url(${logoImage}) no-repeat center`,
        backgroundSize: 'auto 80px'
    }
}

export default PasswordReset;
