import React from 'react';
import {Col, Empty, List, Row, Typography, Tooltip} from "antd";
import {EyeOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const UnitResources = (props) => {
    return (
        <List
            size="small"
            bordered
            dataSource={props.unitState.resources}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Resources Listed" /> }}
            renderItem={item =>
                <List.Item
                    actions={[
                        item.resourceFileUrl ?
                            <a href={item.resourceFileUrl} target="_blank"><EyeOutlined style={{ marginTop: 4, fontSize: 22 }} /></a>
                            :
                            <Tooltip title={item.description}>
                                <EyeOutlined style={{ marginTop: 4, fontSize: 22, color: Colors.brandPrimary }} />
                            </Tooltip>
                    ]}
                >
                    <Row gutter={10}>
                        <Col>
                            {item.residentViewable ? <UserOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/> : <LockOutlined style={{fontSize: 20, color: Colors.monoMedLightGrey}}/>}
                        </Col>
                        <Col>
                            <Text strong>{item.name ? item.name : item.resourceFileName}</Text> - {DateTimeFormatter.standardDateTime(item.createdAt)}
                        </Col>
                    </Row>
                </List.Item>
            }
        />
    );
}

export default UnitResources;