import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Divider, Statistic, Card, Skeleton } from "antd";
import {UserOutlined, HomeOutlined, ExceptionOutlined, MinusSquareOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {gql, useQuery} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import LatestUnreadPostsList from "./dashboard/LatestUnreadPostsList";
import LatestInvoicesTable from "./dashboard/LatestInvoicesTable";
import LatestTasksList from "./dashboard/LatestTasksList";

const Dashboard = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [communityStatsState, setCommunityStatsState] = useState({});

    const { data: communityStatsData, loading: loadingCommunityStats } = useQuery(gql(CommunityQueries.getCommunityStats), { notifyOnNetworkStatusChange: true, variables: { id: communityState.id } } );

    useEffect(() => {
        communityStatsData && communityStatsData.community && setCommunityStatsState(communityStatsData.community);
    }, [communityStatsData]);

    return(
        <Row justify="center" gutter={20}>
            <Col span={14}>
                <Row justify="center">
                    <Col>
                        <Card style={ styles.metricsCard }>
                            {loadingCommunityStats ?
                                <Skeleton paragraph={{ rows: 1 }} active />
                                :
                                <Statistic title="Current Residents" value={communityStatsState.currentResidentsCount} prefix={<UserOutlined />} valueStyle={{ color: Colors.brandPrimary }} />
                            }
                        </Card>
                    </Col>
                    <Col>
                        <Card style={ styles.metricsCard }>
                            {loadingCommunityStats ?
                                <Skeleton paragraph={{ rows: 1 }} active />
                                :
                                <Statistic title="Open Invoices" value={communityStatsState.openUnitResidentInvoicesCount} prefix={<ExceptionOutlined />} valueStyle={{ color: Colors.brandPrimary }} />
                            }
                        </Card>
                    </Col>
                    <Col>
                        <Card style={ styles.metricsCard }>
                            {loadingCommunityStats ?
                                <Skeleton paragraph={{ rows: 1 }} active />
                                :
                                <Statistic title="Vacant Units" value={communityStatsState.vacantUnitsCount} prefix={<MinusSquareOutlined />} valueStyle={{ color: Colors.brandPrimary }} />
                            }
                        </Card>
                    </Col>
                    <Col>
                        <Card style={ styles.metricsCard }>
                            {loadingCommunityStats ?
                                <Skeleton paragraph={{ rows: 1 }} active />
                                :
                                <Statistic title="Upcoming Closings" value={communityStatsState.unitsPendingCloseCount} prefix={<HomeOutlined />} valueStyle={{ color: Colors.brandPrimary }} />
                            }
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Divider>Latest Invoice Activity</Divider>
                    <LatestInvoicesTable />
                </Row>
            </Col>
            <Col span={10}>
                <Row style={{minHeight: 250, maxHeight: "50%", overflow: "scroll"}}>
                    <Divider>Latest Tasks</Divider>
                    <LatestTasksList />
                </Row>
                <Row>
                    <Divider>Latest Unread Posts</Divider>
                    <LatestUnreadPostsList />
                </Row>
            </Col>
        </Row>
    )
}

const styles = {
    metricsCard: {
        margin: 10,
        minWidth: 180
    }
};

export default Dashboard;