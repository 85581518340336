import React from 'react';
import {Col, Row, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CheckSquareOutlined, CreditCardOutlined, MessageOutlined} from "@ant-design/icons";

const { Title } = Typography;

const CommunityContent = (props) => {
    return (
        <Row style={{ height: '100%' }} justify="center" align="middle">
            <Col style={{ backgroundColor: Colors.monoMedGreySemiTransparent, padding: 24, borderRadius: 10 }} span={16}>
                <Title style={{ color: Colors.monoBright }}>Mission Control for Your Community</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><CreditCardOutlined style={{ marginRight: 6 }} /> Process Dues</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><MessageOutlined style={{ marginRight: 6 }} /> Communicate with Residents</Title>
                <Title style={{ color: Colors.monoBright }} level={4}><CheckSquareOutlined style={{ marginRight: 6 }} /> Track Initiatives</Title>
            </Col>
        </Row>
    );
}

export default CommunityContent;