import React, {useEffect, useState} from 'react';
import {Col, Empty, Row, Table, Tag, Typography} from "antd";
import {gql, useQuery} from "@apollo/client";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {LoadingOutlined, MinusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import {useHistory} from "react-router-dom";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";

const { Text } = Typography;

const LatestInvoicesTable = (props) => {
    const invoicePageSize = 10;
    const history = useHistory();

    const [invoicesState, setInvoicesState] = useState([]);

    const {data: invoicesData, loading: loadingInvoices} = useQuery(gql(InvoiceQueries.getInvoices), {notifyOnNetworkStatusChange: true, variables: {stripeCustomerId: props.currentUserUnitResident.stripeCustomerId, status: "open", first: invoicePageSize}});

    useEffect(() => {
        invoicesData && setInvoicesState(invoicesData.invoices);
    }, [invoicesData]);

    const navToPayments = (invoice) => {
        history.push({
            pathname: '/resident/payments'
        })
    }

    const invoiceStatusText = (invoice) => {
        switch (invoice.status) {
            case "open":
                return invoice.pastDue ?
                    <Text strong style={{ color: Colors.dangerRed }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                    :
                    <Text style={{ color: Colors.mainBlue }}>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
                break;
            default:
                return <Text>{StringFormatter.capitalizeFirstLetter(invoice.status)}</Text>
        }
    }

    const tableColumns = [
        {
            title: 'Status',
            width: 100,
            render: (text, record) => (
                invoiceStatusText(record.node)
            )
        },
        {
            title: 'Due',
            key: 'dueDate',
            render: (text, record) => (
                record.node.dueDate ?
                    <Row gutter={5}>
                        <Col>
                            <Text type={record.node.pastDue ? 'danger' : 'default'}>{DateTimeFormatter.simpleDateOnly(record.node.dueDate)}</Text>
                        </Col>
                        {record.node.pastDue &&
                        <Col>
                            <Tag color="red">PAST DUE</Tag>
                        </Col>
                        }
                    </Row>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Total Due',
            width: 100,
            align: "right",
            render: (text, record) => (
                <Text>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        }
    ];

    return (
        <Table
            style={{ minHeight: "26vh" }}
            columns={tableColumns}
            dataSource={invoicesState && invoicesState.edges}
            rowKey={record => record.node.id}
            scroll={{ y: "26vh" }}
            loading={{spinning: loadingInvoices, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Open Invoices"/>}}
            pagination={false}
            onRow={(record) => {
                return {
                    onClick: event => { navToPayments() }
                };
            }}
        />
    );
}

export default LatestInvoicesTable;