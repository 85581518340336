import React, { useState, useEffect, useContext } from "react";
import {Button, Col, Divider, Form, Input, notification, Row, Typography, Switch, Select} from "antd";
import {gql, useMutation} from "@apollo/client";
import ResourceQueries from "@dwellpass-client/common/utils/constants/queries/ResourceQueries";
import {FileOutlined, InfoCircleOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const { Title } = Typography;
const { TextArea } = Input;

const ResourceForm = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [showDisplayOnTimeline, setShowDisplayOnTimeline] = useState(false);
    const [form] = Form.useForm();

    const [createResource, {loading: loadingResourceCreation}] = useMutation(gql(ResourceQueries.createResource));
    const [updateResource, {loading: loadingResourceUpdate}] = useMutation(gql(ResourceQueries.updateResource));

    useEffect(() => {
        form.setFieldsValue({
            name: props.selectedResourceState.name,
            description: props.selectedResourceState.description,
            residentViewable: props.selectedResourceState.residentViewable,
            resourceableId: props.selectedResourceState.resourceable ? props.selectedResourceState.resourceable.id : null,
            displayOnTimeline: props.selectedResourceState.displayOnTimeline,
        });
        props.selectedResourceState.resourceable ? setShowDisplayOnTimeline(true) : setShowDisplayOnTimeline(false);
    }, [props.selectedResourceState]);

    const resetForm = () => {
        setTimeout(() => {
            form.resetFields();
        }, 500);
    };

    const unitSelectionChanged = () => {
        setShowDisplayOnTimeline(true);
    }

    const unitSelectionCleared = () => {
        setShowDisplayOnTimeline(false);
        form.setFieldsValue({displayOnTimeline: false});
    }

    const handleFormSubmit = async (values) => {
        try {
            let resourceable = values.resourceableId ? {type: "Unit", id: values.resourceableId} : {
                type: null,
                id: null
            };

            props.selectedResourceState.id ?
                await updateResource({
                    variables: {
                        id: props.selectedResourceState.id,
                        name: values.name,
                        residentViewable: values.residentViewable,
                        description: values.description,
                        resourceableType: resourceable.type,
                        resourceableId: resourceable.id,
                        displayOnTimeline: values.displayOnTimeline
                    }
                })
            :
                await createResource({
                    variables: {
                        communityId: communityState.id,
                        name: values.name,
                        residentViewable: values.residentViewable,
                        description: values.description,
                        resourceableType: resourceable.type,
                        resourceableId: resourceable.id,
                        displayOnTimeline: values.displayOnTimeline
                    }
                });

            resetForm();
            props.formSuccess();
        } catch (error) {
            notification['error']({
                message: 'Resource Error',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    }

    return (
        <>
            {props.selectedResourceState.resourceFileName &&
            <>
                <Col span={22}>
                    <Row wrap={false}>
                        <Col>
                            <FileOutlined style={{marginRight: 10, fontSize: 30, color: Colors.brandPrimary}}/>
                        </Col>
                        <Col>
                            <Title
                                level={3}>{props.selectedResourceState.id ? props.selectedResourceState.resourceFileName : null}</Title>
                        </Col>
                    </Row>
                </Col>
                <Divider></Divider>
            </>
            }
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: props.selectedResourceState.resourceFileName ? false : true,
                                    message: 'Please input a name',
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label={props.selectedResourceState.resourceFileName ? "Description" : "Information"}
                            rules={[
                                {
                                    required: props.selectedResourceState.resourceFileName ? false : true,
                                    message: 'Please input the resource information',
                                }
                            ]}
                        >
                            <TextArea showCount maxLength={250}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="residentViewable"
                            label="Visible to Residents"
                            tooltip={{
                                title: "Should this resource be visible to Residents?",
                                icon: <InfoCircleOutlined/>
                            }}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="resourceableId"
                            label="Unit"
                            tooltip={{title: "Assign this resource to a Unit", icon: <InfoCircleOutlined/>}}
                        >
                            <Select
                                style={{width: 200}}
                                placeholder="Select a Unit"
                                allowClear
                                showSearch
                                onSelect={unitSelectionChanged}
                                onClear={unitSelectionCleared}
                                loading={props.loadingUnits}
                                options={props.unitsOptions}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showDisplayOnTimeline &&
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="displayOnTimeline"
                            label="Display on Unit Timeline"
                            tooltip={{
                                title: "Should this resource display on the Unit timeline?",
                                icon: <InfoCircleOutlined/>
                            }}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                </Row>
                }
                <Divider/>
                <Row>
                    <Col>
                        <Form.Item>
                            <Button style={{width: 100}} type="primary" htmlType="submit"
                                    loading={(loadingResourceUpdate || loadingResourceCreation)}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default ResourceForm;