import React, {useEffect, useState} from "react";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {Button, Empty, Row, Col, Table, Typography, Radio, Select, Alert, Tag, Tooltip, Modal, notification} from "antd";
import {ClockCircleOutlined, ExclamationCircleOutlined, FileTextOutlined, LoadingOutlined, MinusOutlined} from "@ant-design/icons";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import InvoiceChargeDetail from "./InvoiceChargeDetail";
import LoadingContent from "../../../utility/LoadingContent";
import MarkInvoicePaidModal from "./MarkInvoicePaidModal";

const { Text, Title } = Typography;

const UnitInvoicesTable = (props) => {
    const invoicePageSize = 10;

    const [invoicesState, setInvoicesState] = useState([]);
    const [totalInvoiceCount, setTotalInvoiceCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [upcomingInvoiceState, setUpcomingInvoiceState] = useState({});
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState("open");
    const {data: unitSubscriptionsData, loading: loadingUnitSubscriptions} = useQuery(gql(UnitQueries.getUnitSubscriptions), {notifyOnNetworkStatusChange: true, variables: {id: props.unitState.id}});
    const {data: unitCurrentResidentsData, loading: loadingUnitCurrentResidents} = useQuery(gql(UnitQueries.getUnitCurrentUnitResidents), {notifyOnNetworkStatusChange: true, variables: {id: props.unitState.id}});
    const [subscriptionsOptions, setSubscriptionsOptions] = useState([]);
    const [currentResidentsOptions, setCurrentResidentsOptions] = useState([]);
    const [subscriptionFilterState, setSubscriptionFilterState] = useState(null);
    const [currentResidentFilterState, setCurrentResidentFilterState] = useState(null);
    const [getInvoices, {data: invoicesData, loading: loadingInvoices}] = useLazyQuery(gql(InvoiceQueries.getInvoices), { notifyOnNetworkStatusChange: true, fetchPolicy: "network-only" });
    const [getStripeUpcomingInvoice, {data: stripeUpcomingInvoiceData}] = useLazyQuery(gql(InvoiceQueries.getStripeUpcomingInvoice));
    const [loadStripeInvoiceData, ] = useLazyQuery(gql(InvoiceQueries.getStripeInvoice), { notifyOnNetworkStatusChange: true } );
    const [showMarkInvoicePaidModal, setShowMarkInvoicePaidModal] = useState(false);
    const [isLoadingInvoiceModalOpen, setIsLoadingInvoiceModalOpen] = useState(false);
    const [selectedInvoiceState, setSelectedInvoiceState] = useState({});
    const [refreshInvoicesTable, setRefreshInvoicesTable] = useState(0);
    const [currentTablePage, setCurrentTablePage] = useState(1);

    useEffect(() => {
        invoicesData && setInvoicesState(invoicesData.invoices);
        invoicesData && setTotalInvoiceCount(invoicesData.invoices.totalCount);
    }, [invoicesData]);

    useEffect(() => {
        stripeUpcomingInvoiceData && setUpcomingInvoiceState(stripeUpcomingInvoiceData.stripeUpcomingInvoice);
    }, [stripeUpcomingInvoiceData]);

    useEffect(() => {
        var options = [];
        const subscriptions = (unitSubscriptionsData && unitSubscriptionsData.unit.subscriptions) ?
            [...unitSubscriptionsData.unit.subscriptions].sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
            :
            [];

        subscriptions.length >= 1 &&
            subscriptions.map(sub => {
                sub.stripeSubscriptionId && (
                    options.push({ label: subscriptionFilterOptionLabel(sub), value: sub.stripeSubscriptionId })
                )
            });

        options.length >= 1 && setSubscriptionsOptions(options);
    }, [unitSubscriptionsData]);

    useEffect(() => {
        var options = [];
        unitCurrentResidentsData &&
        unitCurrentResidentsData.unit.currentUnitResidents.map(ur => {
            ur.stripeCustomerId && (
                options.push({ label: `${ur.resident.firstName} ${ur.resident.lastName}`, value: ur.stripeCustomerId })
            )
        });
        setCurrentResidentsOptions(options);
    }, [unitCurrentResidentsData]);

    useEffect(() => {
        setInvoicesState([]);
        setUpcomingInvoiceState({});
        onFirstPage() ? performGetStripeInvoices() : clearPageOffset();
    }, [invoiceStatusFilter, subscriptionFilterState, currentResidentFilterState]);

    useEffect(() => {
        performGetStripeInvoices();
    }, [pageOffsetState, refreshInvoicesTable]);

    const clearPageOffset = () => {
        changeInvoiceTablePage(1, invoicePageSize);
    }

    const onFirstPage = () => {
        return currentTablePage === 1
    }

    const performGetStripeInvoices = () => {
        if((currentResidentFilterState || subscriptionFilterState)) {
            getInvoices({notifyOnNetworkStatusChange: true, variables: {stripeCustomerId: currentResidentFilterState, status: invoiceStatusFilter, stripeSubscriptionId: subscriptionFilterState, first: invoicePageSize, after: pageOffsetState}});
            invoiceStatusFilter === "open" && getStripeUpcomingInvoice({notifyOnNetworkStatusChange: true, variables: {customer: currentResidentFilterState, subscription: subscriptionFilterState}});
        } else {
            props.unitState && props.unitState.id && getInvoices({notifyOnNetworkStatusChange: true, variables: {unitId: props.unitState.id, status: invoiceStatusFilter, first: invoicePageSize, after: pageOffsetState}});
        }
    }

    const subscriptionFilterOptionLabel = (subscription) => {
        return subscription.canceledAt ?
            `${DateTimeFormatter.simpleDateOnly(subscription.createdAt)} - ${DateTimeFormatter.simpleDateOnly(subscription.canceledAt)}`
            :
            `${DateTimeFormatter.simpleDateOnly(subscription.createdAt)} - Present`;
    }

    const filterBySubscription = (stripeSubscriptionId) => {
        clearFilteredCurrentResident();
        setSubscriptionFilterState(stripeSubscriptionId);
    }

    const clearFilteredSubscription = () => {
        setSubscriptionFilterState(null);
    }

    const filterByCurrentResident = (stripeCustomerId) => {
        clearFilteredSubscription();
        setCurrentResidentFilterState(stripeCustomerId);
    }

    const clearFilteredCurrentResident = () => {
        setCurrentResidentFilterState(null);
    }

    const promptMarkInvoicePaid = (invoice) => {
        setSelectedInvoiceState(invoice);
        setShowMarkInvoicePaidModal(true);
    }

    const hideMarkInvoicePaidModal = () => {
        setShowMarkInvoicePaidModal(false);
        setTimeout(() => {
            setSelectedInvoiceState({});
        }, 500);
    }

    const openStripeHostedInvoiceUrl = async (stripeInvoiceId) => {
        setIsLoadingInvoiceModalOpen(true);

        let stripeInvoiceData = await loadStripeInvoiceData({variables: { stripeInvoiceId: stripeInvoiceId }})
        setTimeout(() => {
            setIsLoadingInvoiceModalOpen(false);
            stripeInvoiceData?.data?.stripeInvoice?.hostedInvoiceUrl ?
                window.open(stripeInvoiceData.data.stripeInvoice.hostedInvoiceUrl, "_blank")
                :
                notification["error"]({
                    message: "Error Retrieving Invoice",
                    description:
                        "Something went wrong. Please try again.",
                });
        }, 1000);
    }

    const performInvoiceTableRefresh = () => {
        setRefreshInvoicesTable(prev => prev + 1);
    }

    const refreshInvoicesWithUnitState = () => {
        performInvoiceTableRefresh();
        props.refreshUnitState();
    }

    const changeInvoiceTablePage = (page, pageSize) => {
        setCurrentTablePage(page);
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const invoicesOpenTableColumns = [
        {
            title: 'Invoice Type',
            key: 'recurring',
            render: (text, record) => (
                record.node.recurring ?
                    "Recurring Charges"
                    :
                    "One-time Invoice"
            ),
        },
        {
            title: 'Resident',
            key: 'resident',
            render: (text, record) => (
                `${record.node.unitResident.resident.firstName} ${record.node.unitResident.resident.lastName}`
            ),
        },
        {
            title: 'Due',
            key: 'dueDate',
            render: (text, record) => (
                record.node.dueDate ?
                    <Row gutter={5}>
                        <Col>
                            <Text type={record.node.pastDue ? 'danger' : 'default'}>{DateTimeFormatter.simpleDateOnly(record.node.dueDate)}</Text>
                        </Col>
                        {record.node.pastDue &&
                            <Col>
                                <Tag color="red">PAST DUE</Tag>
                            </Col>
                        }
                    </Row>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Method',
            key: 'collectionMethod',
            render: (text, record) => (
                record.node.collectionMethod === 'send_invoice' ?
                    <Text type="danger">Manual Pay</Text>
                    :
                    <Text type="success">Autopay</Text>
            ),
        },
        {
            title: 'Total',
            key: 'totalCents',
            render: (text, record) => (
                <Text strong>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        },
        {
            title: 'View',
            key: 'view',
            render: (text, record) => (
                record.node.stripeInvoiceId ?
                    <Button type="link" onClick={(e) => {
                        openStripeHostedInvoiceUrl(record.node.stripeInvoiceId)
                    }}><FileTextOutlined style={{fontSize: 13}}/>View</Button>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: '',
            align: 'right',
            render: (text, record) => (
                record.node.pendingCharges ?
                    <Tooltip title="Payment pending">
                        <ClockCircleOutlined
                            style={{
                                marginTop: 6,
                                fontSize: 20,
                                color: Colors.monoMedLightGrey
                            }}/>
                    </Tooltip>
                    :
                    !!record.node.charges[0] &&
                        <Tooltip title="Payment failure occurred">
                            <ExclamationCircleOutlined
                                style={{
                                    marginTop: 6,
                                    fontSize: 20,
                                    color: Colors.dangerRed
                                }}/>
                        </Tooltip>
            )
        },
        {
            title: '',
            key: 'markPaidAction',
            align: 'right',
            render: (text, record) => (
                record.node.paid ?
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
                    :
                    <Button type="primary" style={{
                        backgroundColor: !record.node.pendingCharges && Colors.brandPrimary,
                        fontWeight: "bold"
                    }} onClick={(e) => {
                        e.stopPropagation();
                        promptMarkInvoicePaid(record.node);
                    }} disabled={record.node.pendingCharges}>Mark Paid</Button>

            ),
        }
    ];

    const invoicesPaidTableColumns = [
        {
            title: 'Invoice Type',
            key: 'recurring',
            render: (text, record) => (
                record.node.recurring ?
                    "Recurring Charges"
                    :
                    "One-time Invoice"
            ),
        },
        {
            title: 'Resident',
            key: 'resident',
            render: (text, record) => (
                `${record.node.unitResident.resident.firstName} ${record.node.unitResident.resident.lastName}`
            ),
        },
        {
            title: 'Paid',
            key: 'paidAt',
            render: (text, record) => (
                record.node.paidAt ?
                    DateTimeFormatter.simpleDateOnly(record.node.paidAt)
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        },
        {
            title: 'Method',
            key: 'collectionMethod',
            render: (text, record) => (
                record.node.collectionMethod === 'send_invoice' ?
                    <Text type="danger">Manual Pay</Text>
                    :
                    <Text type="success">Autopay</Text>
            ),
        },
        {
            title: 'Total',
            key: 'totalCents',
            render: (text, record) => (
                <Text strong>{MoneyFormatter.standardMoneyUSD(record.node.totalCents)}</Text>
            )
        },
        {
            title: 'View',
            key: 'view',
            render: (text, record) => (
                record.node.stripeInvoiceId ?
                    <Button type="link" onClick={(e) => {
                        openStripeHostedInvoiceUrl(record.node.stripeInvoiceId)
                    }}><FileTextOutlined style={{fontSize: 13}}/>View</Button>
                    :
                    <MinusOutlined style={{fontSize: 20, color: Colors.monoLightGrey}}/>
            ),
        }
    ];

    const changeInvoiceStatusFilter = (toStatus) => {
        setInvoiceStatusFilter(toStatus);
    }

    const invoiceChargesPresent = () => {
        return invoicesState ? invoicesState.edges ? invoicesState.edges.find(i => i.node.charges.length > 0) : false : false;
    }

    const loadingInvoiceModal = () => {
        return <Modal style={{ padding: 100 }} visible={isLoadingInvoiceModalOpen} closable={false} maskClosable={false} footer={null}>
            <Row justify="center" style={{ marginBottom: 5 }}>
                <Title level={3}>Retrieving Invoice</Title>
            </Row>
            <Row justify="center">
                <LoadingOutlined style={{ fontSize: 35, color: Colors.brandPrimary }} />
            </Row>
        </Modal>;
    }

    return (
        <>
            <Row gutter={16} style={{marginBottom: 10}} justify="space-between">
                <Col>
                    <Radio.Group onChange={(e) => changeInvoiceStatusFilter(e.target.value)} defaultValue="open"
                                 buttonStyle="solid">
                        <Radio.Button value="open">Open</Radio.Button>
                        <Radio.Button value="paid">Paid</Radio.Button>
                    </Radio.Group>
                </Col>
                <Col>
                    <Row align="middle" gutter={10}>
                        <Col>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Filter by Resident"
                                onSelect={(value) => filterByCurrentResident(value)}
                                onClear={clearFilteredCurrentResident}
                                value={currentResidentFilterState}
                                loading={loadingUnitCurrentResidents}
                                options={currentResidentsOptions}
                                allowClear
                            />
                        </Col>
                        <Col>
                            <Select
                                style={{ width: 220 }}
                                placeholder="Filter by Cycle"
                                onSelect={(value) => filterBySubscription(value)}
                                onClear={clearFilteredSubscription}
                                value={subscriptionFilterState}
                                loading={loadingUnitSubscriptions}
                                options={subscriptionsOptions}
                                allowClear
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {upcomingInvoiceState.status &&
                <Alert message={`An upcoming invoice is scheduled to be sent on ${DateTimeFormatter.simpleDateOnly(upcomingInvoiceState.createdAt)} in the amount of ${MoneyFormatter.standardMoneyUSD(upcomingInvoiceState.total)}`} type="info" showIcon />
            }
            <Row justify="center">
                <Table
                    style={{width: "100%"}}
                    dataSource={invoicesState ? invoicesState.edges : null}
                    columns={invoiceStatusFilter === "open" ? invoicesOpenTableColumns : invoicesPaidTableColumns}
                    locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Invoices" />}}
                    loading={{spinning: loadingInvoices, indicator: <LoadingContent />}}
                    rowKey={record => record.node.id}
                    pagination={{
                        current: currentTablePage,
                        pageSize: invoicePageSize,
                        position: ["none", "bottomCenter"],
                        total: totalInvoiceCount,
                        onChange: (page, pageSize) => { changeInvoiceTablePage(page, pageSize) }
                    }}
                    expandable={
                        invoiceChargesPresent() ? {
                            expandIconColumnIndex: 8,
                            rowExpandable: record => !!record.node.charges[0],
                            expandedRowRender: record => <InvoiceChargeDetail invoiceId={record.node.id} />
                        }
                        : undefined
                    }
                />
            </Row>
            <MarkInvoicePaidModal visible={showMarkInvoicePaidModal} hideMe={hideMarkInvoicePaidModal} invoice={selectedInvoiceState} onSuccess={refreshInvoicesWithUnitState}/>
            {loadingInvoiceModal()}
        </>
    )
}

export default UnitInvoicesTable;