import React, { useState } from "react";
import {Row, Col, Modal, Typography, List, Space, Button, Result} from "antd";
import {gql, useMutation} from "@apollo/client";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import {FileTextOutlined, CalendarOutlined, DollarCircleOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const MarkInvoicePaidModal = (props) => {
    const [paymentErrors, setPaymentErrors] = useState([]);
    const [markInvoicePaid, { loading: loadingMarkInvoicePaid }] = useMutation(gql(InvoiceQueries.markInvoicePaid));
    const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

    const submitPayment = async () => {
        try {
            setPaymentErrors([]);

            await markInvoicePaid({variables: {stripeInvoiceId: props.invoice.stripeInvoiceId}});

            setShowPaymentSuccess(true);
        }
        catch (error) {
            console.log(error);
            setPaymentErrors([{message: "The payment failed to process. Please try again."}]);
        }
    }

    const resetAndHideMe = () => {
        props.hideMe();
        setTimeout(() => {
            setPaymentErrors([]);
            setShowPaymentSuccess(false);
        }, 500);
    }

    const hideSuccessfulResult = () => {
        resetAndHideMe();
        props.onSuccess();
    }

    const paymentData = [
        {icon: <FileTextOutlined />, title: "Invoice Type", data: props.invoice && props.invoice.recurring ? "Recurring Charges" : "One-time Invoice"},
        {icon: <CalendarOutlined />, title: "Due on", data: props.invoice && DateTimeFormatter.simpleDateOnly(props.invoice.dueDate)},
        {icon: <DollarCircleOutlined />, title: "Invoice Total", data: props.invoice && MoneyFormatter.standardMoneyUSD(props.invoice.totalCents)}
    ]

    const modalFooter = () => {
        return showPaymentSuccess ?
            false
            :
            [
                <Button key="back" onClick={resetAndHideMe}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loadingMarkInvoicePaid} onClick={submitPayment}>
                    Confirm
                </Button>
            ]
    }

    return(
        <Modal
            title="Mark Invoice Paid"
            visible={props.visible}
            footer={modalFooter()}
        >
            {!showPaymentSuccess ?
                <Space direction="vertical">
                    {paymentErrors && (
                        paymentErrors.map(error =>
                            <Text key={error.message} type="danger">{error.message}<br/></Text>
                        )
                    )}
                    <List
                        size="small"
                        bordered
                        dataSource={paymentData}
                        renderItem={item =>
                            <List.Item>
                                <Row style={{width: "100%"}} justify="space-between" align="middle">
                                    <Col>
                                        <Text style={{color: Colors.brandPrimary}} strong>{item.icon} {item.title}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Text strong>{item.data}</Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        }
                    />
                    <Row style={{width: "100%", padding: "5px 17px"}} justify="space-between" align="middle">
                        <Col>
                            <Text strong>Payment Processing Fee</Text>
                        </Col>
                        <Col>
                            <Text strong>{props.invoice && MoneyFormatter.standardMoneyUSD(props.invoice?.estimatedOutOfBandServiceFeeAmountCents)}</Text>
                        </Col>
                    </Row>
                    <Text>This will mark the invoice paid without collecting payment from the resident. You will be
                        responsible for collecting payment manually for this invoice, and the processing fee associated will be charged to your Community's connected account.</Text>
                </Space>
                :
                <Result
                    status="success"
                    title="Invoice Marked as Paid"
                    subTitle="Note: It may take a few minutes for the payment to be displayed within our systems."
                    extra={[
                        <Button type="primary" onClick={hideSuccessfulResult}>
                            Dismiss
                        </Button>
                    ]}
                />
            }
        </Modal>
    )
}

export default MarkInvoicePaidModal;