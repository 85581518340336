import React, { useContext, useEffect, useState } from 'react';
import {Button, Card, Row, Col, notification, Typography, Popconfirm} from "antd";
import {CalendarOutlined, CheckCircleFilled, CloseCircleFilled, LoadingOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import SubscriptionQueries from "@dwellpass-client/common/utils/constants/queries/SubscriptionQueries";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import FeeActivationModal from "./FeeActivationModal";

const { Text } = Typography;

const SubscriptionStatusCard = (props) => {
    const [unitState, updateUnitState] = useContext(UnitContext);
    const [subscription, setSubscription] = useState();
    const [showFeeActivationModal, setShowFeeActivationModal] = useState(false);

    const [updateSubscriptions, {loading: loadingUpdateAutopay}] = useMutation(gql(SubscriptionQueries.updateSubscriptions));
    const [loadUnit, { loading: loadingUnit }] = useLazyQuery(gql(UnitQueries.getUnitWithCommunity), {fetchPolicy: "network-only", onCompleted: (unitData) => {updateUnitState({type: "set", payload: unitData.unit})}});

    useEffect(() => {
        setSubscription(subscriptionReader(props.type));
    }, [unitState]);

    const hideFeeActivationModal = () => {
        setShowFeeActivationModal(false);
    }

    const subscriptionFees = () => {
        return unitState.fees.filter(fee => fee.recurring === props.type);
    }

    const cardTitle = (type) => {
        switch (type) {
            case "monthly":
                return (<Text><CalendarOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}}/>Monthly
                    Billing</Text>);
            case "quarterly":
                return (<Text><CalendarOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}}/>Quarterly
                    Billing</Text>);
            default:
                return "Billing";
        }
    }

    const subscriptionReader = (type) => {
        switch (type) {
            case "monthly":
                return unitState.activeMonthlySubscriptions[0];
            case "quarterly":
                return unitState.activeQuarterlySubscriptions[0];
            default:
                return {};
        }
    }

    const toggleSubscriptionAutopay = async () => {
        await updateSubscriptions({variables: {interval: props.type, unitResidentId: props.currentUserUnitResident.id, autopay: !subscription.autopayAt}});

        await loadUnit({variables: {id: unitState.id}});

        notification['success']({
            message: 'Autopay Change Successful',
            description: 'You\'ll see this change reflected on your next recurring invoice.',
            duration: 15
        })
    }

    const feeActivationSuccess = () => {
        props.refreshInvoicesTable();
        hideFeeActivationModal();
    }

    return (
        <Col>
            <Card title={cardTitle(props.type)}
                  extra={subscription && <Button type="link" style={{ paddingRight: 0 }} onClick={() => setShowFeeActivationModal(true)}>View Fees</Button>}
                  style={{width: 300, minHeight: 150}}>
                {subscription ?
                    <Row justify="space-between" align="middle">
                        {(loadingUpdateAutopay || loadingUnit) ?
                            <Col span={24}>
                                <Row justify="center">
                                    <Col>
                                        <LoadingOutlined style={{fontSize: 30, color: Colors.brandPrimary}}/>
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <>
                                <Col>
                                    <Text>Autopay: </Text>
                                    {subscription.autopayAt ?
                                        <Text type="success">Enabled <CheckCircleFilled/></Text>
                                        :
                                        <Text type="danger">Disabled <CloseCircleFilled/></Text>}
                                </Col>
                                <Col>
                                    {subscription.autopayAt ?
                                        <Popconfirm title="Are you sure?" okText="Yes" cancelText="No"
                                                    onConfirm={() => toggleSubscriptionAutopay()}>
                                            <Button type="primary">Disable</Button>
                                        </Popconfirm>
                                        :
                                        <Button type="primary"
                                                onClick={() => toggleSubscriptionAutopay()}>Enable</Button>
                                    }
                                </Col>
                            </>
                        }
                    </Row>
                    :
                    loadingUnit ?
                        <Row justify="center">
                            <Col>
                                <LoadingOutlined style={{fontSize: 30, color: Colors.brandPrimary}}/>
                            </Col>
                        </Row>
                        :
                        <Button style={{width: "100%"}} type="primary" onClick={() => setShowFeeActivationModal(true)}>Setup Billing</Button>
                }
            </Card>
            <FeeActivationModal
                visible={showFeeActivationModal}
                hideMe={hideFeeActivationModal}
                currentUserUnitResident={props.currentUserUnitResident}
                fees={subscriptionFees()}
                type={props.type}
                defaultPaymentMethod={props.defaultPaymentMethod}
                derivedPaymentMethodState={props.derivedPaymentMethodState}
                subscription={subscription}
                success={feeActivationSuccess}
            />
        </Col>
    );
}

export default SubscriptionStatusCard;