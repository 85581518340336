import React from 'react';
import {Row, Col, List, Typography, Divider, Tooltip} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    PauseCircleOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";

const { Text } = Typography;

const InvoiceChargeDetail = (props) => {
    const refundStatusIcon = (status) => {
        switch (status) {
            case "pending":
                return <PauseCircleOutlined style={{fontSize: 13}}/>
                break;
            case "succeeded":
                return <CheckCircleOutlined style={{fontSize: 13}}/>
                break;
            case "failed":
                return <CloseCircleOutlined style={{fontSize: 13}}/>
                break;
            default:
                return;
        }
    }

    return (
        !!props.charges[0] ?
            <>
                {props.charges.map(charge => (
                    <Row style={{backgroundColor: Colors.monoBright}} key={charge.id}>
                        <Row
                            style={{
                                width: "100%",
                                padding: 16,
                                border: `1px solid ${Colors.monoLightGrey}`
                            }}
                            justify="space-between"
                            align="middle"
                        >
                            <Col>
                                <Row gutter={5} align="middle">
                                    <Col>
                                        <Text style={{color: Colors.brandPrimary}} strong>Payment
                                            ({DateTimeFormatter.simpleDateOnly(charge.stripeCreated)})</Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row gutter={5} align="middle">
                                    {charge.serviceFeeAmountCents > 0 &&
                                        <>
                                            <Col>
                                                <Text
                                                    strong>Payment Processing Fee: {MoneyFormatter.standardMoneyUSD(charge.serviceFeeAmountCents)}</Text>
                                            </Col>
                                            <Divider type="vertical"></Divider>
                                        </>
                                    }
                                    {charge.amountRefundedCents > 0 &&
                                    <>
                                        <Col>
                                            <Text
                                                strong>Refunded: {MoneyFormatter.standardMoneyUSD(charge.amountRefundedCents)}</Text>
                                        </Col>
                                        <Divider type="vertical"></Divider>
                                    </>
                                    }
                                    <Col>
                                        <Text
                                            strong>Total: {MoneyFormatter.standardMoneyUSD(charge.amountCents)}</Text>
                                    </Col>
                                    <Divider type="vertical"></Divider>
                                    <Col>
                                        <Text
                                            strong>Status: {StringFormatter.capitalizeFirstLetter(charge.status)}</Text>
                                    </Col>
                                    {charge.status === "failed" &&
                                        <Tooltip title={charge.failureMessage}>
                                            <InfoCircleOutlined
                                                style={{
                                                    marginLeft: 8,
                                                    fontSize: 18,
                                                    color: Colors.monoMedLightGrey
                                                }}/>
                                        </Tooltip>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        {!!charge.refunds[0] &&
                        <Row style={{width: "100%"}}>
                            <List
                                bordered
                                style={{width: "100%", backgroundColor: Colors.monoBright}}
                                dataSource={charge.refunds}
                                locale={{emptyText: "No Refunds"}}
                                renderItem={item => (
                                    <List.Item>
                                        <Row style={{width: "100%"}} justify="space-between" align="middle">
                                            <Col>
                                                <Text style={{color: Colors.brandPrimary}} strong>Refund ({DateTimeFormatter.simpleDateOnly(item.stripeCreated)})</Text>
                                            </Col>
                                            <Col>
                                                <Row gutter={5} align="middle">
                                                    <Col>
                                                        <Text strong>Amount: {MoneyFormatter.standardMoneyUSD(item.amountCents)}</Text>
                                                    </Col>
                                                    <Divider type="vertical"></Divider>
                                                    <Col>
                                                        <Text style={{color: Colors.brandPrimary}}
                                                              strong>Status: {StringFormatter.capitalizeFirstLetter(item.status)} {refundStatusIcon(item.status)}</Text>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Row>
                        }
                    </Row>
                ))}
            </>
            :
            <Row style={{
                padding: 24,
                border: `1px solid ${Colors.monoLightGrey}`,
                borderRadius: 2,
                backgroundColor: Colors.monoBright
            }} justify="center">
                <Col>
                    <Text style={{color: Colors.brandPrimary}} strong>Payment Manually Collected by
                        Community</Text>
                </Col>
            </Row>
    )
}

export default InvoiceChargeDetail;