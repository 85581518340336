import React from "react";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {Col, Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const LoadingContent = () => {
    return(
        <Row style={{ height: "100%", backgroundColor: Colors.monoBright }} justify="center" align="middle">
            <Col span={24}>
                <Row justify="center">
                    <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40 }} />
                </Row>
            </Col>
        </Row>
    )
}

export default LoadingContent;