import React from 'react';
import {Modal, notification} from "antd";
import {gql, useMutation} from "@apollo/client";
import PayoutAccountQueries from "@dwellpass-client/common/utils/constants/queries/PayoutAccountQueries";

const DeletePayoutAccountModal = (props) => {
    const [destroyPayoutAccount, { loading: loadingDestroyPayoutAccount }] = useMutation(gql(PayoutAccountQueries.destroyPayoutAccount));

    const successDescription = () => {
        return `Successfully deleted account: ${props.selectedPayoutAccountState.name}.`;
    }

    const handlePayoutAccountDeletion = async () => {
        await destroyPayoutAccount({ variables: { id: props.selectedPayoutAccountState.id } });
        popPayoutAccountDeletionSuccessNotification();
        props.deletionSuccess();
    }

    const popPayoutAccountDeletionSuccessNotification = () => {
        notification["success"]({
            message: "Account Deleted",
            duration: 10,
            description: successDescription(),
        });
    };

    return (
        <Modal
            title="Are You Sure?"
            visible={props.visible}
            onOk={() => handlePayoutAccountDeletion()}
            confirmLoading={loadingDestroyPayoutAccount}
            onCancel={() => props.hideMe()}
            okText="Delete"
            cancelText="Cancel"
        >
            <p>Are you sure you want to delete this account?</p>
        </Modal>
    );
}

export default DeletePayoutAccountModal;