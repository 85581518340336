import React, {useContext} from 'react';
import {Card, Col, Drawer, Row, Typography} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {useHistory} from "react-router-dom";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";

const { Text } = Typography;

const UserUnitSelectionDrawer = (props) => {
    let history = useHistory();

    const [userState, ] = useContext(UserContext);

    const switchToSelectedUnit = (unit) => {
        history.replace({pathname: "/boot", state: { request: "unit", unit: unit }})
    }

    return (
        <Drawer
            title="Your Units"
            placement="right"
            closable={true}
            onClose={props.onUnitDrawerClose}
            visible={props.unitDrawerOpen}
            width={350}
        >
            { [...userState.currentUnits].sort(function(a, b) {return a.internalIdentifier.toLowerCase().localeCompare(b.internalIdentifier.toLowerCase());}).map((unit, i) => {
                return(
                    <Card
                        hoverable
                        style={{ marginTop: 16, borderColor: Colors.brandPrimary }}
                        key={i}
                        onClick={() => switchToSelectedUnit(unit)}
                    >
                        <Row>
                            <Col>
                                <Text strong>
                                    {unit.address && unit.address.street1}
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                )
            })}
        </Drawer>
    );
}

export default UserUnitSelectionDrawer;