import React, { useContext, useEffect, useState } from "react";
import {Button, Col, Empty, Row, Switch, Table, Input, Typography, Avatar, Tag} from "antd";
import {FormOutlined, LoadingOutlined, ReloadOutlined, UserOutlined, MinusOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {gql, useQuery} from "@apollo/client";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";

const { Search } = Input;
const { Text } = Typography;

const MainTasksTable = (props) => {
    const taskPageSize = 30;

    const [communityState, ] = useContext(CommunityContext);
    const [userState, ] = useContext(UserContext);
    const [tasksState, setTasksState] = useState([]);
    const [totalTaskCount, setTotalTaskCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const [myTasksOnlyState, setMyTasksOnlyState] = useState(false);
    const [inactiveOnlyState, setInactiveOnlyState] = useState(false);
    const [searchTextState, setSearchTextState] = useState("");

    const { data: taskData, refetch: refetchTasks, loading: loadingTasks } = useQuery(gql(TaskQueries.getTasks), { notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id, searchTerm: searchTextState, assigneeId: myTasksOnlyState ? userState.id : null, active: !inactiveOnlyState, inactive: inactiveOnlyState, first: taskPageSize, after: pageOffsetState } } );

    useEffect(() => {
        if(taskData) {
            setTasksState(taskData.tasks);
            setTotalTaskCount(taskData.tasks.totalCount);
        }
    }, [taskData]);

    useEffect(() => {
        refetchTasks();
    }, [pageOffsetState, inactiveOnlyState, myTasksOnlyState, props.refreshMainTasksTable, searchTextState]);

    const changeTaskTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    const taskStatus = status => {
        switch (status) {
            case "open":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainBlue}>Open</Tag>;
            case "in_progress":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.brandPrimary}>In Progress</Tag>;
            case "on_hold":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainOrange}>On Hold</Tag>;
            case "canceled":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.monoMedGrey}>Canceled</Tag>;
            case "completed":
                return <Tag style={{fontSize: 13, fontWeight: "bold"}} color={Colors.mainGreenDark}>Completed</Tag>;
            default:
                return "--";
        }
    }

    const columns = [
        {
            title: "Title",
            key: 'title',
            dataIndex: ['node', 'title'],
            render: (text, record) => (
                <>
                    <Row wrap={false} style={{marginBottom: 5}}>
                        <span style={{ flexShrink: 1, fontWeight: "bold", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{record.node.title}</span>{record.node.description ? <span style={{ flexShrink: 2, fontWeight: "bold", color: Colors.monoMedLightGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>&nbsp; - {record.node.description}</span> : null}
                    </Row>
                    <Row>
                        <Tag style={{fontSize: 13}}>
                            {StringFormatter.capitalizeFirstLetter(record.node.category)}
                        </Tag>
                    </Row>
                </>
            ),
        },
        {
            title: "Assignee",
            key: 'assignee',
            dataIndex: ['node', 'assignee'],
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                record.node.assignee ?
                    <Row gutter={8} align="middle" wrap={false}>
                        <Avatar style={{ minWidth: 32, marginRight: 8 }} icon={<UserOutlined/>} src={record.node.assignee.profileImageUrl}/>
                        <span style={{ fontWeight: "bold", color: Colors.monoMedGrey, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{record.node.assignee.fullName}</span>
                    </Row>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: "Due",
            key: 'dueAt',
            dataIndex: ['node', 'dueAt'],
            width: 140,
            render: (text, record) => (
                record.node.dueAt ?
                    <Text type={(record.node.pastDue && record.node.active) ? 'danger' : 'default'}>{DateTimeFormatter.standardDateTime(record.node.dueAt)}</Text>
                    :
                    <MinusOutlined style={{ fontSize: 20, color: Colors.monoLightGrey }} />
            ),
        },
        {
            title: "Status",
            key: 'status',
            dataIndex: ['node', 'status'],
            width: 140,
            render: (text, record) => (
                <div>{taskStatus(record.node.status)}</div>
            )
        },
        {
            title: "Created",
            key: 'created',
            dataIndex: ['node', 'createdAt'],
            width: 140,
            align: "right",
            render: (text, record) => (
                <div>{DateTimeFormatter.standardDateTime(record.node.createdAt)}</div>
            ),
        }
    ];

    const filterByInactiveTasks = (checked) => {
        checked && setMyTasksOnlyState(false);
        setInactiveOnlyState(checked);
    }

    const filterByMyTasks = (checked) => {
        checked && setInactiveOnlyState(false);
        setMyTasksOnlyState(checked);
    }

    return(
        <>
            <Row style={{ padding: 10 }} justify="space-between">
                <Col>
                    <Button type="primary" onClick={() => props.startCreatingTask()}><FormOutlined />New Task</Button>
                </Col>
                <Col>
                    <Row align="middle">
                        <Col>
                            <Search style={{ width: 200, marginRight: 15 }} placeholder="Search text..." allowClear onSearch={(term) => setSearchTextState(term)} />
                        </Col>
                        <Col>
                            <Switch style={{ marginRight: 15 }} checked={myTasksOnlyState} checkedChildren="My Tasks" unCheckedChildren="My Tasks" onChange={(checked) => { filterByMyTasks(checked) }} />
                        </Col>
                        <Col>
                            <Switch checked={inactiveOnlyState} checkedChildren="Inactive" unCheckedChildren="Inactive" onChange={(checked) => { filterByInactiveTasks(checked) }} />
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => refetchTasks()}>
                                {loadingTasks ?
                                    <LoadingOutlined style={{fontSize: 20, color: Colors.brandPrimary}}/>
                                    :
                                    <ReloadOutlined style={{fontSize: 20}}/>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={tasksState ? tasksState.edges : null}
                        loading={{spinning: loadingTasks, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
                        rowKey={record => record.node.id}
                        pagination={{
                            pageSize: taskPageSize,
                            position: ["none", "bottomCenter"],
                            total: totalTaskCount,
                            onChange: (page, pageSize) => { changeTaskTablePage(page, pageSize) }
                        }}
                        scroll={{ y: "58vh" }}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={searchTextState ? "No Tasks Found" : "All caught up!"} /> }}
                        onRow={(record) => {
                            return {
                                onClick: event => { props.selectTask(record) }
                            };
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default MainTasksTable;