import React, {useContext, useEffect, useState} from 'react';
import {Table, Typography, Row, Col} from "antd";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import MetricsQueries from "@dwellpass-client/common/utils/constants/queries/MetricsQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import {LoadingOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const UnitRevenuesTable = (props) => {
    const unitPageSize = 20;

    const [communityState,] = useContext(CommunityContext);
    const [unitRevenuesState, setUnitRevenuesState] = useState([]);
    const [unitGrandTotalCents, setUnitGrandTotalCents] = useState();
    const [unitTotalInvoiceCount, setUnitTotalInvoiceCount] = useState(0);
    const [totalUnitCount, setTotalUnitCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);

    const {
        data: unitRevenuesData,
        loading: loadingUnitRevenues,
        refetch: refetchUnitRevenues
    } = useQuery(gql(MetricsQueries.getUnitRevenues), {
        notifyOnNetworkStatusChange: true,
        variables: {communityId: communityState.id, unitId: props.unitFilterState, status: props.statusFilterState, startDate: props.startDateFilterState, endDate: props.endDateFilterState, first: unitPageSize, after: pageOffsetState}
    });

    useEffect(() => {
        unitRevenuesData && unitRevenuesData.unitRevenues && setUnitRevenuesState(unitRevenuesData.unitRevenues);
        unitRevenuesData && unitRevenuesData.unitRevenues && setUnitGrandTotalCents(unitRevenuesData.unitRevenues.grandTotalCents);
        unitRevenuesData && unitRevenuesData.unitRevenues && setUnitTotalInvoiceCount(unitRevenuesData.unitRevenues.totalInvoiceCount);
        unitRevenuesData && unitRevenuesData.unitRevenues && setTotalUnitCount(unitRevenuesData.unitRevenues.totalCount);
    }, [unitRevenuesData]);

    useEffect(() => {
        refetchUnitRevenues();
    }, [props.unitFilterState, props.statusFilterState, props.startDateFilterState, props.endDateFilterState, pageOffsetState]);

    const tableColumns = [
        {
            title: 'Unit',
            dataIndex: ['node', 'internalIdentifier'],
        },
        {
            title: 'Invoices',
            dataIndex: ['node', 'invoiceCount'],
        },
        {
            title: 'Revenue',
            render: (text, record) => (
                <Text>{MoneyFormatter.standardMoneyUSD(record.node.totalRevenueAmountCents)}</Text>
            )
        }
    ];

    const tableFooter =
        unitGrandTotalCents > 0 ?
            <Row gutter={10} justify="center">
                <Col>
                    <Text strong>Units: {totalUnitCount}</Text>
                </Col>
                <Col>
                    <Text strong>|</Text>
                </Col>
                <Col>
                    <Text strong>Invoices: {unitTotalInvoiceCount}</Text>
                </Col>
                <Col>
                    <Text strong>|</Text>
                </Col>
                <Col>
                    <Text strong>Grand Total: {MoneyFormatter.standardMoneyUSD(unitGrandTotalCents)}</Text>
                </Col>
            </Row>
            : undefined;

    const changeUnitTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page-1)*pageSize));
    }

    return (
        <Table
            columns={tableColumns}
            dataSource={unitRevenuesState && unitRevenuesState.edges}
            size="small"
            rowKey={record => record.node.id}
            scroll={{ y: "40vh" }}
            loading={{spinning: loadingUnitRevenues, indicator: <LoadingOutlined style={{ color: Colors.brandPrimary, fontSize: 40, marginLeft: -20 }} />}}
            pagination={{
                pageSize: unitPageSize,
                position: ["none", "bottomCenter"],
                total: totalUnitCount,
                onChange: (page, pageSize) => { changeUnitTablePage(page, pageSize) }
            }}
            footer={() => tableFooter}
        />
    );
}

export default UnitRevenuesTable;