import React, {useContext, useEffect, useState} from 'react';
import {Row, Col, Divider, Typography, Card, Tag, Popconfirm, message} from "antd";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import UnitQueries from "@dwellpass-client/common/utils/constants/queries/UnitQueries";
import PaymentSourceQueries from "@dwellpass-client/common/utils/constants/queries/PaymentSourceQueries";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import LoadingContent from "../utility/LoadingContent";
import {BankOutlined, CreditCardOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import NewBankAccountModal from "../shared/NewBankAccountModal";
import VerifyBankAccountModal from "../shared/VerifyBankAccountModal";
import NewPaymentCardModal from "../shared/NewPaymentCardModal";
import PaymentMethodQueries from "@dwellpass-client/common/utils/constants/queries/PaymentMethodQueries";
import StringFormatter from "@dwellpass-client/common/utils/formatters/StringFormatter";

const { Text } = Typography;

const PaymentMethods = (props) => {
    const [unitState,] = useContext(UnitContext);
    const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false);
    const [showNewPaymentCardModal, setShowNewPaymentCardModal] = useState(false);
    const [showVerifyBankAccountModal, setShowVerifyBankAccountModal] = useState(false);
    const [currentUserUnitResident, setCurrentUserUnitResident] = useState({});
    const [stripeCustomerState, setStripeCustomerState] = useState({});
    const [paymentMethodsState, setPaymentMethodsState] = useState([]);
    const [selectedSource, setSelectedSource] = useState({});

    const {data: currentUserUnitResidentData} = useQuery(gql(UnitQueries.getUnitCurrentUserUnitResident), {notifyOnNetworkStatusChange: true, variables: {id: unitState.id}});
    const [loadStripeCustomer, {data: stripeCustomerData, loading: loadingStripeCustomer}] = useLazyQuery(gql(PaymentSourceQueries.getStripeCustomerWithSources), {notifyOnNetworkStatusChange: true, fetchPolicy: "network-only"});
    const [deleteSource, {loading: loadingDeleteSource}] = useMutation(gql(PaymentSourceQueries.deleteSource));
    const [loadStripePaymentMethods, {data: stripePaymentMethodsData, loading: loadingStripePaymentMethods}] = useLazyQuery(gql(PaymentMethodQueries.getStripePaymentMethods), {notifyOnNetworkStatusChange: true, fetchPolicy: "network-only"});
    const [detachPaymentMethod, {loading: loadingDetachPaymentMethod}] = useMutation(gql(PaymentMethodQueries.detachPaymentMethod));
    const [setDefaultPaymentMethod, {loading: loadingSetDefaultPaymentMethod}] = useMutation(gql(PaymentMethodQueries.setDefaultPaymentMethod));

    useEffect(() => {
        currentUserUnitResidentData?.unit && setCurrentUserUnitResident(currentUserUnitResidentData.unit.currentUserUnitResident);
    }, [currentUserUnitResidentData]);

    useEffect(() => {
        currentUserUnitResident?.stripeCustomerId && loadCustomerWithPaymentMethods();
    }, [currentUserUnitResident]);

    useEffect(() => {
        stripeCustomerData?.stripeCustomer && setStripeCustomerState(stripeCustomerData.stripeCustomer);
    }, [stripeCustomerData]);

    useEffect(() => {
        stripePaymentMethodsData?.stripePaymentMethods && setPaymentMethodsState(stripePaymentMethodsData?.stripePaymentMethods);
    }, [stripePaymentMethodsData]);

    const loadCustomerWithPaymentMethods = () => {
        loadStripeCustomer({ variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId} });
        loadStripePaymentMethods({ variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId}});
    }

    const promptToAddNewBankAccount = () => {
        setShowNewBankAccountModal(true);
    }

    const promptToAddNewPaymentCard = () => {
        setShowNewPaymentCardModal(true);
    }

    const promptToVerifyBankAccount = (source) => {
        setSelectedSource(source);
        setTimeout(() => {
            setShowVerifyBankAccountModal(true);
        }, 200);
    }

    const hideNewBankAccountModal = () => {
        setShowNewBankAccountModal(false);
        setTimeout(() => {
            clearSelectedSource();
        }, 500);
    }

    const hideNewPaymentCardModal = () => {
        setShowNewPaymentCardModal(false);
    }

    const hideVerifyBankAccountModal = () => {
        setShowVerifyBankAccountModal(false);
        setTimeout(() => {
            clearSelectedSource();
        }, 500);
    }

    const clearSelectedSource = () => {
        setSelectedSource({});
    }

    const sourceDetailsFromId = (source) => {
        return stripeCustomerState?.sources?.find((stripeSource) => stripeSource.id === source.id) || {};
    }

    const handleSetDefaultPaymentMethod = async (paymentMethod) => {
        try {
            await setDefaultPaymentMethod({variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId, stripePaymentMethodId: paymentMethod.id}});

            loadCustomerWithPaymentMethods();
            const last4 = paymentMethod.type === "card" ? paymentMethod.card?.last4 : paymentMethod.bankAccount?.last4;
            message.success(`Payment Method ending in ${last4} was set as the default`);
        }
        catch (error) {
            message.error("There was a problem setting the payment method as the default");
        }
    }

    const handleDeleteSource = async (source) => {
        try {
            await deleteSource({variables: {stripeCustomerId: currentUserUnitResident.stripeCustomerId, stripeSourceId: source.id}});

            loadCustomerWithPaymentMethods();
            message.success(`Account ending in ${source.last4} was deleted`);
        }
        catch (error) {
            message.error("There was a problem deleting the account");
        }
    }

    const handleDetachPaymentMethod = async (paymentMethod) => {
        try {
            await detachPaymentMethod({variables: {stripePaymentMethodId: paymentMethod.id}});

            loadCustomerWithPaymentMethods();
            const last4 = paymentMethod.type === "card" ? paymentMethod.card?.last4 : paymentMethod.bankAccount?.last4;
            message.success(`Payment Method ending in ${last4} was deleted`);
        }
        catch (error) {
            message.error("There was a problem deleting the payment method");
        }
    }

    const paymentMethodCardTitle = (paymentMethod) => {
        return (
            <Row gutter={10} align="middle">
                <Col>
                    {paymentMethod.type === "card" ?
                        <Text><CreditCardOutlined style={{ color: Colors.brandPrimary, fontSize: 20, marginRight: 10 }} />Card</Text>
                    :
                        <Text><BankOutlined style={{ color: Colors.brandPrimary, fontSize: 20, marginRight: 10 }} />Bank Account</Text>
                    }
                </Col>
                {paymentMethod.id === (stripeCustomerState?.invoiceSettings?.defaultPaymentMethod || stripeCustomerState?.defaultSource) &&
                    <Col>
                        <Tag color="blue">Default</Tag>
                    </Col>
                }
            </Row>
        );
    }

    const deleteSourceButton = (source) => {
        return <Popconfirm title="Are you sure you want to delete this payment method?" onConfirm={() => handleDeleteSource(source)} okText="Yes" cancelText="No">
            <Text strong style={{color: Colors.brandPrimary}}>Delete</Text>
        </Popconfirm>;
    }

    const deletePaymentMethodButton = (paymentMethod) => {
        return <Popconfirm title="Are you sure you want to delete this payment method?" onConfirm={() => handleDetachPaymentMethod(paymentMethod)} okText="Yes" cancelText="No">
            <Text strong style={{color: Colors.brandPrimary}}>Delete</Text>
        </Popconfirm>;
    }

    const paymentMethodActionButtons = (paymentMethod) => {
        if (paymentMethod.id !== (stripeCustomerState?.invoiceSettings?.defaultPaymentMethod || stripeCustomerState?.defaultSource) && (paymentMethod.type === "card" || sourceDetailsFromId(paymentMethod)?.status === "verified")) {
            return [
                <Text strong style={{ color: Colors.mainBlue }} onClick={() => handleSetDefaultPaymentMethod(paymentMethod)}>Set as Default</Text>,
                deletePaymentMethodButton(paymentMethod)
            ];
        } else if (paymentMethod.type !== "card" && sourceDetailsFromId(paymentMethod)?.status !== "verified") {
            return [
                <Text strong type="warning" onClick={() => promptToVerifyBankAccount(paymentMethod)}>Verify</Text>,
                deleteSourceButton(paymentMethod)
            ];
        } else {
            return [
                deletePaymentMethodButton(paymentMethod)
            ];
        }
    }

    return (
        <>
            <Divider>Payment Methods</Divider>
            <Row style={{ marginBottom: 20 }} justify="center">
                <Col>
                    <Text type="secondary">Note that your default payment method is the one that is used for invoice payment.</Text>
                </Col>
            </Row>
            {(!stripeCustomerState.id || loadingStripeCustomer || loadingSetDefaultPaymentMethod || loadingDetachPaymentMethod || loadingDeleteSource || loadingStripePaymentMethods) ?
                <Row style={{ marginTop: 30 }} justify="center">
                    <Col>
                        <LoadingContent />
                    </Col>
                </Row>
            :
                <>
                    <Row gutter={[16, 24]} justify="center">
                        {paymentMethodsState.map(paymentMethod => (
                            <Col key={paymentMethod.id}>
                                <Card
                                    style={{ width: 300, borderColor: Colors.monoLightGrey }}
                                    actions={paymentMethodActionButtons(paymentMethod)}
                                >
                                    <Card.Meta
                                        title={paymentMethodCardTitle(paymentMethod)}
                                        description={<><Text type="secondary">{StringFormatter.capitalizeFirstLetter(paymentMethod.type === "card" ? paymentMethod.card?.brand : paymentMethod.bankAccount?.bankName)} - </Text><Text strong style={{ color: Colors.brandPrimary }}>{paymentMethod.type === "card" ? paymentMethod.card?.last4 : paymentMethod.bankAccount?.last4}</Text></>}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={10} justify="center">
                        <Col>
                            <Card style={{ width: 300, marginTop: 14, backgroundColor: Colors.brandPrimary }} hoverable onClick={promptToAddNewBankAccount}>
                                <Row gutter={10} justify="center" align="middle">
                                    <Col>
                                        <BankOutlined style={{ fontSize: 24, color: Colors.monoBright }} />
                                    </Col>
                                    <Col>
                                        <Text strong style={{ fontSize: 18, color: Colors.monoBright }}>Add Bank Account</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: 300, marginTop: 14, backgroundColor: Colors.brandPrimary }} hoverable onClick={promptToAddNewPaymentCard}>
                                <Row gutter={10} justify="center" align="middle">
                                    <Col>
                                        <CreditCardOutlined style={{ fontSize: 24, color: Colors.monoBright }} />
                                    </Col>
                                    <Col>
                                        <Text strong style={{ fontSize: 18, color: Colors.monoBright }}>Add Card</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <NewBankAccountModal visible={showNewBankAccountModal} hideMe={hideNewBankAccountModal} currentUserUnitResident={currentUserUnitResident} formSuccess={loadCustomerWithPaymentMethods} />
                    <NewPaymentCardModal visible={showNewPaymentCardModal} hideMe={hideNewPaymentCardModal} currentUserUnitResident={currentUserUnitResident} formSuccess={loadCustomerWithPaymentMethods} />
                    <VerifyBankAccountModal selectedSource={selectedSource} visible={showVerifyBankAccountModal} hideMe={hideVerifyBankAccountModal} currentUserUnitResident={currentUserUnitResident} formSuccess={loadCustomerWithPaymentMethods} />
                </>
            }
        </>
    );
}

export default PaymentMethods;