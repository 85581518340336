import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import DirectoryListingQueries from "@dwellpass-client/common/utils/constants/queries/DirectoryListingQueries";
import {gql, useQuery} from "@apollo/client";
import {Space, Col, Row, Typography, Collapse, Badge, Card} from "antd";
import { LinkOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import LoadingContent from "../../utility/LoadingContent";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const DirectoryListingCollapse = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const { data: directoryListingsData, loading: loadingDirectoryListings } = useQuery(gql(DirectoryListingQueries.getDirectoryListings),{ notifyOnNetworkStatusChange: true, variables: { communityId: communityState.id } });
    const [directoryListingsState, setDirectoryListingsState] = useState([]);
    const [directoryListingCategoriesState, setDirectoryListingCategoriesState] = useState([]);

    useEffect(() => {
        directoryListingsData && setDirectoryListingsState(directoryListingsData.directoryListings);
        directoryListingsData && directoryListingsData.directoryListings && setDirectoryListingCategoriesState([...new Set(directoryListingsData.directoryListings.edges.map(dl => dl.node.category))]);
    }, [directoryListingsData]);

    const filterDirectoryListingByCategory = (category) => {
        return directoryListingsState.edges.filter(dl => dl.node.category === category)
    }

    return(
        <>
            {loadingDirectoryListings ?
                <LoadingContent/>
                :
                <Row justify="center">
                    <Collapse style={{width: "100%"}} defaultActiveKey={directoryListingCategoriesState[0]}>
                        {directoryListingCategoriesState.map(category =>
                            <Panel header={category}
                                   key={category}
                                   showArrow={false}
                                   extra={<Badge style={{backgroundColor: Colors.brandPrimary, fontWeight: "bold"}}
                                                 count={filterDirectoryListingByCategory(category).length}/>}>
                                <Row gutter={16}>
                                    {filterDirectoryListingByCategory(category).map(directoryListing =>
                                        <Col span={8} key={directoryListing.node.id}>
                                            <Card type="inner"
                                                  title={<Title level={5}>{directoryListing.node.name}</Title>}
                                                  style={{borderColor: Colors.monoMedLightGrey}}>
                                                <Space direction="vertical">
                                                    {(!directoryListing.node.phoneNumber && !directoryListing.node.email && !directoryListing.node.website) ?
                                                        <Text type="secondary">No Information Listed</Text>
                                                        :
                                                        <>
                                                            {directoryListing.node.phoneNumber &&
                                                            <a href={`tel:${directoryListing.node.phoneNumber}`}><PhoneOutlined/> {directoryListing.node.phoneNumber}
                                                            </a>}
                                                            {directoryListing.node.email &&
                                                            <a href={`mailto:${directoryListing.node.email}`}><MailOutlined/> {directoryListing.node.email}
                                                            </a>}
                                                            {directoryListing.node.website &&
                                                            <a href={directoryListing.node.website}
                                                               target="_blank"><LinkOutlined/> Website</a>}
                                                        </>
                                                    }
                                                </Space>
                                            </Card>
                                        </Col>
                                    )
                                    }
                                </Row>
                            </Panel>
                        )}
                    </Collapse>
                </Row>
            }
        </>
    )
}

export default DirectoryListingCollapse;