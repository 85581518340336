import React, {useEffect, useState, useImperativeHandle, forwardRef} from "react";
import {Divider, Row, Col, Steps, Descriptions, Select, Form, DatePicker, Input, Upload, Button, notification, Spin, Tabs, Badge, Tag, message} from "antd";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import {UploadOutlined, CalendarOutlined} from "@ant-design/icons";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import moment from "moment";
import {gql, useMutation} from "@apollo/client";
import TaskQueries from "@dwellpass-client/common/utils/constants/queries/TaskQueries";

const { Step } = Steps;

const TaskDetail = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [attachments, setAttachments] = useState([]);
    const [removingAttachment, setRemovingAttachment] = useState(null);

    const [updateTask] = useMutation(gql(TaskQueries.updateTask));
    const [uploadTaskAttachments, { loading: loadingUploadTaskAttachments }] = useMutation(gql(TaskQueries.uploadTaskAttachments));
    const [removeTaskAttachment] = useMutation(gql(TaskQueries.removeTaskAttachment));

    useEffect(() => {
        setAttachments(props.selectedTaskState?.attachments?.length >= 1 ? props.selectedTaskState?.attachments?.map(attachment => {
            return {
                uid: attachment.id,
                name: attachment.filename,
                status: "done",
                type: attachment.contentType,
                url: attachment.url
            }
        }) : []);
        form.setFieldsValue({
            title: props.selectedTaskState?.title,
            description: props.selectedTaskState?.description,
            status: props.selectedTaskState?.status,
            category: props.selectedTaskState?.category,
            priority: props.selectedTaskState?.priority,
            assigneeId: props.selectedTaskState?.assignee?.id,
            unitId: props.selectedTaskState?.unit?.id,
            dueAt: props.selectedTaskState?.dueAt ? moment(props.selectedTaskState?.dueAt) : undefined,
        });
    }, [props.selectedTaskState]);

    useImperativeHandle(ref, () => ({
        triggerTaskFormSubmit() {
            form.submit();
        }
    }));

    const handleFormSubmit = async (values) => {
        props.setTaskFormLoadingState(true);

        try {
            let dueAt = values.dueAt ? values.dueAt.toISOString() : null;
            let assigneeId = values.assigneeId ? values.assigneeId : null;
            let unitId = values.unitId ? values.unitId : null;

            await updateTask({
                variables: {
                    id: props.selectedTaskState?.id,
                    title: values.title,
                    description: values.description,
                    status: values.status,
                    category: values.category,
                    unitId: unitId,
                    assigneeId: assigneeId,
                    priority: values.priority,
                    dueAt
                }
            });

            message.success('Saved');
            props.formSuccess();
            props.setTaskFormChangedState(false);
            props.setTaskFormLoadingState(false);
        }
        catch (e) {
            notification['error']({
                message: 'Error Updating Task',
                description:
                    'Something went wrong. Please try again.',
            });
            props.setTaskFormLoadingState(false);
        }
    }

    const currentStepBasedOnStatus = () => {
        switch (props.selectedTaskState?.status) {
            case "in_progress":
                return 1;
            case "completed":
                return 2;
            default:
                return 0;
        }
    }

    const currentStepStatusBasedOnTaskStatus = () => {
        switch (props.selectedTaskState?.status) {
            case "on_hold":
            case "canceled":
                return "wait";
            case "completed":
                return "finish";
            default:
                return "process";
        }
    }

    const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            await uploadTaskAttachments({variables: { taskId: props.selectedTaskState.id, attachments: [file] }});

            onSuccess('File added successfully');
        }
        catch (e) {
            notification['error']({
                message: 'Error Uploading Attachment',
                duration: 8,
                description:
                    'Something went wrong. Please verify your file does not exceed 30MB and try again.',
            });
        }
    };

    const onRemove = async (file) => {
        setRemovingAttachment(file);
        try {
            await removeTaskAttachment({variables: {taskId: props.selectedTaskState.id, attachmentId: file.uid}});
            const updatedAttachments = attachments.filter(attachment => attachment.uid !== file.uid);
            setAttachments(updatedAttachments);
            setRemovingAttachment(null);
        }
        catch (e) {
            notification['error']({
                message: 'Error Removing Attachment',
                description:
                    'Something went wrong. Please try again.',
            });
            setRemovingAttachment(null);
        }
    }

    const itemRender = (originNode, file, fileList) => {
        if (file.uid === removingAttachment?.uid) {
            return (
                <div className="ant-upload-list-item" style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 14 }}>
                    <Spin />
                </div>
            );
        }
        return originNode;
    };

    const Uploader = () => {
        return <>
            <Upload
                key={attachments?.length}
                listType="picture"
                itemRender={itemRender}
                defaultFileList={attachments?.length >= 1 ? [...attachments] : []}
                customRequest={customRequest}
                onRemove={onRemove}
            >
                <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            {loadingUploadTaskAttachments &&
                <div className="ant-upload-list-picture">
                    <div className="ant-upload-list-item" style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 14}}>
                        <Spin/>
                    </div>
                </div>
            }
        </>;
    }

    return (
        <>
            {(props.selectedTaskState?.status !== "on_hold" && props.selectedTaskState?.status !== "canceled") &&
                <Row gutter={16}>
                    <Col span={24} style={{ paddingTop: 15 }}>
                        <Steps
                            current={currentStepBasedOnStatus()}
                            status={currentStepStatusBasedOnTaskStatus()}
                            size="small"
                            progressDot
                        >
                            <Step title="Open" />
                            <Step title="In Progress" />
                            <Step title="Completed" />
                        </Steps>
                        <Divider></Divider>
                    </Col>
                </Row>
            }
            <Form form={form}
                  onValuesChange={() => props.setTaskFormChangedState(true)}
                  onFinish={handleFormSubmit}
            >
                <Descriptions column={1} layout="vertical" labelStyle={{fontWeight: "bold"}}>
                    <Descriptions.Item>
                        <Form.Item
                            name="title"
                            style={{marginBottom: 6, width: "100%"}}
                            rules={[{ required: true, message: 'Please enter the title' }]}
                        >
                            <Input
                                style={{ padding: 0, fontWeight: "bold", fontSize: 18, borderRadius: 0 }}
                                bordered={false}
                            />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                    <Descriptions column={2} labelStyle={{fontWeight: "bold", alignItems: "center"}}>
                        <Descriptions.Item span={2} label="Status">
                            <Form.Item
                                name="status"
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    style={{width: 140}}
                                    bordered={false}
                                >
                                    <Select.Option value="open"><Tag style={{fontWeight: "bold"}} color={Colors.mainBlue}>Open</Tag></Select.Option>
                                    <Select.Option value="in_progress"><Tag style={{fontWeight: "bold"}} color={Colors.brandPrimary}>In Progress</Tag></Select.Option>
                                    <Select.Option value="completed"><Tag style={{fontWeight: "bold"}} color={Colors.mainGreenDark}>Completed</Tag></Select.Option>
                                    <Select.Option value="on_hold"><Tag style={{fontWeight: "bold"}} color={Colors.mainOrange}>On Hold</Tag></Select.Option>
                                    <Select.Option value="canceled"><Tag style={{fontWeight: "bold"}} color={Colors.monoMedGrey}>Canceled</Tag></Select.Option>
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Category">
                            <Form.Item
                                name="category"
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    style={{width: 140}}
                                    bordered={false}
                                >
                                    <Select.Option value="maintenance">Maintenance</Select.Option>
                                    <Select.Option value="repair">Repair</Select.Option>
                                    <Select.Option value="construction">Construction</Select.Option>
                                    <Select.Option value="miscellaneous">Miscellaneous</Select.Option>
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Priority">
                            <Form.Item
                                name="priority"
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    style={{width: 140}}
                                    bordered={false}
                                >
                                    <Select.Option value="low">Low</Select.Option>
                                    <Select.Option value="medium">Medium</Select.Option>
                                    <Select.Option value="high">High</Select.Option>
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Assignee">
                            <Form.Item
                                name="assigneeId"
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    style={{width: 140}}
                                    placeholder="--"
                                    bordered={false}
                                    allowClear
                                    showSearch
                                    loading={props.loadingCommunityManagersOptions}
                                    options={props.communityManagersOptions}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Unit">
                            <Form.Item
                                name="unitId"
                                style={{marginBottom: 0}}
                            >
                                <Select
                                    style={{width: 140}}
                                    placeholder="--"
                                    bordered={false}
                                    allowClear
                                    showSearch
                                    loading={props.loadingUnitsOptions}
                                    options={props.unitsOptions}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Due">
                            <Form.Item
                                name="dueAt"
                                style={{marginBottom: 0}}
                            >
                                <DatePicker
                                    style={{width: 200}}
                                    showTime
                                    placeholder="--"
                                    showNow={false}
                                    bordered={false}
                                    suffixIcon={<CalendarOutlined style={{ color: props.selectedTaskState?.pastDue ? Colors.dangerRed : Colors.monoMedLightGrey }} />}
                                    disabledDate={current => {
                                        return current && current < moment();
                                    }}
                                    format="MM/DD/YYYY h:mma"
                                />
                            </Form.Item>
                        </Descriptions.Item>
                        {props.selectedTaskState?.pastDue &&
                            <Descriptions.Item contentStyle={{marginTop: 4}}>
                                <Tag color="red">PAST DUE</Tag>
                            </Descriptions.Item>
                        }
                    </Descriptions>
                <Descriptions column={1} layout="vertical" labelStyle={{fontWeight: "bold"}}>
                    <Descriptions.Item label="Description">
                        <Form.Item
                            name="description"
                            style={{marginBottom: 0, width: "100%"}}
                        >
                            <Input.TextArea
                                style={{padding: 0, borderRadius: 0}}
                                placeholder="Add a description..."
                                bordered={false}
                                autoSize={true}
                            />
                        </Form.Item>
                    </Descriptions.Item>
                </Descriptions>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane
                        tab={
                            <Row gutter={6} align="middle">
                                <Col>
                                    Attachments
                                </Col>
                                {attachments.length > 0 &&
                                    <Col>
                                        <Badge style={{ backgroundColor: Colors.brandPrimary }} count={attachments.length}/>
                                    </Col>
                                }
                            </Row>}
                        key="1"
                    >
                        <Form.Item
                            name="attachments"
                            style={{marginBottom: 0, width: "100%"}}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.fileList}
                        >
                            <Uploader />
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
                <Divider></Divider>
                <Descriptions column={1} labelStyle={{ color: Colors.monoMedLightGrey }} contentStyle={{ color: Colors.monoMedLightGrey }}>
                    <Descriptions.Item label="Created by" labelStyle={{ fontWeight: "bold" }}>{props.selectedTaskState?.createdBy?.fullName}</Descriptions.Item>
                    <Descriptions.Item label="Created" labelStyle={{ fontWeight: "bold" }}>{DateTimeFormatter.standardDateTime(props.selectedTaskState?.createdAt)}</Descriptions.Item>
                </Descriptions>
            </Form>
        </>
    )
})

export default TaskDetail;