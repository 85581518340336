import React, {useState, useRef, useEffect} from "react";
import {Modal, Form, Row, InputNumber, Typography, notification, Col, Radio, Space} from "antd";
import {gql, useMutation} from "@apollo/client";
import InvoiceQueries from "@dwellpass-client/common/utils/constants/queries/InvoiceQueries";
import MoneyFormatter from "@dwellpass-client/common/utils/formatters/MoneyFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";

const { Text } = Typography;

const CreateRefundModal = (props) => {
    const [form] = Form.useForm();
    const [formErrors, setFormErrors] = useState([]);
    const [loadingFormSubmission, setLoadingFormSubmission] = useState(false);
    const [refundStyleState, setRefundStyleState] = useState(null);
    const amountInput = useRef();

    const [createRefund,] = useMutation(gql(InvoiceQueries.createRefund));

    useEffect(() => {
        refundStyleState === "partial" &&
            setTimeout(() => {
                amountInput.current.focus();
            }, 200);
        form.resetFields();
        setFormErrors([]);
    }, [refundStyleState]);

    const handleFormSubmit = async (values) => {
        setLoadingFormSubmission(true);

        try {
            setFormErrors([]);
            await createRefund({variables: {stripeChargeId: props.chargeState.stripeChargeId, amount: refundStyleState === "partial" ? (values.amount * 100) : null}});

            openSuccessNotification();

            props.formSuccess();
            resetFieldsAndHideMe();
        } catch (error) {
            setFormErrors(error.graphQLErrors ? error.graphQLErrors : [error]);
            setLoadingFormSubmission(false);
        }
    }

    const openSuccessNotification = () => {
        notification["success"]({
            message: "Refund Processing",
            description: "We've submitted the refund. Please allow 4-5 business days for the banks to process the request."
        });
    }

    const resetFieldsAndHideMe = () => {
        props.hideMe();
        setTimeout(() => {
            form.resetFields();
            setLoadingFormSubmission(false);
            setRefundStyleState(null);
        }, 500);
    }

    const refundableAmount = () => {
        return props.chargeState.amountCents - props.chargeState.amountRefundedCents;
    }

    return (
        <Modal
            forceRender
            title="Create Refund"
            visible={props.visible}
            onOk={refundStyleState === "partial" ? form.submit : handleFormSubmit}
            okText="Submit Refund"
            okButtonProps={{disabled: !refundStyleState}}
            confirmLoading={loadingFormSubmission}
            onCancel={() => resetFieldsAndHideMe()}
        >
            <Row justify="center">
                <Col>
                    <Space direction="vertical">
                        <Row style={{ marginBottom: 10 }} gutter={10} justify="center" align="middle">
                            <Col>
                                <Text>Refund type:</Text>
                            </Col>
                            <Col>
                                <Radio.Group defaultValue="full" onChange={e => setRefundStyleState(e.target.value)} value={refundStyleState}>
                                    <Radio.Button value="full">Full</Radio.Button>
                                    <Radio.Button value="partial">Partial</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {refundStyleState === "full" &&
                        <>
                            {formErrors && (
                                <Row justify="center">
                                    {formErrors.map(error =>
                                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                                    )}
                                </Row>
                            )}
                            <Row justify="center">
                                <Col>
                                    <Text strong style={{fontSize: 26}}>Refund Amount:</Text>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <Text strong style={{fontSize: 30, color: Colors.brandPrimary}}>{MoneyFormatter.standardMoneyUSD(refundableAmount())}</Text>
                                </Col>
                            </Row>
                        </>
                        }
                        {refundStyleState === "partial" &&
                        <>
                            <Row gutter={10} justify="center" align="middle">
                                <Col>
                                    <Text strong style={{ fontSize: 20 }}>You can refund up to:</Text>
                                </Col>
                                <Col>
                                    <Text strong style={{ fontSize: 20, color: Colors.brandPrimary }}>{MoneyFormatter.standardMoneyUSD(refundableAmount())}</Text>
                                </Col>
                            </Row>
                            <Form
                                form={form}
                                onFinish={handleFormSubmit}
                                requiredMark={false}
                            >
                                {formErrors && (
                                    formErrors.map(error =>
                                        <Text key={error.message} type="danger">{error.message}<br/></Text>
                                    )
                                )}
                                <Row style={{marginTop: 10}} gutter={16} justify="center">
                                    <Col>
                                        <Form.Item
                                            label="Partial Refund Amount"
                                            name="amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required',
                                                }
                                            ]}
                                        >
                                            <InputNumber
                                                min={1}
                                                max={(refundableAmount() / 100)}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                precision={2}
                                                ref={amountInput}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                        }
                        {refundStyleState &&
                            <Text style={{ color: Colors.monoMedLightGrey }}>Note that refunds take 4-5 business days to process.</Text>
                        }
                    </Space>
                </Col>
            </Row>
        </Modal>
    )
}

export default CreateRefundModal;