import React from "react";
import {Divider, Row, Col, Typography, Descriptions, Button, Popconfirm, Card} from "antd";
import DateTimeFormatter from "@dwellpass-client/common/utils/formatters/DateTimeFormatter";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {
    ApartmentOutlined,
    CloseOutlined,
    FileOutlined,
    HomeOutlined,
    LockOutlined,
    UserOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    ContainerOutlined
} from "@ant-design/icons";

const { Title, Text } = Typography;

const ResourceDetail = (props) => {

    return(
        <>
            <Row gutter={10} style={{ padding: "24px 24px 0px 24px" }} justify="space-between">
                <Col span={22}>
                    <Row wrap={false}>
                        {props.selectedResourceState.name ?
                            <>
                                <Col>
                                    <ContainerOutlined style={{ marginRight: 10, fontSize: 30, color: Colors.brandPrimary }} />
                                </Col>
                                <Col>
                                    <Title level={3}>{props.selectedResourceState.name}</Title>
                                </Col>
                            </>
                            :
                            <>
                                <Col>
                                    <FileOutlined style={{ marginRight: 10, fontSize: 30, color: Colors.brandPrimary }} />
                                </Col>
                                <Col>
                                    <Title level={3}>{props.selectedResourceState.resourceFileName}</Title>
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
                <Col span={2}>
                    <CloseOutlined style={{ marginLeft: 10, fontSize: 20, color: Colors.monoMedLightGrey }} onClick={event => { props.clearSelectedResource() }} />
                </Col>
            </Row>
            <Divider></Divider>
            <div style={{ padding: "0px 24px 24px 24px" }}>
                <Row style={{ marginBottom: 15 }} align="middle" gutter={10}>
                    {props.selectedResourceState.resourceFileUrl &&
                        <Col style={{ marginBottom: 10 }}>
                            <a href={props.selectedResourceState.resourceFileUrl} target="_blank"><Button type="primary"><EyeOutlined />View/Download</Button></a>
                        </Col>
                    }
                    <Col style={{ marginBottom: 10 }}>
                        <Button type="primary" onClick={() => props.editResource()}><EditOutlined />Edit</Button>
                    </Col>
                    <Col style={{ marginBottom: 10 }}>
                        <Popconfirm title="Are you sure" icon={<QuestionCircleOutlined style={{ color: "red" }} />} onConfirm={() => props.handleResourceDestroy()}>
                            <Button type="primary" loading={props.destroyingResource}><DeleteOutlined />Delete</Button>
                        </Popconfirm>
                    </Col>
                </Row>
                {(props.selectedResourceState.name && props.selectedResourceState.resourceFileName) &&
                    <Row style={{marginBottom: 15}}>
                        <><FileOutlined style={{marginRight: 10, fontSize: 20, color: Colors.brandPrimary}}/><Text>{props.selectedResourceState.resourceFileName}</Text></>
                    </Row>
                }
                <Row style={{ marginBottom: 15 }}>
                    {props.selectedResourceState.residentViewable ?
                        <><UserOutlined style={{marginRight: 10, fontSize: 20, color: Colors.brandPrimary}}/><Text>Residents can view</Text></>
                        :
                        <><LockOutlined style={{marginRight: 10, fontSize: 20, color: Colors.monoMedLightGrey}}/><Text style={{color: Colors.monoMedLightGrey}}>Not available to Residents</Text></>
                    }
                </Row>
                <Row style={{ marginBottom: 15 }} align="middle">
                {props.selectedResourceState.resourceable ?
                        <><HomeOutlined style={{marginRight: 10, fontSize: 18, color: Colors.brandPrimary}}/><Text>Unit Resource: {props.selectedResourceState.resourceable.internalIdentifier}</Text></>
                        :
                        <><ApartmentOutlined style={{marginRight: 10, fontSize: 18, color: Colors.brandPrimary}}/><Text>Community Resource</Text></>
                }
                </Row>
                <Descriptions column={1}>
                    {props.selectedResourceState.resourceFileUrl &&
                        <Descriptions.Item label="Description">{props.selectedResourceState.description ? props.selectedResourceState.description : "N/A"}</Descriptions.Item>
                    }
                    {props.selectedResourceState.resourceable &&
                        <Descriptions.Item label="Visible on Unit Timeline">{props.selectedResourceState.displayOnTimeline ? "Yes" : "No"}</Descriptions.Item>
                    }
                    <Descriptions.Item label="Resource Created">{DateTimeFormatter.standardDateTime(props.selectedResourceState.createdAt)}</Descriptions.Item>
                    <Descriptions.Item label="Uploaded by">{props.selectedResourceState.createdBy && props.selectedResourceState.createdBy.fullName}</Descriptions.Item>
                </Descriptions>
                {!props.selectedResourceState.resourceFileUrl &&
                    <Card>
                        <Row justify="center">
                            <Text strong style={{ fontSize: 32 }}>{props.selectedResourceState.description}</Text>
                        </Row>
                    </Card>
                }
            </div>
        </>
    )
}

export default ResourceDetail;