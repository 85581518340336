import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import {gql, useQuery} from "@apollo/client";
import {Avatar, Empty, Row, Table} from "antd";
import {UserOutlined} from "@ant-design/icons";
import UnitResidentQueries from "@dwellpass-client/common/utils/constants/queries/UnitResidentQueries";
import LoadingContent from "../../utility/LoadingContent";

const ResidentsList = (props) => {
    const unitResidentPageSize = 30;

    const [communityState,] = useContext(CommunityContext);
    const [totalUnitResidentCount, setTotalUnitResidentCount] = useState(0);
    const [pageOffsetState, setPageOffsetState] = useState(null);
    const {
        data: unitResidentsData,
        loading: loadingUnitResidents
    } = useQuery(gql(UnitResidentQueries.getUnitResidents), {
        notifyOnNetworkStatusChange: true,
        variables: {
            communityId: communityState.id,
            currentOnly: true,
            first: unitResidentPageSize,
            after: pageOffsetState
        }
    });
    const [unitResidentsState, setUnitResidentsState] = useState([]);

    useEffect(() => {
        unitResidentsData && setUnitResidentsState(unitResidentsData.unitResidents);
        unitResidentsData && setTotalUnitResidentCount(unitResidentsData.unitResidents.totalCount);
    }, [unitResidentsData]);

    const changeUnitResidentTablePage = (page, pageSize) => {
        setPageOffsetState(btoa((page - 1) * pageSize));
    }

    const unitResidentsTableColumns = [
        {
            key: 'image',
            render: (text, record) => (
                <Avatar size={36} icon={<UserOutlined/>}
                        src={record.node.resident.profileImageUrl && record.node.resident.profileImageUrl}/>
            ),
        },
        {
            title: 'First Name',
            key: 'firstName',
            dataIndex: ['node', 'resident', 'firstName'],
            sorter: true
        },
        {
            title: 'Last Name',
            key: 'lastName',
            dataIndex: ['node', 'resident', 'lastName'],
            sorter: true
        }
        ,
        {
            title: 'Unit',
            key: 'unit',
            dataIndex: ['node', 'unit', 'internalIdentifier'],
            sorter: true
        }
    ];

    return (
        <>
            <Row justify="center">
                {loadingUnitResidents ?
                    <LoadingContent/>
                    :
                    <Table
                        style={{width: "100%"}}
                        size="small"
                        dataSource={unitResidentsState ? unitResidentsState.edges : null}
                        columns={unitResidentsTableColumns}
                        rowKey={record => record.node.id}
                        pagination={{
                            pageSize: unitResidentPageSize,
                            position: ["none", "bottomCenter"],
                            total: totalUnitResidentCount,
                            onChange: (page, pageSize) => {
                                changeUnitResidentTablePage(page, pageSize)
                            }
                        }}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Residents"/>}}
                        onRow={(record) => {
                            return {
                                onClick: event => {
                                    props.startCreatingPostWith({
                                        receivableType: "UnitResident",
                                        receivable: record.node
                                    })
                                }
                            };
                        }}
                    />
                }
            </Row>
        </>
    )
}

export default ResidentsList;