import React, { useEffect, useState, useContext } from "react";
import {Row, Col, Tabs, Drawer} from "antd";
import ResidentsList from "./engagement/ResidentsList";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import UnitsList from "./engagement/UnitsList";
import MainPostsTable from "./engagement/MainPostsTable";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import PostQueries from "@dwellpass-client/common/utils/constants/queries/PostQueries";
import PostDetail from "./engagement/PostDetail";
import PostForm from "./engagement/PostForm";
import LoadingContent from "../utility/LoadingContent";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const { TabPane } = Tabs;

const Engagement = (props) => {
    const [communityState, ] = useContext(CommunityContext);
    const [selectedPostState, setSelectedPostState] = useState({});
    const [refreshMainPostsTable, setRefreshMainPostsTable] = useState(0);
    const [creatingPostState, setCreatingPostState] = useState(false);
    const [creatingPostWith, setCreatingPostWith] = useState({receivableType: null, receivable: null});

    const [loadPost, { loading: loadingPost, refetch: refetchPost }] = useLazyQuery(gql(PostQueries.getPost), { notifyOnNetworkStatusChange: true, onCompleted: ( postData ) => { setSelectedPostState(postData.post) }, fetchPolicy: "network-only" })
    const [readPost, ] = useMutation(gql(PostQueries.readPost), { onCompleted: ( postData ) => { performRefreshMainPostsTable(); } });

    useEffect(() => {
        props.location.state && props.location.state.postDetails && startCreatingPostWith(props.location.state.postDetails);
        props.location.state && props.location.state.showPost && selectPost(props.location.state.showPost);
    },[]);

    const [drawerVisible, setDrawerVisible] = useState(false);

    const openDrawer = () => {
        setDrawerVisible(true);
    };
    const onDrawerClose = () => {
        setDrawerVisible(false);
        setTimeout(() => {
            clearSelectedPost();
            stopCreatingPost();
        }, 500);
    };

    const selectPost = (post) => {
        loadPost({ variables: { id: post.node.id } });
        markPostReadIfNecessary(post);
        openDrawer();
    }

    const markPostReadIfNecessary = (post) => {
        if(post.node.unread || post.node.unreadReplies) {
            readPost({ variables: { id: post.node.id, readerType: "CommunityManager", readerId: communityState.currentUserCommunityManager.id } });
        }
    }

    const performRefreshMainPostsTable = () => {
        setRefreshMainPostsTable(prev => prev + 1);
    }

    const clearSelectedPost = () => {
        setSelectedPostState({});
    }

    const reloadSelectedPost = () => {
        refetchPost();
    }

    const onPostFormSuccess = () => {
        performRefreshMainPostsTable();
        onDrawerClose();
    }

    const startCreatingPostWith = (details) => {
        setCreatingPostState(true);
        setCreatingPostWith(details);
        openDrawer();
    }

    const stopCreatingPost = () => {
        setCreatingPostState(false);
        setCreatingPostWith({type: null, receivable: null});
    }

    return (
        <>
            <Row gutter={10}>
                <Col span={16} style={{ borderRight: `2px solid ${Colors.monoLightGrey}` }}>
                    <MainPostsTable selectPost={selectPost} refreshMainPostsTable={refreshMainPostsTable} startCreatingPostWith={startCreatingPostWith} />
                </Col>
                <Col span={8}>
                    <Tabs type="card">
                        <TabPane tab="Residents" key="1">
                            <ResidentsList startCreatingPostWith={startCreatingPostWith} />
                        </TabPane>
                        <TabPane tab="Units" key="2">
                            <UnitsList startCreatingPostWith={startCreatingPostWith} />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Drawer
                title="Post Details"
                placement="right"
                width={720}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                {creatingPostState ?
                    <PostForm formSuccess={onPostFormSuccess} creatingPostWith={creatingPostWith} />
                :
                    loadingPost ?
                            <LoadingContent />
                            :
                            <PostDetail selectedPostState={selectedPostState} refetchPost={reloadSelectedPost} />

                }
            </Drawer>
        </>
    )
}

export default Engagement;