import React from "react";
import {Divider, Row, Col, Typography, Descriptions, Button, Card} from "antd";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import {
    ApartmentOutlined,
    CloseOutlined,
    FileOutlined,
    HomeOutlined,
    EyeOutlined,
    ContainerOutlined
} from "@ant-design/icons";

const { Title, Text } = Typography;

const ResourceDetail = (props) => {

    return(
        <>
            <Row gutter={10} style={{ padding: "24px 24px 0px 24px" }} justify="space-between">
                <Col span={22}>
                    <Row wrap={false}>
                        {props.selectedResourceState.name ?
                            <>
                                <Col>
                                    <ContainerOutlined style={{ marginRight: 10, fontSize: 30, color: Colors.brandPrimary }} />
                                </Col>
                                <Col>
                                    <Title level={3}>{props.selectedResourceState.name}</Title>
                                </Col>
                            </>
                            :
                            <>
                                <Col>
                                    <FileOutlined style={{ marginRight: 10, fontSize: 30, color: Colors.brandPrimary }} />
                                </Col>
                                <Col>
                                    <Title level={3}>{props.selectedResourceState.resourceFileName}</Title>
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
                <Col span={2}>
                    <CloseOutlined style={{ marginLeft: 10, fontSize: 20, color: Colors.monoMedLightGrey }} onClick={event => { props.clearSelectedResource() }} />
                </Col>
            </Row>
            <Divider></Divider>
            <div style={{ padding: "0px 24px 24px 24px" }}>
                {props.selectedResourceState.resourceFileUrl &&
                    <Row style={{ marginBottom: 15 }} align="middle" gutter={10}>
                        <Col style={{ marginBottom: 10 }}>
                            <a href={props.selectedResourceState.resourceFileUrl} target="_blank"><Button type="primary"><EyeOutlined />View/Download</Button></a>
                        </Col>
                    </Row>
                }
                {(props.selectedResourceState.name && props.selectedResourceState.resourceFileName) &&
                    <Row style={{marginBottom: 15}}>
                        <><FileOutlined style={{marginRight: 10, fontSize: 20, color: Colors.brandPrimary}}/><Text>{props.selectedResourceState.resourceFileName}</Text></>
                    </Row>
                }
                <Row style={{ marginBottom: 15 }} align="middle">
                {props.selectedResourceState.resourceable ?
                        <><HomeOutlined style={{marginRight: 10, fontSize: 18, color: Colors.brandPrimary}}/><Text>Unit Resource</Text></>
                        :
                        <><ApartmentOutlined style={{marginRight: 10, fontSize: 18, color: Colors.brandPrimary}}/><Text>Community Resource</Text></>
                }
                </Row>
                {props.selectedResourceState.resourceFileName ?
                    <Descriptions column={1}>
                        <Descriptions.Item label="Description">{props.selectedResourceState.description ? props.selectedResourceState.description : "N/A"}</Descriptions.Item>
                    </Descriptions>
                    :
                    <Card>
                        <Row justify="center">
                            <Text strong style={{ fontSize: 32 }}>{props.selectedResourceState.description}</Text>
                        </Row>
                    </Card>
                }
            </div>
        </>
    )
}

export default ResourceDetail;