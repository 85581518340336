import React, { useContext, useState, useEffect } from "react";
import {Button, Col, Form, Row, Typography, Space, Select, Card, Divider} from "antd";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import CommunityQueries from "@dwellpass-client/common/utils/constants/queries/CommunityQueries";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";
import PrimaryInput from "../shared/PrimaryInput";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import FeeQueries from "@dwellpass-client/common/utils/constants/queries/FeeQueries";
import {SettingsContext} from "@dwellpass-client/common/contexts/SettingsContext";

const { Text, Title, Paragraph } = Typography;

const DuesSetupStep = (props) => {
    const defaultDuesValueState = 100;

    const [formState, setFormState] = useState({isSubmitting: false, errorMessage: null});
    const [duesValueState, setDuesValueState] = useState(defaultDuesValueState);
    const [communityState, updateCommunityState] = useContext(CommunityContext);
    const [settingsState, _] = useContext(SettingsContext);
    const [form] = Form.useForm();

    const [createFee, ] = useMutation(gql(FeeQueries.createFee));
    const [loadCommunity, { data: communityData }] = useLazyQuery(gql(CommunityQueries.getCommunity), { fetchPolicy: "network-only" });

    const handleFormSubmit = async (values) => {
        try {
            setFormState({isSubmitting: true, errorMessage: null});

            !hasHoaDuesFees() && await createFee({
                variables: {
                    communityId: communityState.id,
                    classification: "HOA Dues",
                    name: "HOA Dues",
                    priceCents: (values.duesAmount * 100),
                    recurring: values.duesInterval,
                    unitDefault: true,
                    payoutAccountId: communityState.payoutAccounts[0].id
                }});

            await loadCommunity({ variables: { id: communityState.id } });
            setFormState({isSubmitting: false, errorMessage: null});
        }
        catch (e) {
            setFormState({isSubmitting: false, errorMessage: e.message});
        }
    }

    const hasHoaDuesFees = () => {
        return communityState.hoaDuesFees.length >= 1;
    }

    useEffect(() => {
        communityData && communityData.community && updateCommunityState({type: "set", payload: communityData.community});
        communityData && communityData.community && props.goToNextStep();
    }, [communityData]);

    const getSettingValue = (settingVar) => {
        const setting = settingsState.find(setting => setting.var === settingVar);
        return setting ? setting.value : 0;
    }

    return (
        <>
            <Form form={form}
                  layout="vertical"
                  hideRequiredMark
                  onFinish={handleFormSubmit}
                  initialValues={{
                      ["duesAmount"]: hasHoaDuesFees() ? (communityState.hoaDuesFees[0].priceCents / 100) : 100,
                      ["duesInterval"]: hasHoaDuesFees() ? communityState.hoaDuesFees[0].recurring : "monthly"
                  }}
            >
                {formState.errorMessage && (
                    <Text type="danger">{formState.errorMessage}</Text>
                )}
                <Row>
                    <Col span={24}>
                        <Space direction="vertical" align="center" size="large">
                            <Row justify="center">
                                <Col span={20} style={{textAlign: "center"}}>
                                    <Text>We are committed to clear and transparent pricing on our platform, ensuring you always understand any associated fees. See the information breakdown below.</Text>
                                </Col>
                            </Row>
                            <Row gutter={24} align="middle">
                                <Col span={16}>
                                    <Title level={4}>Payment Processing Fee:</Title>
                                    <Paragraph style={{marginBottom: "0px"}}>• This fee is charged to Residents when making payments and covers costs associated with digital payment processing via Stripe.
                                    <br/>• The fee varies based on the payment method chosen, as indicated to the right.
                                    <br/>• We accept payments via credit/debit cards, bank accounts, and also recognize payments marked as "paid out of band". This is when you opt to accept a payment off-platform, through methods like cash or check.</Paragraph>
                                </Col>
                                <Col span={8}>
                                    <Card style={{ textAlign: "center", boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)" }}>
                                        <Title level={4} style={{color: Colors.brandQuaternary}}>Based on HOA Dues:</Title>
                                        <Text strong style={{ marginTop: "22px", fontSize: "14px", textAlign: "center", color: Colors.monoMedGrey }}>Credit Card ({getSettingValue("card_service_fee_percentage")}%):</Text><br />
                                        <Text strong style={{ fontSize: "20px", color: Colors.mainGreenDark }}>${(parseFloat(duesValueState * getSettingValue("card_service_fee_percentage")) / 100).toFixed(2)}</Text><br />
                                        <Text strong style={{ marginTop: "22px", fontSize: "14px", textAlign: "center", color: Colors.monoMedGrey }}>Bank Account ({getSettingValue("bank_account_service_fee_percentage")}%):</Text><br />
                                        <Text strong style={{ fontSize: "20px", color: Colors.mainGreenDark }}>${(parseFloat(duesValueState * getSettingValue("bank_account_service_fee_percentage")) / 100).toFixed(2)}</Text><br />
                                        <Text strong style={{ marginTop: "22px", fontSize: "14px", textAlign: "center", color: Colors.monoMedGrey }}>Out of Band ({getSettingValue("out_of_band_service_fee_percentage")}%):</Text><br />
                                        <Text strong style={{ fontSize: "20px", color: Colors.mainGreenDark }}>${(parseFloat(duesValueState * getSettingValue("out_of_band_service_fee_percentage")) / 100).toFixed(2)}</Text>
                                    </Card>
                                </Col>
                            </Row>
                            <Row gutter={24} align="middle">
                                <Col span={16}>
                                    <Title level={4}>Platform Fee:</Title>
                                    <Text>• For the features and convenience our platform offers, we charge a fixed fee of $3 per active unit each month.</Text>
                                </Col>
                                <Col span={8}>
                                    <Card style={{textAlign: "center", boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)"}}>
                                        <Title level={4} style={{color: Colors.brandQuaternary}}>Based on {communityState.communityStatedUnitCount} Units:</Title>
                                        <Text strong style={{ fontSize: "24px", color: Colors.mainBlue }}>${((getSettingValue("default_monthly_active_unit_application_fee_cents") * communityState.communityStatedUnitCount) / 100).toFixed(2)} month</Text>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Divider />
                                    <Text strong>To get started, input your standard dues rate. You can view the estimated fees above.</Text>
                                    <Row style={{ marginTop: 20 }} gutter={16} justify="center" align="middle">
                                        <Col>
                                            <Text strong style={{ fontSize: 20 }}>Community HOA Dues:</Text>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                name="duesAmount"
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <PrimaryInput
                                                    type="numeric"
                                                    size="large"
                                                    min={1}
                                                    max={9999}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    onChange={(value) => setDuesValueState(value || 0)}
                                                    disabled={hasHoaDuesFees()}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                name="duesInterval"
                                            >
                                                <Select
                                                    style={{ width: 120 }}
                                                    size="large"
                                                    defaultValue="monthly"
                                                    disabled={hasHoaDuesFees()}
                                                >
                                                    <Select.Option value="monthly">Monthly</Select.Option>
                                                    <Select.Option value="quarterly">Quarterly</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 25}} justify="center">
                    <Col>
                        <Button style={{margin: '0 8px'}} onClick={() => props.goBackAStep()}>
                            Go Back
                        </Button>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button type="primary" style={{margin: '0 8px'}} htmlType="submit" loading={formState.isSubmitting}>
                                Continue
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DuesSetupStep;