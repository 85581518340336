import React from 'react';
import logoImageBrand from "../../assets/images/dwellPass-logo-red-800w.png";
import logoImageMono from "../../assets/images/dwellPass-logo-white-800w.png";
import emblemLogoMono from "../../assets/images/dwellPass-logo-white-emblem-400w.png";
import betaLogoImageBrand from "../../assets/images/dwellPass-logo-beta-red-800w.png";
import betaLogoImageMono from "../../assets/images/dwellPass-logo-beta-white-800w.png";
import betaEmblemLogoMono from "../../assets/images/dwellPass-logo-beta-white-emblem-400w.png";

const logoImage = (type, variant, beta = false) => {
    switch(type) {
        case "mono":
            switch(variant) {
                case "full":
                    return (beta ? betaLogoImageMono : logoImageMono);
                case "emblem":
                    return (beta ? betaEmblemLogoMono : emblemLogoMono);
            }
        case "brand":
            switch(variant) {
                case "full":
                    return (beta ? betaLogoImageBrand : logoImageBrand);
            }
    }
}

export {
    logoImage
};