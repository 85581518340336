import React, { useContext } from "react";
import {Avatar, Button, Comment, Form, Input, notification, Switch, Row, Col} from "antd";
import {InfoCircleOutlined, SendOutlined, UserOutlined} from "@ant-design/icons";
import {gql, useMutation} from "@apollo/client";
import PostReplyQueries from "@dwellpass-client/common/utils/constants/queries/PostReplyQueries";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import {CommunityContext} from "@dwellpass-client/common/contexts/CommunityContext";

const PostReplyForm = (props) => {
    const [createPostReply, { loading: loadingPostReply }] = useMutation(gql(PostReplyQueries.createPostReply));
    const [userState, _] = useContext(UserContext);
    const [communityState, ] = useContext(CommunityContext);
    const [form] = Form.useForm();

    const handleReplySubmit = async (values) => {
        try {
            await createPostReply({ variables: { postId: props.selectedPostState.id, replyableType: "CommunityManager", replyableId: communityState.currentUserCommunityManager.id, hidePoster: values.hidePoster, body: values.body } });
            form.resetFields();
            props.postReplyCreationSuccess();
        } catch (e) {
            notification['error']({
                message: 'Error Creating Reply',
                description:
                    'Something went wrong. Please try again.',
            });
        }
    };

    return(
        <Comment
            avatar={<Avatar style={{ marginLeft: 10, marginRight: 20 }} icon={<UserOutlined />} src={userState.profileImageUrl && userState.profileImageUrl} />}
            content={
                <Form form={form}
                      hideRequiredMark
                      initialValues={{ hidePoster: communityState.maskPostCreatorByDefault }}
                      onFinish={handleReplySubmit}
                >
                    <Form.Item
                        name="body"
                        rules={[{ required: true, message: 'Please enter the body' }]}
                    >
                        <Input.TextArea
                            rows={2}
                        />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    loading={loadingPostReply}
                                    type="primary"
                                >
                                    <SendOutlined /> Reply
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="hidePoster"
                                label="Mask Creator"
                                tooltip={{ title: "If enabled, this reply will appear to come from the Community, hiding the creator details.", icon: <InfoCircleOutlined /> }}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            }
        />
    )
}

export default PostReplyForm;