import React, { useContext } from 'react';
import SubscriptionStatusCard from "./SubscriptionStatusCard";
import {Divider, Row, Col, Space, Typography, Card, Result} from "antd";
import {CheckCircleOutlined, PlusCircleOutlined, ClockCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {UnitContext} from "@dwellpass-client/common/contexts/UnitContext";
import {UserContext} from "@dwellpass-client/common/contexts/UserContext";
import Colors from "@dwellpass-client/common/utils/constants/Colors";
import PaymentMethod from "./PaymentMethod";

const { Text } = Typography;

const PaymentProfile = (props) => {
    const [unitState,] = useContext(UnitContext);
    const [userState,] = useContext(UserContext);

    const subsAlreadyManagedBy = () => {
        return unitState.activeSubscriptions.length !== 0 && (unitState.activeSubscriptions[0].subscriber.resident.id !== userState.id) ? unitState.activeSubscriptions[0].subscriber.resident : false;
    }

    return (
        <>
            <Divider>Payment Profile</Divider>
            <Row gutter={16} wrap={false}>
                {subsAlreadyManagedBy() ?
                    <Col span={24}>
                        <Row justify="center">
                            <Col>
                                <Result
                                    icon={<CheckCircleOutlined style={{color: Colors.brandPrimary, fontSize: 50}}/>}
                                    subTitle={`Payments managed by another resident: ${subsAlreadyManagedBy().firstName} ${subsAlreadyManagedBy().lastName}`}
                                />
                            </Col>
                        </Row>
                    </Col>
                    :
                    <>
                        <Col>
                            <PaymentMethod
                                defaultSource={props.defaultSource}
                                defaultPaymentMethodId={props.defaultPaymentMethodId}
                                defaultPaymentMethod={props.defaultPaymentMethod}
                                derivedPaymentMethodState={props.derivedPaymentMethodState}
                                loadingPaymentProfile={props.loadingPaymentProfile}
                                refreshStripeCustomer={props.performLoadStripeCustomer}
                                currentUserUnitResident={props.currentUserUnitResident}
                            />
                        </Col>
                        {(props.derivedPaymentMethodState === "none" || props.derivedPaymentMethodState === "unverifiedSource") ?
                            !props.loadingPaymentProfile &&
                                <Col>
                                    <Card title="Payment Setup">
                                        <Space direction="vertical">
                                            <Text><PlusCircleOutlined
                                                style={{color: Colors.brandPrimary, fontSize: 16, marginRight: 10}}/>First,
                                                you'll add the bank account you'd like to use for payment.</Text>
                                            <Text><ClockCircleOutlined
                                                style={{color: Colors.brandPrimary, fontSize: 16, marginRight: 10}}/>From
                                                there we'll make two temporary deposits into your account (less than $1 each).
                                                This process can take a couple of days, but we'll remind you to come back.</Text>
                                            <Text><CheckCircleOutlined
                                                style={{color: Colors.brandPrimary, fontSize: 16, marginRight: 10}}/>Once
                                                you enter the amounts, you'll be able to complete the setup and pay any
                                                Community dues going forward.</Text>
                                        </Space>
                                    </Card>
                                </Col>
                            :
                            <>
                                {unitState.fees.length === 0 &&
                                    <Col>
                                        <Card title={<Text><InfoCircleOutlined style={{color: Colors.brandPrimary, fontSize: 20, marginRight: 10}}/>No Fees</Text>} style={{width: 300, minHeight: 150}}>
                                            <Text>No fees have been assigned to this unit.</Text>
                                        </Card>
                                    </Col>
                                }
                                {(unitState.monthlyFees.length !== 0 || !!unitState.activeMonthlySubscriptions[0]) &&
                                    <Col>
                                        <SubscriptionStatusCard type="monthly"
                                                                refreshInvoicesTable={() => props.refreshInvoicesTable()}
                                                                currentUserUnitResident={props.currentUserUnitResident}
                                                                defaultPaymentMethod={props.defaultPaymentMethod}
                                                                derivedPaymentMethodState={props.derivedPaymentMethodState}
                                        />
                                    </Col>
                                }
                                {(unitState.quarterlyFees.length !== 0 || !!unitState.activeQuarterlySubscriptions[0]) &&
                                    <Col>
                                        <SubscriptionStatusCard type="quarterly"
                                                                refreshInvoicesTable={() => props.refreshInvoicesTable()}
                                                                currentUserUnitResident={props.currentUserUnitResident}
                                                                defaultPaymentMethod={props.defaultPaymentMethod}
                                                                derivedPaymentMethodState={props.derivedPaymentMethodState}
                                        />
                                    </Col>
                                }
                            </>
                        }
                    </>
                }
            </Row>
        </>
    );
}

export default PaymentProfile;